import { Button, Chip, Grid, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IApiFighterFightTeam } from '../../Model/ApiTeamModel';
import { putFightGroupRanks } from '../../util/ApiFightgroup';
import { cancelFight } from '../../util/ApiFollowing';
import { IApiShiajoModel, TApiShiajosModel } from '../../util/ApiModel/ApiShiajoModel';
import { IApiCompetitionTreeModel } from '../../util/ApiModel/Following/ApiCompetitionTree';
import { moveFightgroupToShiaijo } from '../../util/ApiScheduller';
import { FightGroupNodeLabel } from '../Fight/FightGroupNodeLabel';
import { ConfirmModal } from '../Modal/ConfirmModal';
import { FightGroupScoreModalButton } from '../Modal/FightGroupScoreModalButton';
import { ShiaijoLabel } from '../Shiaijo/ShiaijoLabel';
import { ShiaijoSelect } from '../Shiaijo/ShiajoSelect';
import { TDispatchNotification } from '../Util/ErrorMessage';
import { useFollowingSelectRank } from './followingSelectRank';


export function FollowingArrayFightGroup(props: {
    fightGroup: IApiCompetitionTreeModel,
    teamList: Map<number, IApiFighterFightTeam>,
    shiaijoTableSchedule?: TApiShiajosModel,
    dispatchNotification: TDispatchNotification,
    reload: () => Promise<void>
}) {
    const { t, i18n } = useTranslation();
    const [comp, ranks] = useFollowingSelectRank(props.fightGroup, props.teamList);
    const [status, setStatus] = useState(props.fightGroup.status);
    const finishedFight = status === "completed";
    const readyFight = status === "ready";

    useEffect(() => {
        setStatus(props.fightGroup.status);
    }, [props.fightGroup.status])

    const fgShiajo = props.shiaijoTableSchedule?.find(s => s.fightGroups?.find(fg => fg.fightGroupNodeId === props.fightGroup.fightGroupNodeId));
    let chipColor: "success" | "primary" | "error" = "success";
    let chipVariant: undefined | "outlined" = undefined;
    switch (status) {
        case "ready":
            chipColor = "primary"
            break;
        case "completed":
            chipVariant = "outlined";
            break;
        case "uninstantiated":
            chipColor = "error";
            chipVariant = "outlined";
            break;
    }
    return <>
        <Paper elevation={3} style={{ /*minHeight: '200px',*/ padding: "10px", margin: "5px",  minWidth: '400px' } }>
            <Grid container rowSpacing={2}>
                <Grid item xs={6} className={"tree-fightgoup-nodelabel"}>
                    <FightGroupNodeLabel fg={props.fightGroup} />
                    {readyFight && <>
                        {fgShiajo && <>{t("label.word.shiaijo")} <ShiaijoLabel shiaijo={fgShiajo} /></>}
                        {fgShiajo === undefined && <>{t("label.scheduler.withoutShiaijo")} </>}
                    </>
                    }
                    <br /><Chip label={status} size='small' color={chipColor} variant={chipVariant} /><br />
                    <FightGroupScoreModalButton
                        fightGroupId={props.fightGroup.fightGroupNodeId}
                        nodeId={true}
                    />

                </Grid>


                <Grid item xs={6}>
                    {/* Rank */}
                    {readyFight && <Grid container xs={12}>
                        <Grid item xs={12} className={"tree-fightgoup-rank"}>
                            {comp}
                        </Grid>
                        <Grid item xs={12} className={"tree-fightgoup-rank"}>
                            <Button variant="contained" size='small' onClick={() => {
                                putFightGroupRanks(props.fightGroup.fightGroupNodeId, ranks, props.dispatchNotification).then(r => {
                                    if (r.succeed) {
                                        setStatus("completed");
                                        props.reload();
                                    }
                                });
                            }}>{t("action.following.forceranking")}</Button>
                        </Grid>
                    </Grid>}

                    {finishedFight && <Grid item xs={12} className={"tree-fightgoup-rank"}>
                        <Grid item xs={12}>
                            {t("label.word.ranking")} :
                        </Grid>
                        {props.fightGroup.ranking.map((fgid, k) => {
                            return <Grid item xs={12} key={k}>
                                {k + 1} - {props.teamList.get(fgid)?.name}
                            </Grid>
                        })}
                        <Grid item xs={12}>
                            <ConfirmModal label={t("action.following.cancelFight")} message={t("confirmation.following.cancelFight")} action={() => {
                                cancelFight(props.fightGroup.fightGroupNodeId, props.dispatchNotification).then(r => {
                                    if (r) props.reload();
                                })
                            }} />
                        </Grid>
                    </Grid>}

                    {/* Move shiaijo */}
                    {/* Shiaijo */}
                    {readyFight && <Grid spacing={2} item xs={6}>

                        <Grid item xs={12} className={"tree-fightgoup-moveshiaijo"}>
                            {props.shiaijoTableSchedule && <>
                                {t('action.fightgroup.moveFightGroup')}
                                <ShiaijoSelect
                                    shiaijos={props.shiaijoTableSchedule}
                                    onSelect={(shiaijo: IApiShiajoModel | undefined) => {
                                        if (shiaijo) {
                                            moveFightgroupToShiaijo(props.fightGroup.competitionId, shiaijo.externalId, props.fightGroup, props.dispatchNotification).then(r => {
                                                if (r) props.reload();
                                            })
                                        }
                                    }}
                                /></>}
                        </Grid>
                    </Grid>
                    }
                </Grid>
            </Grid>
        </Paper >
    </>
}