export interface IApiFighterModel {
  id: number;
  externalId:number;
  lastName: string;
  firstName: string;
  order: number;
  fightTeamId:number;
  nationality?: string;
}

export const noneFighter: IApiFighterModel = {
  id: 0,
  externalId: 0,
  lastName: "Aucun combatant",
  firstName: "Aucun combatant",
  order: 0,
  fightTeamId: 0
}

