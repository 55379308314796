import { loadConfigurationData, storeConfigurationData } from "../Competition/CompetitionConfiguration"

export interface ICompetitionFilter {
    place: string,
    date: string,//date exact filter
    dateFrom: Date | undefined,
}
export interface IConfiguration {
    filter: ICompetitionFilter
    withChrono: boolean,
    simplifiedAction: boolean,
    selectedCompetitionIds: Array<number>
}

export interface ICompetitionSelection {
    selectedCompetition: Array<number>
}

export const emptySelection: ICompetitionSelection = {
    selectedCompetition: []
}

export const emptyConfiguration: IConfiguration = {
    withChrono: true,
    simplifiedAction: false,
    filter: {
        place: "",
        date: "",
        dateFrom: undefined
    },
    selectedCompetitionIds: []
}

export function getConfiguration(): IConfiguration {
    let configurationStr = localStorage.getItem('configuration');
    let configurationStored: IConfiguration = configurationStr ? JSON.parse(configurationStr) : emptyConfiguration;
    return configurationStored;
}

export function storeConfiguration(newConfiguration: IConfiguration) {

    localStorage.setItem('configuration', JSON.stringify(newConfiguration));
}

//TODO: use cache and reload configuration if needed
export function getConfigurationSelection(selectionName?: string, nullIfUUndefined?: boolean): ICompetitionSelection|undefined {

    if (!selectionName) {
        return {
            selectedCompetition: loadConfigurationData().selectedCompetitionIds
        };
    }
    else {
        let configurationStr = localStorage.getItem(selectionName ?? 'selection');
        let configurationStored: ICompetitionSelection|undefined = configurationStr ? JSON.parse(configurationStr) : (nullIfUUndefined ? undefined : emptySelection);
        if (configurationStored) configurationStored.selectedCompetition = configurationStored?.selectedCompetition.filter((v, i, a) => a.indexOf(v) === i);
        return configurationStored;
    }
}


/**
 * @deprecated
 * @param newConfiguration 
 * @param selectionName 
 */
export function storeSelection(newConfiguration: ICompetitionSelection, selectionName?: string) {
    if (selectionName) {
        localStorage.setItem(selectionName, JSON.stringify(newConfiguration));
    }
    else {
        //localStorage.setItem(selectionName ?? 'selection', JSON.stringify(newConfiguration));
        storeConfigurationData({
            ...loadConfigurationData(),
            selectedCompetitionIds: newConfiguration.selectedCompetition
        })
    }
}

/*
export async function reloadSelection(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, selectionName?: string, nullIfUUndefined?: boolean): Promise<boolean> {
    const selection = getConfigurationSelection(selectionName, nullIfUUndefined);
    const newCompetition: IApiCompetitionModel = await getCompetition(dispatchNotification, competitionId);
    if (newCompetition) {
        const newSelection = [...selection.selectedCompetition];
        const previousCompetitionIndex = newSelection.findIndex(c => c.id === competitionId);

        if (previousCompetitionIndex >= 0) newSelection[previousCompetitionIndex] = newCompetition;
        else newSelection.push(newCompetition);
        storeSelection({
            ...selection,
            selectedCompetition: newSelection
        });
    }
    return (newCompetition !== undefined);
}*/

export function clearNamedSelections() {
    for (const ls of Object.keys(localStorage)) {
        if (ls !== "selection" && ls !== "configuration") {
            localStorage.removeItem(ls);
        }
    }
}