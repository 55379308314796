import { isValid, store } from "./CacheHandler";

var debug = require('debug')('ServiceTablesMinimalFrontend:ApiCall');

export interface IApiResult {
    succeed: boolean;
}


export interface IApiError extends IApiResult {
    errorMessage: string;
    errorLabel: string;
    errorCode: number;
    stack?: any;
    status: number;
}

export function api<T extends IApiResult>(
    request: RequestInfo,
    failOnError: boolean=false,
    checkJson: boolean=true,
    timeout: number=10000
): Promise<T | IApiError> {

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    //make the request
    return fetch(request, {
        signal: controller.signal  
    }).then(response => {
        clearTimeout(id);
        //error case with message
        if (!response.ok) {
            
            debug("KO : " + JSON.stringify(response));
            let result = response.json().then((data) => {
                console.log(data);
                return Object.assign({
                    errorMessage: "API Error : " + data.detail,
                    errorLabel: "API Error",
                    errorCode: 0,
                    status: 0,
                    succeed: false
                }, data);
            }).catch((error) => {
                throw new Error("error.label.incorrectJsonAndKO");
            })
            if (failOnError){
                throw result;
             }
             return result;
        }
        else {
            
            debug("OK : " + JSON.stringify(response.body));
            return response.text().then(dataRaw => {
                if (! checkJson){
                    return ({ succeed: true, data:dataRaw}); 
                }
                let data = null;
                if (dataRaw != null && dataRaw.length > 0) {
                    data = JSON.parse(dataRaw)
                }
                debug("JSON : " + JSON.stringify(data));
                return ({ succeed: true, data:data}); 
            }).catch((error) => {
                throw new Error("error.label.incorrectJsonAndOk");
            })
        }
    }).catch((error: Error) => {
        clearTimeout(id);
        let result = {
            errorMessage: error.message,
            errorLabel: error.message,
            errorCode: 0,
            stack: error.stack,
            status: 0,
            succeed: false
        }
        if (failOnError){
            throw result;
         }
         return result;
    });
    
}


export async function apidelete<T extends IApiResult>(
    path: string,
    host: string = (window as any)['REACT_APP_API_URL'] ? (window as any)['REACT_APP_API_URL'] : "localhost",
    args: RequestInit = { method: "delete", headers: { 'Content-Type': 'application/json' }},
    token?: string
): Promise<T | IApiError> {
    //@ts-ignore
    args.headers = Object.assign(args.headers, {'Authorization' : 'Bearer ' + await cnkdrSsoGetIdToken()});
    debug("Fetch api delete : " + JSON.stringify(path));
    return api<T>(new Request(host + path, args));
};

export async function apiget<T extends IApiResult>(
    path: string,
    host: string = (window as any)['REACT_APP_API_URL'] ? (window as any)['REACT_APP_API_URL'] : "localhost",
    args: RequestInit = { method: "get", headers: { 'Content-Type': 'application/json' }},
    cacheInterval: number = 0,
    token?: string,
    checkJson?: boolean
): Promise<T | IApiError> {
    //args.headers = Object.assign(args.headers, {'Authorization' : 'Bearer ' + await cnkdrSsoGetIdToken()});
    let result:T | IApiError;
    let cacheKey = host + path;
    if (cacheInterval){
        let cached = isValid(cacheKey, cacheInterval);
        if (cached.isValid && cached.value){
            debug(`Use Cache for ${cacheKey}`)
            result = JSON.parse(cached.value);
            return result;
        }
    }
    
    args.headers = Object.assign(args.headers as any, {
        //@ts-ignore
        'Authorization' : 'Bearer ' + (token ? token : await cnkdrSsoGetIdToken()),
        "Cache-Control" : "no-cache, no-store, must-revalidate",
        "Pragma": "no-cache",
        "Expires": "0"
    });
    debug("Fetch api get : " + JSON.stringify(path));
    result = await api<T>(new Request(cacheKey, args), undefined, checkJson);
    if (cacheInterval && result.succeed){
        store(cacheKey, JSON.stringify(result));
        debug(`Store Cache for ${cacheKey}`)
    }
    return result;
};

export async function apipatch<T extends IApiResult>(
    path: string,
    body: any,
    host: string = (window as any)['REACT_APP_API_URL'] ? (window as any)['REACT_APP_API_URL'] : "localhost",
    args: RequestInit = { method: "PATCH", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body, function(k, v) { return v === undefined ? null : v; }) },
    token?: string
): Promise<T | IApiError> {
    args.headers = Object.assign(args.headers as any, {
        //@ts-ignore
        'Authorization' : 'Bearer ' +  (token ? token : await cnkdrSsoGetIdToken()),
        "Cache-Control" : "no-cache, no-store, must-revalidate",
        "Pragma": "no-cache",
        "Expires": "0"
    });
    debug("Fetch api post : " + JSON.stringify(path));
    return api<T>(new Request(host + path, args));
};

export async function apipost<T extends IApiResult>(
    path: string,
    body: any,
    host: string = (window as any)['REACT_APP_API_URL'] ? (window as any)['REACT_APP_API_URL'] : "localhost",
    args: RequestInit = { method: "post", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body, function(k, v) { return v === undefined ? null : v; }) },
    token?: string
): Promise<T | IApiError> {
    args.headers = Object.assign(args.headers as any, {
        //@ts-ignore
        'Authorization' : 'Bearer ' + (token ? token : await cnkdrSsoGetIdToken()),
        "Cache-Control" : "no-cache, no-store, must-revalidate",
        "Pragma": "no-cache",
        "Expires": "0"
    });
    debug("Fetch api post : " + JSON.stringify(path));
    return api<T>(new Request(host + path, args));
};

export async function apiput<T extends IApiResult>(
    path: string,
    body: any,
    host: string = (window as any)['REACT_APP_API_URL'] ? (window as any)['REACT_APP_API_URL'] : "localhost",
    args: RequestInit = { method: "put", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) },
    token?: string
): Promise<T | IApiError> {
    args.headers = Object.assign(args.headers as any, {
        //@ts-ignore
        'Authorization' : 'Bearer ' +  (token ? token : await cnkdrSsoGetIdToken()),
        "Cache-Control" : "no-cache, no-store, must-revalidate",
        "Pragma": "no-cache",
        "Expires": "0"
    });
    debug("Fetch api put : " + JSON.stringify(path));
    return api<T>(new Request(host + path, args));
};

export function manageApiError<T extends IApiResult>(result: T | IApiError): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        if (!result.succeed) {
            debug("Api Call failed");
            reject(result);
        }
        else {
            
            debug("Api Call succeed");
            resolve(result as T);
        }
    });
}
