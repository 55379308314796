import { Alert, Box, Button, Container, FormControl, FormGroup, FormLabel, Grid, MenuItem, Modal, Paper, Select, Tab, Tabs, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import produce from 'immer';
import React, { useCallback, useEffect, useState } from 'react';
import { TConfigurationAdvancedService, getShiajos, updateCompetitionAdvanced } from '../../Backend/ApiCompetition';
import { EventForm } from "../../EventComponent/Event/EventForm";
import { EventProcess } from '../../EventComponent/Event/EventProcess';
import { EventUsers } from '../../EventComponent/Event/EventUsers';
import { ShiaijosDataGrid } from '../../EventComponent/Shiaijos/ShiaijosDataGrid';
import { IApiCompetitionModel, IApiEditCompetitionModel, IServiceCompetitionAdvancedOrdonancementRules, IServiceCompetitionAdvancedTableRules, OrdonancementDispatchRules } from '../../Model/ApiCompetitionModel';
import { getCompetition } from '../../util/ApiCompetition';
import { IAuthentication } from '../Auth/auth';
import { IShiaijoUpdateValues, advancedConfiguration } from '../Competition/CompetitionModal';
import { putApiObject } from '../Operation/GenericOperation';
import { IConfiguration } from '../Util/Configuration';
import { IServiceTableNotification } from '../Util/ErrorMessage';
import { ITableCompetitionFilter } from './TableCompetitions';

const debug = require('debug')('ServiceTablesMinimalFrontend:competitionEdit')

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '15%',
    left: '15%',
    //transform: 'translate(-15%, -15%)',
    width: '70%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
};

const style = {
    position: 'absolute' as 'absolute',
    top: '10%',
    left: '10%',
    //transform: 'translate(-90%, -90%)',
    width: document.body.clientWidth * 0.8,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
    height: 700,
};



const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export type TCreateCompetitionProps = false | {
    type: "competition" | "group",
    parentId?: number
};

export interface ICompetitionEditResult {
    selectCompetition: (competition: IApiCompetitionModel | undefined) => void,
    createCompetition: (createCompetition: TCreateCompetitionProps) => void,
    updateCompetitionTableAdvanced: (competitionId: number, attribute: keyof IServiceCompetitionAdvancedTableRules, value: string) => void,
    modal: JSX.Element
}


export function useCompetitionEdit(
    competitionConfiguration: IConfiguration
    , shiaijoCompetitionFilter: ITableCompetitionFilter
    , dispatchNotification: React.Dispatch<IServiceTableNotification>
    , onCreated: (c:IApiEditCompetitionModel | undefined) => void
    , onEdited: (c:IApiEditCompetitionModel | undefined) => void
    , authentication: IAuthentication): ICompetitionEditResult {

    const [selectedCompetition, setSelectedCompetition] = useState<IApiCompetitionModel | undefined>(undefined);//Selected competition to edit
    const [createCompetition, setCreateCompetition] = useState<TCreateCompetitionProps>(false);//Competition Creation
    const [selectedModalTab, setSelectedModalTab] = useState<number>(0);//Selected modal tab
    const selectedRule = (selectedCompetition && selectedCompetition.advancedOrdonancement && selectedCompetition.advancedOrdonancement["dispatchRule"]) ? String(selectedCompetition.advancedOrdonancement["dispatchRule"]) : "balance_next_tree_part";

    const [shiaijoUpdateValues, setShiaijoUpdateValues] = useState<IShiaijoUpdateValues>({
        shiaijoMessage: "",
        shiaijoDisplayMode: "standard",
        resultMessage: ""
    }) //values for shiaijo update

    const [parentGroup, setParentGroup] = useState<IApiCompetitionModel | undefined>(undefined);

    /**selectedCompetition.parentId
         * Clear when selected competition change
         */
    useEffect(() => {
        setShiaijoUpdateValues({
            shiaijoMessage: "",
            shiaijoDisplayMode: "standard",
            resultMessage: ""
        })
    }, [selectedCompetition]);

    useEffect(() => {
        if (selectedCompetition?.parentId) {
            getCompetition(dispatchNotification, selectedCompetition?.parentId).then((c) => {
                setParentGroup(c);
            })
        }
        else {
            setParentGroup(undefined);
        }
    }, [dispatchNotification, selectedCompetition])

    let fupdateCompetitionAdvanced = useCallback((competitionId: number,
        advanced: keyof IApiCompetitionModel,
        attribute: keyof IServiceCompetitionAdvancedTableRules | keyof IServiceCompetitionAdvancedOrdonancementRules
        , value: any, service: TConfigurationAdvancedService) => {


        if (selectedCompetition) {
            const newSelectedCompetition = produce(selectedCompetition, draft => {
                if (draft[advanced] === undefined) {
                    //@ts-ignore
                    draft[advanced] = {}
                }
                let a = draft[advanced] as any
                if (selectedCompetition) {
                    debug(`Set attribute value for competition ${competitionId} : ${attribute} = ${value}`)
                    if (value !== undefined) a[attribute] = value;
                    else delete a[attribute];
                }
            });

            updateCompetitionAdvanced(dispatchNotification, newSelectedCompetition, service, newSelectedCompetition[advanced]).then(() => {
                /*//update child in memory
                const childs = newCompetitionOptionList.filter(c => c.parentId === competitionId);
                for (const child of childs) {
                    child.advancedOrdonancementParent = selectedCompetition?.advancedOrdonancement;
                    child.advancedTablesParent = selectedCompetition?.advancedTables;
                }
                debug(newCompetitionOptionList);*/
                //TODO : refaire reload propre
                setSelectedCompetition(newSelectedCompetition);
            })
        }
    }, [dispatchNotification, selectedCompetition]);

    let updateCompetitionTableAdvanced = useCallback((competitionId: number, attribute: keyof IServiceCompetitionAdvancedTableRules, value: string) => {
        fupdateCompetitionAdvanced(competitionId, 'advancedTables', attribute, value, "ServicesTables")
    }, [fupdateCompetitionAdvanced]);

    let updateCompetitionOrdonancementAdvanced = useCallback((competitionId: number, attribute: keyof IServiceCompetitionAdvancedOrdonancementRules, value: string) => {
        fupdateCompetitionAdvanced(competitionId, 'advancedOrdonancement', attribute, value, "ServiceOrdonancement")
    }, [fupdateCompetitionAdvanced]);

    return {
        selectCompetition: (competition: IApiCompetitionModel | undefined) => {
            setSelectedCompetition(competition);
        },
        createCompetition: (createCompetition: TCreateCompetitionProps) => {
            setCreateCompetition(createCompetition)
        },
        updateCompetitionTableAdvanced: updateCompetitionTableAdvanced,
        modal: <Modal
            open={selectedCompetition !== undefined || createCompetition !== false}
            onClose={(event) => {
                setSelectedCompetition(undefined);
                setCreateCompetition(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle} id="modal-box">
                {/* FORM CREATE */}
                {createCompetition !== false && <Grid item xs={12}>
                    <EventForm
                        type={createCompetition.type}
                        parentId={createCompetition.parentId}
                        competition={undefined}
                        dispatchNotification={dispatchNotification}
                        onChange={(c) => {
                            setCreateCompetition(false);
                            onCreated(c);
                        }}
                        onCancel={() => {
                            setCreateCompetition(false);
                        }}
                    />
                </Grid>}
                {/* FORM EDIT COMPETITION */}
                {selectedCompetition && <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectedModalTab} onChange={(event: React.SyntheticEvent, newValue: number) => {
                            setSelectedModalTab(newValue);
                        }} aria-label="basic tabs example">
                            <Tab label="Configuration" {...a11yProps(0)} />
                            <Tab label="Shiaijos" {...a11yProps(1)} />
                            <Tab label="Roles" {...a11yProps(2)} />
                            <Tab label="Rules" {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                    <Grid container spacing={2}>
                        {selectedModalTab === 0 && <Grid container item xs={12}>
                            <Grid item xs={12} id="modal-btn-top">
                                <EventProcess
                                    currentStatus={selectedCompetition.status}
                                    dispatchNotification={dispatchNotification}
                                    event={selectedCompetition}
                                    onChange={() => {
                                        setSelectedCompetition(undefined);
                                        onEdited(undefined);
                                    }}
                                    small={false}
                                />
                            </Grid>
                            <EventForm competition={selectedCompetition} dispatchNotification={dispatchNotification}
                                onChange={(c) => {
                                    setSelectedCompetition(undefined);
                                    onEdited(c);
                                }}
                                onCancel={(() => {
                                    setSelectedCompetition(undefined);
                                })}
                            />
                        </Grid>}


                        {selectedModalTab === 1 && selectedCompetition && <Grid container item xs={12}>
                            {/* SHIAIJOS */}
                            <ShiaijosDataGrid
                                authentication={authentication}
                                competition={selectedCompetition}
                                competitionFilter={shiaijoCompetitionFilter}
                                dispatchNotification={dispatchNotification}
                                group={parentGroup}
                            />
                        </Grid>}

                        {/* USERS */}
                        {selectedModalTab === 2 && selectedCompetition && <Grid container item xs={12} rowSpacing={2}>
                            <EventUsers
                                competitionId={selectedCompetition.id}
                                eventDispatcher={dispatchNotification}
                            />
                        </Grid>}

                        {selectedModalTab === 3 && selectedCompetition && <Grid container item xs={12}>
                            {/* Rules */}
                            <Grid item container xs={12}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    {selectedCompetition.fullname}
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    <Container>
                                        <Paper elevation={3} className={"competition-config-paper"}>
                                            <Grid container >
                                                <Grid item xs={4}>
                                                    <FormLabel component="legend">Shiaijos configuration</FormLabel>
                                                    <TextField label={t("label.shiaijo.message")} onChange={(e) => {
                                                        setShiaijoUpdateValues({ ...shiaijoUpdateValues, shiaijoMessage: e.target.value })
                                                    }}
                                                        value={shiaijoUpdateValues.shiaijoMessage}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <p>{t("label.shiaijo.displayMode")}</p>
                                                    <Select onChange={(e) => {
                                                        setShiaijoUpdateValues({ ...shiaijoUpdateValues, shiaijoDisplayMode: e.target.value as "welcome" | "poolsTree" | "standard" | "closing" | "noFightEmpty" })

                                                    }}
                                                        value={shiaijoUpdateValues.shiaijoDisplayMode}
                                                        label={t("label.shiaijo.displayMode")}
                                                    >
                                                        <MenuItem value="welcome">{t("label.shiaijo.welcome")}</MenuItem>
                                                        <MenuItem value="poolsTree">{t("label.shiaijo.poolsTree")}</MenuItem>
                                                        <MenuItem value="standard">{t("label.shiaijo.standard")}</MenuItem>
                                                        <MenuItem value="noFightEmpty">{t("label.shiaijo.noFightEmpty")}</MenuItem>
                                                        <MenuItem value="closing">{t("label.shiaijo.closing")}</MenuItem>


                                                    </Select>
                                                </Grid>
                                                <Grid item xs={4}></Grid>
                                                <Grid item xs={2}></Grid>
                                                <Grid item xs={2}>
                                                    <Button variant='contained' onClick={() => {
                                                        const f = async () => {

                                                            //warning, get from competition service
                                                            const ss = await getShiajos(dispatchNotification,
                                                                selectedCompetition.id);

                                                            let succeed: boolean = ss.length > 0 ? true : false;
                                                            let message = ss.length > 0 ? "Updated succeed" : "No shiaijo to update";
                                                            for (const shiaijo of ss) {
                                                                //id from competition service is external id for table
                                                                const r = await putApiObject("/Shiajos", shiaijo.id, {
                                                                    displayMode: shiaijoUpdateValues.shiaijoDisplayMode,
                                                                    displayMessage: shiaijoUpdateValues.shiaijoMessage
                                                                }, { succeed: false }, dispatchNotification).then(res => {
                                                                    if (!res.succeed) {
                                                                        return {
                                                                            succeed: false,
                                                                            message: "Failed to update shiaijo " + shiaijo.id + " : " + shiaijo.fullname
                                                                        }
                                                                    }
                                                                    else {
                                                                        return {
                                                                            succeed: true,
                                                                            message: ""
                                                                        }
                                                                    }
                                                                })
                                                                succeed = succeed && r?.succeed;
                                                                if (!succeed) {
                                                                    message = r?.message;
                                                                }
                                                            }

                                                            setShiaijoUpdateValues({
                                                                ...shiaijoUpdateValues,
                                                                success: succeed,
                                                                resultMessage: message
                                                            })
                                                        };
                                                        f();
                                                    }
                                                    }>{t('label.shiaijo.apply')}</Button>
                                                </Grid>

                                            </Grid>
                                        </Paper>
                                        <Paper elevation={3} className={"competition-config-paper"}>
                                            <Grid item xs={12}>
                                                {/* {show.showSchedullerRules && <> */}
                                                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                                    <FormLabel component="legend">Distribution rules</FormLabel>
                                                    <FormGroup>
                                                        {advancedConfiguration(selectedCompetition.id, selectedCompetition.advancedOrdonancement, selectedCompetition.advancedOrdonancementParent, 'retainSemiFinal', updateCompetitionOrdonancementAdvanced, t, "boolean")}

                                                        {advancedConfiguration(selectedCompetition.id, selectedCompetition.advancedOrdonancement, selectedCompetition.advancedOrdonancementParent, 'retainFinal', updateCompetitionOrdonancementAdvanced, t, "boolean")}

                                                        {advancedConfiguration(selectedCompetition.id, selectedCompetition.advancedOrdonancement, selectedCompetition.advancedOrdonancementParent, 'retainTree', updateCompetitionOrdonancementAdvanced, t, "boolean")}

                                                        {advancedConfiguration(selectedCompetition.id, selectedCompetition.advancedTables, selectedCompetition.advancedTablesParent, 'resolveNone', updateCompetitionTableAdvanced, t, "boolean")}

                                                    </FormGroup>

                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>


                                                {t("label.competition.dispatchRule")}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Select
                                                    value={selectedRule}
                                                    label={t(`label.competition.advanced.dispatchRule`)}
                                                    onChange={(e: any) => {

                                                        updateCompetitionOrdonancementAdvanced(selectedCompetition.id, "dispatchRule", String(e.target.value));
                                                    }}
                                                >
                                                    {OrdonancementDispatchRules.map((i, k) => {

                                                        return <MenuItem key={k} value={i}>{t(`label.competition.advanced.dispatchRule_${i}`)}</MenuItem>
                                                    })}

                                                </Select>

                                                <Alert severity="info">{t(`label.competition.advanced.dispatchRule_${selectedRule}`)} : {t(`label.competition.advanced.dispatchRule_${selectedRule}_detail`)}</Alert>
                                            </Grid>
                                        </Paper>
                                        <Paper elevation={3} className={"competition-config-paper"}>

                                            <Grid item xs={12}>
                                                {t("label.competition.combatTime")} : <b>{selectedCompetition.combatTime}</b> (Configurée sur l'interface compétition, future évolution)
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                                    <FormLabel component="legend">Display modes</FormLabel>
                                                    <FormGroup></FormGroup>
                                                    {advancedConfiguration(selectedCompetition.id, selectedCompetition.advancedTables, selectedCompetition.advancedTablesParent, 'showCountry', updateCompetitionTableAdvanced, t, "boolean")}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {/* {show.showSchedullerRules && <> */}
                                                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                                    <FormLabel component="legend">Assign responsibility</FormLabel>
                                                    <FormGroup></FormGroup>
                                                    {advancedConfiguration(selectedCompetition.id, selectedCompetition.advancedTables, selectedCompetition.advancedTablesParent, 'canChangeBeforeFirstFight', updateCompetitionTableAdvanced, t, "boolean")}

                                                    {advancedConfiguration(selectedCompetition.id, selectedCompetition.advancedTables, selectedCompetition.advancedTablesParent, 'canChangeInPool', updateCompetitionTableAdvanced, t, "boolean")}

                                                    {advancedConfiguration(selectedCompetition.id, selectedCompetition.advancedTables, selectedCompetition.advancedTablesParent, 'canChangeInTree', updateCompetitionTableAdvanced, t, "boolean")}

                                                </FormControl>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={4}>
                                                    {advancedConfiguration(selectedCompetition.id, selectedCompetition.advancedTables, selectedCompetition.advancedTablesParent, 'defaultMaxScore', updateCompetitionTableAdvanced, t)}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {advancedConfiguration(selectedCompetition.id, selectedCompetition.advancedTables, selectedCompetition.advancedTablesParent, 'poolMaxScore', updateCompetitionTableAdvanced, t)}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {advancedConfiguration(selectedCompetition.id, selectedCompetition.advancedTables, selectedCompetition.advancedTablesParent, 'eliminationMaxScore', updateCompetitionTableAdvanced, t)}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {advancedConfiguration(selectedCompetition.id, selectedCompetition.advancedTables, selectedCompetition.advancedTablesParent, 'quarterFinalMaxScore', updateCompetitionTableAdvanced, t)}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {advancedConfiguration(selectedCompetition.id, selectedCompetition.advancedTables, selectedCompetition.advancedTablesParent, 'semiFinalMaxScore', updateCompetitionTableAdvanced, t)}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {advancedConfiguration(selectedCompetition.id, selectedCompetition.advancedTables, selectedCompetition.advancedTablesParent, 'finalMaxScore', updateCompetitionTableAdvanced, t)}
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Container>
                                </Typography>
                            </Grid>
                        </Grid>}
                    </Grid>
                </>}
            </Box>
        </Modal >
    }
}