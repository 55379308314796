import { getApiObject, postApiObject, putApiObject } from "../components/Operation/GenericOperation";
import { IServiceTableNotification } from "../components/Util/ErrorMessage";

export interface IDrawFightOrder {
    idFighterRed: number,
    idFighterWhite: number,
    numero: number,
    ordre: number,
    type: string
}

export interface IDrawRepartitionSimple {
    idFighter: number,
    numero: number,
    position: number,
}

export async function validTirage(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, host?: string, token?: string): Promise<any> {
    return putApiObject<{
        succeed: boolean,
        data: any
    }>('/validetirage', competitionId, undefined, {
        succeed: false,
        data: undefined
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_DRAW_URL'], undefined, token).then((result) => {
        return result.data;
    });
}

export async function getDrawRepartition(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, host?: string, token?: string): Promise<Array<IDrawRepartitionSimple>> {
    return getApiObject<{
        succeed: boolean,
        data: any
    }>('/readRepartitionSimple', competitionId, {
        succeed: false,
        data: undefined
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_DRAW_URL'], undefined, token).then((result) => {
        let data: Array<IDrawRepartitionSimple> = [];
        for (let key in result.data) {
            let item = result.data[key];
            data.push({
                idFighter: Number(item.idFighter),
                numero: Number(item.numero),
                position: Number(item.position)
            });
        }
        return data;
    });
}

export interface IDrawExport {
    tirage: {
        id: number,
        poule: number
        type: string,
        competition_id: number,
        nobody: number,
        ecart_pays: number,
        ecart_club: number,
        ecart_tete: number,
        taille_poule: number,
        valide: number
    }
    repartition: Array<IDrawRepartitionSimple>
}

export async function getDrawExport(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, host?: string, token?: string): Promise<IDrawExport> {
    return getApiObject<{
        succeed: boolean,
        data: any
    }>('/export', competitionId, {
        succeed: false,
        data: undefined
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_DRAW_URL'], undefined, token).then((result) => {
        let data: IDrawExport = {
            tirage: {
                id: Number(result.data.tirage.id),
                poule: Number(result.data.tirage.codetype),
                type: result.data.tirage.type,
                competition_id: Number(result.data.tirage.competition_id),
                nobody: Number(result.data.tirage.nobody),
                ecart_pays: Number(result.data.tirage.ecart_pays),
                ecart_club: Number(result.data.tirage.ecart_club),
                ecart_tete: Number(result.data.tirage.ecart_tete),
                taille_poule: Number(result.data.tirage.taille_poule),
                valide: Number(result.data.tirage.valide)
            },
            repartition: []
        };
        for (let key in result.data.repartition) {
            let item = result.data.repartition[key];
            data.repartition.push({
                idFighter: Number(item.idFighter),
                numero: Number(item.numero),
                position: Number(item.position)
            });
        }
        return data;
    });
}

export async function getDrawFightOrder(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, host?: string, token?: string): Promise<Array<IDrawFightOrder>> {
    return getApiObject<{
        succeed: boolean,
        data: any
    }>("/getFightsOrder", competitionId, {
        succeed: false,
        data: undefined
    }, dispatchNotification, host ?? (window as any)['REACT_APP_API_DRAW_URL'], undefined, token).then((result) => {
        let data: Array<IDrawFightOrder> = [];
        for (let key in result.data) {
            let item = result.data[key];
            data.push({
                idFighterRed: Number(item.idFighterRed),
                idFighterWhite: Number(item.idFighterWhite),
                numero: Number(item.numero),
                ordre: Number(item.ordre),
                type: String(item.type),
            });
        }
        return data;
    });
}


export async function importDraw(dispatchNotification: React.Dispatch<IServiceTableNotification>
    , competitionId: number
    , drawexport: IDrawExport
    , host?: string, token?: string
): Promise<any> {
    return postApiObject<{
        succeed: boolean,
        data: any,
        status: number
    }>("/import", Object.assign({},
        drawexport.tirage,
        {
            id: competitionId,
        },
        { repartition: drawexport.repartition })
        , {
            succeed: false,
            data: undefined,
            status: 400
        }, dispatchNotification, host ? host : (window as any)['REACT_APP_API_DRAW_URL'], undefined, token).then((result) => {
            /*let data: Array<IDrawFight> = [];
            for (let key in result.data) {
                let item = result.data[key];
                data.push({
                    idFighterRed: Number(item.idFighterRed),
                    idFighterWhite: Number(item.idFighterWhite),
                    numero: Number(item.numero),
                    ordre: Number(item.ordre),
                    type: String(item.type),
                });
            }*/
            console.log(result);
            return result.data;
        });
}

export async function invertRepartition(dispatchNotification: React.Dispatch<IServiceTableNotification>,
    competitionId: number, fighter1Id: number, fighter2Id: number
    , host?: string, token?: string) {
    return postApiObject<{
        succeed: boolean,
        data: any,
        status: number
    }>(`/invertFighters/${competitionId}/${fighter1Id}/${fighter2Id}`,
        {},
        {
            succeed: false,
            data: undefined,
            status: 400
        }, dispatchNotification, host ? host : (window as any)['REACT_APP_API_DRAW_URL'], undefined, token).then((result) => {
            return result;
        });
}

export async function refreshFighters(dispatchNotification: React.Dispatch<IServiceTableNotification>,
    competitionId: number
    , host?: string, token?: string) {
    return postApiObject<{
        succeed: boolean,
        data: any,
        status: number
    }>(`/refreshFighters/${competitionId}`,
        {},
        {
            succeed: false,
            data: undefined,
            status: 400
        }, dispatchNotification, host ? host : (window as any)['REACT_APP_API_DRAW_URL'], undefined, token).then((result) => {
            return result;
        });
}





