import React from "react";
import { useParams } from "react-router-dom";
import { FightCard } from "./FightCard";

export function FightGroupPage() {
    let params = useParams() as { fightGroupId: number | undefined };
    if (params.fightGroupId) {
        return <FightCard fightGroupId={params.fightGroupId} />
    }
    else {
        return <>toto</>
    }
}