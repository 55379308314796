import { AddCircle } from "@mui/icons-material";
import { Autocomplete, Box, Button, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Paper, Select, TextField } from "@mui/material";
import produce from "immer";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFightersTeams } from "../../Backend/ApiFighter";
import { IApiFighterFightTeam } from "../../Model/ApiTeamModel";
import { emptyApiFightCreationModel } from "../../util/ApiModel/ApiFightModel";
import { EFightGroupType, IApiFightGroupCreationModel, emptyApiFightGroupCreationModel } from "../../util/ApiModel/ApiFightgroupModel";
import { IApiShiajoModel } from "../../util/ApiModel/ApiShiajoModel";
import { useCompetitionsLoader } from "../Competition/CompetitionLoader";
import { addFightGroup } from "../Operation/ShiajoOperation";
import { IServiceTableNotification } from "../Util/ErrorMessage";
export interface IFigthGroupCreationModalProps {
    shiaijo: IApiShiajoModel,
    open: boolean,
    onClose: (created: boolean) => void,
    dispatchNotification: React.Dispatch<IServiceTableNotification>
}

export function FigthGroupCreationModal(props: IFigthGroupCreationModalProps) {
    const { t, i18n } = useTranslation();
    const [fightgroup, setFightgroup] = React.useState<IApiFightGroupCreationModel>(emptyApiFightGroupCreationModel);
    const [teamList, setTeamList] = useState<Array<IApiFighterFightTeam>>([]);

    const [competitions, loadCompetitions] = useCompetitionsLoader(props.dispatchNotification, props.shiaijo.competitionId, false);

    useEffect(() => {
        loadCompetitions(props.shiaijo.competitionId);
    }, [loadCompetitions, props.shiaijo.competitionId])

    useEffect(() => {
        setFightgroup(f => {
            const competitionId:number = competitions
                .filter(c => c && c.type === 'competition')[0]?.id ?? -1
            return {
                ...emptyApiFightGroupCreationModel,
                competitionId: competitionId
            }
        })
    }, [competitions, props.shiaijo.competitionId]);

    useEffect(() => {
        let display = true;
        if (fightgroup.competitionId !== undefined) {
            getFightersTeams(
                props.dispatchNotification,
                fightgroup.competitionId
            ).then((result) => {
                if (display) setTeamList(result);
            })
        } else {
            setTeamList([]);
        }
        return () => { display = false }
    }, [fightgroup.competitionId, props.dispatchNotification]);

    return <Dialog
        open={props.open}
        onClose={() => { props.onClose(false) }}
        fullWidth={true}
        maxWidth="lg"
    >
        <DialogTitle>Fight group creation on shiaijo {props.shiaijo.shortname}</DialogTitle>
        <DialogContent>
            <Box className="modalBox" sx={{ overflowY: "scroll" }}>
                <Paper elevation={5} style={{

                }}>

                    <Grid container xs={12}>
                        <Grid item xs={6} className="FightGroupCreationModal-label">
                            Select competition
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                value={fightgroup.competitionId}
                                onChange={(e) => {
                                    setFightgroup({
                                        ...emptyApiFightGroupCreationModel,
                                        competitionId: parseInt(e.target.value.toString())
                                    })
                                }}>
                                {competitions.filter(c => c && c.type === 'competition')
                                    .map((competition, index) => {
                                        return <MenuItem key={index} value={competition?.id}>{competition?.id} {competition?.fullname}</MenuItem>
                                    })}
                            </Select>

                        </Grid>
                        <Grid item xs={3} className="FightGroupCreationModal-label">
                            Fights
                        </Grid>
                        <Grid container item xs={6}>
                            {fightgroup.fights.map((fight, index) => {
                                return <React.Fragment key={index}>
                                    <Grid container item xs={12}>
                                        {t("label.word.red")}
                                        <Autocomplete
                                            disablePortal
                                            options={teamList}
                                            value={teamList.find(t => t.id === fight.redFightTeamExternalId)}
                                            onChange={(event: any, newValue: IApiFighterFightTeam | null) => {
                                                setFightgroup(produce(fightgroup, (draft) => {
                                                    draft.fights[index].redFightTeamExternalId = newValue?.id ?? 0
                                                }));
                                            }}
                                            getOptionLabel={(option) => {
                                                return `Team ${option.name}`
                                            }}
                                            renderInput={(params) => (
                                                <div ref={params.InputProps.ref}>
                                                    <input type="text" {...params.inputProps} />
                                                </div>
                                            )}
                                        />
                                        {t("label.word.versus")} {t("label.word.white")}
                                        <Autocomplete
                                            disablePortal
                                            options={teamList}
                                            value={teamList.find(t => t.id === fight.whiteFightTeamExternalId)}
                                            onChange={(event: any, newValue: IApiFighterFightTeam | null) => {
                                                setFightgroup(produce(fightgroup, (draft) => {
                                                    draft.fights[index].whiteFightTeamExternalId = newValue?.id ?? 0
                                                }));
                                            }}
                                            getOptionLabel={(option) => {
                                                return `Team ${option.name}`
                                            }}
                                            renderInput={(params) => (
                                                <div ref={params.InputProps.ref}>
                                                    <input type="text" {...params.inputProps} />
                                                </div>
                                            )}
                                        />
                                    </Grid>
                                </React.Fragment>
                            })}
                            <AddCircle onClick={() => {
                                setFightgroup(produce(fightgroup, (draft) => {
                                    draft.fights.push({
                                        ...emptyApiFightCreationModel
                                    })
                                    return draft;
                                }));
                            }} />
                        </Grid>
                        <Grid item xs={6} className="FightGroupCreationModal-label">
                            Type
                        </Grid>
                        <Grid item xs={6}>
                            <Select value={fightgroup.type}>

                                <MenuItem value={EFightGroupType.DIRECT_ELIMINATION.valueOf()}>Direct elimination</MenuItem>
                                <MenuItem value={EFightGroupType.POOL.valueOf()}>Pool</MenuItem>

                            </Select>
                        </Grid>
                        <Grid item xs={6} className="FightGroupCreationModal-label">
                            Label
                        </Grid>
                        <Grid item xs={6}>
                            <TextField value={fightgroup.label} onChange={(e) => {
                                setFightgroup({
                                    ...fightgroup,
                                    label: e.target.value
                                });
                            }} />
                        </Grid>
                        <Grid item xs={6} className="FightGroupCreationModal-label">
                            Qualifying

                        </Grid>
                        <Grid item xs={6}>

                            {<Select value={fightgroup.qualifying}>
                                {[...Array(3)].map((x, i) =>
                                    <MenuItem value={i + 1}>{i + 1}</MenuItem>
                                )}
                            </Select>}
                        </Grid>
                        <Grid item xs={6} className="FightGroupCreationModal-label">
                            Depth
                        </Grid>
                        <Grid item xs={6}>
                            {fightgroup.depth}
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    if (fightgroup.fights.length === 0){
                                        //TODO : faire plus propre
                                        alert(t("message.fightgroup.atLeastOneFight"))
                                    }
                                    else {
                                        addFightGroup(props.shiaijo.externalId, fightgroup, props.dispatchNotification).then((r) => {
                                            if (r) props.onClose(true);
                                        });
                                    }
                                    
                                }}
                            >{t('action.fightgroup.create')}</Button>
                        </Grid>
                    </Grid>

                </Paper>
            </Box>
        </DialogContent>
    </Dialog>
}