import { Tab, Tabs, TabsProps } from "@mui/material";
import React from "react";

import { styled } from "@mui/material/styles";

import { IApiShiajoModel } from "../../util/ApiModel/ApiShiajoModel";

export interface IShiajoSelection {
    shiajos: Array<IApiShiajoModel>,
    shiajoSelected: number | null
}

interface StyledTabProps {
    value: number,
    label: string
}

const AntTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        zIndex: 1,
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        flexShrink: 0,
        color: 'white',
        '&:hover': {
            color: '#FFF',
            fontWeight: 'bold',
            opacity: 1,
        },
        '&.Mui-selected': {
            color: 'black',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);

/*
interface StyledTabsProps {
    children?: React.ReactNode,
    value: number | false,
    onChange: (event: React.SyntheticEvent, newValue: string) => void,
    variant: "scrollable",
    scrollButtons: "auto"
}*/

//https://mui.com/material-ui/api/tabs/
const StyledTabs = styled((props: TabsProps) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-flexContainer': {
        // paddingLeft: 20,
        // paddingRight: 20,
    },
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        top: 3,
        bottom: 3,
        right: 3,
        height: 'auto',
        background: 'none',
        zIndex: 0,
    },
    '& .MuiTabs-indicatorSpan': {
        position: 'absolute',
        top: 7,
        left: 0,
        right: 0,
        bottom: 7,
        borderRadius: 50,
        backgroundColor: 'white',
        boxShadow: '0 4px 12px 0 rgba(0,0,0,0.16)',
    },
    '&.MuiTabs-root': {
        backgroundColor: '#27348B',
        color: '#000'
    },
    '& .MuiTabs-scrollButtons': {
        color: 'white'
    }
});


export function ShiajoTabs(props: {
    shiajosSelection: IShiajoSelection
    , selectShiaijo: (shiaijoId: number | undefined) => void
}) {

    return <StyledTabs
        value={props.shiajosSelection.shiajoSelected ?? false}
        onChange={(e, newValue: string) => {
            props.selectShiaijo(Number(newValue ?? undefined))
        }
        }
        variant="scrollable"
        scrollButtons="auto"
    >
        {
            props.shiajosSelection.shiajos.map((shiajo: IApiShiajoModel, k: number) => {
                return <AntTab
                    key={k}
                    value={shiajo.externalId}
                    label={`${shiajo.fullname} (comp id ${shiajo.externalId})`}
                />
            })
        }
    </StyledTabs >
}