import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import React, { useCallback } from 'react';
import { IApiRoundEventModel } from '../../util/ApiModel/ApiEventModel';
import { IApiFighterModel } from '../../util/ApiModel/ApiFighterModel';
import { EditEventModal } from '../Modal/EditEventModal';
import useShiajoModal from '../Modal/UseShiajoModal';
import { IServiceTableNotification } from '../Util/ErrorMessage';

import { ApiDataString } from './ApiDataString';

export interface IRoundEventProps {
    redFighter: IApiFighterModel,
    whiteFighter: IApiFighterModel,
    event: IApiRoundEventModel,
    deleteEvent(eventId: number): void,
    dispatchNotification: React.Dispatch<IServiceTableNotification>,
    reloadRound: () => void
    //dispatchModal: React.Dispatch<IModalAction>
}

export function RoundEventListItem(props: IRoundEventProps) {
    const [isShowing, toggle] = useShiajoModal();

    let afterToggle = useCallback(() => {
        toggle();
        //after showing, recalcul round
        if (isShowing) {
            props.reloadRound();
        }
    }, [isShowing, props, toggle])

    //let el = <div>event edit</div>;
    let display = moment(props.event.date).format("HH:mm:ss");

    return <>
        <EditEventModal
            showing={isShowing}
            toggle={afterToggle}
            event={props.event}
            redFighter={props.redFighter}
            whiteFighter={props.whiteFighter}
            dispatchNotification={props.dispatchNotification}
        />
        <Grid container>
            <Grid item xs={8}>
                <Typography variant="caption">
                    <ApiDataString /*apiSync={e.apiSync}*/ value={display + "-" + props.event.type} />
                </Typography>
            </Grid>
            <Grid item xs={2}>

                <svg onClick={() => {
                    props.deleteEvent(props.event.id)
                }} width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-x-octagon-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zm-6.106 4.5a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                </svg>

            </Grid>
            <Grid item xs={2}>

                <svg onClick={toggle}
                    width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                </svg>
            </Grid>
        </Grid>
    </>

}