import { Alert, AlertTitle, Button, Checkbox, Chip } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useCallback, useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IApiCompetitionModel } from '../../Model/ApiCompetitionModel';
import { IApiClientState } from '../../ScoreKeeperScreen';
import { patchFightGroupSolve } from '../../util/ApiFightgroup';
import { IApiFightModel, IFightTeamState } from '../../util/ApiModel/ApiFightModel';
import { IApiFightScoresModel, IFightScoreResult, emptyFightScore } from '../../util/ApiModel/ApiFightScore';
import { EFightGroupStatus, EFightGroupType, IApiFightGroupModel, IApiFightGroupResult, emptyApiFightGroupModel, emptyFightgroup } from '../../util/ApiModel/ApiFightgroupModel';
import { SessionCache } from '../../util/SessionStorage';
import { useCompetitionLoader } from '../Competition/CompetitionLoader';
import { FollowingSelectRank } from '../Following/followingSelectRank';
import { ConfirmModal } from '../Modal/ConfirmModal';
import { FightGroupScoreModalButton } from '../Modal/FightGroupScoreModalButton';
import { clearCalculFightGroup, deleteFightgroup, isFollowedFightGroup, unCancelFightgroup } from '../Operation/FightGroupOperation';
import { deleteApiObject, getApiObject, patchApiObject, postApiObject, putApiObject } from '../Operation/GenericOperation';
import { TSizeType, useScreenSize } from '../Use/UseScreenSize';
import { ChronoState, TChronoCommand } from '../Util/Chronometer';
import { getConfiguration, storeConfiguration } from '../Util/Configuration';
import { IServiceTableNotification, useErrorMessage } from '../Util/ErrorMessage';
import { StyledPaper } from '../Util/Styled';
import { Fight } from './Fight';
import { FightGroupScore } from './FightGroupScore';
import { } from './RoundAction';
import { TeamOrder } from './TeamOrder';
//import Media from 'react-media';

var debug = require('debug')('ServiceTablesMinimalFrontend:FightGroup')


export interface IFightGroupState extends IApiClientState {
  fightgroup: IApiFightGroupModel,
}

export const emptyFightGroupState: IFightGroupState = {
  fightgroup: emptyApiFightGroupModel,
  loading: "unload"
}

export interface IFightGroupAction {
  type: "ADDITIONNAL_FIGHT" | "PUT_FIGHT" | "SELECT_ROUND" | "SELECT_FIGHT" | "LOAD" | "COMMIT" | "CLEAR" | "LOAD_SCORE" | "CLEAR_FIGHTS",
  id?: number
}

/**
 * Return label for fightgroup status
 * @param state 
 * @returns 
 */
export function statusLabel(fightgroup: IApiFightGroupModel): string {
  return fightGroupStatusLabel(fightgroup.status);
}

export function fightGroupStatusLabel(status: EFightGroupStatus): string {
  let res = "label.word.undefined";
  switch (status) {
    case EFightGroupStatus.COMMITTED: res = "label.fight.status.committed"; break;
    case EFightGroupStatus.FINISHED: res = "label.fight.status.finished"; break;
    case EFightGroupStatus.INPROGRESS: res = "label.fight.status.inprogress"; break;
    case EFightGroupStatus.UNDEFINED: res = "label.fight.status.undefined"; break;
    case EFightGroupStatus.WAIT: res = "label.fight.status.wait"; break;
    case EFightGroupStatus.CANCELED: res = "label.fight.status.canceled"; break;
  }
  return res
}

// interface IApiFightPut {
//   currentRoundId: number,
//   apiSync: EApiDataSync
// }

/**
 * @deprecated should be removed TODO
 * @param apiData 
 */
function reloadFightKeys(apiData: IApiFightGroupResult) {
  for (let index = 0; index < apiData.data.fights.length; index++) {
    const element = apiData.data.fights[index];
    element.reloadKey = Date.now();
  }
}

export interface IFightGroupProps {
  id: number,
  dispatchNotification: React.Dispatch<IServiceTableNotification>,
  dispatchChrono: (command: TChronoCommand) => void,
  shiajoLoad: (force?: boolean) => void,
  chronoState: ChronoState,
  fullscreen: boolean,
  timestamp: number
}

/**
 * A fightgroup
 */
export function FightGroup(props: IFightGroupProps) {
  const { t, i18n } = useTranslation();
  const [dispatchNotification, errorComponent] = useErrorMessage();
  const [changeTeamOrder, setChangeTeamOrder] = useState(false);
  const sizeType: TSizeType = useScreenSize();
  const [forceRankFgNodeId, setForceRankFgNodeId] = useState<number | undefined>(undefined);
  const [useChrono, setUseChrono] = useState<boolean>(getConfiguration().withChrono);
  const [useSimplifiedAction, setUseSimplifiedAction] = useState<boolean>(getConfiguration().simplifiedAction);
  const [fightGroupState, setFightGroupState] = useState<IFightGroupState>({
    loading: "unload",
    fightgroup: emptyFightgroup.data
  });
  const [score, setScore] = useState<IApiFightScoresModel | undefined>();

  //get competition informations
  const [competition, loadCompetitionId] = useCompetitionLoader(dispatchNotification, fightGroupState.fightgroup.competitionId, true);

  useEffect(() => {
    loadCompetitionId(fightGroupState.fightgroup.competitionId);
  }, [fightGroupState.fightgroup.competitionId, loadCompetitionId])


  //FUNCTIONS ======================================================

  //Select a fight
  let selectFight = useCallback(async (pid: number) => {
    if (pid) {
      let apiData: IApiFightGroupResult = await putApiObject<IApiFightGroupResult>('/fightgroups', fightGroupState.fightgroup.id, {
        currentFightId: pid
      },
        {
          data: emptyApiFightGroupModel,
          succeed: false,
          status: 400
        }
        , dispatchNotification);
      if (apiData.succeed) {
        setFightGroupState({
          loading: "loaded",
          fightgroup: apiData.data
        })
      }
    }
  }, [dispatchNotification, fightGroupState.fightgroup.id]);

  useEffect(() => {
    //if finished get rank
    if (fightGroupState.loading === "loaded") {//&& fightGroupState.fightgroup.status === EFightGroupStatus.FINISHED) {
      debug("Load Score")
      getApiObject<IFightScoreResult>('/fightgroups/' + fightGroupState.fightgroup.id + "/score", null, emptyFightScore, dispatchNotification).then((apiRank) => {
        setScore(apiRank ? apiRank.data : undefined);
      });
    }
  }, [dispatchNotification, fightGroupState])

  /**
   * When props change load fightgroup and set state
   */
  let loadFightGroup = useCallback(async (pid: number): Promise<IApiFightGroupModel | undefined> => {
    debug("LOAD FIGHT GROUP");
    let fgId = pid;
    if (fgId !== null && fgId > 0) {
      let apiData = await SessionCache.get("fightgroup", props.id
        , () => getApiObject<IApiFightGroupResult>('/fightgroups', fgId, emptyFightgroup, dispatchNotification)
        , 500) ?? emptyFightgroup;


      if (apiData.succeed) {

        reloadFightKeys(apiData);

        setFightGroupState({
          loading: "loaded",
          fightgroup: apiData.data,
        });

        return apiData.data;
      }
    } else {
      dispatchNotification({
        operation: "read",
        type: "error"
        , message: "error.message.invalidFightGroupId"
      });
      props.shiajoLoad();
      return undefined;
    }
  }, [dispatchNotification, props])



  //Clear data
  let clear = () => {
    return {
      loading: "loaded",
      data: emptyFightgroup.data
    };
  }

  /*
    // Load scores
    let loadScore = async (fgid: number) => {
      debug("Load_Score")
      let newState = await getApiObject<IFightScoreResult>(`/FightGroups/${fgid}/score`, null, emptyFightScore, dispatchNotification).then((scoreRes) => {
        if (scoreRes.succeed) {
          return { ...fightGroupState, score: scoreRes.data };
        }
        else return fightGroupState;
      });
      setFightGroupState(newState);
    }*/

  // Commite result
  let commit = async (pid: number) => {
    let newState = { ...fightGroupState }
    newState.fightgroup = await postApiObject<IApiFightGroupResult>('/FightGroups/' + pid + "/commit",
      {}, {
      data: fightGroupState.fightgroup,
      succeed: false,
      status: 400
    }, dispatchNotification).then((fightGroupResult: IApiFightGroupResult) => {
      if (fightGroupResult.succeed) {
        props.shiajoLoad();
      }
      return fightGroupResult.data;
    });
    setFightGroupState(newState);
  }

  // Trigger additionnal fight to add
  let additionalFight = async () => {
    setFightGroupState({
      loading: "loaded",
      fightgroup: (await postApiObject<IApiFightGroupResult>('/FightGroups/' + fightGroupState.fightgroup.id + "/fights/additionnal"
        , {}
        , {
          data: fightGroupState.fightgroup,
          succeed: false,
          status: 400
        }, dispatchNotification)).data
    });
  }

  // Cancel current fight
  let cancelFightgroup = (): void => {
    deleteFightgroup(fightGroupState.fightgroup.id, props.dispatchNotification).then((res: boolean) => {
      if (res) {
        props.shiajoLoad();
      }
    })
  }

  const uncancelFightgroup = (): void => {
    unCancelFightgroup(fightGroupState.fightgroup.id, props.dispatchNotification).then((res: boolean) => {
      if (res) {
        props.shiajoLoad(true);
      }
    });
  }
  //EFFECT ======================================================

  /**
   * Automaticaly select first fight
   */
  useEffect(() => {
    if (fightGroupState.fightgroup.id > 0) {
      if (fightGroupState.fightgroup.fights.length === 1 && fightGroupState.fightgroup.currentFight === undefined) {
        selectFight(fightGroupState.fightgroup.fights[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fightGroupState.fightgroup.id, selectFight]);

  //On fight selected change
  useEffect(() => {
    setChangeTeamOrder(false);
  }, [props.id])

  useEffect(() => {
    if (props.timestamp > 0) {
      setFightGroupState({
        loading: "unload",
        fightgroup: emptyFightgroup.data
      })
    }
  }, [props.timestamp]);

  //???
  useEffect(() => {
    debug("UseEffect");
    if (props.id > 0) {
      if (fightGroupState.loading === "unload" || props.id !== fightGroupState.fightgroup.id) {
        loadFightGroup(props.id);
      }
    }
    else if (fightGroupState.fightgroup.id > 0) {
      debug("CLEAR");
      //dispatch({ type: "CLEAR" });
      clear();
    };
  }, [loadFightGroup, props.id, fightGroupState.fightgroup.id, fightGroupState.loading]);


  useEffect(() => {
    storeConfiguration({ ...getConfiguration(), withChrono: useChrono });
  }, [useChrono])

  useEffect(() => {
    storeConfiguration({ ...getConfiguration(), simplifiedAction: useSimplifiedAction });
  }, [useSimplifiedAction])

  //COMPONENTS

  //CANCEL confirmation
  if (fightGroupState.fightgroup.status === EFightGroupStatus.CANCELED) {
    return <>
      <Grid item xs={12}>
        {errorComponent}
      </Grid>
      <Grid item xs={12}>
        <Button size="small" color="error" variant='contained' onClick={() => cancelFightgroup()}>
          {t('label.fightgroup.pleaseConfirmCancelation')}
        </Button>
        <Button size="small" color="success" variant='contained' onClick={() => uncancelFightgroup()}>
          {t('label.fightgroup.restoreCancelation')}
        </Button>
      </Grid>
    </>
  }
  //Fight order management
  else if (fightGroupState.fightgroup.fights.length > 0) {
    let defineOrderTeam: Array<IFightTeamState> = [];
    if (changeTeamOrder) {
      // ajouter les équipes qui doivent avoir un ordre de défini
      fightGroupState.fightgroup.fights.forEach(f => {
        if (defineOrderTeam.filter(f2 => f2.id === f.redFightTeam.id).length === 0) {
          defineOrderTeam.push(f.redFightTeam);
        }
        if (defineOrderTeam.filter(f2 => f2.id === f.whiteFightTeam.id).length === 0) {
          defineOrderTeam.push(f.whiteFightTeam);
        }
      });
    }
    else if (fightGroupState.fightgroup.type === EFightGroupType.POOL) {
      // ajouter les équipes qui doivent avoir un ordre de défini, si leur ordre n'a pas encore été défini
      fightGroupState.fightgroup.fights.forEach(f => {
        if (f.redFightTeam.fighterOrderDate === null && f.redFightTeam.fighters.length > 1) {
          if (defineOrderTeam.filter(f2 => f2.id === f.redFightTeam.id).length === 0) {
            defineOrderTeam.push(f.redFightTeam);
          }
        }
        if (f.whiteFightTeam.fighterOrderDate === null && f.whiteFightTeam.fighters.length > 1) {
          if (defineOrderTeam.filter(f2 => f2.id === f.whiteFightTeam.id).length === 0) {
            defineOrderTeam.push(f.whiteFightTeam);
          }
        }
      });
    }

    //si on a besoin de définir l'ordre
    if (defineOrderTeam.length > 0) {
      let orderFightLimit: number = fightGroupState.fightgroup.fights.map(f => f.rounds?.length ?? 0).reduce((v, nv) => Math.max(v, nv));
      return <TeamOrder teams={defineOrderTeam} orderFightLimit={orderFightLimit} onUpdated={() => {
        setChangeTeamOrder(false);
        //dispatch({ type: "LOAD", id: props.id });
        loadFightGroup(props.id);
      }}
        onCancel={() => {
          setChangeTeamOrder(false);
          //dispatch({ type: "LOAD", id: props.id });
          loadFightGroup(props.id);
        }}
      ></TeamOrder>
    }
    else {
      //let fightGroupStatus = <div>{t(fightGroupStatusLabel(state.fightgroup.status))}</div>;
      let fightGroupAction = undefined;
      //Combat should be commited to central table
      if (fightGroupState.fightgroup.status === EFightGroupStatus.FINISHED && fightGroupState.fightgroup.commit === null) {
        fightGroupAction = <div></div>
        if (score) {
          //Ranking is ok, ready to commt
          if (score.ranking.length > 0) {
            fightGroupAction = <div>
              <Button color="success" variant='contained' onClick={(e: any) => {
                //dispatch({ type: "COMMIT", id: props.id })
                commit(props.id);
              }}>
                {t('action.fightgroup.commit')}
              </Button>
            </div>
          } else {
            //Score is not ok, can't commit and propose to add a fight
            fightGroupAction = <div>
              <Button color="warning" variant='contained' onClick={(e: any) => {
                //dispatch({ type: "ADDITIONNAL_FIGHT" })
                additionalFight();
              }}>
                {t('action.fightgroup.additionalFight')}
              </Button></div>
          }
        } else {
          fightGroupAction = t('label.fightgroup.scoreLoading');
        }
      }



      return <>
        <StyledPaper elevation={3} >
          <Grid container>
            <Grid item xs={4}>
              <h6 className='title is-4'>{t('label.title.currentGroupFight')} : <strong>{fightGroupState.fightgroup.label}</strong>
                ({isFollowedFightGroup(fightGroupState.fightgroup) ? fightGroupState.fightgroup.fightGroupNodeId : t('label.fightgroup.notFollowed')})
              </h6>

            </Grid>

            {competition && <>
              <Grid item xs={8}>
                <h3 className='title is-4'>{t("label.word.competition")} {competition.fullname} ({competition.id}) - {competition.martialArt}</h3>
              </Grid>
            </>
            }
            <Grid item xs={12}>
              Configuration :
              <Checkbox checked={useChrono} onChange={(e) => {
                setUseChrono(e.target.checked);
              }} />
              <span>{t('action.configuration.useChrono')}</span>
              <Checkbox checked={useSimplifiedAction} onChange={(e) => {
                setUseSimplifiedAction(e.target.checked);
              }} />
              <span>{t('action.configuration.useSimplifiedAction')}</span>
            </Grid>
          </Grid>
        </StyledPaper>
        {competition && <>
          <Grid item xs={12} margin={'10px'}>

            <Button size="small" color="warning" variant='contained' onClick={() => setChangeTeamOrder(true)}>
              {t('action.fightgroup.changeDefaultTeamOrder')}
            </Button>
            <Button size='small' onClick={() => {
              clearCalculFightGroup(fightGroupState.fightgroup.id, dispatchNotification).then((d) => {
                if (d) {
                  setFightGroupState({
                    loading: "unload",
                    fightgroup: emptyFightgroup.data
                  })
                }
              })
            }
            } color="warning" variant='contained'>
              {t('action.fightgroup.clearCalcul')}
            </Button>

            {isFollowedFightGroup(fightGroupState.fightgroup) && <Button size='small' color="error" variant='contained' onClick={() => {
              setForceRankFgNodeId(fightGroupState.fightgroup.fightGroupNodeId);
            }}>
              {t('action.following.forceranking')}
            </Button>}
            {!isFollowedFightGroup(fightGroupState.fightgroup) && <Chip label={t('label.fightgroup.notFollowedForceRank')} />}
            {forceRankFgNodeId && <FollowingSelectRank
              dispatchNotification={props.dispatchNotification}
              fightGroupNodeId={forceRankFgNodeId}
              onPutted={(ranks) => {
                setForceRankFgNodeId(undefined);
                if (ranks.length > 0) {
                  props.shiajoLoad();
                }
              }}
            />}
            {isFollowedFightGroup(fightGroupState.fightgroup) && <>
              <ConfirmModal label={t(`action.fightgroup.postpone`)} message={t(`confirmation.following.postpone`)} action={() => {
                deleteApiObject<IApiFightGroupResult>(`/fightgroups?postpone=true&fightGroupNodeId=${fightGroupState.fightgroup.fightGroupNodeId}`, null, emptyFightgroup
                  , props.dispatchNotification
                ).then((result: IApiFightGroupResult) => {
                  if (result.succeed) {
                    props.shiajoLoad();
                  }
                });
              }} />
            </>
            }
            {!isFollowedFightGroup(fightGroupState.fightgroup) && <>
              <Chip label={t('label.fightgroup.notFollowedPostpone')} />
            </>}
            <ConfirmModal label={t(`action.fightgroup.close`)} message={t(`confirmation.fightgroup.close`)} action={() => {
              postApiObject<IApiFightGroupResult>(`/fightgroups/${fightGroupState.fightgroup.id}/close`, null, emptyFightgroup
                , props.dispatchNotification
              ).then((result: IApiFightGroupResult) => {
                if (result.succeed) {
                  props.shiajoLoad();
                }
              });
            }} />


          </Grid>
        </>
        }

        <Grid item xs={12}>
          {errorComponent}
        </Grid>

        {
          fightGroupAction &&
          <Grid item xs={12}>
            <Alert severity="success" variant="outlined">
              <AlertTitle>{t("label.fightgroup.checkRankBeforeCommit")}</AlertTitle>
              <Grid container spacing={0}>
                <Grid item xs={4}>
                  {fightGroupAction}
                </Grid>
                <Grid item xs={8}>
                  {t("detail.fightgroup.checkRankBeforeCommit")}

                  {score && <>
                    <FightGroupScoreModalButton fightGroupId={fightGroupState.fightgroup.id} />
                    <FightGroupScore fightGroup={fightGroupState.fightgroup} score={score} />
                  </>}
                </Grid>
              </Grid>
            </Alert>
          </Grid>
        }

        <Grid item xs={12}>
          <FightGroupTableComponent
            shiajoLoad={props.shiajoLoad}
            fightgroup={fightGroupState.fightgroup}
            loadFightGroup={loadFightGroup}
            dispatchNotification={props.dispatchNotification}
            competition={competition}
            dispatchChrono={props.dispatchChrono}
            selectFight={selectFight}
            setChangeTeamOrder={setChangeTeamOrder}
            chronoState={props.chronoState}
            simpleActions={useSimplifiedAction}
          />
        </Grid>

        <Grid item xs={12}>
          {score && <FightGroupScore fightGroup={fightGroupState.fightgroup} score={score} />}
          <div className="alert alert-warning" role="alert">Maj à la fin de chaque combat</div>
        </Grid>
      </>;
    }
  }
  //No fights
  else {
    return <Container fluid>
      <Grid item xs={12} >
        {errorComponent}
      </Grid>
      <Grid item xs={12} >{t('label.fightgroup.pleaseSelect')}</Grid>
      {fightGroupState.fightgroup.id >= 0 && <Grid item xs={12} >
        <Button variant='contained' color='warning'
          onClick={() => {
            deleteFightgroup(fightGroupState.fightgroup.id, props.dispatchNotification).then(res => {
              if (res) {
                props.shiajoLoad();
              }
            })
          }}>
          {t('label.fightgroup.cancelFightGroup')}

        </Button>
      </Grid>}
    </Container>
  }
}

function FightGroupTableComponent(props: {
  shiajoLoad: () => void,
  competition?: IApiCompetitionModel,
  fightgroup: IApiFightGroupModel,
  dispatchNotification: React.Dispatch<IServiceTableNotification>,
  loadFightGroup: (pid: number) => Promise<IApiFightGroupModel | undefined>,
  setChangeTeamOrder: React.Dispatch<React.SetStateAction<boolean>>,
  dispatchChrono: (command: TChronoCommand) => void,
  selectFight: (fightId: number) => void,
  chronoState?: ChronoState,
  simpleActions: boolean
}) {
  const { t, i18n } = useTranslation();
  const sizeType: TSizeType = useScreenSize();

  //is none fightgroup
  const isNoneFightGroup = props.fightgroup.fights.filter(f => f.redFightTeam.externalId > 0 && f.whiteFightTeam.externalId > 0).length === 0;
  let resolveNoneButton: null | JSX.Element = null;

  //Remove existing values
  let clearFights = async () => {
    //should be clear but already set
    let pathResult = await patchApiObject<IApiFightGroupResult>('/fightgroups/' + props.fightgroup.id + "/clear", {}, emptyFightgroup, props.dispatchNotification);
    if (pathResult.succeed) {
      props.loadFightGroup(props.fightgroup.id)
    }
  }

  //Resolution button
  if (isNoneFightGroup) {
    if (isFollowedFightGroup(props.fightgroup)) {


      resolveNoneButton = <Button size="small" color="info" variant='contained'
        onClick={() => {
          patchFightGroupSolve(props.dispatchNotification, props.fightgroup.fightGroupNodeId).then((f) => {
            //To Check (was props.id before)
            //props.loadFightGroup(props.fightgroup.id);
            props.shiajoLoad();
          })
        }}>
        {t('action.fightgroup.autoresolve')}

      </Button>
    }
    else {
      <Chip label={t('label.fightgroup.notFollowedResolveNone')} />
    }
  }

  return <Table striped bordered hover className='shiajo_fightgroup'>
    <thead>
      {sizeType === 15 && <tr>
        {/* <td>{fightGroupStatus}</td> */}
        <td colSpan={2}>{t('label.word.teams')}
          {resolveNoneButton && <>
            <br />
            {resolveNoneButton}
          </>}


        </td>
        <td>{t('label.word.status')}</td>
      </tr>}

      {sizeType >= 17 && <tr>
        {/* <td>{fightGroupStatus}</td> */}
        <td>{t('label.word.teams')}
          {resolveNoneButton && <>
            <br />
            {resolveNoneButton}
          </>}
        </td>
        <td>{t('label.word.status')}</td>

        <td>{t('label.word.round')}</td>
        <td>{t('label.word.roundstatus')}</td>
        <td className='red'>{t('label.word.red')}</td>
        <td className="ippon">{props.fightgroup.label} - {t(statusLabel(props.fightgroup))} -
          (node id {isFollowedFightGroup(props.fightgroup) ? props.fightgroup.fightGroupNodeId : t('label.fightgroup.notFollowed')})  </td>
        <td className='white'>{t('label.word.white')}</td>
        <td>{t('label.word.details')}&nbsp;
          <br />
          <Button size="small" onClick={() => {
            //dispatch({ type: "LOAD", id: props.id })
            props.loadFightGroup(props.fightgroup.id);
          }}>
            {t('action.fightgroup.reload')}
          </Button>
          <ConfirmModal label={t("action.fightgroup.clear")} action={() => {
            //dispatch({ type: "CLEAR_FIGHTS", id: props.id })
            clearFights();
          }} message={t("confirmation.fightgroup.clear")} />

          <br />
        </td></tr>
      }
    </thead>

    <tbody>
      {props.fightgroup.fights.sort((a: IApiFightModel, b: IApiFightModel) => {
        //if (a.id === state.fightgroup.currentFightId) return -1;
        //if (b.id === state.fightgroup.currentFightId) return 1;
        if (a.order !== b.order) return a.order - b.order;
        else return a.id - b.id;
      }).map((fight: IApiFightModel, k) => {

        fight.keyname = k;
        let active = (props.fightgroup.currentFightId ? (fight.id === props.fightgroup.currentFightId) : false)

        //doesn't remember why this need to play with key, probably a bad use to force refresh
        //TODO : correction needed, but lots of test too
        return props.competition ? <Fight
          key={fight.id + (fight.reloadKey ? fight.reloadKey : 0)}
          simpleActions={props.simpleActions}
          active={active}
          dispatchChrono={props.dispatchChrono}
          dispatchNotification={props.dispatchNotification}
          //dispatchFightGroup={dispatch}
          fightGroupLoad={() => props.loadFightGroup(props.fightgroup.id)}
          fightGroupSelectFight={props.selectFight}
          /*dispatchModal={props.dispatchModal}*/
          data={fight}
          chronoState={props.chronoState}
          competition={props.competition}
        /> : <p>{t('label.fightgroup.competitionNotFound')} : id {props.fightgroup.competitionId}</p>
      })}
    </tbody>
  </Table >;
}