import { MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { IApiCompetitionModel } from "../../Model/ApiCompetitionModel";
import { IApiShiajoModel } from "../../util/ApiModel/ApiShiajoModel";
import { CompetitionLabelStr } from "../Competition/CompetitionLabel";

export interface IShiaijoSelectProps {
    shiaijos: Array<IApiShiajoModel>,
    competitions?: Array<IApiCompetitionModel>,//to display competition label if needed
    onSelect: (shiaijo : IApiShiajoModel | undefined) => void
}
export function ShiaijoSelect(props: IShiaijoSelectProps) {
    const [shiaijo, setShiaijo] = useState<IApiShiajoModel | undefined>(undefined);

    return <Select
        value={shiaijo?.externalId}
        label="Shiaijo"
        size="small"
        onChange={(event) => props.onSelect(props.shiaijos.find(s => s.externalId === (typeof event.target.value === 'string' ? parseInt(event.target.value) : event.target.value)))}
    >
        {props.shiaijos.sort((s1, s2) => s1.externalId - s2.externalId).map((s, key) => {
            let competitionLabel = undefined;
            if (props.competitions){
                competitionLabel = s.competitionId.map((cid,k) => {
                    let fullName = undefined;
                    if (props.competitions) fullName = CompetitionLabelStr(props.competitions.find((c2) => c2.id === cid));
                    return <React.Fragment key={cid}>{fullName}</React.Fragment>
                })
            }
            return <MenuItem key={s.externalId} value={s.externalId}>{s.fullname} {competitionLabel}</MenuItem>
        })}
    </Select>
}