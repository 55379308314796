import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row, Table, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { getShiajos } from "../../util/ApiCompetition";
import { getFollowingFightGroup } from "../../util/ApiFollowing";
import { emptyFightgroup, IApiFightGroupResult, searchFighterInTeams } from "../../util/ApiModel/ApiFightgroupModel";
import { emptyFightScore, IApiFightScoresModel, IFightScoreResult } from "../../util/ApiModel/ApiFightScore";
import { FighterLabel } from "../Fighter/FighterLabel";
import { ConfirmModal } from "../Modal/ConfirmModal";
import { EditEventModal, EditEventModalProps } from "../Modal/EditEventModal";
import { clearCalculFightGroup, getFightGroupNodeId } from "../Operation/FightGroupOperation";
import { getApiObject, postApiObject } from "../Operation/GenericOperation";
import { emptyFightGroupState, IFightGroupState } from "../Table/FightGroup";
import { useErrorMessage } from "../Util/ErrorMessage";
import { FightIpponTable } from "./FightIpponTable";
import { FightLabel } from "./FightLabel";
import { FightScoreTable } from "./FightScoreTable";



export interface IFightCardState {
    fightGroupId: number,
    nodeId?: boolean
}

export interface IFightCardFilter {
    iponOnly: boolean
};



export function FightCard(props: IFightCardState) {
    const { t, i18n } = useTranslation();
    const [filter, setFilters] = useState<IFightCardFilter>({
        iponOnly: true
    });
    const svgRef = useRef(null);

    const [fightGroupState, setFightGroupState] = useState<IFightGroupState>(emptyFightGroupState);
    const [score, setScore] = useState<IApiFightScoresModel | undefined>();
    const [dispatchNotification, errorComponent] = useErrorMessage(false);
    const [editModalProps, setEditModalProps] = useState<EditEventModalProps | undefined>();
    const handlePrint = useReactToPrint({
        content: () => {
            return svgRef.current ?? null
        },
        onBeforeGetContent: () => {

        },
        onAfterPrint: () => {

        }
    });


    const loadFG = useCallback(() => {
        //nodeId

        getApiObject<IApiFightGroupResult>("/FightGroups" + (props.nodeId ? "/fightGroupNodeId" : ""), props.fightGroupId, emptyFightgroup, dispatchNotification).then((fightGroupRes) => {
            if (fightGroupRes.succeed) {
                getApiObject<IFightScoreResult>("/FightGroups/" + fightGroupRes.data.id + "/score", null, emptyFightScore, dispatchNotification).then((scoreRes) => {
                    if (scoreRes.succeed) {
                        let fightGroupScored: IFightGroupState = {
                            loading: "loaded",
                            fightgroup: fightGroupRes.data,
                        }
                        setFightGroupState(fightGroupScored);
                        setScore(scoreRes.data);
                    }
                });
            }
        })
    }, [props.fightGroupId, props.nodeId, dispatchNotification]);

    useEffect(() => {

        loadFG();
    }, [dispatchNotification, props.fightGroupId, props.nodeId, loadFG]);


    return <>
        {errorComponent}
        {editModalProps && <EditEventModal
            showing={editModalProps.showing}
            toggle={editModalProps.toggle}
            event={editModalProps.event}
            redFighter={editModalProps.redFighter}
            whiteFighter={editModalProps.whiteFighter}
            dispatchNotification={dispatchNotification}
        />}
        <Container ref={svgRef}>
            <Row className='no-print'>
                <Col xs={4}>
                    <Button variant="contained" onClick={() => handlePrint()}>Print</Button>
                </Col>
            </Row>
            <Row>
                <Col xs={4}>
                    <h1>{fightGroupState.fightgroup.label} - Fight Group Card ({fightGroupState.fightgroup.id})</h1>
                </Col>
            </Row>
            <Row className='no-print'>
                <Col xs={6}>
                    <ToggleButtonGroup type="radio"
                        value={filter.iponOnly ? 1 : 0}
                        name="filter"
                        onChange={(val: unknown) => {
                            setFilters({ ...filter, iponOnly: val as boolean });
                        }}>
                        <ToggleButton id="tbg-btn-1" value={1}>
                            {t('action.fightgroup.showEventIpons')}
                        </ToggleButton>
                        <ToggleButton id="tbg-btn-1" value={0}>
                            {t('action.fightgroup.showEventAll')}
                        </ToggleButton>

                    </ToggleButtonGroup>
                </Col>
                <Col xs={6} className='no-print'>
                    <Button onClick={() => {
                        //todo : a faire plus propre
                        (async () => {
                            const id = props.nodeId ? (await getFightGroupNodeId(props.fightGroupId, dispatchNotification))?.id : props.fightGroupId;
                            if (id) {
                                clearCalculFightGroup(id, dispatchNotification).then((d) => {
                                    loadFG();
                                })
                            }
                        })()
                    }
                    } variant={"warning"}>
                        {t('action.fightgroup.clearCalcul')}
                    </Button>

                    {props.nodeId && <Button onClick={() => {
                        (async () => {
                            const fgfollow = await getFollowingFightGroup(props.fightGroupId, dispatchNotification);
                            if (fgfollow) {
                                const shiaijos = await getShiajos(dispatchNotification, fgfollow.competitionId);
                                if (shiaijos.length > 0) {
                                    //use shiaijo external id
                                    await postApiObject<IApiFightGroupResult>(`/Shiajos/${shiaijos[0].id}/fightgroups`, {
                                        fightGroupNodeId: fgfollow.fightGroupNodeId
                                    }, emptyFightgroup, dispatchNotification).then((fg) => {
                                        loadFG();
                                    });
                                }
                            }
                        })()
                    }
                    }
                        variant={"danger"}>
                        {t('action.fightgroup.createTableFight')}
                    </Button>
                    }
                    {/* Todo : duplication de code FightGroup */}
                    {fightGroupState.fightgroup && <ConfirmModal label={t(`action.fightgroup.close`)} message={t(`confirmation.fightgroup.close`)} action={() => {
                            postApiObject<IApiFightGroupResult>(`/fightgroups/${fightGroupState.fightgroup.id}/close`, null, emptyFightgroup
                                , dispatchNotification
                            ).then((result: IApiFightGroupResult) => {
                                if (result.succeed) {
                                    loadFG();
                                }
                            });
                        }} />
                    }


                </Col>
            </Row>
            <Row>
                <Col xs={12}>

                    {fightGroupState.fightgroup.fights.map((fight, key) => {
                        return <React.Fragment key={key}>
                            <h3>Fight <FightLabel {...fight} /></h3>
                            <Table striped bordered hover>
                                <Row key={key}>
                                    <Col>Id</Col>
                                    <Col>Date</Col>
                                    <Col>Fighter</Col>
                                    <Col>Opponent</Col>
                                    <Col>Event</Col>
                                    <Col>Action</Col>
                                </Row>
                                {fight.rounds?.map((round, key) => {
                                    return <React.Fragment key={key}>
                                        {round.events?.filter(e => {
                                            let valid = true;
                                            if (filter.iponOnly) valid = (e.points > 0)
                                            return valid;
                                        }).map((event, key) => {
                                            let [fighter, color] = event.fighterId ? searchFighterInTeams(fightGroupState.fightgroup, event.fighterId) : [undefined, undefined];
                                            const opponentFighterid = round.redFighterId === event.fighterId ? round.whiteFighterId : round.redFighterId;
                                            const [opponent, opponentColor] = opponentFighterid ? searchFighterInTeams(fightGroupState.fightgroup, opponentFighterid) : [undefined, undefined];
                                            return <Row key={key}>
                                                <Col>{round.id}</Col>
                                                <Col>{event.date?.toLocaleString()}</Col>
                                                <Col>{fighter ? <FighterLabel fighter={fighter} /> : `${event.fighterId ?? ''}`}</Col>
                                                <Col>{opponent ? <FighterLabel fighter={opponent} /> : `${opponentFighterid ?? ''}`}</Col>
                                                <Col>{event.type}</Col>
                                                <Col>
                                                    <svg onClick={() => {
                                                        setEditModalProps({
                                                            ...editModalProps,
                                                            showing: true,
                                                            toggle: () => { setEditModalProps(undefined) },
                                                            redFighter: round.redFighter,
                                                            whiteFighter: round.whiteFighter,
                                                            event: event,
                                                            dispatchNotification: dispatchNotification
                                                        })
                                                    }
                                                    }
                                                        width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                                    </svg>
                                                </Col>
                                            </Row>
                                        })}
                                    </React.Fragment>
                                })}
                            </Table>
                        </React.Fragment>
                    })}
                </Col>
                <Row>
                    <Col xs={12}>
                        <h2>Ippons</h2>
                    </Col>
                    <Col xs={12}>
                        <FightIpponTable
                            {...fightGroupState}
                            dispatchNotification={dispatchNotification}
                            reload={loadFG}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <h2>Score</h2>
                    </Col>
                    <Col xs={12}>
                        <FightScoreTable
                            {...fightGroupState}
                            score={score}
                        />
                    </Col>
                </Row>
            </Row>
        </Container >
    </>
}
