import { Checkbox } from '@mui/material';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Translation, useTranslation } from 'react-i18next';
import { ERoundEventTypeModel, IApiRoundEventModel } from '../../util/ApiModel/ApiEventModel';
import { IApiFighterModel } from '../../util/ApiModel/ApiFighterModel';
import { FighterLabelStr } from '../Fighter/FighterLabel';
import { updateEvent } from '../Operation/EventOperation';
import { IServiceTableNotification, useErrorMessage } from '../Util/ErrorMessage';
import ShiajoModal from './ShiajoModal';


var debug = require('debug')('ServiceTablesMinimalFrontend:useEditEvent')

export interface EditEventModalProps {
  showing: boolean,
  toggle: () => void,
  redFighter: IApiFighterModel,
  whiteFighter: IApiFighterModel,
  event: IApiRoundEventModel,
  dispatchNotification: React.Dispatch<IServiceTableNotification>
}

/*
function useEditEventFormFields(initialState: IApiRoundEventModel): [IApiRoundEventModel, (e: any) => void] {
  const [fields, setValues] = useState(initialState);

  return [
      fields,
      
      function (e: any) {
          const target = e.target;
          let value = target.type === 'checkbox' ? target.checked : target.value;
          let name = target.name;

          debug("New value " + name + " : " + value)
          console.log(target);
          let time: number = new Date(fields.date).getTime();
          switch (name) {
              case "hours":
                  break;
              case "minutes":
                  break;
              case "seconds":
                  time = time - (Math.floor(time / 1000) % 60) + Number(value) * 1000;
                  value = new Date(time);
                  name = "date";
                  break;
              case "milliseconds":
                  time = time - (time % 1000) + Number(value);
                  value = new Date(time);
                  name = "date";
                  break;
          }
          setValues({
              ...fields,
              [name]: e.target.value,
              id: 5
          });
      }
  ];
}

function useInput() {
  const [value, setValue] = useState("");
  const input = <input value={value} onChange={e => setValue(e.target.value)} type="text" />;
  return [value, input];
}*/



export function EditEventModal(props: EditEventModalProps) {
  const { t, i18n } = useTranslation();
  const [dispatchNotification, errorComponent] = useErrorMessage();
  const [force, setForce] = useState<boolean>(false);


  //const formRef = useRef<any>(null);
  //const [isShowing, toggle] = useShiajoModal();
  let handleSubmit = (e: any) => {
    //if (formRef && formRef.current) {
    //@ts-ignore
    let event: IApiRoundEventModel = { ...props.event };
    const formData = new FormData(e.target);
    let time: number = new Date(event.date).getTime();

    formData.forEach((value, element) => {
      let property = String(element);
      switch (String(property)) {
        case "hours":
          time = time + (Number(value) - date.getHours()) * 60 * 60 * 1000;
          event.date = new Date(time);
          break;
        case "minutes":
          time = time - (Math.floor(time / (60 * 1000)) % 60) * (60 * 1000) + Number(value) * 60 * 1000;
          event.date = new Date(time);
          break;
        case "seconds":
          time = time - (Math.floor(time / 1000) % 60) * (1000) + Number(value) * 1000;
          event.date = new Date(time);
          break;
        case "milliseconds":
          time = time - (time % 1000) + Number(value);
          event.date = new Date(time);
          break;
        case "fighterId":
          event = {
            ...event,
            fighterId: value ? Number(value) : 0
          }
          break;
        default:
          event = {
            ...event,
            [property]: String(value)
          }
          break;
      }
    });
    if (force) {
      event = { ...event, ignoreRoundCheck: true };
    }

    //send it
    updateEvent(event, dispatchNotification).then(s => {
      if (s) props.toggle();
    })
    //

    //debug("To submit : " + JSON.stringify(e));
    e.preventDefault();
    // }
  };

  //if (event) {
  let event = props.event;

  let date = new Date(event.date);

  debug(event.date);
  debug(date.toISOString());
  debug(date);

  let milliseconds: number = date.getTime();
  let millisecondsDisplay = ("0" + (milliseconds % 1000)).slice(-2);
  let secondsDisplay = ("0" + (Math.floor(milliseconds / 1000) % 60)).slice(-2);
  let minutesDisplay = ("0" + (Math.floor(milliseconds / 60000) % 60)).slice(-2);
  //let hoursDisplay = ("0" + (Math.floor(milliseconds / (60 * 60000)) % 24)).slice(-2);
  //TODO : GTCù
  let hoursDisplay = date.getHours().toString();// ("0" + Math.floor(milliseconds / 3600000 % 24)).slice(-2);*/


  return (
    <>
      <ShiajoModal

        isShowing={props.showing}
        hide={props.toggle}
        title="event">
        <Row>
          {errorComponent}
        </Row>
        <Row>
          <Col md={12}>
            <Form onSubmit={handleSubmit} >
              <Form.Group as={Row} controlId="formEditEventId">
                <Form.Label column md={2}> ID</Form.Label>
                <Col md={10}>

                  <Form.Control type="number" defaultValue={String(event.id)} placeholder="ID" />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formEditEventDateHour">
                <Form.Label column md={2}> Hours</Form.Label>
                <Col md={10}>
                  <Form.Control name="hours" type="number" defaultValue={hoursDisplay} placeholder="Hours" />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formEditEventDateMin">
                <Form.Label column md={2}> Minutes</Form.Label>
                <Col md={10}>
                  <Form.Control name="minutes" type="numbernumber" defaultValue={minutesDisplay} placeholder="Minutes" />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formEditEventDateSec">
                <Form.Label column md={2}> Seconds</Form.Label>
                <Col md={10}>
                  <Form.Control name="seconds" type="number" defaultValue={secondsDisplay} placeholder="Seconds" />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formEditEventDateMs">
                <Form.Label column md={2}> Milliseconds</Form.Label>
                <Col md={10}>
                  <Form.Control name="milliseconds" type="number" defaultValue={millisecondsDisplay} placeholder="Milliseconds" />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formEditEventFighter">
                <Form.Label column md={2}>Fighter</Form.Label>
                <Col md={10}>
                  <Form.Control as="select" name="fighterId" defaultValue={props.event.fighterId} >
                    <Translation>
                      {
                        (t, { i18n }) => < option value={0}>{t('label.fighter.none')}</option>
                      }
                    </Translation>
                    < option value={props.redFighter ? props.redFighter.id : 0}>
                      {FighterLabelStr(props.redFighter, false, t)}
                    </option>
                    < option value={props.whiteFighter ? props.whiteFighter.id : 0}>
                      {FighterLabelStr(props.whiteFighter, false, t)}
                    </option>
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formEditEventType">
                <Form.Label column md={2}>Type</Form.Label>
                <Col md={10}>
                  <Form.Control as="select" name="type" defaultValue={event.type} >
                    {Object.values(ERoundEventTypeModel).map((key) => {

                      let action: ERoundEventTypeModel = ERoundEventTypeModel[key as keyof typeof ERoundEventTypeModel];
                      let caption = "?";
                      switch (action) {
                        case ERoundEventTypeModel.MEN: caption = "MEN"; break;
                        case ERoundEventTypeModel.DO: caption = "DO"; break;
                        case ERoundEventTypeModel.KOTE: caption = "KOTE"; break;
                        case ERoundEventTypeModel.HANTEI: caption = "HANTEI"; break;
                        case ERoundEventTypeModel.TSUKI: caption = "TSUKI"; break;
                        case ERoundEventTypeModel.HANSOKU: caption = "HANSOKU"; break;
                        case ERoundEventTypeModel.UNDEFINED: caption = "UNDEFINED"; break;
                        case ERoundEventTypeModel.START: caption = "START"; break;
                        case ERoundEventTypeModel.PAUSE: caption = "PAUSE"; break;
                        case ERoundEventTypeModel.END: caption = "END"; break;
                        case ERoundEventTypeModel.ENCHO: caption = "ENCHO"; break;
                        case ERoundEventTypeModel.IPON: caption = "IPON"; break;
                        case ERoundEventTypeModel.FUSENGACHI: caption = "FUSEN SHO"; break;
                        case ERoundEventTypeModel.SUNE: caption = "SUNE"; break;
                        case ERoundEventTypeModel.ASHI: caption = "ASHI"; break;
                      }

                      if (caption !== "?") {
                        return < option key={key} value={key} >{caption}</option>
                      }
                    })
                    }
                  </Form.Control>
                </Col>
              </Form.Group>
              <Checkbox
                value={force}
                onClick={() => {
                  setForce(!force);
                }}
              />`Force update`
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row >

      </ShiajoModal>
    </>
  );
}