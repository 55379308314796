import { TEventStatus } from "../EventComponent/Event/EventStatus";
import { IApiResult } from "../UtilEvent/ApiCall";


export interface ICompetitionFilter {
    pastEvent: boolean,
    closedEvent: boolean,
    selectedGroup?: number
}

export interface IServiceCompetitionAdvancedTableRules {
    defaultMaxScore?: number,
    poolMaxScore?: number,
    eliminationMaxScore?: number,
    quarterFinalMaxScore?: number,
    semiFinalMaxScore?: number,
    finalMaxScore?: number,
}

export const OrdonancementDispatchRules = ["balance_ordered", "random", "tree_part", 'balance_next_tree_part'] as const;
export type TOrdonancementDispatchRules = typeof OrdonancementDispatchRules

export interface IServiceCompetitionAdvancedOrdonancementRules {
    retainSemiFinal: boolean,
    retainFinal: boolean,
    retainTree: boolean,
    dispatchRule: TOrdonancementDispatchRules
}

export type TApiEditCompetitionType = "competition" | "group" ;//| "grade"
export type TMartialArt = "INCONNUE" | "KENDO" | "IAIDO" | "NAGINATA" | "JODO" | "SPORT CHANBARA" | "KYUDO"

export interface IApiCompetitionModel {
    id: number,
    combatTime: number,
    comment: string,
    fullname: string,
    place: string,
    poolsize: number,
    rules: "TwoQualifiedPerPool" | "OneQualifiedPerPool" | "DirectDrawing",
    status: TEventStatus,
    teamOrderChangeAllowed: number,
    teamSize: number,
    teamSizeMax: number,
    martialArt: TMartialArt,
    advancedTables?: IServiceCompetitionAdvancedTableRules,
    advancedOrdonancement?: IServiceCompetitionAdvancedOrdonancementRules,
    advancedTablesParent?: IServiceCompetitionAdvancedTableRules,
    advancedOrdonancementParent?: IServiceCompetitionAdvancedOrdonancementRules,
    offlineStatus: TEventStatus,
    competitionDate: string, //format .toISOString().split('T')[0] 
    competitionEndDate?: string | undefined,
    registeringDate?: string | undefined,
    registeringEndDate?: string | undefined,
    registerOrganisation?:number,
    type: TApiEditCompetitionType
    category: string,
    publish: number,
    parentId?: number,
    picture?: string,
    information?: string,
    frozen?: boolean
}



export interface IApiEditCompetitionModel {
    id: number,
    fullname?: string,
    type?: TApiEditCompetitionType 
    combatTime?: number,
    comment?: string,
    place?: string,
    poolsize?: number,
    rules?: "TwoQualifiedPerPool" | "OneQualifiedPerPool" | "DirectDrawing",
    status?: TEventStatus,
    teamOrderChangeAllowed?: number,
    teamSize?: number,
    teamSizeMax?: number,
    martialArt?: TMartialArt,
    advancedTables?: IServiceCompetitionAdvancedTableRules,
    advancedOrdonancement?: IServiceCompetitionAdvancedOrdonancementRules,
    offlineStatus?: string,
    competitionDate?: string | undefined, //format .toISOString().split('T')[0] 
    competitionEndDate?: string | undefined,
    registeringDate?: string | undefined,
    registeringEndDate?: string | undefined,
    registerOrganisation?:number,
    category?: string,
    publish?: number,
    parentId?: number,
    picture?: string
    information?: string
}

export const emptyEditCompetitionModel: IApiEditCompetitionModel = {
    id: 0,
    fullname: "",
    type: "competition",
    competitionDate: undefined,
    /*combatTime: 300,
    comment: "",
    
    place: "",
    poolsize: 3,
    rules: "TwoQualifiedPerPool",
    status: "Created",
    teamOrderChangeAllowed: 7,
    teamSize: 1,
    teamSizeMax: 1,
    martialArt: "Kendo",
    advancedTables: undefined,
    offlineStatus: "",
    competitionDate: new Date().toISOString().split('T')[0],
    
    category: "",*/
    publish: 1
}

export const emptyCompetitionModel: IApiCompetitionModel = {
    id: 0,
    fullname: "",
    type: "competition",
    combatTime: 300,
    comment: "",
    
    place: "",
    poolsize: 3,
    rules: "TwoQualifiedPerPool",
    status: "Created",
    teamOrderChangeAllowed: 7,
    teamSize: 1,
    teamSizeMax: 1,
    martialArt: "KENDO",
    advancedTables: undefined,
    offlineStatus: "",
    competitionDate: new Date().toISOString().split('T')[0],
    
    category: "",
    publish: 1,
}


export const sampleCompetitionModels: Array<IApiCompetitionModel> = [{
    id: 2,
    combatTime: 0,
    comment: "un comment",
    fullname: "competition de test",
    place: "une place",
    poolsize: 1,
    rules: "TwoQualifiedPerPool",
    status: "Created",
    teamOrderChangeAllowed: 0,
    teamSize: 0,
    teamSizeMax: 0,
    martialArt: "KENDO",
    advancedTables: undefined,
    offlineStatus: "",
    competitionDate: new Date().toISOString().split('T')[0],
    type: "competition",
    category: "une category",
    publish: 1

}]

export interface IApiShiajoResult extends IApiResult {
    data: IApiCompetitionModel
}

export interface IApiCompetitionCreateShiajoModel {
    fullname: string,
    comment: string,
    shortname: string,
    order: number
}

export type TCompetitionStatus = "Created" | "RegistrationOpen" | "RegistrationClosed" | "DrawingsDone" | "DrawingsPublished" | "Ongoing" | "Finished" | 'offline' | "";

export interface IApiCompetitionShiajoModel {
    fullname: string,
    comment: string,
    shortname: string,
    competitionId: Array<number>,
    id: number,
    order: number
}

export const emptyCompetitionShiajoModel: IApiCompetitionShiajoModel = {
    fullname: "",
    comment: "",
    shortname: "",
    competitionId: [],
    id: 0,
    order: 0
}

export interface IApiShiajoResult extends IApiResult {
    data: IApiCompetitionModel
}

export interface IDocumentInfo {

    getPath: string,
    name: string

}
export interface IServiceCompetitionAdvancedTableRules {
    defaultMaxScore?: number,
    poolMaxScore?: number,
    eliminationMaxScore?: number,
    quarterFinalMaxScore?: number,
    semiFinalMaxScore?: number,
    finalMaxScore?: number,
    resolveNone?: boolean,
    configurationDone?: boolean,
    canChangeBeforeFirstFight?: boolean;
    canChangeInTree?: boolean;
    canChangeInPool?: boolean;
    showCountry?: boolean;
    document?: {
        [name: string]: IDocumentInfo
    }
}