import React from 'react';
import { useTranslation } from 'react-i18next';
import { patchFightSolve } from '../../util/ApiFight';
import { IApiFighterModel } from '../../util/ApiModel/ApiFighterModel';
import { IApiFightModel, IFightTeamState } from '../../util/ApiModel/ApiFightModel';
import { IApiRoundModel } from '../../util/ApiModel/ApiRoundModel';
import { IServiceTableNotification } from '../Util/ErrorMessage';
import { IFightState } from './Fight';

export interface IFightAutomaticResolveButton {
    fight: IFightState,
    winner?: IFightTeamState,
    dispatchNotification: React.Dispatch<IServiceTableNotification>,
    reload: (fight: IApiFightModel) => Promise<void>
}

export function FightAutomaticResolveButton(props: IFightAutomaticResolveButton) {
    const { t } = useTranslation();

    return <button type="button" className="btn btn-outline-primary  btn-sm shiajo_button_fighter_action"
        onClick={() => {
            patchFightSolve(props.dispatchNotification, props.fight.data.id).then((res) => {
                if (res) props.reload(res);
            })
        }}
    >
        {t("action.fight.autoresolve")}
    </button>
}