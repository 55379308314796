import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ShiajoModal from './ShiajoModal';
import useShiajoModal from "./UseShiajoModal";

export interface IShowScoreModalProps {
    message?: string;
    label: JSX.Element | string;
    action: () => void;
    color?: "error" | "primary" | "secondary" | "info" | "success" | "warning" | "inherit" | undefined
    buttonSize?: 'small' | 'medium' | 'large',
    disabled?: boolean
}

export function ConfirmModal(props: IShowScoreModalProps) {
    const [isShowing, toggle] = useShiajoModal();
    const { t, i18n } = useTranslation();

    const message = props.message ? props.message : t('label.modal.confirm');

    const messageLines = message.split('\n').map((m, i) => {
        return <React.Fragment key={i}>{m}<br /></React.Fragment >
    });

    return <>
        <ShiajoModal
            isShowing={isShowing}
            hide={toggle}

            title={t("label.title.confirm")}>
            {messageLines}
            <Button variant='contained' color="success" onClick={() => {
                props.action();
                toggle();
            }}>{t('action.modal.confirm')}</Button>
            <Button variant='contained' color="error" onClick={() => toggle()}>{t('action.modal.cancel')}</Button>
        </ShiajoModal>

        {(typeof props.label === "string") && <Button disabled={props.disabled} variant='contained' size={props.buttonSize ?? 'small'} color={props.color ? props.color : "error"} onClick={
            () => toggle()}>
            {props.label}
        </Button>}
        {(typeof props.label !== "string") && <div
            onClick={() => {
                if (!props.disabled) toggle()
                }}>

            {props.label}
        </div >}
    </>
}