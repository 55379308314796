import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { getCompetition } from "../../Backend/ApiCompetition";
import { IApiFighterFightTeam } from "../../Model/ApiTeamModel";
import { ICompTreeRank, getTreeRank } from '../../util/ApiCompetition';
import { TSvgParam, getCompetitionSvg, getFollowingCompetitionAll } from "../../util/ApiFollowing";
import { ERoundEventTypeModel } from "../../util/ApiModel/ApiEventModel";
import { IApiCompetitionTreeModel } from "../../util/ApiModel/Following/ApiCompetitionTree";
import { SessionCache } from "../../util/SessionStorage";
import { getApiObject } from '../Operation/GenericOperation';
import { CountryFlagScreen } from "../Screen/CountryFlagScreen";
import { lazyLoadFightTeams } from '../Team/LazyFightTeam';
import { getConfigurationSelection } from "../Util/Configuration";
import { IServiceTableNotification } from "../Util/ErrorMessage";
import { ICompetitionEventsResult, ISvgFightgroup } from "./TreeGroupSvg";

export interface ISvgTreeConf {
    replaceFighter: boolean,
    replaceIppons: boolean,
    width?: number,
    maxHeight?: number,
    fullscreen: boolean,
    showResult?: boolean
}



export type TSvgParams = Map<number, TSvgParam>;
function treeSvgEvent(type: string): string | undefined {
    let res = undefined;
    switch (type) {
        case ERoundEventTypeModel.FUSENGACHI:
            res = "FUSENGACHI";
            break;
        case ERoundEventTypeModel.UNDEFINED:
            res = "?";
            break;
        case ERoundEventTypeModel.DO:
            res = "D";
            break;
        case ERoundEventTypeModel.HANSOKU:
            //res = "x";
            break;
        case ERoundEventTypeModel.HANTEI:
            res = "Ht";
            break;
        case ERoundEventTypeModel.KOTE:
            res = "K";
            break;
        case ERoundEventTypeModel.SUNE:
            res = "S";
            break;
        case ERoundEventTypeModel.ASHI:
            res = "A";
            break;
        case ERoundEventTypeModel.IPON:
            res = "I";
            break;
        case ERoundEventTypeModel.MEN:
            res = "M";
            break;
        case ERoundEventTypeModel.TSUKI:
            res = "T";
            break;
    }
    return res;
}

function eventSummary(events: Array<{
    type: string,
    date: Date,
    color: 'red' | 'white'
}>, filter: 'red' | 'white') {
    let resultMap = events.filter(e => e.color === filter).map(e => {
        return treeSvgEvent(e.type)
    }).filter(e => e !== undefined);


    if (resultMap.length <= 2) {
        return resultMap.join("")
    }
    else {
        let entries = resultMap.reduce((iMap: Map<string, number>, e) => {
            if (e && e.length > 0) {
                iMap.set(e, (iMap.get(e) ?? 0) + 1);
            }
            return iMap;
        }, new Map());
        return [...entries.entries()].map(([type, count]) => {
            return `${count > 1 ? count : ""}${type}`;
        }).join("");
    }

}

/*
//Proprieté des svgs
            for (let index = 0; index < competitonIds.length; index++) {
                const competitonId = competitonIds[index].id;
                const competitonInfo = getSelection().selectedCompetition.find(c => c.id === competitonId);
                const svgStringToAdd = svgs[index];
                //TODO ; optimiser en ne demandant que la taille maximum
                if (svgStringToAdd) newSvg.push({ name: `${competitonId} - ${competitonInfo?.fullname}`, svg: svgStringToAdd, id: competitonId });
            }
            
            */

export function CompetitionTreeSvg(
    props: {
        competitionId: number,
        dispatchNotification: React.Dispatch<IServiceTableNotification>,
        svgTreeConf: ISvgTreeConf,
        svgParam: TSvgParam,
        selectionName?: string,
        showResult?: boolean,
        init?: Array<number>
    }
): JSX.Element {
    //const [loadTime, setLoadTime] = useState<number | undefined>(undefined)
    const { t } = useTranslation();
    //const [svgParams, setSvgParams] = useState<TSvgParam>(props.svgParam);
    const [svg, setSvg] = useState<{
        svgString: string | undefined,
        treeRank: ICompTreeRank | undefined,
        fightTeams: Array<IApiFighterFightTeam>,
        fightGroups: Array<ISvgFightgroup>,
        infos: IApiCompetitionTreeModel[],
        messageStatus: string,
        lastTry: number | undefined
    }>({
        svgString: undefined,
        treeRank: undefined,
        fightTeams: [],
        fightGroups: [],
        infos: [],
        messageStatus: "LOADING",
        lastTry: undefined
    });
    const [svgTransform, setSvgTransform] = useState<{
        svgScaledHeight: number,
        scroll: number,
        maxHeight: number
    }>({
        maxHeight: 0,
        scroll: 0,
        svgScaledHeight: 0
    });
    const [competition, setCompetition] = useState<number | undefined>(
        (getConfigurationSelection(props.selectionName, true)?.selectedCompetition ?? (props.init ?? []))?.find(c => c === props.competitionId))
    const [toPrint, setToPrint] = useState<boolean>(false);
    const [width, SetWidth] = useState<number | undefined>(props.svgTreeConf.width);
    const svgRef = useRef(null);//for print

    const [competitionName, setCompetitionName] = useState<string | undefined>(undefined)

    const [selectedParams, setSelectedParams] = useState<{
        displayType: number | undefined
    }>
        ({
            displayType: undefined
        })



    const printAction = useReactToPrint({
        content: () => {
            return svgRef.current ?? null
        },
        onBeforeGetContent: () => {

        },
        onAfterPrint: () => {
            setToPrint(false);
        }
    });

    /**
     * Change competition selection
     */
    useEffect(() => {
        let newcompetition = (getConfigurationSelection(props.selectionName, true)?.selectedCompetition ?? (props.init ?? [])).find(c => c === props.competitionId);
        if ((competition ?? -1) !== (newcompetition ?? -1)) {
            setCompetition(newcompetition);
        }
    }, [competition, props.competitionId, props.init, props.selectionName])

    /**
         * Change competition selection
         */
    useEffect(() => {
        let display = true;
        if (competition) {
            SessionCache.get('competition', competition, () => getCompetition(props.dispatchNotification, competition), 1000).then(c => {
                if (display) {
                    if (c) {
                        setCompetitionName(c.fullname);
                    }
                    else {
                        setCompetitionName(undefined);
                    }
                }
            });
        }
        else {
            setCompetitionName(undefined);
        }
        return () => { display = false; };
    }, [competition, props.dispatchNotification]);

    /**
     * Print
     */
    useEffect(() => {
        if (toPrint) {
            printAction();
        }
    }, [printAction, toPrint])

    /**
     * Size
     */

    useEffect(() => {
        SetWidth(props.svgTreeConf.width);
    }, [props.svgTreeConf.width])

    /**
     * Svg config
     */
    /*
    useEffect(() => {
        if (JSON.stringify(props.svgParam) !== JSON.stringify(svgParams)) {
            setSvgParams(props.svgParam);
        }
    }, [props.svgParam])*/

    /**
     * Display type
     */
    /*
    const updateDisplayType = useCallback((depthSize: number) => {
        if (svgParams.displayType !== (depthSize > 16 ? 2 : 1)) {
            setSvgParams((s) => {
                return {
                    ...s,
                    displayType: (depthSize > 16 ? 2 : 1)
                }
            });
        }
    }, [svgParams.displayType])*/
    const SVG_TRANSFORM_INTERVAL = 2000;
    useEffect(() => {
        let i: NodeJS.Timer | undefined = undefined;
        if (i === undefined && props.svgParam.resizeHeight === false && svgTransform.maxHeight > 0 && svgTransform.svgScaledHeight > svgTransform.maxHeight) {
            i = setInterval(() => {
                setSvgTransform(s => {
                    let newScroll = (s?.scroll ?? 0) + 50;
                    if (newScroll > (svgTransform.svgScaledHeight - svgTransform.maxHeight + 100)) newScroll = 0;
                    return {
                        ...s,
                        scroll: newScroll
                    };
                })
            }, SVG_TRANSFORM_INTERVAL)
        }
        else {
            if (svgTransform.scroll > 0) {
                setSvgTransform((s) => {
                    return {
                        ...s,
                        scroll: 0
                    }
                });
            }
        }
        return () => {
            if (i !== undefined) {
                clearInterval(i);
                i = undefined;
            }
        }
    }, [props.svgParam.resizeHeight, svgTransform.maxHeight, svgTransform.scroll, svgTransform.svgScaledHeight]);
    /**
     * load svg
     */
    useEffect(() => {
        let mounted = true;

        let refresh = async () => {
            const controller = new AbortController();
            const svgString = mounted ? await getCompetitionSvg(props.competitionId, {
                ...props.svgParam,
                displayType: selectedParams.displayType ?? props.svgParam.displayType
            }, props.dispatchNotification) : undefined;
            if (svgString?.succeed) {
                const treeRank = mounted ? await getTreeRank(props.dispatchNotification, props.competitionId) : undefined;
                let fightTeam: Array<IApiFighterFightTeam> = [];
                if (props.svgTreeConf.replaceFighter) {
                    fightTeam = mounted ? await lazyLoadFightTeams({
                        id: props.competitionId,
                        dispatchNotification: props.dispatchNotification
                    }) : [];
                }
                const fightgroups: ICompetitionEventsResult | undefined = mounted ? await getApiObject<ICompetitionEventsResult>(`/competitions/${props.competitionId}/events`, null, {
                    succeed: false,
                    data: {
                        fightgroups: []
                    }
                }, props.dispatchNotification) : undefined;

                const infos = mounted ? await getFollowingCompetitionAll(props.competitionId, props.dispatchNotification) : undefined;

                /* const depth = infos.reduce((m, d) => Math.max(m, d.depth), 0);
                 const depthSize = infos.filter(i => i.depth === depth).length;
                 updateDisplayType(depthSize);*/

                if (svgString?.succeed) {
                    if (mounted) {

                        setSvg((s) => {
                            return {
                                ...s,
                                fightTeams: fightTeam,
                                fightGroups: fightgroups?.succeed ? fightgroups.data.fightgroups : [],
                                treeRank: treeRank,
                                svgString: svgString?.data,
                                infos: infos ?? [],
                                messageStatus: "Loaded"
                            }
                        });
                    }
                } else {
                    //not succeed
                    setTimeout(() => {
                        if (mounted) {
                            setSvg((s) => {
                                return {
                                    ...s,
                                    lastTry: Date.now()
                                }
                            });
                        }
                    }, 3000);

                }
            }
            else {
                //not succeed
                setTimeout(() => {
                    if (mounted) {
                        setSvg((s) => {
                            return {
                                ...s,
                                lastTry: Date.now()
                            }
                        });
                    }
                }, 3000);

            }

        };
        refresh();
        let refreshTimeout = setTimeout(() => {
            if (mounted) {
                setSvg((s) => {
                    return {
                        ...s,
                        lastTry: Date.now()
                    }
                });
            }
        }, 30000);
        return (() => {
            clearTimeout(refreshTimeout);
            mounted = false
        });
    }, [props.competitionId, props.dispatchNotification, props.svgTreeConf.replaceFighter, props.svgParam, svg.lastTry, selectedParams.displayType]);

    let transformedSvg: string | undefined = svg?.svgString?.toString();

    const widthToUse = toPrint ? 990 : props.svgTreeConf.width;
    if (transformedSvg && transformedSvg.length > 0) {

        //svgString = svgString?.replace(/<svg width="([0-9*]+)" height="([0-9*]+)"/, '<svg width="100" height="100"  viewBox="0 0 $1 $2"');
        //svgString = svgString?.replace(/preserveAspectRatio="xMidYMid meet"/, '');
        let svgMatch = /<svg +width="([0-9]+)" +height="([0-9]+)"/.exec(transformedSvg ?? "");

        if (svgMatch && widthToUse) {
            let svgWidth = parseInt(svgMatch[1]);
            let svgHeight = parseInt(svgMatch[2]);
            const maxScale = (props.svgParam.resizeHeight && props.svgTreeConf.maxHeight) ? props.svgTreeConf.maxHeight / svgHeight : 10;

            const widthScale = widthToUse / svgWidth;
            const scale = widthScale < maxScale ? widthScale : maxScale;
            const svgScaledHeight = svgHeight * scale;
            if (svgScaledHeight !== svgTransform.svgScaledHeight || svgTransform.maxHeight !== (props.svgTreeConf.maxHeight ?? 0))
                setSvgTransform({
                    ...svgTransform,
                    svgScaledHeight: svgScaledHeight,
                    maxHeight: props.svgTreeConf.maxHeight ?? 0
                });
            transformedSvg = transformedSvg?.replace(/<svg +width="[0-9*]+" ([^>]*)>/, `<svg width="${widthToUse}" $1><g transform="scale(${scale})">`);
            transformedSvg = transformedSvg?.replace(/<\/svg>/, `</g></svg>`);
        }

        transformedSvg = transformedSvg?.replace('height="150"', 'height="350"');//tableau des rank, en attendant un paramètre
        if (svg.treeRank && props.svgTreeConf.showResult) {
            if (svg.treeRank.first || svg.treeRank.second || svg.treeRank.third.length > 0 || svg.treeRank.fightingSpirit.length > 0) {
                while (transformedSvg.search("<td>@") >= 0) transformedSvg = transformedSvg?.replace("<td>@", "<td style='font-size: 14px;font-weight:bold;'>@");
                transformedSvg = transformedSvg?.replace("@FIRST@",
                    `
                ${props.svgParam.countryFlag && svg.treeRank.first?.nationality ? `<img className="flag" width="20px" style="margin: 5px" 
                src="img/flags/1x1/${svg.treeRank.first?.nationality.toLowerCase()}.svg" alt=${svg.treeRank.first?.nationality.toLowerCase()} />` : ''}
                ${t('label.word.shortfirst')} - ${svg.treeRank.first?.name ?? ""} (${svg.treeRank.first?.club ?? t('label.fighter.noclub')})`) ?? "";
                transformedSvg = transformedSvg?.replace("@SECOND@",
                    `
                ${props.svgParam.countryFlag && svg.treeRank.second?.nationality ? `<img className="flag" width="20px" style="margin: 5px" 
                src="img/flags/1x1/${svg.treeRank.second?.nationality.toLowerCase()}.svg" alt=${svg.treeRank.second?.nationality.toLowerCase()} />` : ''}
                ${t('label.word.shortsecond')} - ${svg.treeRank.second?.name ?? ""} (${svg.treeRank.second?.club ?? t('label.fighter.noclub')})`) ?? "";

                transformedSvg = transformedSvg?.replace("@THIRD@",
                    `${t('label.word.shortthird')}<br/> ${svg.treeRank.third.map(f => `
                    ${props.svgParam.countryFlag && f.nationality ? `<img className="flag" width="20px" style="margin: 5px"
                src="img/flags/1x1/${f.nationality.toLowerCase()}.svg" alt=${f.nationality.toLowerCase()} />` : ''}
                    
                    - ${f.name} (${f.club ?? t('label.fighter.noclub')})`).join('<br/>')}`);
                transformedSvg = transformedSvg?.replace("@FS@", `Fighting spirit <br/>${svg.treeRank.fightingSpirit.map(f =>
                    `
                    ${props.svgParam.countryFlag && f.nationality ? `<img className="flag" width="20px" style="margin: 5px" 
                src="img/flags/1x1/${f.nationality.toLowerCase()}.svg" alt=${f.nationality.toLowerCase()} />` : ''}
                    ${f.firstname} ${f.lastname} (${f.club ?? t('label.fighter.noclub')})`).join('<br/>')}`) ?? "";
            }
            else {
                transformedSvg = transformedSvg?.replace("name='scoreTable'", "name='scoreTable' class='table-hidden'");
            }
        }
        else {
            transformedSvg = transformedSvg?.replace("name='scoreTable'", "name='scoreTable' class='table-hidden'");
        }
        //FIGHTGROUPS
        //@Fighter${fightTeam.id}@
        //@Fighter13@
        for (let index = 0; index < svg.fightTeams.length; index++) {

            const fightTeam = svg.fightTeams[index];
            const country = fightTeam.nationality?.toLowerCase() ?? "";
            let fontSize = 18;
            if (fightTeam.name.length > 30) {
                fontSize = 10;
            }
            else if (fightTeam.name.length > 20) {
                fontSize = 13;
            }
            else if (fightTeam.name.length > 15) {
                fontSize = 15;
            }

            const name =
                `<tspan dy="-0.2em" style="font-size:${fontSize}px;">${fightTeam.name}</tspan>`;
            //let re = new RegExp('@Fighter' + fightTeam.id + '@', "g");
            let re = new RegExp('Fighter' + fightTeam.id + '<', "g");
            //debug(`@Fighter${fightTeam.id}@`);

            transformedSvg = transformedSvg?.replace(re, name + "<");


            re = new RegExp('class="Fighter' + fightTeam.id + '_flag"', "g");
            transformedSvg = transformedSvg?.replace(re, " xlink:href='img/flags/1x1/" + country + ".svg' ");



            re = new RegExp('@Fighter' + fightTeam.id + '@', "g");
            transformedSvg = transformedSvg?.replace(re, name);
        }
        const fgs = svg.fightGroups;

        //IPPONS
        for (let index = 0; index < fgs.length; index++) {
            const fg = fgs[index];
            if (props.svgTreeConf.replaceIppons) {
                let redeventstr = `<tspan dy="-0.1em">${eventSummary(fg.events, "red")}</tspan>`;
                let whiteeventstr = `<tspan  dy="0.4em">${eventSummary(fg.events, "white")}</tspan>`;
                //eventstr = `${eventstr.toLowerCase()}`;

                /*let str = "";
                for (let index = 0; index < fg.events.length; index++) {
                    const element = fg.events[index];
                    const ch = treeSvgEvent(element.type);
                    if (ch) str = "a";
                }
                if (str.length > 0) */
                //console.error(`@${fg.fightGroupNodeId}@ : ${eventstr}`);
                //let str = redeventstr;
                //str += `${str.length > 0 && whiteeventstr.length > 0 ? "-" : ""}${whiteeventstr}`
                transformedSvg = transformedSvg?.replace(`@${fg.fightGroupNodeId}R@`, redeventstr);
                transformedSvg = transformedSvg?.replace(`@${fg.fightGroupNodeId}W@`, whiteeventstr);
            }
            else {
                transformedSvg = transformedSvg?.replace(`@${fg.fightGroupNodeId}R@`, "");
                transformedSvg = transformedSvg?.replace(`@${fg.fightGroupNodeId}W@`, "");
            }
            //console.error(res.indexOf(eventstr));
        }
    }
    else {
        transformedSvg = undefined;
    }



    const re = new RegExp('@[0-9]+[RW]@', "g");
    transformedSvg = transformedSvg?.replace(re, "");
    return <div ref={svgRef} /*style={{
        width: widthToUse
    }}*/ >
        {svg.svgString === undefined && <>{svg.messageStatus}</>}
        <Grid item xs={12} className='no-print'>
            {props.svgTreeConf.fullscreen !== true &&
                <>
                    {<Button variant="contained" color="success" onClick={() => {
                        let newSelectedParams = {
                            displayType: (selectedParams.displayType ?? props.svgParam.displayType) === 2 ? 1 : 2,
                        }
                        setSelectedParams(newSelectedParams);
                    }}>
                        {(selectedParams.displayType ?? props.svgParam.displayType) === 2 ? t('action.following.simpletree') : t('action.following.doubletree')}
                    </Button>}
                    <Button variant="contained" color="primary" onClick={() => {
                        setToPrint(true);
                    }}>
                        Imprimer
                    </Button>
                </>
            }
        </Grid>
        <Grid item xs={12}><h3 className="tree-svg-h1">{competitionName}</h3></Grid>
        <Grid item xs={12} style={{
            overflow: 'hidden'
        }
        }>
            {transformedSvg && <div className="tree-svg-content"
                style={{
                    //marginTop: `${svgTransform.scroll * -1}px`,
                    transform: toPrint ? undefined : `translate(0, ${svgTransform.scroll * -1}px)`
                }}
                dangerouslySetInnerHTML={{ __html: transformedSvg }} /*style={{
                width: toPrint ? '100px' : '100px' 
            }}*/ />}
            {transformedSvg === undefined && svg.treeRank && props.svgTreeConf.showResult && <TableContainer component={Paper}>
                <Table >
                    <TableBody>
                        <TableRow>
                            <TableCell >
                                {props.svgParam.countryFlag && <CountryFlagScreen country={svg.treeRank.first?.nationality} />}
                                {svg.treeRank.first?.name ?? ""}
                            </TableCell >
                        </TableRow>
                        <TableRow>
                            <TableCell >
                                {props.svgParam.countryFlag && <CountryFlagScreen country={svg.treeRank.second?.nationality} />}
                                {svg.treeRank.second?.name ?? ""}
                            </TableCell >
                        </TableRow>
                        <TableRow>
                            <TableCell >
                                {svg.treeRank.third.map(f => <>
                                    {props.svgParam.countryFlag && <CountryFlagScreen country={f.nationality} />}
                                    {f.name}
                                </>).join('<br/>')}
                            </TableCell >
                        </TableRow>
                        <TableRow>
                            <TableCell >
                                Fighting spirit <br />
                                {svg.treeRank.fightingSpirit.map(f => <>
                                    {props.svgParam.countryFlag && <CountryFlagScreen country={f.nationality} />}
                                    {f.firstname} {f.lastname}
                                </>).join('<br/>')}
                            </TableCell >
                        </TableRow>
                    </TableBody>
                </Table>

            </TableContainer>
            }
        </Grid>
    </div >
}
