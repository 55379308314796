
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem, Select } from '@mui/material';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { announcementList, IEventAnnoucement, postCompetitionNotification, TannouncementSubject, TDateType } from '../../Backend/ApiNotification';
import { IApiCompetitionModel } from '../../Model/ApiCompetitionModel';
import { useErrorMessage } from '../Util/ErrorMessage';

export interface IAnnouncementDialog {
    competition: IApiCompetitionModel
}



export function useAnnouncementDialog(props: IAnnouncementDialog): [(open: boolean) => void, JSX.Element] {
    const [open, setOpen] = React.useState(false);
    const [dispatchNotification, errorComponent] = useErrorMessage(true, "Div");
    const [announcement, setAnnoucement] = React.useState<IEventAnnoucement>({
        subject: "CUSTOM",
        details: "",
        dateType: "date",
        hour: new Date().getHours(),
        min: new Date().getMinutes(),
    });
    const { t } = useTranslation();

    const sendAnnouncement = () => {
        postCompetitionNotification(dispatchNotification, props.competition, announcement);
    }

    const dialog = <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {t("title.announcement.send")}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {t("label.announcement.send")}
            </DialogContentText>
            {errorComponent}
            <Grid container spacing={0}>
                <Grid item xs={12}>

                    <Select
                        onChange={(e) => setAnnoucement({ ...announcement, subject: e.target.value as TannouncementSubject })}
                        value={announcement.subject}>
                        {announcementList.map((v, k) => {
                            return <MenuItem key={k} value={v}>{t("label.announcement." + v)}</MenuItem>
                        })}
                    </Select>
                    <Select
                        onChange={(e) => setAnnoucement({ ...announcement, dateType: e.target.value as TDateType })}
                        value={announcement.dateType}>
                        {['date', 'duration', 'noDate'].map((v, k) => {
                            return <MenuItem key={k} value={v}>{t("label.announcement." + v)}</MenuItem>
                        })}
                    </Select>
                </Grid>
                {announcement.dateType === 'date' && <Grid item xs={12}>
                    <TextField
                        style={{ width: 100 }}
                        value={announcement.hour}
                        onChange={(e) => setAnnoucement({
                            ...announcement, hour:
                                parseInt(e.target.value)
                        })}
                        id="outlined-basic" label="Hours" variant="outlined" />
                    <TextField
                        style={{ width: 100 }}
                        value={announcement.min}
                        onChange={(e) => setAnnoucement({
                            ...announcement, min:
                                parseInt(e.target.value)
                        })}
                        id="outlined-basic" label="Minutes" variant="outlined" />
                </Grid>}
                {announcement.dateType === 'duration' && <Grid item xs={12}>
                    <TextField
                        style={{ width: 100 }}
                        value={announcement.min}
                        onChange={(e) => setAnnoucement({
                            ...announcement, min:
                                parseInt(e.target.value)
                        })}
                        id="outlined-basic" label="Minutes" variant="outlined" />
                </Grid>}
                <Grid item xs={12}>
                    <TextField
                        value={announcement.details}
                        onChange={(e) => setAnnoucement({ ...announcement, details: e.target.value })}
                        id="outlined-basic" label="Comments" variant="outlined" />

                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                sendAnnouncement()
            }}>{t("action.announcement.send")}</Button>
            <Button onClick={() => setOpen(false)}> {t("action.announcement.quit")}</Button>
        </DialogActions>
    </Dialog>

    return [
        setOpen,
        dialog]
}