
import { Backdrop, Box, Button, Checkbox, CircularProgress, Grid, Input, MenuItem, Modal, Paper, Select, Typography } from '@mui/material';
import produce from 'immer';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { getFightersTeam } from '../../Backend/ApiFighter';
import { IApiCompetitionModel, emptyCompetitionModel } from '../../Model/ApiCompetitionModel';
import { IApiResult } from '../../util/ApiCall';
import { IApiCompetitionShiajoModel, deleteCompetitionShiaijo, emptyApiCompetitionShiajoModel, getShiajo, getShiajosMap, patchCompetitionShiaijo, patchShiajo } from '../../util/ApiCompetition';
import { getAvailable, getFollowingFightGroup, postponeSuivi, reload, sortCompetitionTreeModel } from '../../util/ApiFollowing';
import { IApiFightGroupResult, emptyFightgroup } from '../../util/ApiModel/ApiFightgroupModel';
import { IApiShiajoModel } from '../../util/ApiModel/ApiShiajoModel';
import { IApiCompetitionTreeModel, IApiCompetitionTreeResult } from '../../util/ApiModel/Following/ApiCompetitionTree';
import { allocateToShiaijo, cancelSchedulledFight, emptySchedullerResult, moveFightgroupToShiaijo } from '../../util/ApiScheduller';
import { IAuthentication } from '../Auth/auth';
import { useConfiguration } from '../Competition/CompetitionConfiguration';
import ShiajoModal from '../Modal/ShiajoModal';
import { deleteFightgroupNodeId } from '../Operation/FightGroupOperation';
import { deleteApiObject, getApiObject, patchApiObject, putApiObject } from '../Operation/GenericOperation';
import { deleteNotPostpone } from '../Operation/ShiajoOperation';
import { ShiaijoSelectModal } from "../Shiaijo/ShiaijoSelectModal";
import { usePopOver } from '../Use/UsePopOver';
import { useSelectedCompetitions } from '../Use/UseSelectCompetitions';
import { IServiceTableNotification } from '../Util/ErrorMessage';
import { useSseSecureClient } from '../Util/SseSecureClient';
import { SchedullerCompetitition } from './SchedullerCompetition';
import { IShiaijoSchedullerPropsNoAction, ShiaijoScheduller } from './ShiaijoScheduller';

var debug = require('debug')('ServiceTablesMinimalFrontend:SchedullerTable');
export interface ISchedullerList {
    competitions: Array<ISchedullerWithMissing>,
    shiaijosMap: Map<number, IApiCompetitionShiajoModel>,
    shiaijos: Array<IShiaijoSchedullerPropsNoAction>
}

/**
 * Schedulle for a competition :
 * - list of fightgroup
 * - list of shiaijos
 */
export interface IScheduller {
    competition: number,
    fight: Array<ISchedullerFight>,
    shiaijo: Array<ISchedullerShiaijo>
}

/**
 * Schedulle for a competition :
 * - list of fightgroup
 * - list of fight
 * - list of unschedulled fightgroups (fightgroups in following but not in scheduller)
 */
export interface ISchedullerWithMissing extends IScheduller {
    unschedulledFights: Array<IApiCompetitionTreeModel>;
}

/**
 * Schedulled fight
 */
export interface ISchedullerFight {
    competition: number,
    fightGroupNodeId: number,
    id: number,
    shiaijo: number,
    status: undefined | "finished" | "postponed" | "progress",
    newShiaijoId?: undefined | number,
    label: string,
    poolFightGroup: boolean,
    fightOrder: number,
    depth: number
}

export interface ISchedullerShiaijo {
    comment: string,
    competition: number,
    ext_id: number,
    fight: Array<ISchedullerFight>,
    available: boolean,
    order: number
}





export interface ISchedullerResult extends IApiResult {
    data: IScheduller
}

export interface ISchedullerShiaijoResult extends IApiResult {
    data: ISchedullerShiaijo
}



export interface ISchedullerProps {
    dispatchNotification: React.Dispatch<IServiceTableNotification>,
    authentication: IAuthentication
}


async function loadShiaijos(selectedCompetitions: Array<IApiCompetitionModel>, dispatchNotification: React.Dispatch<IServiceTableNotification>): Promise<Map<number, IApiCompetitionShiajoModel>> {
    let cList = selectedCompetitions;
    let sMap: Map<number, IApiCompetitionShiajoModel> = new Map();
    for (let index = 0; index < cList.length; index++) {
        const c = cList[index];
        let ss = await getShiajosMap(dispatchNotification, c.id);
        ss.forEach(s => {
            sMap.set(s.id, s);
        });
    }
    return sMap;
}

const schedullerFightContent = async (params: ISchedullerFight | IApiCompetitionTreeModel | undefined
    , dispatchNotification: React.Dispatch<IServiceTableNotification>
    , t: any
) => {
    let result = {
        title: "",
        content: <></>
    };
    if (params) {
        //load fightgroup from table
        let data = await getApiObject<IApiFightGroupResult>('/fightgroups/fightGroupNodeId', params.fightGroupNodeId, emptyFightgroup, dispatchNotification).then(
            (result: IApiFightGroupResult) => {
                if (result.succeed) {
                    return result.data;
                } else {
                    return undefined;
                }
            }
        );
        if (data) {
            result = {
                title: `${data.fightGroupNodeId} - ${data.label}`,
                content: <>
                    {data.fights.map((f, k) => {
                        return <Row key={k}>
                            <Col md={4}>
                                {t("label.word.red")} {f.redFightTeam.name}
                            </Col>
                            <Col md={4}>
                                {t("label.word.versus")}
                            </Col>
                            <Col md={4}>
                                {t("label.word.white")} {f.whiteFightTeam.name}
                            </Col>
                        </Row>
                    })}
                </>
            }
        } else {

            let followingInfo = await getFollowingFightGroup(params.fightGroupNodeId, dispatchNotification);
            let teamInfos: Array<JSX.Element> = [];
            if (followingInfo) for (const fightGroupData of followingInfo?.fightGroupData) {
                const teamInfo1 = await getFightersTeam(dispatchNotification, fightGroupData.redTeamId);
                const teamInfo2 = await getFightersTeam(dispatchNotification, fightGroupData.whiteTeamId);
                teamInfos.push(<Row>
                    <Col md={4}>
                        {t("label.word.red")} {teamInfo1.name}
                    </Col>
                    <Col md={4}>
                        {t("label.word.versus")}
                    </Col>
                    <Col md={4}>
                        {t("label.word.white")} {teamInfo2.name}
                    </Col>
                </Row>);
            }
            result = {
                title: `${params.label} - ${params.fightGroupNodeId}`,
                content: <><Row>
                    <Col md={12}>
                        {t("message.tables.fightGroupNotFound")}
                    </Col>
                </Row>
                    <Row>
                        {teamInfos.map((t, k) => {
                            return <React.Fragment key={k}>{t}</React.Fragment>
                        })}
                    </Row>
                </>
            }
        }
    }
    return result;
};

export interface IFightSchedullerActions {
    postponeSuivi?: (fightgroupnodeid: number, force: boolean) => Promise<void>,
    scheduleAllocate?: (competitionId: number, fightNodeId: number) => Promise<void>,
    changeNewShiajo?: (fightGroup: ISchedullerFight | IApiCompetitionTreeModel, value?: string) => Promise<void>,
    scheduleCreateFight?: (competitionId: number, fightNodeId: number) => Promise<void>,
    deleteNotPostpone?: (fightgroupnodeid: number) => Promise<void>,

}
export interface ISchedullerActions extends IFightSchedullerActions {
    editShiaijo: (competitionId: number, shiaijo: ISchedullerShiaijo) => Promise<void>,
    switchShiaijoAvailability: (shiaijo: ISchedullerShiaijo) => Promise<void>,
    removeCompetitionShiaijo: (shiaijo: ISchedullerShiaijo, competition: ISchedullerWithMissing | IApiCompetitionModel) => Promise<void>
}


const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '40%',
    left: '40%',
    //transform: 'translate(-50%, -50%)',
    //width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

async function syncSchedulerCompetition(competition: ISchedullerWithMissing | IApiCompetitionModel
    , dispatchNotification: React.Dispatch<IServiceTableNotification>): Promise<boolean> {
    let competitionId: undefined | number = undefined;

    if ('competition' in competition) {
        competitionId = (competition as ISchedullerWithMissing).competition;
    }
    else if ('id' in competition) {
        competitionId = (competition as IApiCompetitionModel).id;
    }

    if (competitionId) {
        return putApiObject<ISchedullerResult>(`/competitions/${competitionId}`, null,
            null,
            emptySchedullerResult,
            dispatchNotification, (window as any)['REACT_APP_API_ORDO_URL'])
            .then((result: ISchedullerResult) => {
                return result.succeed;
                //setSchedullerData({ ...schedullerData, loaded: false })
            });
    }
    else return false;

};

async function loadSchedullerCompetitionData(
    dispatchNotification: React.Dispatch<IServiceTableNotification>
    , comp: IApiCompetitionModel
    , newSchedullerData: ISchedullerList) {
    return await getApiObject<ISchedullerResult>("/competitions", comp.id, emptySchedullerResult
        , dispatchNotification, (window as any)['REACT_APP_API_ORDO_URL'])
        .then((scheduledFights: ISchedullerResult) => {
            if (scheduledFights.succeed) {
                //Get shiaijos ordered
                scheduledFights.data.shiaijo = scheduledFights.data.shiaijo.sort((a, b) => {
                    //let shiajoCompetitiona = newSchedullerData.shiaijosMap.get(a.ext_id);
                    //let shiajoCompetitionb = newSchedullerData.shiaijosMap.get(b.ext_id);
                    if (a.order !== b.order) {
                        return a.order - b.order
                    } else {
                        return (a.ext_id === b.ext_id) ? 0 : ((a.ext_id < b.ext_id) ? -1 : 1);
                    }
                })

                let unschedulledFights: Array<IApiCompetitionTreeModel> = [];

                //Load following informations
                return getAvailable(comp.id, dispatchNotification).then((availableFights: IApiCompetitionTreeResult) => {
                    //Detect fight available but not schedulled
                    for (let index = 0; index < availableFights.data.length; index++) {
                        const availableFight: IApiCompetitionTreeModel = availableFights.data[index];
                        if (!scheduledFights.data.fight.find(f => {
                            return f.fightGroupNodeId === availableFight.fightGroupNodeId
                        })) {
                            unschedulledFights.push(availableFight);
                        }
                    };
                    unschedulledFights = unschedulledFights.sort(sortCompetitionTreeModel);
                    newSchedullerData.competitions.push(Object.assign(
                        {
                            competition: 0,
                            fight: [],
                            shiaijo: [],
                            unschedulledFights: []
                        }
                        , scheduledFights.data,
                        { unschedulledFights: unschedulledFights }));

                });
            }
        });
}

async function loadSchedullerData(
    selectedCompetitions: Array<IApiCompetitionModel>,
    dispatchNotification: React.Dispatch<IServiceTableNotification>
): Promise<ISchedullerList> {
    let newSchedullerData: ISchedullerList = {
        competitions: [],
        shiaijosMap: new Map<number, IApiCompetitionShiajoModel>(),
        shiaijos: []
    }
    newSchedullerData.shiaijosMap = await loadShiaijos(selectedCompetitions, dispatchNotification);

    let comps = selectedCompetitions;

    //Load each competition
    for (let index = 0; index < comps.length; index++) {
        const comp: IApiCompetitionModel = comps[index];
        await loadSchedullerCompetitionData(dispatchNotification, comp, newSchedullerData);
    }

    //CREATE SHIAIJO SCHEDULLER LIST : list of shiaijos with linked list of competitions
    newSchedullerData.shiaijos = [];

    newSchedullerData.competitions.forEach((competition) => {
        //For each shiaijo in competition
        competition.shiaijo.forEach(sf => {
            //create the shiaijo
            if (!newSchedullerData.shiaijos.find(s => s.shiaijo.ext_id === sf.ext_id)) {
                newSchedullerData.shiaijos.push({
                    shiaijo: sf,
                    shiajoModel: newSchedullerData.shiaijosMap.get(sf.ext_id) ?? emptyApiCompetitionShiajoModel,
                    shiaijoCompetitions: []
                });
            }
            //récupération des informations du shiaijo
            //let shiaijoCompetition = newSchedullerData.shiaijosMap.get(sf.ext_id);
            //add competition to list of shiaijo
            let compList = newSchedullerData.shiaijos.find(s => s.shiaijo.ext_id === sf.ext_id)?.shiaijoCompetitions;
            if (compList && !compList.find(sc => sc.competition.id === sf.competition)) {
                compList.push({
                    //shiaijoCompetition: shiaijoCompetition,
                    competition: selectedCompetitions.find(c => c.id === competition.competition) ?? emptyCompetitionModel,
                    fights: competition.shiaijo.find(s => s.ext_id === sf.ext_id)?.fight ?? []
                });
            }
        })
    });
    return newSchedullerData;
};

export function SchedullerTable(props: ISchedullerProps) {
    const competitionConfiguration = useConfiguration(/*{
        dispatchNotification: props.dispatchNotification,
        authentication: props.authentication
    }*/);
    const [selectedCompetitionsComponent, selectedCompetitions] = useSelectedCompetitions({
        dispatchNotification: props.dispatchNotification,
        //showCompetitionConfiguration: competitionConfiguration.showCompetitionConfiguration
    });
    const inputDeleteFightgroup = useRef(null);
    const inputDeleteFightgroupCompetitionId = useRef(null);

    const [deleteFightgroup, setDeleteFightgroup] = useState<undefined | "scheduller" | "table">(undefined);




    const [schedullerData, setSchedullerData] = useState<{
        data: ISchedullerList | null
    }>({
        data: null
    });

    const [schedullerLoad, setSchedullerLoad] = useState<number | undefined>(undefined);
    //const [reloadOnEvent, setReloadOnEvent] = useState(false);

    //On shiaijo message
    const onMessage = useCallback((shiaijoEvent: IApiShiajoModel) => {
        //if (reloadOnEvent)setSchedullerLoad(undefined);
        setSchedullerData((d) => {
            if (d.data) {
                let shouldBeReloaded = false;
                const newData = produce(d, (draft) => {
                    for (const shiaijo of draft.data?.shiaijos ?? []) {
                        if (shiaijo.shiaijo.ext_id === shiaijoEvent.externalId) {
                            for (const shiaijoCompetition of shiaijo.shiaijoCompetitions) {
                                shiaijoCompetition.fights = shiaijoCompetition.fights.filter(f => {
                                    return shiaijoEvent.fightGroups.find(fgEvent => fgEvent.fightGroupNodeId === f.fightGroupNodeId);
                                });

                                const missings = shiaijoEvent.fightGroups
                                    .filter(fg => fg.competitionId === shiaijoCompetition.competition.id)
                                    .filter(
                                        fg => (
                                            fg.fightGroupNodeId > 0
                                            && undefined === shiaijoCompetition.fights.find(sf => sf.fightGroupNodeId === fg.fightGroupNodeId)));

                                if (missings.length > 0) {
                                    /*for (const missing of missings) {
                                        //create a fake one to avoid full reload
                                        shiaijoCompetition.fights.push(
                                            {
                                                competition: missing.competitionId,
                                                fightGroupNodeId: missing.fightGroupNodeId,
                                                id: 0,
                                                shiaijo: shiaijo.shiaijo.ext_id,
                                                status: "progress",
                                                label: missing.label,
                                                poolFightGroup: false,
                                                fightOrder: 0,
                                                depth: 0
                                        })
                                    }*/
                                    shouldBeReloaded = true;
                                    console.log("Should be reloaded, size different for competition " + shiaijoCompetition.competition.id);
                                }
                            }
                        }
                    }

                });
                if (shouldBeReloaded) {
                    setSchedullerLoad(undefined);
                    return d;//{ data: d.data, loaded: false }
                }
                else return newData;
            }
            else {
                return d;
            }

        })
    }, []);

    //SSE EVENTS
    const [setSseFilter, sseConnected, connectedIcon] = useSseSecureClient(
        "schedullerTable",
        "shiajos",
        onMessage,
        props.dispatchNotification,
        true
    )

    //const [tout, setTout] = useState<any>(null);
    const { t, i18n } = useTranslation();

    //TODO : basculer en component
    //affichage de la modal d'un fightgroup
    const [popover, displayPopOver] = usePopOver<ISchedullerFight | IApiCompetitionTreeModel>({
        dispatchNotification: props.dispatchNotification,
        loader: (params: ISchedullerFight | IApiCompetitionTreeModel | undefined) => schedullerFightContent(params, props.dispatchNotification, t)
    }
    );
    const [editShiaijo, setEditShiaijo] = useState<undefined | {
        shiaijo: IApiCompetitionShiajoModel,
        competitionId: number
    }>(undefined);
    const [displayFightGroupDetails, setDisplayFightGroupDetails] = useState<boolean>(false);
    const [showShiaijoSelectFg, setShowShiaijoSelectFg] = useState<ISchedullerFight | IApiCompetitionTreeModel | undefined>(undefined);
    const [addCompetitionShiaijoSelectShiaijo, setAddCompetitionShiaijoSelectShiaijo] = useState<ISchedullerWithMissing | IApiCompetitionModel | undefined>(undefined);

    const actions: ISchedullerActions = useMemo(() => {
        return {
            scheduleAllocate: async (competitionId: number, fightgroupnodeid: number): Promise<void> => {
                allocateToShiaijo(competitionId, fightgroupnodeid, props.dispatchNotification).then(r => {
                    if (r) {
                        setSchedullerLoad(undefined);
                    }
                })
            },
            editShiaijo: async (competitionId: number, shiaijo: ISchedullerShiaijo) => {
                getShiajo(props.dispatchNotification, shiaijo.ext_id).then(r => {
                    if (r) {
                        setEditShiaijo({
                            shiaijo: r,
                            competitionId: competitionId
                        });
                    }
                    else {
                        setEditShiaijo(undefined);
                    }
                })
            },
            postponeSuivi: (fightgroupnodeid: number, force: boolean = false): Promise<void> => {
                /**
                 * Order shiaijo to postpone fight (meaning if fight hasn't been comitted, it will be deleted)
                 * If  failed but has to force, Order following to postpone the fight, meaning a delete order will be pass to scheduller but shiaijo won't be informed
                 */
                return deleteApiObject<IApiResult>(`/fightgroups?postpone=true&fightGroupNodeId=${fightgroupnodeid}`, null, {
                    succeed: false
                }, props.dispatchNotification).then((res: IApiResult) => {
                    if (res.succeed) {
                        setSchedullerLoad(undefined);
                    }
                    else if (force) {
                        postponeSuivi(fightgroupnodeid, props.dispatchNotification).then(r => {
                            if (r) {
                                setSchedullerLoad(undefined);
                            }
                        })
                    }
                })
            },
            removeCompetitionShiaijo: async (shiaijo: ISchedullerShiaijo, competition: ISchedullerWithMissing | IApiCompetitionModel) => {
                let competitionId: undefined | number = undefined;
                if ('competition' in competition) {
                    competitionId = (competition as ISchedullerWithMissing).competition;
                }
                else if ('id' in competition) {
                    competitionId = (competition as IApiCompetitionModel).id;
                }

                if (competitionId) deleteCompetitionShiaijo(props.dispatchNotification, competitionId, shiaijo.ext_id).then((d) => {
                    syncSchedulerCompetition(competition, props.dispatchNotification).then(succeed => {
                        setSchedullerLoad(undefined);
                    })
                })
            },
            switchShiaijoAvailability: async (shiaijo: ISchedullerShiaijo) => {
                patchApiObject<ISchedullerShiaijoResult>(`/shiaijos/${shiaijo.ext_id}?available=${shiaijo.available ? 'false' : 'true'}`, null,
                    {
                        data: {
                            comment: "",
                            competition: 0,
                            ext_id: 0,
                            fight: [],
                            available: false,
                            order: 0
                        },
                        succeed: false
                    },
                    props.dispatchNotification, (window as any)['REACT_APP_API_ORDO_URL'])
                    .then((result: ISchedullerShiaijoResult) => {
                        if (result.succeed) {
                            setSchedullerLoad(undefined);
                        }
                    });
            },
            setNewShiajo: async (fightGroup: IApiCompetitionTreeModel, value: string) => {
                let newShiajoId: number | undefined = Number(value);
                if (isNaN(newShiajoId)) newShiajoId = undefined;

                if (newShiajoId) {
                    putApiObject<ISchedullerResult>(`/competitions/${fightGroup.competitionId}/${newShiajoId}/${fightGroup.fightGroupNodeId}`, null,
                        {},
                        emptySchedullerResult,
                        props.dispatchNotification, (window as any)['REACT_APP_API_ORDO_URL'])
                        .then((result: ISchedullerResult) => {
                            if (result.succeed) {
                                setSchedullerLoad(undefined);
                            }
                        });
                }
            },
            changeNewShiajo: async (fightGroup: ISchedullerFight | IApiCompetitionTreeModel, value?: string) => {
                if (value === undefined) {
                    setShowShiaijoSelectFg(fightGroup);
                }
                else {
                    let newShiajoId: number | undefined = Number(value);
                    if (isNaN(newShiajoId)) newShiajoId = undefined;

                    let competitionId: number | undefined = undefined;
                    if ('competitionId' in fightGroup) {
                        competitionId = (fightGroup as IApiCompetitionTreeModel).competitionId;
                    }
                    else if ('competition' in fightGroup) {
                        competitionId = (fightGroup as ISchedullerFight).competition;
                    }

                    if (newShiajoId && competitionId) {
                        await moveFightgroupToShiaijo(competitionId, newShiajoId, fightGroup, props.dispatchNotification).then(r => {
                            if (r) {
                                setSchedullerLoad(undefined);
                            }
                        });
                    }
                }
            },
            scheduleCreateFight: async (competitionId: number, fightNodeId: number) => {
                let fightGroup = await getFollowingFightGroup(fightNodeId, props.dispatchNotification);
                if (fightGroup) {
                    await putApiObject<ISchedullerResult>(`/competitions/${competitionId}/addCombat/${fightNodeId}`, null,
                        fightGroup,
                        emptySchedullerResult,
                        props.dispatchNotification, (window as any)['REACT_APP_API_ORDO_URL'])
                        .then((result: ISchedullerResult) => {
                            if (result.succeed) {
                                setSchedullerLoad(undefined);
                            }
                        });
                }
            },
            deleteNotPostpone: async (fightgroupnodeid: number) => {
                return deleteNotPostpone(fightgroupnodeid, props.dispatchNotification)
                    .then((result: IApiFightGroupResult) => {
                        if (result.succeed) {
                            setSchedullerLoad(undefined);
                        }
                    });
            }
        }
    }, [props.dispatchNotification, schedullerData]);

    const addCompetitionShiaijo = useCallback(async (competition: ISchedullerWithMissing | IApiCompetitionModel, value?: string) => {
        let competitionId: undefined | number = undefined;

        if ('competition' in competition) {
            competitionId = (competition as ISchedullerWithMissing).competition;
        }
        else if ('id' in competition) {
            competitionId = (competition as IApiCompetitionModel).id;
        }

        if (value === undefined) {
            setAddCompetitionShiaijoSelectShiaijo(competition);
        }
        else {
            let shiajoId: number | undefined = Number(value);
            if (isNaN(shiajoId)) shiajoId = undefined;

            if (shiajoId && competitionId) {
                patchCompetitionShiaijo(props.dispatchNotification, competitionId, shiajoId).then((d) => {
                    syncSchedulerCompetition(competition, props.dispatchNotification).then(r => {
                        if (r) setSchedullerLoad(undefined);
                    })
                })
            }
        }
    }, [props.dispatchNotification, schedullerData]);






    useEffect(() => {

        setSseFilter(null);
    }, []);

    useEffect(() => {
        if (selectedCompetitions.length > 0 && schedullerData.data?.competitions
            .map(c => c.competition)
            .sort()
            .join(',')
            !== selectedCompetitions.map(c => c.id)
                .sort()
                .join(',')) {
            setSchedullerLoad(undefined);
        }
        else if (selectedCompetitions.length === 0 && (schedullerData.data?.competitions.length ?? 0) > 0) {
            setSchedullerData({
                data: {
                    competitions: [],
                    shiaijosMap: new Map(),
                    shiaijos: []
                }
            });
        }
    }, [schedullerData.data?.competitions, selectedCompetitions])

    useEffect(() => {
        let displayed = true;
        (async () => {
            debug("Use effect");
            //let timer: NodeJS.Timeout | undefined = undefined;
            if (!schedullerLoad) {
                await loadSchedullerData(selectedCompetitions, props.dispatchNotification).then((newSchedullerData) => {
                    if (displayed) {
                        setSchedullerLoad(Date.now());
                        setSchedullerData({
                            data: newSchedullerData
                        });
                    }

                    /*timer = setTimeout(() => {
                        setSchedullerData({ data: null, loaded: false })
                    }, 30000);*/
                })
            }

        })();
        return () => {
            displayed = false;
        }
    }, [props.dispatchNotification, schedullerLoad, selectedCompetitions])

    // let competitionFirstCell = useCallback((competition: any, competitionLines: any, postponed: any, closed: any, competitionInfo: any) => {
    //     return <td rowSpan={competitionLines + Math.max(1, closed.length) + Math.max(1, competition.unschedulledFights.length) + Math.max(postponed.length, 1)} className="shiajo_scheduller_competition">
    //         <p>
    //             {competitionInfo?.fullname} - {competition.competition}
    //         </p>
    //         <br />
    //         {competitionInfo?.advancedOrdonancement && Object.keys(competitionInfo?.advancedOrdonancement).map(
    //             (i, k) => {
    //                 let val = false;
    //                 if (competitionInfo?.advancedOrdonancement) {
    //                     val = competitionInfo?.advancedOrdonancement[i as keyof IServiceCompetitionAdvancedOrdonancementRules]
    //                 }
    //                 return <React.Fragment key={k}>
    //                     {t(`label.competition.advanced.${i}`)} : {t(`label.competition.advanced.${val}`) /*t('label.word.' + (val ? "yes" : "no"))*/}<br />
    //                 </React.Fragment>
    //             })}
    //         <br />

    //         <TooltipButton
    //             action={() => {
    //                 syncSchedulerCompetition(competition);
    //             }}
    //             i18nActionMessage='action.following.syncCompetition'
    //             id='syncCompetition'
    //             i18nDetailMessage='detail.competition.syncCompetition' />
    //     </td >
    // }, [syncSchedulerCompetition, t]);

    return <>
        {connectedIcon}
        {popover}
        {/* Modal */}
        {showShiaijoSelectFg && <ShiaijoSelectModal
            onClose={() => setShowShiaijoSelectFg(undefined)}
            shiaijos={schedullerData.data?.shiaijosMap ? [...schedullerData.data?.shiaijosMap.values()] : []}
            competitions={selectedCompetitions}
            onSelect={(shiaijo: IApiShiajoModel | IApiCompetitionShiajoModel | undefined) => {
                if (actions.changeNewShiajo) actions.changeNewShiajo(showShiaijoSelectFg, String(shiaijo?.id))
                setShowShiaijoSelectFg(undefined);
            }}
            open={true}
        />}
        {addCompetitionShiaijoSelectShiaijo && <ShiaijoSelectModal
            onClose={() => setAddCompetitionShiaijoSelectShiaijo(undefined)}
            shiaijos={schedullerData.data?.shiaijosMap ? [...schedullerData.data?.shiaijosMap.values()] : []}
            competitions={selectedCompetitions}
            onSelect={(shiaijo: IApiShiajoModel | IApiCompetitionShiajoModel | undefined) => {
                addCompetitionShiaijo(addCompetitionShiaijoSelectShiaijo, String(shiaijo?.id))
                setAddCompetitionShiaijoSelectShiaijo(undefined);
            }}
            open={true}
        />}



        {deleteFightgroup && <ShiajoModal
            isShowing={true}
            hide={() => { setDeleteFightgroup(undefined); }}
            title={t("label.title.deleteFightgroup")}>
            {deleteFightgroup === 'scheduller' && <>{t('label.fightgroup.competitionId')}<input type='text' ref={inputDeleteFightgroupCompetitionId} /></>}
            <br />
            {t('label.fightgroup.fightGroupNodeId')}  <input type='text' ref={inputDeleteFightgroup} /><br />
            <Button variant='contained' color="success" onClick={() => {
                const inputDeleteFightgroupFightGroupNodeId = (inputDeleteFightgroup?.current as any)?.value;
                const inputDeleteFightgroupCompetitionIdValue = (inputDeleteFightgroupCompetitionId?.current as any)?.value;
                if (inputDeleteFightgroupFightGroupNodeId) {
                    if (deleteFightgroup === 'scheduller') {
                        if (inputDeleteFightgroupCompetitionIdValue) cancelSchedulledFight(
                            inputDeleteFightgroupCompetitionIdValue,
                            inputDeleteFightgroupFightGroupNodeId, props.dispatchNotification).then((d) => {
                                setDeleteFightgroup(undefined);
                            });
                    }
                    else {
                        deleteFightgroupNodeId(inputDeleteFightgroupFightGroupNodeId, props.dispatchNotification).then((d) => {
                            if (d) setDeleteFightgroup(undefined);
                            else {
                                confirmAlert({
                                    title: "Deletion failed",
                                    message: `La suppression a échoué, voulez vous tenter de forcer la suppression (risque de desynchronisation avec les autres services) ?
                                `,
                                    buttons: [
                                        {
                                            label: 'Yes',
                                            onClick: () => {
                                                deleteFightgroupNodeId(inputDeleteFightgroupFightGroupNodeId, props.dispatchNotification).then((d) => {
                                                    if (d) setDeleteFightgroup(undefined);
                                                });
                                            }
                                        },
                                        {
                                            label: 'No',
                                            onClick: () => {

                                            }
                                        }
                                    ],
                                });
                            }
                        });
                    }
                }
            }}>{t('action.modal.confirm')}</Button>
            <Button variant='contained' color="error" onClick={() => setDeleteFightgroup(undefined)}>{t('action.modal.cancel')}</Button>
        </ShiajoModal>
        }
        {
            editShiaijo && <Modal
                open={true}
                onClose={() => setEditShiaijo(undefined)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Input type="text" value={editShiaijo.shiaijo.fullname} onChange={() => setEditShiaijo({
                        ...editShiaijo,
                        shiaijo: {
                            ...editShiaijo.shiaijo,
                            fullname: editShiaijo.shiaijo.fullname,
                            shortname: editShiaijo.shiaijo.shortname
                        }

                    })} /><br />
                    <Select
                        id="standard-basic"
                        label="order"
                        value={editShiaijo.shiaijo.order}
                        onChange={(e) => setEditShiaijo({
                            ...editShiaijo,
                            shiaijo: {
                                ...editShiaijo.shiaijo,
                                order: parseInt(e.target.value.toString())
                            }
                        })}
                    >
                        {Array.from(Array(20).keys()).map(v => {
                            return <MenuItem value={v} key={v}>{v}</MenuItem>
                        })}
                    </Select><br />
                    <Button
                        onClick={(e) => {
                            patchShiajo(props.dispatchNotification, editShiaijo.competitionId, editShiaijo.shiaijo).then((r) => {
                                if (r) {
                                    //TODO : remplacer le model en prenant directmeent competition et pas competition id
                                    let competition = schedullerData.data?.competitions.find(c => c.competition === editShiaijo.competitionId);
                                    if (competition) {
                                        syncSchedulerCompetition(competition, props.dispatchNotification).then(succeed => {
                                            setSchedullerLoad(undefined);
                                        })
                                    }
                                    setEditShiaijo(undefined);
                                }
                            })
                        }}
                    >Save</Button>
                    <Button
                        onClick={(e) => {
                            setEditShiaijo(undefined);
                        }}
                    >Cancel</Button>
                </Box>
            </Modal>
        }
        {/* Loading screen */}
        {
            false && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={!schedullerLoad}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        }
        {(schedullerData.data === null || schedullerLoad === undefined) && <><Typography variant='h2'>Loading...</Typography></>}

        {/* Content */}
        <Box sx={{ flexGrow: 1 }}>

            <Grid container spacing={1}>
                {/* Header */}
                <Grid item xs={6} >
                    {selectedCompetitionsComponent}
                </Grid>
                {/* Header */}
                <Grid item xs={6} >

                    {/* <Button variant='contained' color='success' style={{opacity: reloadOnEvent ? '1' : '0.5'}} onClick={() => {
                        setReloadOnEvent(!reloadOnEvent);
                    }}>{reloadOnEvent ? 'Reloading on event' : 'Not reloading on event' }</Button> */}

                    <Button variant='contained' color='primary' size='small' onClick={() => {
                        setSchedullerLoad(undefined);
                    }}>Reload</Button>
                    <Button variant='contained' color='success' size='small' onClick={() => {
                        (async () => {
                            for (const competition of selectedCompetitions) {
                                await syncSchedulerCompetition(competition, props.dispatchNotification);
                            }
                            await reload(props.dispatchNotification);
                        })();
                    }}>{t("action.scheduller.syncCompetitions")}</Button><br />

                    <Button variant='contained' color='error' size='small' onClick={() => {
                        setDeleteFightgroup("table")
                    }}>{t("action.fightgroup.deleteFromTable")}</Button>
                    <Button variant='contained' color='error' size='small' onClick={() => {
                        setDeleteFightgroup("scheduller")
                    }}>{t("action.fightgroup.deleteFromScheduler")}</Button><br />
                    <Checkbox value={displayFightGroupDetails}
                        onClick={() => setDisplayFightGroupDetails(!displayFightGroupDetails)} />{t('action.scheduller.displayFightGroupDetails')}
                </Grid>
                {/* Shiaijos */}
                <Grid container item spacing={1}>
                    {[...(schedullerData.data?.shiaijos ?? [])].sort((s1, s2) => (s1.shiaijo.order ?? 0) - (s2.shiaijo.order ?? 0)).map((shiaijo, key) => {
                        return <Grid item xs={3} key={shiaijo.shiaijo.ext_id} >
                            <Paper elevation={3} style={{ minHeight: '300px' }}>
                                <ShiaijoScheduller {...shiaijo}
                                    displayPopOver={displayPopOver}
                                    displayFightGroupDetails={displayFightGroupDetails}
                                    actions={{
                                        editShiaijo: actions.editShiaijo,
                                        postponeSuivi: actions.postponeSuivi,
                                        switchShiaijoAvailability: actions.switchShiaijoAvailability,
                                        changeNewShiajo: actions.changeNewShiajo,
                                        removeCompetitionShiaijo: actions.removeCompetitionShiaijo,
                                        deleteNotPostpone: actions.deleteNotPostpone
                                    }}
                                />
                            </Paper>
                        </Grid>
                    })}

                </Grid>

                <Paper elevation={3} style={{ marginTop: "20px" }}>
                    <Grid container >
                        {schedullerData.data?.competitions.map((competition: ISchedullerWithMissing, keyc: any) => {
                            return <Grid item xs={12} key={keyc}>
                                <SchedullerCompetitition
                                    actions={actions}
                                    competition={competition}
                                    addCompetitionShiaijo={addCompetitionShiaijo}
                                    dispatchNotification={props.dispatchNotification}
                                    displayPopOver={displayPopOver}
                                    reload={() => setSchedullerLoad(undefined)}
                                    schedullerData={schedullerData}
                                    selectedCompetitions={selectedCompetitions}
                                    syncSchedulerCompetition={syncSchedulerCompetition}
                                    displayFightGroupDetails={displayFightGroupDetails}
                                />
                            </Grid>
                        })}
                    </Grid>
                </Paper>

            </Grid>
        </Box>

        {/*<Table striped bordered hover className="shiajo_scheduller">
            <thead>
                <tr>
                    <th>{t('label.word.competition')}</th>
                    <th>{t('label.word.shiajo')} (id)</th>
                    <th>{t('label.word.fightgroupnodid')}</th>
                    <th>{t('label.fightgroup.changeShiajo')} (id)</th>
                    <th>{t('label.word.action')}</th>
                    <th>{t('label.word.action')}</th>
                </tr>
            </thead>
            <tbody>
                {schedullerData.data?.competitions.map((competition: ISchedullerWithMissing, keyc: any) => {

                    let competitionLines = competition.shiaijo.reduce((sacc, shiaijo) => sacc + Math.max(1, shiaijo.fight.length), 0);
                    let postponed = competition.fight.filter(f => f.status === "postponed");
                    let closed = competition.fight.filter(f => f.status === null || f.status === "finished");
                    let competitionInfo: IApiCompetitionModel | undefined =
                        getSelection().selectedCompetition.find(c => c.id === Number(competition.competition));
                    //shiaijos
                    return <React.Fragment key={keyc}>
                        {competition.shiaijo.length === 0 && <tr>{competitionFirstCell(competition, competitionLines, postponed, closed, competitionInfo)}<td></td><td></td><td></td><td></td><td></td></tr>}
                        {competition.shiaijo.map((shiaijo, keys: any) => {
                            let fightLines = Math.max(1, competition.shiaijo[keys].fight.length);

                            let shiaijoCellContent = <>
                                {schedullerData.data?.shiaijosMap.get(shiaijo.ext_id)?.fullname} (id: {shiaijo.ext_id})
                                &nbsp;&nbsp;<Button size="sm" variant={shiaijo.available ? 'danger' : 'success'} className="btn-sm" onClick={() => {
                                    actions.switchShiaijoAvailability(shiaijo);
                                }}>
                                    {shiaijo.available ? t('action.following.shiaijoDisable') : t('action.following.shiaijoEnable')}
                                </Button>
                            </>

                            //fights
                            return <React.Fragment key={keys}>
                                {competition.shiaijo[keys].fight.length === 0 &&
                                    <tr>
                                        {keys === 0 && competitionFirstCell(competition, competitionLines, postponed, closed, competitionInfo)}
                                        <td rowSpan={fightLines}>
                                            {shiaijoCellContent}

                                        </td>

                                        <td></td><td></td><td></td><td></td>

                                    </tr>}
                                {competition.shiaijo[keys].fight.map((fightGroup, key) => {
                                    return <tr key={key}>
                                        {keys === 0 && key === 0 &&
                                            competitionFirstCell(competition, competitionLines, postponed, closed, competitionInfo)
                                        }
                                        {key === 0 && <td rowSpan={fightLines}>{shiaijoCellContent}</td>}

                                        <td>
                                            <Button size="sm" variant="info" onClick={(e: any) => {
                                                displayFightOverlay(e.target, fightGroup.fightGroupNodeId);
                                            }}>
                                                {fightGroup.fightGroupNodeId}

                                            </Button>

                                        </td>
                                        <td><input type="text" value={fightGroup.newShiaijoId} onBlur={(event: any) => {
                                            if (actions.changeNewShiajo) actions.changeNewShiajo(fightGroup, event.target.value);
                                            event.target.value = "";
                                        }} />
                                        </td>
                                        <td>
                                            <Button size="sm" variant="warning" onClick={() => {
                                                actions.postponeSuivi && actions.postponeSuivi(fightGroup.fightGroupNodeId, false);
                                            }}>
                                                {t('action.following.postpone')}
                                            </Button>
                                        </td><td>
                                            <Button size="sm" variant="warning" onClick={() => {
                                                actions.postponeSuivi && actions.postponeSuivi(fightGroup.fightGroupNodeId, true);
                                            }}>
                                                {t('action.following.postponeforce')}
                                            </Button>
                                        </td>
                                    </tr >
                                })}
                            </React.Fragment>
                        })}

                        {closed.length === 0 && <tr><td>{t('label.scheduler.closed')}</td><td></td><td></td><td></td><td></td></tr>}
                        {closed.map((f, k) => {

                            return <React.Fragment key={k}>
                                <tr>
                                    {k === 0 &&
                                        <td rowSpan={Math.max(1, closed.length)}>{t('label.scheduler.closed')}</td>
                                    }

                                    <td>
                                        <Button size="sm" variant="info" onClick={(e: any) => {
                                            displayFightOverlay(e.target, f.fightGroupNodeId);
                                        }}>
                                            {f.fightGroupNodeId}

                                        </Button>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </React.Fragment>

                        })
                        }

                        {postponed.length === 0 && <tr><td>{t('label.scheduler.postponed')}</td><td></td><td></td><td></td><td></td></tr>}
                        {postponed.map((f, k) => {
                            return <React.Fragment key={k}>
                                <tr>
                                    {k === 0 && <td rowSpan={Math.max(1, postponed.length)}>{t('label.scheduler.postponed')}</td>}
                                    <td><Button variant="info" onClick={(e: any) => {
                                        displayFightOverlay(e.target, f.fightGroupNodeId);
                                    }}>
                                        {f.fightGroupNodeId}

                                    </Button>
                                    </td>
                                    <td><Button size="sm" variant="success" onClick={() => {
                                        actions.scheduleAllocate && actions.scheduleAllocate(competition.competition, f.fightGroupNodeId);
                                    }}>
                                        {t('action.following.allocate')}
                                    </Button>
                                    </td>
                                    <td>
                                        <input type="text" value={f.newShiaijoId} onBlur={(event: any) => {
                                            if (actions.changeNewShiajo) actions.changeNewShiajo(f, event.target.value);
                                            event.target.value = "";
                                        }} />
                                    </td>

                                    <td></td>
                                </tr>
                            </React.Fragment>
                        })
                        }


                        {competition.unschedulledFights.length === 0 && <tr><td>{t('label.scheduler.followingSyncError')}</td><td></td><td></td><td></td><td></td></tr>}
                        {competition.unschedulledFights.map((f, k) => {
                            return <React.Fragment key={k}>
                                <tr>
                                    {k === 0 && <td rowSpan={Math.max(1, competition.unschedulledFights.length)}>{t('label.scheduler.followingSyncError')}</td>}
                                    <td>
                                        <Button size="sm" variant="info" onClick={(e: any) => {
                                            displayFightOverlay(e.target, f.fightGroupNodeId);
                                        }}>
                                            {f.fightGroupNodeId}

                                        </Button>
                                    </td>
                                    <td>
                                        <input type="text" onBlur={(event: any) => {
                                            if (actions.changeNewShiajo) actions.changeNewShiajo(f, event.target.value);
                                            event.target.value = "";
                                        }} />
                                    </td>
                                    <td><Button size="sm" variant="success" onClick={() => {
                                        if (actions.scheduleCreateFight) actions.scheduleCreateFight(competition.competition, f.fightGroupNodeId);
                                    }}>
                                        {t('action.following.resend')}
                                    </Button><br />
                                    </td>
                                    <td></td>

                                </tr>
                            </React.Fragment>
                        })
                        }

                    </React.Fragment>
                })}
            </tbody>
            </Table >*/}</>

}

/*<td>{Object.keys(competition.shiaijo[shiajoEntry]).map((entrie, key) => {
                            return <React.Fragment key={key}>{shiajo[entrie]} ; </React.Fragment>
                        })}</td>*/