import React from "react";
import { IApiFighterFightTeam } from "../../Model/ApiTeamModel";
import { IFightTeamState } from "../../util/ApiModel/ApiFightModel";

export const emptyFightTeamState: IFightTeamState = {
    id: 0,
    externalId: 0,
    name: "unknown",
    fighters: [],
    fighterOrderDate: null
}




export function FightTeam(props: { fightTeam: IApiFighterFightTeam | undefined, short?: boolean }) {
    if (props.fightTeam) {
        if (props.short) {
            return <>
                {props.fightTeam.name} {props.fightTeam.club}
            </>
        }
        else {
            return <>
                {props.fightTeam.name} {props.fightTeam.club} (id {props.fightTeam.id})
            </>
        }
    }
    else {
        return <></>;
    }
}