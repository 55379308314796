import { Chip } from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IApiString } from '../../util/ApiModel/Following/ApiCompetitionTree';
import { apiDiscordStatus, apiMongoDBStatus, apiNotificationCheck, apiSetDiscordStatus, apiSetTwitterStatus, apiTwitterStatus } from '../../util/ApiNotification';
import { getApiObject } from '../Operation/GenericOperation';

export interface INotificationConfig {
    check: boolean,
    loaded: boolean,
    discord?: boolean,
    twitter?: boolean,
    mongodb?: boolean
}

export interface INotificationAction {
    type: "check" | 'discord' | 'twitter' | 'mongodb' | 'loaded',
    value?: boolean
}


export type TServiceCheckStatus = "unknow" | "error" | "ready";
export interface IServiceCheck {
    name: string,
    root: string,
    status: TServiceCheckStatus,
    detail?: string
}

function notificationConfigReducer(
    config: INotificationConfig
    , action: INotificationAction): INotificationConfig {
    let nconfig = Object.assign({}, config);
    switch (action.type) {
        case 'check':
            nconfig.check = (action.value ?? false);
            nconfig.loaded = true;
            break;
        case 'discord':
            nconfig.discord = (action.value ?? false);
            break;
        case 'twitter':
            nconfig.twitter = (action.value ?? false);
            break;
        case 'mongodb':
            nconfig.mongodb = (action.value ?? false);
            break;
        case 'loaded':
            nconfig.loaded = (action.value ?? false);
            break;
    }
    return nconfig;
}


export interface ICheckServiceReducerAction {
    serviceName: string;
    status: TServiceCheckStatus;
}
function checkServiceReducer(state: Array<IServiceCheck>, action: ICheckServiceReducerAction) {
    let newState = [...state];
    let s = newState.find(s => s.name === action.serviceName);
    if (s) s.status = action.status;
    return newState;
}

export function BackendConfig(props: {}) {
    const { t, i18n } = useTranslation();
    const [beginCheckLoad, setBeginCheckLoad] = useState<boolean>(false);
    const [notificationConfig, dispatchNotificationConfig] = useReducer(notificationConfigReducer, {
        check: false,
        loaded: false
    });


    const [serviceCheckList, reduceServiceCheck] = useReducer(checkServiceReducer, [
        {
            name: "tables",
            root: `${(window as any)['REACT_APP_API_URL']}`,
            status: "unknow"
        },
        {
            name: "following",
            root: `${(window as any)['REACT_APP_API_SUIVI_URL']}`,
            status: "unknow"
        },
        {
            name: "notification",
            root: `${(window as any)['REACT_APP_API_NOTIFICATION']}`,
            status: "unknow"

        },
        {
            name: "remotenotification",
            root: `${window.location.protocol + '//' + window.location.host + "/remote/api" + (window as any)['REACT_APP_API_NOTIFICATION_SERVICE']}`,
            status: "unknow"
        },
        {
            name: "fighters",
            root: `${(window as any)['REACT_APP_API_COMBATTANTS_URL']}`,
            status: "unknow"
        },
        {
            name: "competition",
            root: `${(window as any)['REACT_APP_API_COMP_URL']}`,
            status: "unknow"
        },
        {
            name: "scheduller",
            root: `${(window as any)['REACT_APP_API_ORDO_URL']}`,
            status: "unknow"
        }

    ]);

    useEffect(() => {
        if (!notificationConfig.loaded) {
            apiNotificationCheck().then((result) => {
                dispatchNotificationConfig({ type: "check", value: result });
                apiDiscordStatus().then(result => dispatchNotificationConfig({ type: 'discord', value: result }));
                apiTwitterStatus().then(result => dispatchNotificationConfig({ type: 'twitter', value: result }));
                apiMongoDBStatus().then(result => dispatchNotificationConfig({ type: 'mongodb', value: result }));
            })
        }
        else if (!beginCheckLoad) {
            setBeginCheckLoad(true);
            serviceCheckList.forEach(service => {
                if (service.status === "unknow") {
                    getApiObject<IApiString>("/check", null, {
                        succeed: false,
                        data: ""
                    }, undefined, service.root, undefined, undefined, "string").then((res) => {
                        reduceServiceCheck({

                            serviceName: service.name,
                            status: (res.succeed ? "ready" : "error")
                        });
                    })
                }
            });
        }
    }, [beginCheckLoad, notificationConfig.loaded, serviceCheckList]);

    return <>
        <Row>
            <Col md={12}>
                <h2 className='title'>Etat de fonctionnement des services</h2>
            </Col>
        </Row>
        <Row>
            <Col md={12}>
                <h2 className='subtitle'>Notifications</h2>
            </Col>
        </Row>
        <Row>
            <Col md={4}>
                Notification service
            </Col>
            <Col md={2}>
                {notificationConfig.check ? <Chip color="success" label="OK" /> : <Chip color="error" label="OK" />}
            </Col>
        </Row>
        <Row>
            <Col md={4}>
                Twitter
            </Col>
            <Col md={2}>
                {notificationConfig.twitter ? <Chip color="success" label="OK" /> : <Chip color="error" label="Disabled" />}
            </Col>
            <Col md={4} className="text-left">
                <Button size="sm" onClick={() => {
                    apiSetTwitterStatus(!notificationConfig.twitter).then(result => dispatchNotificationConfig({ type: "loaded", value: false }))
                }}>switch</Button>
            </Col>
        </Row>

        <Row>
            <Col md={4}>
                Discord
            </Col>
            <Col md={2}>
                {notificationConfig.discord ? <Chip color="success" label="OK" /> : <Chip color="error" label="Disabled" />}
            </Col>
            <Col md={4} className="text-left">
                <Button size="sm" onClick={() => {
                    apiSetDiscordStatus(!notificationConfig.discord).then(result => dispatchNotificationConfig({ type: "loaded", value: false }))
                }}>switch</Button>
            </Col>
        </Row>
        <Row>
            <Col md={4}>
                Mongodb
            </Col>
            <Col md={2}>
                    {notificationConfig.mongodb ? <Chip color="success" label="OK" /> : <Chip color="error" label="Disabled" />}
            </Col>
            {/*
            <Col md={4} className="text-left">
                <Button size="sm" onClick={() => {
                    apiSetTwitterStatus(!notificationConfig.twitter).then(result => dispatchNotificationConfig({ type: "loaded", value: false }))
                }}>switch</Button>
            </Col>
             */}
        </Row>
        <Row>
            <Col md={12}>
                <h2 className='subtitle'>Status</h2>
            </Col>
        </Row>

        {serviceCheckList.map((s, k) => {
            return <Row key={k}>
                <Col md={4} className="text-right">
                    {t(`label.service.${s.name}`)}
                </Col>
                <Col md={2}>
                    {s.status === 'unknow' && <Chip color="warning" label={s.status} />}
                    {s.status === 'error' && <Chip color="error" label={s.status} />}
                    {s.status === 'ready' && <Chip color="success" label={s.status} />}
                </Col>
                <Col md={4}>{s.detail ? s.detail : s.root}</Col>
            </Row>
        })}
    </>
}