import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type TEventStatus = "Created" | "RegistrationOpen" | "RegistrationClosed" | "DrawingsDone" | "DrawingsPublished" | "Ongoing" | "Finished" | "offline" | "";
export const eventStatusList: Array<TEventStatus> = [
    "Created", "RegistrationOpen", "RegistrationClosed", "DrawingsDone", "DrawingsPublished", "Ongoing", "Finished", "offline", ""
]

export function isEventStatus(competitonStatus: TEventStatus|undefined, status:TEventStatus): boolean {
    return eventStatusList.indexOf(competitonStatus ?? 'Created') >= eventStatusList.indexOf(status)
}

export function EventStatus(props: {
    value?: TEventStatus,
    onChange: (event: any) => void
}) {
    const { t } = useTranslation();

    return <Select
        fullWidth={true}
        labelId="eventStatus"
        value={props.value}
        onChange={props.onChange}
    >
        <MenuItem value={undefined}>{t(`label.competition.status.undefined`)}</MenuItem>
        {eventStatusList.map((v, k) => {
            return <MenuItem key={k} value={v}>{t(`label.competition.status.${v}`)}</MenuItem>
        })}
    </Select>;
}

export function EventStatusEditInputCell(props: GridRenderCellParams) {
    const { id, value, api, field } = props;

    const handleChange = (event: any) => {
        api.setEditCellValue({ id, field, value: event.target.value }, event);
        // Check if the event is not from the keyboard
        // https://github.com/facebook/react/issues/7407
        /*if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
            api.commitCellChange({ id, field });
            api.setCellMode(id, field, 'view');
        }*/
        api.commitCellChange({ id, field });
        api.setCellMode(id, field, 'view');
    };

    return (
        <div>
            <EventStatus
                value={value as TEventStatus}
                onChange={handleChange}
            />
        </div>
    );
}

export function renderEventStatusEditInputCell(params: any) {
    return <EventStatusEditInputCell {...params} />;
}