
import { apiget, apiput, IApiError, manageApiError } from "../../util/ApiCall";
import { emptyFightgroup, IApiFightGroupCreationModel, IApiFightGroupModel, IApiFightGroupResult } from "../../util/ApiModel/ApiFightgroupModel";
import { emptyShiajoResult, IApiShiajoModel, IApiShiajoResult, IApiShiajosResult, ShiajoUpdateModel, TApiShiajosModel } from "../../util/ApiModel/ApiShiajoModel";
import { IServiceTableNotification } from "../Util/ErrorMessage";
import { deleteApiObject, postApiObject, putApiObject } from "./GenericOperation";

var debug = require('debug')('ServiceTablesMinimalFrontend:ShiajoOperation')

export async function loadShiajos(
  competitionIdList: Array<{ id: number }>,
  dispatchNotification: React.Dispatch<IServiceTableNotification>): Promise<IApiShiajosResult> {
  let result: IApiShiajosResult = {
    succeed: false,
    data: new Array<IApiShiajoModel>()
  };

  //for (let index = 0; index < competitionIdList.length; index++) {
    console.log(competitionIdList);
    //let competitionId: number = competitionIdList[index].id;

    let resultShiajo = await apiget<IApiShiajosResult>('/shiajos?competitionId=' + competitionIdList.map((c) => c.id).join(","))
      .then((res: IApiShiajosResult | IApiError) => {
        return manageApiError<IApiShiajosResult>(res)
      })
      .then((res: IApiShiajosResult) => {
        debug("Shiajo list successfully loaded : " + JSON.stringify(res));
        return res;
      })
      .catch((res: IApiError) => {
        debug("Shiajo list failed : " + JSON.stringify(res));
        dispatchNotification({ operation: "read", type: 'error', message: res.errorLabel });
        let data: IApiShiajosResult = {
          succeed: false,
          data: new Array<IApiShiajoModel>()
        }
        return data;
      });
    result.succeed = resultShiajo.succeed;
    if (resultShiajo.succeed) {

      for (let index = 0; index < resultShiajo.data.length; index++) {
        const element = resultShiajo.data[index];
        let item = result.data.find(i => i.id === element.id);
        if (item) {
          item.competitionId = item.competitionId.concat(element.competitionId);
          item.competitionId = item.competitionId.filter((item, i, ar) => ar.indexOf(item) === i);
        } else {
          result.data.push(element);
        }
      }
    }
  //}


  return result;
}

export async function loadShiajoNoCommit(
  id: number,
  dispatchNotification: React.Dispatch<IServiceTableNotification>): Promise<IApiShiajoResult> {
  return apiget<IApiShiajoResult>('/shiajos/' + id + "?commited=false&withTeam=true")
    .then((res: IApiShiajoResult | IApiError) => {
      return manageApiError<IApiShiajoResult>(res)
    })
    .then((res: IApiShiajoResult) => {
      debug("Shiajo successfully loaded : " + JSON.stringify(res));
      return res;
    })
    .catch((res: IApiError) => {
      debug("Shiajo failed : " + JSON.stringify(res));
      dispatchNotification({ operation: "read", type: 'error', message: res.errorLabel });
      let data: IApiShiajoResult = {
        succeed: false,
        data: emptyShiajoResult.data
      }
      return data;
    })
}

export async function loadShiajoFightGroupNoTeam(
  competitionId: number,
  dispatchNotification: React.Dispatch<IServiceTableNotification>): Promise<TApiShiajosModel> {
  return apiget<IApiShiajosResult>('/shiajos?competitionId=' + competitionId + '&commited=false&withFightGroups=true')
    .then((res: IApiShiajosResult | IApiError) => {
      return manageApiError<IApiShiajosResult>(res)
    })
    .then((res: IApiShiajosResult) => {
      debug("Shiajo successfully loaded : " + JSON.stringify(res));
      return res.data;
    })
    .catch((res: IApiError) => {
      debug("Shiajo failed : " + JSON.stringify(res));
      dispatchNotification({ operation: "read", type: 'error', message: res.errorLabel });
      return [];
    })
}

export async function selectFightGroup(shiajoId: number, fgid: number,
  dispatchNotification: React.Dispatch<IServiceTableNotification>): Promise<IApiFightGroupResult> {
  return apiput<IApiFightGroupResult>('/shiajos/' + shiajoId + '/currentfightgroup/' + fgid, {})
    .then((res: IApiFightGroupResult | IApiError) => {
      return manageApiError<IApiFightGroupResult>(res)
    })
    .then((res: IApiFightGroupResult) => {
      debug("Shiajo successfully put : " + JSON.stringify(res));
      return res;
    })
    .catch((res: IApiError) => {
      debug("Shiajo put failed : " + JSON.stringify(res));
      dispatchNotification({ operation: "update", type: 'error', message: res.errorLabel });

      return emptyFightgroup;
    });
}

export async function addFightGroup(shiajoExternalId: number, fightGroupCreation: IApiFightGroupCreationModel, dispatchNotification: React.Dispatch<IServiceTableNotification>
  | undefined): Promise<IApiFightGroupModel | undefined> {
  return postApiObject<{
    data: IApiFightGroupModel,
    succeed: boolean,
    status: number,
  }
  >(`/Shiajos/${shiajoExternalId}/fightgroups`, fightGroupCreation, emptyFightgroup, dispatchNotification).then(d => {
    if (d.succeed) return d.data;
    else return undefined;
  });
}


export async function updateShiajo(shiajoExternalId: number, data: ShiajoUpdateModel, dispatchNotification: React.Dispatch<IServiceTableNotification> | undefined): Promise<IApiShiajoModel | undefined> {
  return putApiObject<{ succeed: false, data: IApiShiajoModel }>("/Shiajos", shiajoExternalId, data, { succeed: false, ...emptyShiajoResult }, dispatchNotification).then(d => {
    if (d.succeed) {
      return d.data;
    } else {
      return undefined;
    }
  })
}

export async function deleteNotPostpone(fightGroupNodeId: number, dispatchNotification: React.Dispatch<IServiceTableNotification> | undefined) {
  return deleteApiObject<IApiFightGroupResult>(`/fightgroups?postpone=false&fightGroupNodeId=${fightGroupNodeId}`, null, emptyFightgroup
    , dispatchNotification
  );
}