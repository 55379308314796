import { Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';

var debug = require('debug')('ServiceTablesMinimalFrontend:ShiajoModal')


type ShiajoModelProps = {
  isShowing: boolean,
  hide: () => void,
  title: string,
  children?: any
}

const ShiajoModal: FunctionComponent<ShiajoModelProps> = ({ isShowing, hide, title, children }
) => {

  /*
  const handleClose = useCallback(() => {
    props.dispatch({ show: false });
  }, [props.dispatch]);

  useEffect(() => {
    debug("Use Effect");
  }, [showing, props.state.element]);
*/
  return isShowing
    ?
    ReactDOM.createPortal(
      <>
        <div className="shiajo-modal-overlay">
          <div className="shiajo-modal-wrapper">
            <div className="shiajo-modal">
              <div className="shiajo-modal-header">
                <Typography variant='h4'>{title}</Typography>
                <button
                  type="button"
                  className="shiajo-modal-close-button"
                  onClick={(e) => { hide() }}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="shiajo-modal-body">{children}</div>
            </div>
          </div>
        </div>
      </>,
      document.body)
    : null;
};

export default ShiajoModal;

/*<React.Fragment>
  <div className="modal-overlay" />
  <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
    <div className="modal">
      <div className="modal-header">
        <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={() => {
          props.dispatch({ show: false });
        }}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <p>
        Hello, I'm a modal.
    </p>
    </div>
  </div>
</React.Fragment>*/