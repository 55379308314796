import { getApiObject, postApiObject, putApiObject } from "../components/Operation/GenericOperation";

import { IServiceTableNotification, TDispatchNotification } from "../components/Util/ErrorMessage";
import { IApiDrawingTableauForSuivi } from "../Model/ApiDrawingModel";
import { IApiFighterFightTeam } from "../Model/ApiTeamModel";
import { IApiResult } from "../util/ApiCall";
import { IApiCompetitionTreeModel, IApiCompetitionTreeOneResult, IApiCompetitionTreeResult, IApiString } from "../util/ApiModel/Following/ApiCompetitionTree";

export type TSvgParam = {
    displayType: number,
    displayLevel: boolean,
    displayIppons: boolean,
    displayPools: boolean,
    countryFlag: boolean,
    resizeHeight: boolean
}

export type TApiFollowingTrees = Array<IApiFollowingTree>;

export interface IApiFollowingTree {
    "fightGroupNodeId": number,
    "label": string,
    "timestamp": number,
    "competitionId": number,
    "status": string,
    "rankingSerialization": string, //"[2534]",
    "fightGroupDataSerialization": string, // "[{\"redTeamId\":2524,\"whiteTeamId\":2534,\"redInstantiated\":true,\"whiteInstantiated\":true}]",
    "nextFightGroupNodeIdColoredSerialization": string, //"[]",
    "depth": number, //0 for the final
    "fightOrder": number,
    "poolFightGroup": boolean,
    "ranking": Array<number>,
    "fightGroupData": Array<
        {
            "redTeamId": number,
            "whiteTeamId": number,
            "redInstantiated": boolean,
            "whiteInstantiated": boolean
        }
    >,
    "nextFightGroupNodeIdColored": string //"[{\"id\":614887,\"color\":\"red\"}]",
    "nobodyFight": boolean,
    "instantiated": boolean,
    "final": boolean
}

export async function getSimulation2Svg(dispatchNotification: React.Dispatch<IServiceTableNotification>, pools: IApiDrawingTableauForSuivi): Promise<string | undefined> {
    return postApiObject<{
        data: string | undefined,
        succeed: boolean,
        status: number;
    }>("/export/svg/simulation2?displayType=2&labelWidth=250&countryFlag=true", pools, {
        data: undefined,
        succeed: false,
        status: 400
    }, dispatchNotification, (window as any)['REACT_APP_API_SUIVI_URL'], "error.backend.following").then(r => {
        return r.data
    });
}

export async function getSimulation2Json(dispatchNotification: React.Dispatch<IServiceTableNotification>, pools: IApiDrawingTableauForSuivi) {
    return postApiObject<{
        data: any,
        succeed: boolean,
        status: number;
    }>("/export/svg/simulation2/json", pools, {
        data: undefined,
        succeed: false,
        status: 400
    }, dispatchNotification, (window as any)['REACT_APP_API_SUIVI_URL'], "error.backend.following").then(r => {
        console.log(r);
        return r;
    });
}

export async function getTreeRank(competitonId: number, dispatchNotification: React.Dispatch<IServiceTableNotification>): Promise<Array<number>> {
    if (competitonId < 0) {
        return [];
    }
    //final
    let finalRank: [number, number] = (await getApiObject<IApiCompetitionTreeResult>(`/all?depth=1&competition=` + competitonId, null, {
        data: [],
        succeed: false,
        status: 400
    }, dispatchNotification, (window as any)['REACT_APP_API_SUIVI_URL']).then((res: IApiCompetitionTreeResult) => {
        if (res.succeed && res.data[0]) {
            return (res.data[0].ranking[0] === res.data[0].fightGroupData[0].redTeamId) ?
                [res.data[0].fightGroupData[0].redTeamId, res.data[0].fightGroupData[0].whiteTeamId]
                : [res.data[0].fightGroupData[0].whiteTeamId, res.data[0].fightGroupData[0].redTeamId]
                ;
        }
        else {
            return [0, 0];
        }
    }));
    let semiFinalRank: [Array<number>, Array<number>] = (await getApiObject<IApiCompetitionTreeResult>(`/all?depth=2&competition=` + competitonId, null, {
        data: [],
        succeed: false,
        status: 400
    }, dispatchNotification, (window as any)['REACT_APP_API_SUIVI_URL']).then((res: IApiCompetitionTreeResult) => {
        if (res.succeed && res.data[0]) {
            return [
                (res.data[0].ranking[0] === res.data[0].fightGroupData[0].redTeamId) ?
                    [res.data[0].fightGroupData[0].redTeamId, res.data[0].fightGroupData[0].whiteTeamId]
                    : [res.data[0].fightGroupData[0].whiteTeamId, res.data[0].fightGroupData[0].redTeamId]
                ,
                (res.data[1].ranking[0] === res.data[1].fightGroupData[0].redTeamId) ?
                    [res.data[1].fightGroupData[0].redTeamId, res.data[1].fightGroupData[0].whiteTeamId]
                    : [res.data[1].fightGroupData[0].whiteTeamId, res.data[1].fightGroupData[0].redTeamId]];
        }
        else {
            return [[0, 0], [0, 0]];
        }
    }));
    return [
        finalRank[0],
        finalRank[1],
        semiFinalRank[0][1],
        semiFinalRank[1][1]
    ]
}

export async function getFollowingFightGroup(fgId: number, dispatchNotification: TDispatchNotification): Promise<IApiCompetitionTreeModel | undefined> {
    return getApiObject<IApiCompetitionTreeOneResult>(`/fight`, fgId, {
        data: null,
        succeed: false
    }, dispatchNotification, (window as any)['REACT_APP_API_SUIVI_URL']).then((res: IApiCompetitionTreeOneResult) => {
        if (res.succeed && res.data) {

            return res.data;
        } else {
            return undefined;
        }
    })
}


export async function getFollowingCompetitionAll(competitionId: number, dispatchNotification: TDispatchNotification): Promise<IApiCompetitionTreeModel[]> {
    if (competitionId < 0) {
        return [];
    }
    let treeArrayList: IApiCompetitionTreeModel[] = await getApiObject<IApiCompetitionTreeResult>(`/all?competition=` + competitionId, null, {
        data: [],
        succeed: false,
        status: 400
    }, dispatchNotification, (window as any)['REACT_APP_API_SUIVI_URL']).then((res: IApiCompetitionTreeResult) => {
        return res.data;
    });
    treeArrayList.sort((a1, a2) => {
        if (a1.depth === a2.depth) return a1.fightGroupNodeId > a2.fightGroupNodeId ? 1 : -1;
        return a1.depth > a2.depth ? -1 : 1;
    });
    return treeArrayList;
}

/**
     * Annulation complete d'un combat et de tous les combats associés
     * @param fightgroupnodeid 
     */
export function cancelFight(fightgroupnodeid: number, dispatchNotification: TDispatchNotification): Promise<boolean> {
    return putApiObject<IApiCompetitionTreeOneResult>(`/fight/${fightgroupnodeid}/cancel`, null, {}, {
        succeed: false,
        data: null
    }, dispatchNotification, (window as any)['REACT_APP_API_SUIVI_URL'], "error.backend.following").then((res: IApiCompetitionTreeOneResult) => {
        return res.succeed
    })
}

export function extractTeamsFromApiCompetitionTreeModel(fg: IApiCompetitionTreeModel, teamMap: Map<number, IApiFighterFightTeam>): Array<IApiFighterFightTeam> {
    const fgteamList: Array<IApiFighterFightTeam> = (fg?.fightGroupData.reduce((teams: Array<number>, fight) => {
        if (!teams.includes(fight.redTeamId)) teams.push(fight.redTeamId);
        if (!teams.includes(fight.whiteTeamId)) teams.push(fight.whiteTeamId);
        return teams;
    }, []).map(fid => teamMap?.get(fid) ?? null).filter(ft => ft !== null) ?? []) as Array<IApiFighterFightTeam>;
    return fgteamList;
}

export function postponeSuivi(fightgroupnodeid: number, dispatchNotification: TDispatchNotification): Promise<boolean> {
    return putApiObject<IApiCompetitionTreeOneResult>(`/fight/${fightgroupnodeid}/postpone`, null, {}, {
        succeed: false,
        data: null
    }, dispatchNotification, (window as any)['REACT_APP_API_SUIVI_URL'], "error.backend.following").then((res: IApiCompetitionTreeOneResult) => {
        return res.succeed
    })
}



/**
 * 
 * @param dispatchNotification 
 * @param fights FigthgroupNodeIdList
 * @returns 
 */
export function resync(dispatchNotification: TDispatchNotification, fights?: Array<number>, competitionId?: number): Promise<boolean> {
    return getApiObject<IApiString>(`/resync?competitionId=${competitionId ?? 0}&${fights && fights.length > 0 ? '?' + fights.map(f => `fights=${f}`).join('&') : ''}`, null, {
        succeed: false,
        data: ""
    }, dispatchNotification, (window as any)['REACT_APP_API_SUIVI_URL'], "error.backend.following").then((res: IApiString) => {
        return res.succeed;
    })
}



export interface ISvgResult extends IApiResult {
    data: string
}

/**
 * 
 * @param competitonId 
 * @param displayType 
 * @param dispatchNotification 
 * @returns 
 */
export async function getCompetitionSvg(competitonId: number, params: TSvgParam, dispatchNotification: TDispatchNotification): Promise<ISvgResult> {
    if (competitonId < 0) {
        const result: ISvgResult = {
            succeed: false,
            data: "Tree not found (comp id < 0)"
        };
        return result;
    }
    return getApiObject<ISvgResult>('/export/svg?competition=' + competitonId + "&displayPools=true&labelWidth=200&displayScore=" + params.displayIppons
        + "&countryFlag=" + params.countryFlag
        + "&displayLevel=" + params.displayLevel
        + "&displayType=" + params.displayType, null, {
        succeed: false,
        data: "Tree not found"
    }, dispatchNotification, (window as any)['REACT_APP_API_SUIVI_URL']);
}

/**
 * Reload following memory with database
 */
export async function reload(dispatchNotification: TDispatchNotification): Promise<boolean> {
    return postApiObject<IApiCompetitionTreeResult>(`/reload/force`, null, {
        data: [],
        succeed: false,
        status: 400
    }, dispatchNotification, (window as any)['REACT_APP_API_SUIVI_URL']).then((res) => res.succeed)
}

export async function getAvailable(competitonId: number, dispatchNotification: TDispatchNotification): Promise<IApiCompetitionTreeResult> {
    if (competitonId < 0) {
        return {
            data: [],
            succeed: false,
            status: 400
        }
    }
    return getApiObject<IApiCompetitionTreeResult>(`/available?competition=` + competitonId, null, {
        data: [],
        succeed: false,
        status: 400
    }, dispatchNotification, (window as any)['REACT_APP_API_SUIVI_URL']);
}

export async function getFollowingCompetitionStatus(competitionId: number, dispatchNotification: TDispatchNotification): Promise<string> {
    if (competitionId < 0) {
        return ""
    }
    return getApiObject<IApiString>(`/competition/${competitionId}/status`, null, {
        succeed: false,
        data: ""
    }, dispatchNotification, (window as any)["REACT_APP_API_SUIVI_URL"], "error.backend.following").then((res: IApiString) => {
        return res.data;
    })
}

export function sortCompetitionTreeModel(a: IApiCompetitionTreeModel, b: IApiCompetitionTreeModel) {
    return (a.depth * 100 + a.fightOrder) - (a.depth * 100 + a.fightOrder);
}