import React from 'react';
import { useTranslation } from 'react-i18next';
import { IApiFightGroupModel } from '../../util/ApiModel/ApiFightgroupModel';
import { IShiajoScreenConfig } from './ShiajoScreen';


var debug = require('debug')('ServiceTablesMinimalFrontend:FightGroupScreen')

export function FightGroupSummaryScreen(props: { fg: IApiFightGroupModel, config: IShiajoScreenConfig }) {
    const { t, i18n } = useTranslation();
    let teamsDiv = <></>;
    if (props.fg.fights?.length > 1) {
        const teams: Map<number, {
            name: string,
            colors: Array<"white" | "red">,
            order: number
        }> = new Map();
        for (const fight of props.fg.fights.sort((a, b) => a.order - b.order)) {
            if (!teams.has(fight.redFightTeam.id)) teams.set(fight.redFightTeam.id, {
                name: fight.redFightTeam.name,
                colors: [],
                order: fight.order
            });
            if (!teams.has(fight.whiteFightTeam.id)) teams.set(fight.whiteFightTeam.id, {
                name: fight.whiteFightTeam.name,
                colors: [],
                order: fight.order
            });
            teams.get(fight.redFightTeam.id)?.colors.push('red');
            teams.get(fight.whiteFightTeam.id)?.colors.push('white');
        }

        teamsDiv = <>
            {[...teams.values()].sort((a, b) => a.order - b.order)
                .map((te, k) => {
                    return <React.Fragment key={k}>
                        {k > 0 ? <b> - </b> : undefined}
                        {te.name}&nbsp;{te.colors.map((c, k) => <span key={k} className={c === 'red' ? 'screen-red-label' : 'screen-white-label' }>{(t("label.word." + c))[0]}</span>)}
                    </React.Fragment>
                }
                )
            }
        </>
    }
    else if (props.fg.fights?.length === 1) {
        teamsDiv = <>
            {props.fg.fights[0].whiteFightTeam.name}(<span className="screen-white-label">{t('label.word.white')}</span>) vs {props.fg.fights[0].redFightTeam.name}(<span className="screen-red-label">{t('label.word.red')}</span>)
        </>
    }
    return <>
        {props.fg.label} : {teamsDiv}<br />
    </>;
}

/*
            {props.fights.sort((a: IFightScreenState, b: IFightScreenState) => {
                if (a.id === props.currentFightId) return -1;
                if (b.id === props.currentFightId) return 1;
                if (a.id > b.id) return 1;
                return -1;
            }).map((fight: IFightScreenState, k) => {

                return <FightScreen key={fight.id} {...fight} />

            })}*/