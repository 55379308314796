import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, List, ListItem, ListItemText } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { IApiCompetitionModel } from "../../Model/ApiCompetitionModel";
import { IApiCompetitionShiajoModel } from '../../util/ApiCompetition';
import { IApiShiajoModel } from "../../util/ApiModel/ApiShiajoModel";
import { CompetitionLabelStr } from "../Competition/CompetitionLabel";

export interface IShiaijoSelectModalProps {
    shiaijos: Array<IApiShiajoModel|IApiCompetitionShiajoModel>,
    onSelect: (shiaijo: IApiShiajoModel | IApiCompetitionShiajoModel| undefined) => void,
    open: boolean,
    onClose: () => void,
    competitions?: Array<IApiCompetitionModel>
}
export function ShiaijoSelectModal(props: IShiaijoSelectModalProps) {
    const { t, i18n } = useTranslation();


    const handleListItemClick = (s: IApiShiajoModel|IApiCompetitionShiajoModel) => {
        props.onSelect(s);
    };
    return <Dialog open={props.open} onClose={(e) => props.onClose()}>
        <DialogTitle>
            {t("label.title.shiajoSelect")}
            <CloseIcon onClick={(e) => props.onClose()} />
        </DialogTitle>
        <List sx={{ pt: 0 }}>
            {props.shiaijos.map((s) => {
                let competitionLabel = undefined;
                if (props.competitions) {
                    competitionLabel = s.competitionId.map((cid, k) => {
                        let fullName = undefined;
                        if (props.competitions) fullName = CompetitionLabelStr(props.competitions.find((c2) => c2.id === cid));
                        return fullName
                    }).join(" , ");
                }
                return <ListItem onClick={() => handleListItemClick(s)} key={s.id}>
                    <ListItemText primary={s.fullname + " - " + (competitionLabel ?? "")} />
                </ListItem>
            })}
        </List>
    </Dialog>
        ;
}