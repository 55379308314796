import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IApiFightGroupModel } from "../../util/ApiModel/ApiFightgroupModel";

export interface IFightGroupTeamsCardProps {
    f: IApiFightGroupModel
}

export function FightGroupTeamsCard(props: IFightGroupTeamsCardProps) {
    const { t } = useTranslation();
    return <>
        <Row>
            <Col md={4}>
                {t("label.word.red")}
            </Col>
            <Col md={4}>
                
            </Col>
            <Col md={4}>
                {t("label.word.white")}
            </Col>
        </Row>
        {props.f.fights.map((f, k) => {
            return <Row key={k}>
                <Col md={4}>
                    {f.redFightTeam.name}
                </Col>
                <Col md={4}>
                    {t("label.word.versus")}
                </Col>
                <Col md={4}>
                    {f.whiteFightTeam.name}
                </Col>
            </Row>
        })}
    </>
}