import { Edit } from '@mui/icons-material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import BackspaceIcon from '@mui/icons-material/Backspace'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import MoveUpIcon from '@mui/icons-material/MoveUp'
import PanToolIcon from '@mui/icons-material/PanTool'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import { CircularProgress, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import { amber, green, grey, lightBlue, lightGreen, lime, orange, red, teal, yellow } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { IApiCompetitionModel } from '../../Model/ApiCompetitionModel'
import { IApiCompetitionShiajoModel } from '../../util/ApiCompetition'
import { IApiCompetitionTreeModel } from '../../util/ApiModel/Following/ApiCompetitionTree'
import { CompetitionLabel } from '../Competition/CompetitionLabel'
import ShiajoModal from '../Modal/ShiajoModal'
import { isFollowedFightGroup } from '../Operation/FightGroupOperation'
import { TPopOverAction } from '../Use/UsePopOver'
import { IFightSchedullerActions, ISchedullerActions, ISchedullerFight, ISchedullerShiaijo } from './SchedullerTable'

export interface IFightSchedullerProps {
    fightgroup: ISchedullerFight | IApiCompetitionTreeModel
    displayPopOver?: TPopOverAction<ISchedullerFight | IApiCompetitionTreeModel>,
    actions: IFightSchedullerActions,
    displayFightGroupDetails: boolean,
}

export function FightScheduller(props: IFightSchedullerProps) {
    const [loading, setLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const [postPoneForce, setPostPoneForce] = useState<undefined | number>(undefined);
    const [postPone, setPostPone] = useState<undefined | number>(undefined);

    

    useEffect(() => {
        setLoading(false);
    }, [props.fightgroup])


    let fightLabel = "";
    if ('fightOrder' in props.fightgroup && props.fightgroup.poolFightGroup === false) {
        fightLabel = `${props.fightgroup.fightOrder + 1} / ${Math.pow(2, props.fightgroup.depth - 1)}`;
    }
    else {
        fightLabel = `${props.fightgroup.label}`;
    }
    let color: string = grey[900];
    if (('poolFightGroup' in props.fightgroup && props.fightgroup.poolFightGroup)
        || ('isPoolFightGroup' in props.fightgroup && (props.fightgroup as any).isPoolFightGroup)) {
        color = lightBlue[800];
    } else {
        switch (props.fightgroup.depth) {
            case 1: color = red[900]; break;
            case 2: color = orange[800]; break;
            case 3: color = amber[600]; break;
            case 4: color = yellow[800]; break;
            case 5: color = lime[800]; break;
            case 6: color = lightGreen[700]; break;
            case 7: color = green[700]; break;
            case 8: color = teal[700]; break;
        }
    }


    return <React.Fragment>
        {postPoneForce &&
            <ShiajoModal
                isShowing={true}
                hide={() => setPostPoneForce(undefined)}
                title={t("tooltip.scheduller.postponeForce")}>
                {t("confirmation.scheduller.postponeForce")}
                <br /><br />
                <Button variant="success" onClick={() => {
                    setLoading(true);
                    if (postPoneForce && props.actions.postponeSuivi) props.actions.postponeSuivi(postPoneForce, true).finally(() => setLoading(false));
                    setPostPoneForce(undefined)
                }}>{t('action.modal.confirm')}</Button>
                <Button variant="danger" onClick={() => setPostPoneForce(undefined)}>{t('action.modal.cancel')}</Button>
            </ShiajoModal>
        }

        {postPone &&
            <ShiajoModal
                isShowing={true}
                hide={() => setPostPone(undefined)}
                title={t("tooltip.scheduller.postpone")}>
                {t("confirmation.scheduller.postpone")}
                <br /><br />
                <Button variant="success" onClick={() => {
                    setLoading(true);
                    if (postPone && props.actions.postponeSuivi) props.actions.postponeSuivi(postPone, false).finally(() => setLoading(false));
                    setPostPone(undefined)
                }}>{t('action.modal.confirm')}</Button>
                <Button variant="danger" onClick={() => setPostPone(undefined)}>{t('action.modal.cancel')}</Button>
            </ShiajoModal>
        }


        <Typography color={color} component="div">
            {fightLabel} {props.displayFightGroupDetails ? `(fgid : ${props.fightgroup.fightGroupNodeId})` : ""}





            {loading && <CircularProgress />}
            {!loading && <>

                <Tooltip title={t("action.fightgroup.clickToShow")}>
                    <HelpOutlineIcon onClick={(e) => {
                        if (props.displayPopOver) props.displayPopOver("display", e.target, props.fightgroup)
                    }}
                        onMouseLeave={(e) => {
                            if (props.displayPopOver) props.displayPopOver("hide", e.target, props.fightgroup)
                        }} />
                </Tooltip>

                {props.actions.changeNewShiajo && <Tooltip title={t("tooltip.scheduller.moveFightGroup") ?? ""}>
                    <MoveUpIcon onClick={() => {
                        setLoading(true);
                        if (props.actions.changeNewShiajo) props.actions.changeNewShiajo(props.fightgroup).finally(() => setLoading(false));
                    }} />
                </Tooltip>}

                {isFollowedFightGroup(props.fightgroup) && <Tooltip title={t("tooltip.scheduller.askpostpone") ?? ""}>
                    <PanToolIcon onClick={() => {
                        if (props.actions.deleteNotPostpone) props.actions.deleteNotPostpone(props.fightgroup.fightGroupNodeId);
                    }} />
                </Tooltip>}




                {props.actions.postponeSuivi && <Tooltip title={t("tooltip.scheduller.postpone") ?? ""}>
                    <BackspaceIcon onClick={() => {
                        setPostPone(props.fightgroup.fightGroupNodeId)
                    }} />
                </Tooltip>}
                {props.actions.postponeSuivi && <Tooltip title={t("tooltip.scheduller.postponeForce") ?? ""}>
                    <HighlightOffIcon onClick={() => {
                        setPostPoneForce(props.fightgroup.fightGroupNodeId)
                    }}
                    />
                </Tooltip>}
                {props.actions.scheduleAllocate && <Tooltip title={t("tooltip.scheduller.allocate") ?? ""}>
                    <ArrowUpwardIcon onClick={() => {
                        setLoading(true);
                        let competitionId = undefined;
                        if ('competitionId' in props.fightgroup) {
                            competitionId = (props.fightgroup as IApiCompetitionTreeModel).competitionId;
                        }
                        else if ('competition' in props.fightgroup) {
                            competitionId = (props.fightgroup as ISchedullerFight).competition;
                        }
                        if (props.actions.scheduleAllocate && competitionId) props.actions.scheduleAllocate(competitionId, props.fightgroup.fightGroupNodeId).finally(() => setLoading(false));
                        else setLoading(false);
                    }} />
                </Tooltip>}
                {props.actions.scheduleCreateFight && <Tooltip title={t("tooltip.scheduller.resend") ?? ""}>
                    <SyncAltIcon onClick={() => {
                        setLoading(true);
                        let competitionId = undefined;
                        if ('competitionId' in props.fightgroup) {
                            competitionId = (props.fightgroup as IApiCompetitionTreeModel).competitionId;
                        }
                        else if ('competition' in props.fightgroup) {
                            competitionId = (props.fightgroup as ISchedullerFight).competition;
                        }
                        if (props.actions.scheduleCreateFight && competitionId) props.actions.scheduleCreateFight(competitionId, props.fightgroup.fightGroupNodeId).finally(() => setLoading(false));
                        else setLoading(false);
                    }} />
                </Tooltip>}
            </>
            }
        </Typography>

    </React.Fragment>
}

export interface IShiaijoSchedullerPropsNoAction {
    shiajoModel: IApiCompetitionShiajoModel,  //model from Competition
    shiaijo: ISchedullerShiaijo, //model from scheduller
    shiaijoCompetitions: Array<{ //list of competition for this shiaijo
        competition: IApiCompetitionModel, //competition model from Competition
        fights: Array<ISchedullerFight> //fights
    }>
}

export interface IShiaijoSchedullerProps extends IShiaijoSchedullerPropsNoAction {
    displayPopOver: TPopOverAction<ISchedullerFight | IApiCompetitionTreeModel>
    actions: ISchedullerActions,
    displayFightGroupDetails: boolean
}

function SchedullerShiaijoActions(props: {
    shiajoModel: IApiCompetitionShiajoModel,
    shiaijo: ISchedullerShiaijo
    actions: ISchedullerActions
}) {
    const { t, i18n } = useTranslation();

    return <>
        <Typography variant="h5">
            {props.shiajoModel.fullname ?? 'Not defined in competition service !'} ({props.shiaijo.ext_id})
        </Typography>
        {
            props.shiaijo.available && <Tooltip title={t('action.following.shiaijoDisable') ?? ""}>
                <RemoveCircleIcon sx={{ color: red[500] }} onClick={() => {
                    props.actions.switchShiaijoAvailability(props.shiaijo);
                }} /></Tooltip>
        }
        {
            props.shiaijo.available === false && <Tooltip title={t('action.following.shiaijoEnable') ?? ""}>
                <AddCircleIcon color="primary" onClick={() => {
                    props.actions.switchShiaijoAvailability(props.shiaijo);
                }} /></Tooltip>
        }
    </>
}

function SchedullerShiaijoCompetitionFightGroups(props: {
    competition: {
        competition: IApiCompetitionModel;
        fights: ISchedullerFight[];
    },
    shiaijo: ISchedullerShiaijo,
    displayPopOver: TPopOverAction<ISchedullerFight | IApiCompetitionTreeModel>,
    actions: ISchedullerActions,
    displayFightGroupDetails: boolean
}) {
    const { t, i18n } = useTranslation();

    return <Grid container item xs={12}>
        <Grid container item xs={12}>
            <Grid container item xs={12} >


                <Typography color={props.shiaijo.available ? teal[900] : red[500]}>

                    <CompetitionLabel competition={props.competition.competition} short={25} />
                </Typography>
                {<Tooltip title={t('action.following.editShiaijo') ?? ""}>
                    <Edit onClick={() => {
                        props.actions.editShiaijo(props.competition.competition.id, props.shiaijo);
                    }} /></Tooltip>}
                {!props.shiaijo.available && <Tooltip title={t('info.shiajo.notAvailable')}><HelpOutlineIcon color='error' /></Tooltip>}
                {<Tooltip title={t('action.following.removeCompetitionShiaijo') ?? ""}>
                    <RemoveCircleIcon sx={{ color: red[500] }} onClick={() => {
                        props.actions.removeCompetitionShiaijo(props.shiaijo, props.competition.competition);

                        //{props.shiaijo.available ?  : t('action.following.shiaijoEnable')}
                    }} /></Tooltip>}

            </Grid>
            {[...props.competition.fights].sort((f1, f2) => f1.fightOrder - f2.fightOrder).map((fg, key) => {
                return <Grid container item xs={12} key={fg.id} >
                    <FightScheduller key={key}
                        fightgroup={fg}
                        displayPopOver={props.displayPopOver}
                        actions={props.actions}
                        displayFightGroupDetails={props.displayFightGroupDetails}
                    /></Grid>
            })}
        </Grid>
    </Grid>
}

/**
 * Component to display a shiaijo with scheulled fightgroups
 * @param props 
 * @returns 
 */
export function ShiaijoScheduller(props: IShiaijoSchedullerProps) {

    return <Grid container spacing={0} >
        <Grid container item xs={12} justifyContent="center">
            <SchedullerShiaijoActions
                shiajoModel={props.shiajoModel}
                shiaijo={props.shiaijo}
                actions={props.actions} />

        </Grid>
        {props.shiaijoCompetitions.map((competition, key) => {
            return <SchedullerShiaijoCompetitionFightGroups
                key={competition.competition.id}
                actions={props.actions}
                competition={competition}
                displayPopOver={props.displayPopOver}
                shiaijo={props.shiaijo}
                displayFightGroupDetails={props.displayFightGroupDetails}
            />
        })}
    </Grid>
}

