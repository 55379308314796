
import React, { memo, useCallback, useEffect, useState } from "react";
import { } from 'react-bootstrap';

var debug = require('debug')('ServiceTablesMinimalFrontend:Chronometer')

export type TChronoCommand = "START" | "PAUSE" | "END" | "TICK" | "ADD_ZERO" | "SET" | "INIT";

export interface IChronometerActions {
  type: TChronoCommand,
  startTs?: number
}

export interface IChronometerState {
  timerOn: boolean,
  timerStart: number,
  timerTime: number
}

function addZero(n: number) {
  return n < 10 ? "0" + n : n;
}

export interface ChronoState {
  readonly startDate: number;
  readonly isChronoActive: boolean;
  readonly pauseDate: number;
  readonly duration?: number;
  readonly message?: string;
}

export const ChronoDisplay = memo(function ChronoDisplay(props: ChronoState) {
  const { isChronoActive, startDate, pauseDate, duration } = props;
  const [milliseconds, setMilliseconds] = useState<number>(0);

  useEffect(() => {
    debug("UseEffect, active : " + isChronoActive)
    let interval: NodeJS.Timeout | null = null;

    if (isChronoActive) {
      interval = setInterval(() => {
        setMilliseconds(Date.now() - startDate);
      }, 10);
    }
    else {
      setMilliseconds(pauseDate - startDate);
    }
    return () => {
      if (interval) clearInterval(interval);
      interval = null;
    }
  }, [isChronoActive, startDate, pauseDate]);

  let centisecondsDisplay = ("0" + (Math.floor(milliseconds / 10) % 100)).slice(-2);
  let seconds = Math.floor(milliseconds / 1000) % 60;
  let secondsDisplay = ("0" + seconds).slice(-2);
  let minutesDisplay = ("0" + (Math.floor(milliseconds / 60000) % 60)).slice(-2);
  let hoursDisplay = ("0" + Math.floor(milliseconds / 3600000)).slice(-2);

  let className = "Chrono"
  if (duration) {
    if (duration - seconds < 10) {
      className += " Chrono-Imminent"
    }
    else if (duration - seconds < 30) {
      className += " Chrono-Soon"
    }
  }

  return (
    <div className={className}>
      {props.message && <>{props.message}<br/></>}
      {minutesDisplay}:{secondsDisplay}:{centisecondsDisplay}
      <br/> {duration ? ("0" + Math.floor(duration / 60)).slice(-2) + ":" + ("0" + duration % 60).slice(-2) + ":00" : ""}
    </div>
  );
});

/**
 * React Chronometer
 */
export function useChronometer(): [(command: TChronoCommand, date?: Date, datePause?: Date, run?: boolean) => void, ChronoState] {
  const [state, setState] = useState<ChronoState>(() => ({ startDate: Date.now(), pauseDate: Date.now(), isChronoActive: false, duration: 0, message: undefined }));

  const toggle = useCallback(() => {
    debug("TOOGLE");
    if (state.isChronoActive) {
      setState(s => ({ ...s, pauseDate: Date.now(), isChronoActive: !s.isChronoActive }));
    }
    else {
      setState(s => ({
        ...s,
        startDate: (s.pauseDate ? (s.startDate + Date.now()) - s.pauseDate : Date.now()),
        isChronoActive: !s.isChronoActive
      }));
    }
  }, [state, setState]);

  const set = useCallback((dateStart?: Date, datePause?: Date, run?: boolean, duration?: number, message?: string) => {
    debug("SET");
    if (dateStart) debug("Set " + dateStart.toString() + run);
    setState(s => ({
      ...s,
      startDate: dateStart ? dateStart.getTime() : Date.now(),
      pauseDate: datePause ? datePause.getTime() : 0,
      isChronoActive: !!run,
      duration: duration,
      message: message
    }));
  }, [setState]);

  const reset = useCallback(() => {
    debug("RESET");
    setState(() => ({ startDate: Date.now(), pauseDate: Date.now(), isChronoActive: false, duration: undefined }))
  }, [setState]);


  const dispatchCallback = useCallback((command: TChronoCommand, dateStart?: Date, datePause?: Date, run?: boolean, duration?: number, message?: string) => {
    debug(command);
    switch (command) {
      case "START":
        toggle();
        break;
      case "PAUSE":
        toggle();
        break;
      case "END":
        reset();
        break;
      case "SET":
        set(dateStart, datePause, run, duration, message);
        break;
    }
  }, [toggle, reset, set]);

  return [dispatchCallback, state];
}
