import { Alert, Autocomplete, Button, ButtonGroup, MenuItem, Select, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useCallback, useEffect, useState } from 'react';
import { getShiajo, getShiajos, postShiajos } from '../../Backend/ApiCompetition';
import { emptyCompetitionShiajoModel, IApiCompetitionModel, IApiCompetitionShiajoModel, ICompetitionFilter } from "../../Model/ApiCompetitionModel";
import { IEventNotification } from "../Util/ErrorMessage";
import { deleteCompetition, getCompetitions, patchCompetition, patchShiajos } from '../../Backend/ApiCompetition';
import { AutocompleteCompetition } from '../Event/UseAutocompleteCompetition';
import { IAuthentication } from '../Auth/auth';


function ValidAlert(props: { error: boolean, helperText: string | undefined }): JSX.Element {
    if (props.error) {
        return <Alert color='error'>
            {props.helperText}
        </Alert>
    }
    else {
        return <></>;
    }
}


export function ShiaijoForm(props: {
    authentication: IAuthentication
    competitionId: number,
    shiaijo: IApiCompetitionShiajoModel,
    dispatchNotification: React.Dispatch<IEventNotification>,
    onChange: (shiaijo: IApiCompetitionShiajoModel | undefined) => void,
    competitionFilter: ICompetitionFilter
}) {
    const [shiaijo, setShiaijo] = useState<IApiCompetitionShiajoModel>(props.shiaijo);
    const [competitionSelected, setCompetitionSelected] = useState<undefined | IApiCompetitionModel>(undefined);


    useEffect(() => {
        setShiaijo(props.shiaijo);
    }, [props.shiaijo]);

    const validField = useCallback((field: keyof IApiCompetitionShiajoModel): { error: boolean, helperText: string | undefined } => {
        let result: { error: boolean, helperText: string | undefined } = { error: false, helperText: undefined }
        switch (field) {
            case 'fullname':
                if (shiaijo[field].length <= 0) {
                    result = {
                        error: true,
                        helperText: "Field must not be empty"
                    }
                }
        }
        return result;
    }, [shiaijo]);

    const isValid = useCallback((): Boolean => {

        for (const k in shiaijo) {
            if (validField(k as keyof IApiCompetitionShiajoModel).error === true) {
                return false;
            }
        }
        return true;
    }, [validField, shiaijo])

    return <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
    >

        <Grid item xs={12}>
            Competition ID: {props.competitionId}
        </Grid>
        <Grid item xs={12}>
            Shiaijo ID: {shiaijo.id}
        </Grid>
        <Grid item xs={12}>
            <TextField
                id="standard-basic"
                label="FullName"
                {...validField('fullname')}
                value={shiaijo.fullname}
                onChange={(e) => {
                    setShiaijo({
                        ...shiaijo
                        , fullname: e.target.value
                    })
                }}
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                id="standard-basic"
                label="Shortname"
                {...validField('shortname')}
                value={shiaijo.shortname.length > 0 ? shiaijo.shortname.length : shiaijo.fullname}
                onChange={(e) => {
                    if (e.target.value !== shiaijo.fullname) {
                        setShiaijo({ ...shiaijo, shortname: e.target.value })
                    }
                }}
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                id="standard-basic"
                label="comment"
                {...validField('comment')}
                value={shiaijo.comment}
                onChange={(e) => setShiaijo({ ...shiaijo, comment: e.target.value })}
            />
        </Grid>
        <Grid item xs={12}>
            <Select
                id="standard-basic"
                label="order"
                value={shiaijo.order}
                onChange={(e) => setShiaijo({ ...shiaijo, order: parseInt(e.target.value.toString()) })}
            >
                {Array.from(Array(20).keys()).map(v => {
                    return <MenuItem value={v} key={v}>{v}</MenuItem>
                })}
            </Select>
            <ValidAlert {...validField('order')} />
        </Grid>

        <Grid item xs={12}>
            <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                    onClick={(e) => {
                        if (shiaijo.id <= 0) {
                            let shiaijoToSave: any = { ...shiaijo };
                            if (shiaijoToSave.shortname.length === 0) {
                                shiaijoToSave.shortname = shiaijoToSave.fullname
                            }

                            delete shiaijoToSave.id;

                            postShiajos(props.dispatchNotification, props.competitionId, shiaijoToSave).then(() => {
                                props.onChange(shiaijoToSave)
                            })
                        }
                        else {
                            patchShiajos(props.dispatchNotification, props.competitionId, shiaijo.id, shiaijo).then(() => {
                                props.onChange(shiaijo)
                            })
                        }
                    }}
                >Save</Button>
                <Button
                    onClick={(e) => {
                        props.onChange(undefined)
                    }}
                >Cancel</Button>
            </ButtonGroup>
        </Grid>

        {
            shiaijo.id > 0 && <>
                <Grid item xs={12}>
                    Competitions avec ce shiaijo : {shiaijo.competitionId.map((c) => {
                        return <React.Fragment key={c}>{c};</React.Fragment>
                    })}
                </Grid>
                <Grid item xs={12}>
                    Ajouter à une competition :<br />
                    <AutocompleteCompetition
                        authentication={props.authentication}
                        dispatchNotification={props.dispatchNotification}
                        competitionSelected={competitionSelected}
                        onSelect={(c) => {
                            setCompetitionSelected(c);
                        }}
                        competitionFilter={props.competitionFilter}
                    />
                </Grid>
                <Grid item xs={12}>

                    <Button
                        variant='contained'
                        onClick={(e) => {
                            if (competitionSelected) {
                                patchShiajos(props.dispatchNotification, competitionSelected.id, shiaijo.id, undefined).then((s) => {
                                    getShiajo(props.dispatchNotification, competitionSelected.id, shiaijo.id).then(s => {
                                        setShiaijo(s);
                                    })
                                })
                            }
                        }}
                    >Ajouter</Button>
                </Grid>


            </>
        }
    </Grid >
}