import React from 'react';
import { useErrorMessage } from '../Util/ErrorMessage';
import { Grid } from '@mui/material';




export function AffichageTable() {
    sessionStorage.setItem("module", "affichage");
    const [dispatchNotification, errorComponent] = useErrorMessage();
    return <>
        <Grid container spacing={2}>
            <Grid item md={12}>
                {errorComponent}
            </Grid>
            <Grid item md={12}>
                <h2>Table Cenrale</h2>
            </Grid>
            <Grid item md={12}>
                <p>use the menu to navigate</p>
            </Grid>
        </Grid>
    </>
}