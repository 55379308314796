import { Alert, AlertColor, Snackbar } from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';


export interface IEventNotification {
  type: "error" | "info" | "success" | "clear",
  operation: "create" | "read" | "update" | "delete",
  message?: string,
  context?: any,
  details?: string
}

export type TEventNotifications = Array<IEventNotification>;

//en cible : empiler les notifications, action de nettoyage
function notificationReducer(
  currentNotification: TEventNotifications
  , action: IEventNotification): TEventNotifications {
  console.log(action);
  switch (action.type) {
    case 'clear':
      return [];
    default:
      return [...currentNotification, action];
  }

}

export function useErrorMessage(detail: boolean = true, mode: "SnackBar" | "Div" = "SnackBar" ): [React.Dispatch<IEventNotification>, any] {
  const { t, i18n } = useTranslation();
  const [tout, setTout] = useState<any>(null);

  const [notifications, dispatchNotification] = useReducer(notificationReducer, []);

  useEffect(() => {
    if (notifications.length > 0) {
      if (tout) {
        clearTimeout(tout);
      }

      setTout(setTimeout(() => {
        dispatchNotification({
          type: "clear",
          operation: "delete"
        })
      }, 5000));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);//DO NOT SET TOUT => infinite loop



  const messageContent = <div>
    {notifications.filter(a => a.operation !== "read" || a.type === "error").map((notification, key) => {
      // "danger" | "info" | "success" | "primary" | "light"
      let className: AlertColor = "info";
      switch (notification.type) {
        case "error": className = "warning"; break;
        case "info": className = "info"; break;
        case "success": className = "success"; break;
      }

      let content = undefined;
      if (detail) {
        if (notification.message && notification.message.length > 0) {
          content = <Alert severity={className}>{i18n.exists(notification.message) ? t(notification.message) : notification.message}
            {notification.details && <p style={{ fontSize: 10 }}><br />{notification.details}</p>}
          </Alert>
        }
      }
      else {
        if (notification.type === "error" || notification.type === "success") {
          content = <Alert severity={className}>{notification.message ? (i18n.exists(notification.message) ? t(notification.message, notification.context) : notification.message) : t("error.internal")}
            {notification.details && <p style={{ fontSize: 10 }}><br />{notification.details}</p>}
          </Alert>
        }
      }
      return <React.Fragment key={key}>
        {content}
      </React.Fragment>
    })}
  </div>

  let errorMessage: JSX.Element;
  if (mode === 'Div') {
    errorMessage = <div>
      Error : {messageContent}
    </div>
  }
  else {
    errorMessage = <Snackbar
      open={notifications.length > 0}
    >
      {messageContent}
    </Snackbar>;
  }

  return [dispatchNotification, errorMessage];
}