import React, { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { IApiFighterModel } from "../../util/ApiModel/ApiFighterModel";
import { IApiFighterFighter } from "../Team/LazyFightTeam";

export function FighterLabelStr(fighter: IApiFighterModel | IApiFighterFighter | undefined, id:boolean, i18t:(token:string) => string = (token:string) => token): string {
    if (fighter) {
        if ('firstName' in fighter){
            const f = fighter as IApiFighterModel;
            return `${f.firstName} ${f.lastName.toUpperCase()} ${id ? ` (${f.id})` : ''}`
        }
        else if ('firstname' in fighter){
            const f = fighter as IApiFighterFighter;
            return `${f.firstname} ${f.lastname.toUpperCase()} ${id ? ` (${f.id})` : ''}`
        }
        else {
            return `${fighter}`
        }
    }
    else {
        return i18t('label.fighter.none')
    }
}

export function FighterLabel(props: { fighter: IApiFighterModel | undefined, id?:boolean }) {
    const { t } = useTranslation();

    return <>{FighterLabelStr(props.fighter, props.id!!, t)}</>
}
