import { BroadcastChannel } from 'broadcast-channel';
import { createContext } from 'react';

export interface IAppMessage {
    action: 
        'TABS_NAVIGATE',//Navigate all app tabs to the first element in array
    params: Array<string>,
    sourceTabId: string
}

export const AppBroadcastContext = createContext<BroadcastChannel<IAppMessage> | undefined>(undefined);
