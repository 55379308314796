import { Paper, PaperOwnProps, styled } from "@mui/material";
import React from "react";

export const StyledPaper = styled((props: PaperOwnProps) => <Paper {...props}>
</Paper>)(
    ({ theme }) => ({
        width: '100%',
        marginTop: '5px',
        paddingBottom: '5px'
    })
);