import { FormatListNumbered, HelpOutline } from "@mui/icons-material";
import { Box, Button, Chip, Grid, Modal, Tab, Tabs, Tooltip } from "@mui/material";

import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppBroadcastContext } from "../../AppContext";
import { getCompetitions } from "../../Backend/ApiCompetition";
import { IApiCompetitionModel } from "../../Model/ApiCompetitionModel";
import { storeData } from "../../Offline/offline_type";
import { IApiResult } from "../../util/ApiCall";
import { EFightStatus, IApiFightModel, IFightTeamState } from "../../util/ApiModel/ApiFightModel";
import { EFightGroupStatus, IApiFightGroupModel, fgTypeLabel } from "../../util/ApiModel/ApiFightgroupModel";
import { IApiShiajoModel } from "../../util/ApiModel/ApiShiajoModel";
import { SessionCache } from "../../util/SessionStorage";
import { FigthGroupCreationModal } from "../Fight/FightGroupCreationModal";
import { ConfirmModal } from "../Modal/ConfirmModal";
import { getFightGroup, isFollowedFightGroup } from "../Operation/FightGroupOperation";
import { deleteApiObject } from "../Operation/GenericOperation";
import { IServiceShiajoState } from "../Table/Shiajo";
import { ITeamOrderProps, TeamOrder } from "../Table/TeamOrder";
import { FightTeamVS } from "../Team/FightTeamVS";
import { TeamCard } from "../Team/TeamCard";
import { usePopOver } from "../Use/UsePopOver";
import { IServiceTableNotification } from "../Util/ErrorMessage";
import { useSseSecureClient } from "../Util/SseSecureClient";
import { StyledPaper } from "../Util/Styled";
import { useTabId } from "../Util/TabId";


export interface IShiaijoFightGroupsHeaderProps {
    showShiajoDialog(): void;
    state: IServiceShiajoState,
    fullscreen: Boolean,
    reload: (force: boolean) => Promise<void>,
    dispatchNotification: React.Dispatch<IServiceTableNotification>,
    selectShiajoFightGroup: (pid: number) => Promise<void>,
    competitions: Array<IApiCompetitionModel>,
    setReloadLock: (lock: boolean) => void,
    shiaijo: IApiShiajoModel
}

const modalStyle = {
    //position: 'absolute' as 'absolute',
    //top: '10%',
    //left: '10%',
    //transform: 'translate(-50%, -50%)',
    //width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



export function ShiaijoFightGroupsHeader(props: IShiaijoFightGroupsHeaderProps) {
    const { t, i18n } = useTranslation();
    const appBroadcast = useContext(AppBroadcastContext);
    const [editWaitingFightgroup, setEditWaitingFightgroup] = useState<ITeamOrderProps | undefined>(undefined);
    const [fightgroupCreation, setFightGroupCreation] = useState<boolean>(false);
    const [groupedFightGroup, setGroupedFightGroup]
        = useState<Map<number, Array<IApiFightGroupModel>>>(new Map());
    const [competitions, setCompetitions] = useState<{
        selectList: Array<number>,
        loaded : Array<IApiCompetitionModel>
    }>({
        selectList: [],
        loaded: []
    });

    useEffect(() => {
        const sortedShiaijoCompetition = [...props.state.shiajo.competitionId].sort().join(',');
        const sortedCompetitions = [...competitions.selectList].sort().join(',');
        if (sortedShiaijoCompetition !== sortedCompetitions) {
            (async () => {
                SessionCache.get('competitions', props.state.shiajo.competitionId.join(','), () =>
                    getCompetitions(props.dispatchNotification, false, {
                        competitionIds: props.state.shiajo.competitionId
                    }), 1000).then(d => {
                        setCompetitions({
                            selectList: props.state.shiajo.competitionId,
                            loaded: d ?? []
                        });
                        if ([...(d ?? [])].map(c => c.id).sort().join(';') !== [...props.state.shiajo.competitionId ?? []].sort().join(';')){
                            console.warn(`Loading incomplete : got ${[...(d ?? [])].map(c => c.id).join(',')} for selected ${ [...props.state.shiajo.competitionId ?? []].sort().join(';')}`)
                        }
                    })
            })()
        }
    }, [competitions, props.dispatchNotification, props.state.shiajo.competitionId])

    const [fightGroupCollapse, setfightGroupCollapse] = useState(true);
    const [popover, displayPopOver] = usePopOver<IFightTeamState>({
        dispatchNotification: props.dispatchNotification,
        loader: async (params: IFightTeamState | undefined) => {
            return params ? {
                title: params.name,
                content: <TeamCard team={params} dispatchNotification={props.dispatchNotification} />
            } : undefined
        }
    });

    const postpone = (fightGroupNodeId: number): void => {
        deleteApiObject<IApiResult>(`/fightgroups?postpone=true&fightGroupNodeId=${fightGroupNodeId}`, null, {
            succeed: false
        }, props.dispatchNotification).then((res: IApiResult) => {
            if (res.succeed) {
                //dispatch({ type: "LOAD", id: props.externalId ?? undefined });
                props.reload(true);
            }
        })
    }

    const tabId = useTabId();

    const onMessage = useCallback((shiaijo: IApiShiajoModel) => {
        if (shiaijo.fightGroups.map(f => f.id).sort().join(',') !== props.state.shiajo.fightGroups.map(f => f.id).sort().join(',')) {
            props.reload(false);
        }
        if (shiaijo.currentFightGroup && (!shiaijo.currentFightGroup?.cancel) !== (!props.state.shiajo.currentFightGroup?.cancel)) {
            props.reload(true);
        }
    }, [props]);

    const [setSseFilter, sseConnected, connectedIcon, sseFilter] = useSseSecureClient(
        "shiaijoScoreKeeper",
        "shiajos",
        onMessage,
        props.dispatchNotification,
        true
    );

    useEffect(() => {
        setSseFilter(props.shiaijo.id.toString())
    }, [props.shiaijo.id, setSseFilter]);

    useEffect(() => {
        if (fightgroupCreation || editWaitingFightgroup) {
            props.setReloadLock(false);
        }
        else {
            props.setReloadLock(true);
        }
    }, [fightgroupCreation, editWaitingFightgroup, props]);

    useEffect(() => {
        if (props.state.shiajo.fightGroups) {
            const newGroupedFightGroup = new Map();
            props.state.shiajo.fightGroups.forEach(fg => {
                if (!newGroupedFightGroup.has(fg.competitionId)) newGroupedFightGroup.set(fg.competitionId, []);
                //@ts-ignore
                newGroupedFightGroup.get(fg.competitionId).push(fg);
            })
            setGroupedFightGroup(newGroupedFightGroup);
        }
    }, [props.state.shiajo.fightGroups])

    return <>
        {popover}
        {fightgroupCreation && <FigthGroupCreationModal
            dispatchNotification={props.dispatchNotification}
            shiaijo={props.state.shiajo}
            open={fightgroupCreation}
            onClose={(created: boolean) => {
                setFightGroupCreation(false);
                if (created) props.reload(true);

            }}
        />}
        {editWaitingFightgroup && <Modal
            open={true}
            onClose={() => {
                setEditWaitingFightgroup(undefined);

            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <TeamOrder
                    {...editWaitingFightgroup}
                />
            </Box>
        </Modal>}


        <StyledPaper elevation={3} >
            <Grid container>
                {/* Description */}
                <Grid className='shiaijo-header' item xs={3}>
                    <strong> {t('label.title.shiajo')} {props.shiaijo.fullname}</strong>  (eid {props.shiaijo.externalId})
                    {/* CompetitionInformations */}
                    <br /><strong className='shiaijo-competitions-info'>{t('label.title.shiajoCompetitions')} </strong> : {
                        competitions.loaded.map(competition => `${competition?.fullname} (${competition?.id})`).join(';')
                    }
                </Grid>
                {/* Buttons */}
                <Grid item xs={9}>

                    <Button
                        variant='contained'
                        onClick={() => setfightGroupCollapse(!fightGroupCollapse)}
                        aria-controls="fightGroupCollapse"
                        aria-expanded={!fightGroupCollapse}
                    >
                        {props.state.shiajo.fightGroups ? t('label.fightgroup.number') + " : " + props.state.shiajo.fightGroups.length
                            : t('label.fightgroup.noFightGroup')}
                    </Button>
                    <Button
                        variant='contained'
                        onClick={() => props.reload(true)}
                    >
                        {t('label.word.refresh')}
                    </Button>
                    <Button variant='contained' color={'success'} onClick={() => props.showShiajoDialog()}>
                        {t('action.shiaijo.editScreenMessage')}
                    </Button>


                    <Button variant='contained' color={'secondary'} onClick={() => {
                        //channel?.postMessage("test");
                        appBroadcast?.postMessage({
                            action: 'TABS_NAVIGATE',
                            params: ['/screen?shiaijoid=' + props.state.shiajo.externalId],
                            sourceTabId: tabId
                        });
                    }}> {t('action.shiaijo.displayOnTabs')}</Button>
                    <Button variant='contained' color={'warning'} onClick={() => {
                        setFightGroupCreation(true)
                    }}>
                        {t('action.fightgroup.create')}
                    </Button>
                    <ConfirmModal buttonSize={'medium'}
                        label={t("action.screen.goOffline")}
                        message={t("confirmation.screen.goOffline")} action={() => {

                            const onShiaijoLoaded = (shiajoData: IApiShiajoModel | undefined) => {
                                storeData({
                                    rounds:
                                        shiajoData?.currentFightGroup?.currentFight?.rounds?.map(r => {
                                            return {
                                                fighter1: r.whiteFighter.lastName,
                                                fighter2: r.redFighter.lastName,
                                                fighter1Strikes: [null, null, null, null].map(s => ({
                                                    strike: null,
                                                    circle: false,
                                                    number: null
                                                })),
                                                fighter2Strikes: [null, null, null, null].map(s => ({
                                                    strike: null,
                                                    circle: false,
                                                    number: null
                                                })),
                                            }
                                        }) ?? []
                                    ,
                                    shortname: shiajoData?.shortname ?? '',
                                    backUrl: window.location.href,
                                    backUrlScreen: '/screen?shiaijoid=' + shiajoData?.externalId + '&fullscreen=1'
                                })
                                //channel?.postMessage("test");
                                appBroadcast?.postMessage({
                                    action: 'TABS_NAVIGATE',
                                    params: [`/offline/index.html?mode=fullscreen&shiajoShortName=${shiajoData?.shortname ?? 'A'}`],
                                    sourceTabId: tabId
                                });
                                setTimeout(() => {
                                    window.location.href = '/offline/index.html';
                                }, 500)

                            }

                            //todo : some work here
                            let shiajoData = props.state.shiajo;
                            //Store data
                            onShiaijoLoaded(shiajoData)
                        }} />

                </Grid>
            </Grid>
        </StyledPaper >

        <StyledPaper elevation={3} >
            <Grid container>
                {/* FightGroup details */}
                <Grid item xs={2} paddingTop={'5px'}>
                    {/* Not full screen details */}
                    {!props.fullscreen &&
                        <h4 className="title is-4">
                            {t('label.title.fightgroupsInProgress')}<Tooltip title={t('label.shiajo.clickToEditFgOrder')}>
                                <HelpOutline />
                            </Tooltip>
                        </h4>

                    }
                    {!props.fullscreen && props.state.shiajo.fightGroups.length === 0 && <>
                        {/* No fight group to resolve */}
                        {t('label.fightgroup.nofightgroupready')}
                    </>
                    }
                </Grid>
                <Grid item xs={10}>
                    <Tabs
                        value={props.state.shiajo.currentFightGroupId ?? 0}
                        onChange={(e, newValue: string) => {
                            //load current fightgroup details, except if already the current one
                            if (props.state.shiajo.currentFightGroupId && props.state.shiajo.currentFightGroupId !== Number.parseInt(newValue)) {
                                getFightGroup(props.state.shiajo.currentFightGroupId, props.dispatchNotification).then(fgcurrent => {
                                    if (fgcurrent) {
                                        if (fgcurrent.status === undefined
                                            ||
                                            (
                                                [EFightGroupStatus.WAIT, EFightGroupStatus.COMMITTED, EFightGroupStatus.UNDEFINED].includes(fgcurrent.status)
                                                && (fgcurrent.currentFight === undefined || fgcurrent.currentFight.status !== EFightStatus.READY)
                                            )
                                        ) {
                                            props.selectShiajoFightGroup(Number.parseInt(newValue));
                                        } else {
                                            //alert("Impossible de changer de group de combat tant que le group de combat active n'est pas terminé")
                                            getFightGroup(Number.parseInt(newValue), props.dispatchNotification).then(fg => {
                                                if (fg) {
                                                    const teams: Array<IFightTeamState> = fg.fights.reduce((acc: Array<IFightTeamState>, f) => {
                                                        const res = [...acc];
                                                        if (!res.find(t => t.id === f.redFightTeam.id)) res.push(f.redFightTeam);
                                                        if (!res.find(t => t.id === f.whiteFightTeam.id)) res.push(f.whiteFightTeam);
                                                        return res;
                                                    }, []);
                                                    let orderFightLimit: number = fg.fights.map(f => (f.rounds?.length ?? 0)).reduce((v, nv) => Math.max(v, nv));
                                                    setEditWaitingFightgroup({
                                                        onCancel: () => {
                                                            setEditWaitingFightgroup(undefined);
                                                        },
                                                        onUpdated: () => {
                                                            setEditWaitingFightgroup(undefined);
                                                        },
                                                        teams: teams,
                                                        orderFightLimit: orderFightLimit,
                                                        message: t('label.fightgroup.orderForNotCurrentFightGroup')
                                                    })
                                                }
                                            })
                                        }
                                    }
                                    else {
                                        //sinon, dans le doute on tente et on passe par l'api
                                        props.selectShiajoFightGroup(Number.parseInt(newValue));
                                    }
                                })
                            }
                            else {
                                props.selectShiajoFightGroup(Number.parseInt(newValue));
                            }
                        }
                        }

                        textColor="primary"
                        indicatorColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab value={0}
                            label={" "}
                        />
                        {[...groupedFightGroup.entries()].map((entry) => {
                            const [competitionId, fightGroups] = entry;
                            //const competition = competitions.find(c => c.id === competitionId);
                            //TODO : changer le mécanisme de tri
                            return fightGroups.sort((f1, f2) => {
                                if (f1.depth !== f2.depth) return (f2?.depth ?? 0) - (f1?.depth ?? 0)
                                else if (f1.fightGroupNodeId !== f2.fightGroupNodeId) return f1.fightGroupNodeId - f2.fightGroupNodeId
                                else return f1.id - f2.id;

                            }).map((fightGroup, k) => {
                                let label = <>{fightGroup.label}</>;
                                if (!fightGroup.label) label = <>{isFollowedFightGroup(fightGroup) ? `${t('label.fightgroup.nolabel')} fightGroup.fightGroupNodeId` : t('label.fightgroup.notFollowed')}</>
                                return <Tab key={fightGroup.id} icon={<FormatListNumbered />}
                                    value={fightGroup.id}
                                    label={label}
                                />
                            })

                        })}
                    </Tabs>
                </Grid>
            </Grid>
        </StyledPaper>


        {/* List of fight Groups details*/}

        <Grid item container xs={12}>
            {!fightGroupCollapse && <Grid item container xs={12} id="fightGroupCollapse">
                {/* List of fight group*/}
                {!props.fullscreen && [...groupedFightGroup.entries()].map((entry) => {
                    const [competitionId, fightGroups] = entry;
                    const competition = props.competitions.find(c => c.id === competitionId);
                    return <Grid item container xs={12} key={competitionId}>
                        {fightGroups.map((fightGroup, k) => {
                            let label = <>{fightGroup.label}</>;

                            if (!fightGroup.label) label = <>{t('label.fightgroup.nolabel')}</>
                            let rowClassname = "";
                            if (fightGroup.id === props.state.shiajo.currentFightGroupId) {
                                rowClassname = "shiajo-currentFightGroup";
                            }
                            return <React.Fragment key={k} >
                                <Grid item container xs={3} className={rowClassname}>

                                    <Grid item xs={12}>
                                        {label} {isFollowedFightGroup(fightGroup) ? `- node id ${fightGroup.fightGroupNodeId}` : t('label.fightgroup.notFollowed')} (id {fightGroup.id})
                                    </Grid>
                                    <Grid item xs={12}>
                                        {t(fgTypeLabel(fightGroup.type))}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {fightGroup.fights?.map((fight: IApiFightModel, k: any) => {
                                            return <FightTeamVS
                                                key={fight.id}
                                                redFightTeam={fight.redFightTeam}
                                                whiteFightTeam={fight.whiteFightTeam}
                                                displayPopOver={displayPopOver}
                                            />
                                        })}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button variant='contained' size="small" color="primary" style={
                                            {
                                                margin: "2px"
                                            }
                                        } onClick={() => /*dispatch({ type: "SELECT_FIGHTGROUP", id: fightGroup.id })*/ props.selectShiajoFightGroup(fightGroup.id)}>
                                            {t('action.fightgroup.select')}
                                        </Button>
                                    </Grid>

                                    {isFollowedFightGroup(fightGroup) && <Grid item xs={6}>
                                        <ConfirmModal label={t("action.following.postpone")} message={t("confirmation.following.postpone")} action={() => {
                                            postpone(fightGroup.fightGroupNodeId);
                                        }} />
                                    </Grid>}
                                    {!isFollowedFightGroup(fightGroup) && <>
                                        <Chip label={t('label.fightgroup.notFollowedPostpone')} />
                                    </>}
                                </Grid>
                            </React.Fragment >
                        })}
                    </Grid>
                })
                }
            </Grid>}
        </Grid>
    </>
}