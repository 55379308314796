import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { activateRegister, getRegisters, IRegister, TREGISTRATION_STATUS } from '../../Backend/ApiRegister';
import { IEventNotification } from '../Util/ErrorMessage';
import { DataGrid, GridCellEditCommitParams, GridColDef, GridComparatorFn, GridRenderCellParams, GridRowParams, GridSortCellParams, GridSortItem, MuiEvent } from '@mui/x-data-grid';
import { DoneOutline, ThumbUpOffAlt } from '@mui/icons-material';

export interface IRegisterTable {
    dispatchNotification: React.Dispatch<IEventNotification>
}
const statusFilter: Array<TREGISTRATION_STATUS> = ['check', 'confirm', 'register'];



export function RegisterTable(props: IRegisterTable) {
    const [registrationList, setRegistrationList] = useState<Array<IRegister> | undefined>(undefined);
    const columns: GridColDef[] = [
        {
            field: 'id', headerName: 'id', minWidth: 100
        },
        {
            field: 'action', headerName: 'action', minWidth: 150
            , renderCell: (params: GridRenderCellParams) => {
                let action = undefined;
                switch (params.row.status as TREGISTRATION_STATUS) {
                    case 'confirm':
                    case 'check':
                    case 'register':
                        action = <ThumbUpOffAlt onClick={() => activateRegister(params.row.email, props.dispatchNotification).then(() => {
                            setRegistrationList(undefined);
                        })} />
                        break;
                }
                return <>
                    {params.value} {action}

                </>
            }
        },
        { field: 'status', headerName: 'status', minWidth: 200 },
        { field: 'login', headerName: 'login', minWidth: 200 },
        { field: 'email', headerName: 'email', minWidth: 250 },
        {
            field: 'licensee', headerName: 'licensee', minWidth: 50
            , renderCell: (params: GridRenderCellParams) => {
                let action = undefined;
                switch (params.row.status as TREGISTRATION_STATUS) {
                    case 'confirm':
                    case 'register':
                        //action = <ThumbUpOffAlt onClick={() => checkLicense(params.row.licensee, props.dispatchNotification)} />
                        break;
                }
                return <>
                    {params.value} {action}

                </>
            }
        },
        { field: 'confirmationKey', headerName: 'confirmationKey', minWidth: 200 },
    ];

    useEffect(() => {
        let display = true;
        if (registrationList === undefined) {
            getRegisters(statusFilter, props.dispatchNotification).then((data) => {
                if (display) setRegistrationList(data)
            })
        }
        return () => {
            display = false;
        }
    }, [props.dispatchNotification, registrationList])



    return <DataGrid
        //sortModel={sortModel.sortItems}
        /*onSortModelChange={(model) => {
            if (JSON.stringify(model) !== JSON.stringify(sortModel.sortItems)) {
                setSortModel({
                    reload: Date.now(),
                    sortItems: model
                });
                setSelectedCompetition(undefined);
                setCompetitionFilter({ ...competitionFilter, selectedGroup: undefined })
    
            }
    
        }}*/
        rows={registrationList ?? []}
        columns={columns}
        /*onSelectionModelChange={(competitionIds: Array<string | number>) => {
            setCreateCompetition(false);
            const competition = competitions.find(c => c.id === competitionIds[0]);
            if (competition?.type === "group") {
                setCompetitionFilter({ ...competitionFilter, selectedGroup: competition.id })
            }
            setSelectedCompetition(competition);
        }}
        onCellEditCommit={handleCellEditCommit}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
        
        getRowClassName={getRowClassName}
    */
        rowsPerPageOptions={[5, 10, 20]}
        autoHeight
        pagination

    />
}