import { putApiObject } from "../components/Operation/GenericOperation";
import { ISchedullerFight, ISchedullerResult } from "../components/Scheduller/SchedullerTable";
import { TDispatchNotification } from "../components/Util/ErrorMessage";
import { IApiResult } from "./ApiCall";
import { getFollowingFightGroup } from "./ApiFollowing";
import { IApiCompetitionTreeModel } from "./ApiModel/Following/ApiCompetitionTree";

export const emptySchedullerResult: ISchedullerResult = {
    succeed: false,
    data: {
        competition: 0,
        fight: [],
        shiaijo: []
    }
}

export async function allocateToShiaijo(competitionId: number, fightNodeId: number, dispatchNotification: TDispatchNotification): Promise<boolean> {
    let fightGroup = await getFollowingFightGroup(fightNodeId, dispatchNotification);
    if (fightGroup) {
        return await putApiObject<ISchedullerResult>(`/competitions/${competitionId}/allocate/${fightNodeId}`, null,
            fightGroup,
            emptySchedullerResult,
            dispatchNotification, (window as any)['REACT_APP_API_ORDO_URL'])
            .then((result: ISchedullerResult) => {
                return result.succeed
            });
    } else {
        return false;
    }
}

export async function allocateAllPostponed(competitionId: number, dispatchNotification: TDispatchNotification): Promise<boolean> {
    return await putApiObject<IApiResult>(`/competitions/${competitionId}/allocate`, null,
        null,
        { succeed: false },
        dispatchNotification, (window as any)['REACT_APP_API_ORDO_URL'])
        .then((result: IApiResult) => {
            return result.succeed
        });

}

export async function moveFightgroupToShiaijo(competitionId: number, newShiajoId: number, fightGroup: ISchedullerFight | IApiCompetitionTreeModel, dispatchNotification: TDispatchNotification): Promise<boolean> {
    return putApiObject<ISchedullerResult>(`/competitions/${competitionId}/${newShiajoId}/${fightGroup.fightGroupNodeId}`, null,
        fightGroup,
        emptySchedullerResult,
        dispatchNotification, (window as any)['REACT_APP_API_ORDO_URL'])
        .then((result: ISchedullerResult) => {
            return result.succeed
        });
}

export function sortSchedullerFight(a: ISchedullerFight, b: ISchedullerFight) {
    return (a.depth * 100 + a.fightOrder) - (b.depth * 100 + b.fightOrder);
}

/**
     * Annulation complete d'un combat et de tous les combats associés
     * @param fightgroupnodeid 
     */
export function cancelSchedulledFight(competitionId: number, fightgroupnodeid: number, dispatchNotification: TDispatchNotification): Promise<boolean> {
    return putApiObject<ISchedullerResult>(`/competitions/${competitionId}/cancel`, fightgroupnodeid, {}, emptySchedullerResult, dispatchNotification, (window as any)['REACT_APP_API_ORDO_URL'], "error.backend.following").then((res: ISchedullerResult) => {
        return res.succeed
    })
}
