import { patchApiObject, postApiObject } from "../components/Operation/GenericOperation";
import { IServiceTableNotification, TDispatchNotification } from "../components/Util/ErrorMessage";
import { IApiResultStatus } from "./ApiCall";
import { emptyApiFightGroupModel, IApiFightGroupModel } from "./ApiModel/ApiFightgroupModel";

export async function patchFightGroupSolve(dispatchNotification: React.Dispatch<IServiceTableNotification>, fightGroupId: number): Promise<IApiFightGroupModel | undefined> {
  return patchApiObject<{
    succeed: boolean,
    data: IApiFightGroupModel
  }>(`/fightgroups/${fightGroupId}/resolve`, {}, {
    succeed: false,
    data: emptyApiFightGroupModel
  }, dispatchNotification, undefined).then((result) => {
    if (result.succeed) return result.data;
    return undefined;
  });
}

/**
 * Data : list of fightteamid, from first to last in order of rank
 * @param fightgroupnodeid 
 * @param data 
 * @param dispatchNotification 
 * @returns 
 */
export function putFightGroupRanks(fightgroupnodeid: number, data: Array<string>, dispatchNotification: TDispatchNotification): Promise<IApiResultStatus> {
  return postApiObject<{
    succeed: boolean,
    data: IApiFightGroupModel | undefined,
    status: number
  }>(`/fightgroups/${fightgroupnodeid}/ranks`,
    {
      ranks: data
    }, {
    succeed: false,
    data: undefined,
    status: 400
  }, dispatchNotification).then((res) => {
    return res;
  })
}
/*
function apiPutFight(state: IFightGroupState, data: IApiFightPut) {
    apiput<IApiFightResult>('/fights/' + state.data.currentFightId, data)
      .then(result => {
  
        manageApiError<IApiFightResult>(result)
          .then(result => {
            let newstate = Object.assign(state);
  
            newstate.data.fights.forEach((fight: IApiFightModel, k: any) => {
              if (fight.id === state.data.currentFightId) {
                newstate.data.fights[k] = Object.assign(fight, result);
              }
            });
            return newstate;
          });
      });
  }*/