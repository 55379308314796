import { IEventNotification } from "../EventComponent/Util/ErrorMessage";
import { IApiUserCompetitionModel, IApiUserModel } from "../Model/ApiUserModel";
import { deleteCompApiObject, getCompApiObject, postCompApiObject, putCompApiObject } from "../UtilEvent/GenericOperation";

export async function getUsers(compid: number, dispatchNotification: React.Dispatch<IEventNotification>): Promise<IApiUserCompetitionModel[]> {
    let result = await getCompApiObject<{
        succeed: boolean,
        data: Array<IApiUserCompetitionModel>
    }>(`/competitions/${compid}/users`,
        null, {
        succeed: false,
        data: []
    }, dispatchNotification, (window as any)['REACT_APP_API_USER_SERVICE']
    );
    return result.data;
}

export async function saveUser(user: IApiUserModel, create:boolean, dispatchNotification: React.Dispatch<IEventNotification>): Promise<IApiUserModel|undefined> {
    let userData = user;
    if (userData.password?.length === 0){
        delete userData.password;
    }
    if (create){
        let result = await postCompApiObject<{
            succeed: boolean,
            data: IApiUserModel|undefined
        }>(`/users`, user, {
            succeed: false,
            data: undefined
        }, dispatchNotification, (window as any)['REACT_APP_API_USER_SERVICE']
        );
        return result.data;
    }
    else {
        let result = await putCompApiObject<{
            succeed: boolean,
            data: IApiUserModel|undefined
        }>(`/users`, user.name, user, {
            succeed: false,
            data: undefined
        }, dispatchNotification, (window as any)['REACT_APP_API_USER_SERVICE']
        );
        return result.data;
    }
    
}



export async function searchUserPartial(userPartialName: string, dispatchNotification: React.Dispatch<IEventNotification>): Promise<IApiUserModel[]> {
    let result = await getCompApiObject<{
        succeed: boolean,
        data: Array<IApiUserModel>
    }>(`/users?partialName=${userPartialName}`,
        null, {
        succeed: false,
        data: []
    }, dispatchNotification, (window as any)['REACT_APP_API_USER_SERVICE']
    );
    return result.data;
}

export async function getUser(name: string, dispatchNotification: React.Dispatch<IEventNotification>): Promise<IApiUserModel | undefined> {
    let result = await getCompApiObject<{
        succeed: boolean,
        data: IApiUserModel | undefined
    }>(`/users/${name}`,
        null, {
        succeed: false,
        data: undefined
    }, dispatchNotification, (window as any)['REACT_APP_API_USER_SERVICE']
    );
    return result.data;
}

export async function addUserGlobalProfile(name: string, profile:string, dispatchNotification: React.Dispatch<IEventNotification>): Promise<IApiUserModel | undefined> {
    let result = await postCompApiObject<{
        succeed: boolean,
        data: IApiUserModel | undefined
    }>(`/users/${name}/profiles/${profile}`,
        null, {
        succeed: false,
        data: undefined
    }, dispatchNotification, (window as any)['REACT_APP_API_USER_SERVICE']
    );
    return result.data;
}

export async function deleteUserGlobalProfile(name: string, profile:string, dispatchNotification: React.Dispatch<IEventNotification>): Promise<IApiUserModel | undefined> {
    let result = await deleteCompApiObject<{
        succeed: boolean,
        data: IApiUserModel | undefined
    }>(`/users/${name}/profiles/${profile}`,
        null, {
        succeed: false,
        data: undefined
    }, dispatchNotification, (window as any)['REACT_APP_API_USER_SERVICE']
    );
    return result.data;
}