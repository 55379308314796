import { IApiResult } from "../ApiCall";
import { IApiShiajoModel } from "./ApiShiajoModel";

export interface IApiFightScoreModel {

    ippons: number,
    victories: number,
    draws: number,
    score: number,
    ipponGets: number
    teamExternalId: number,
    teamId: number,
}

export interface IApiFightTeamScore {
    ippons: number; //Nb de IPPON ou de Drapeaux
    victories: number; //Nb de Victoires
    draws: number; //Nb de nul
    score: number; //+1 ou -1
    ipponGets: number; //ippons gets
}

export interface IApiFightDetailScoreModel {
    id: number,
    redFightTeamId: number,
    whiteFightTeamId: number,
    fightScore: {
        whiteTeamScore: IApiFightTeamScore;
        redTeamScore: IApiFightTeamScore;
    }
}

export interface IApiFightScoresModel {

    scores: Array<IApiFightScoreModel>,
    ranking: Array<IApiFightScoreModel>,
    status: string,
    redFightTeamId: number,
    whiteFightTeamId: number,
    fightScores: Array<IApiFightDetailScoreModel>

}

export interface IFightScoreResult extends IApiResult {
    data: IApiFightScoresModel
}

export const emptyFightScore: IFightScoreResult = {
    data: {
        status: "UNDEFINED",
        scores: [],
        ranking: Array<IApiFightScoreModel>(),
        redFightTeamId: 0,
        whiteFightTeamId: 0,
        fightScores: []
    },
    succeed: false
}