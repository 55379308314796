import { IApiResultStatus } from "../ApiCall";

/**
 * Evenement
 */
export enum ERoundEventTypeModel {
    MEN = "MEN"
    , DO = "DO"
    , KOTE = "KOTE"
    , SUNE = "SUNE"
    , ASHI = "ASHI"
    , TSUKI = "TSUKI"
    , IPON = "IPON"
    , HANSOKU = "HANSOKU"
    , HANTEI = "HANTEI"
    , START = "START"
    , PAUSE = "PAUSE"
    , END = "END"
    , ENCHO = "ENCHO"
    , UNDEFINED = "UNDEFINED"
    , FUSENGACHI = "FUSENGACHI"
    
    
};

export interface IApiRoundEventModel {
    fighterId?: number;
    type: ERoundEventTypeModel;
    date: Date;
    id: number;
    roundId: number;
    points:number;
    ignoreFightStatus?:boolean;
    ignoreRoundCheck?:boolean;
}

export interface IApiRoundEventResult extends IApiResultStatus {
    data: IApiRoundEventModel;
}

export interface IApiRoundEventCreationModel {
    fighterId?: number;
    type: ERoundEventTypeModel;
    date: Date;
}

export const emptyRoundEventResult: IApiRoundEventResult = {
    succeed: false,
    data: {
        type: ERoundEventTypeModel.UNDEFINED,
        date: new Date(),   
        id: 0,
        roundId: 0,
        points: 0
    },
    status: 400
}