import { Tooltip } from '@mui/material';
import React from 'react';
import { IApiCompetitionModel } from "../../Model/ApiCompetitionModel";
import { ERoundEventTypeModel } from "../../util/ApiModel/ApiEventModel";
import { IApiRoundModel } from "../../util/ApiModel/ApiRoundModel";
import { IServiceTableNotification } from "../Util/ErrorMessage";
import { warnSvg } from '../Util/IpponSvg';
import { allowedStrikeEvent } from "../Util/roundUtil";
import { RoundAutomaticResolveButton } from "./RoundAutomaticResolveButton";
import { EFighterColor } from "./Shiajo";

export interface FighterButtonsProps {
    fighterColor: EFighterColor,
    competition: IApiCompetitionModel,
    action(event: ERoundEventTypeModel): any,
    round: IApiRoundModel,
    key: 'white' | 'red',
    dispatchNotification: React.Dispatch<IServiceTableNotification>,
    reload: () => void
}

export function RoundFighterButtons(props: FighterButtonsProps) {

    //.slice(number * 4, ((number + 1) * 4))
    let eventList = Object.values(ERoundEventTypeModel).filter(e => allowedStrikeEvent(
        ERoundEventTypeModel[e as keyof typeof ERoundEventTypeModel], props.competition))
    let eventDisplayList: Array<Array<ERoundEventTypeModel>> = [];
    for (let index = 0; index < eventList.length; index++) {
        let i = Math.floor(index / 4);
        if (!eventDisplayList[i]) eventDisplayList[i] = []
        eventDisplayList[i].push(eventList[index])
    }
    const isNotNone = (props.fighterColor === EFighterColor.white) ? (props.round.whiteFighterId > 0) : (props.round.redFighterId > 0);
    //console.log(eventDisplayList);
    return <>
        {isNotNone === false && <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
            <RoundAutomaticResolveButton
                reload={async (round: IApiRoundModel) => {
                    props.reload();
                }}
                round={props.round}
                dispatchNotification={props.dispatchNotification} />
        </div>}
        {isNotNone && eventDisplayList.map((eventList, key) => {
            return <div key={key} className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                <div className="btn-group mr-2" role="group" aria-label="First group">
                    {eventList.map((action, key) => {
                        let caption: string | JSX.Element = "?";
                        switch (action) {
                            case ERoundEventTypeModel.MEN: caption = "M"; break;
                            case ERoundEventTypeModel.DO: caption = "D"; break;
                            case ERoundEventTypeModel.KOTE: caption = "K"; break;
                            case ERoundEventTypeModel.SUNE: caption = "S"; break;
                            case ERoundEventTypeModel.ASHI: caption = "A"; break;
                            case ERoundEventTypeModel.HANTEI: caption = "Ht"; break;
                            case ERoundEventTypeModel.TSUKI: caption = "T"; break;
                            case ERoundEventTypeModel.HANSOKU: caption = <div style={{
                                width: "20px",
                                height: "20px"
                            }}>{warnSvg(false)}</div>/*"H"*/; break;
                            case ERoundEventTypeModel.IPON: caption = "I"; break;
                            case ERoundEventTypeModel.FUSENGACHI: caption = "Fusen Gashi"; break;
                        }


                        return <Tooltip title={action} key={key}>
                            <button type="button"  className="btn btn-outline-primary  btn-sm shiajo_button_fighter_action" onClick={(e) => props.action(action)}>

                                {caption}
                            </button>
                        </Tooltip>
                    })
                    }
                </div>
            </div>
        })}
    </>
}