import { IApiResult } from "../ApiCall";
import { IApiRoundEventModel } from "./ApiEventModel";
import { IApiFighterModel, noneFighter } from "./ApiFighterModel";

export enum ERoundStatus {
    WAIT = "WAIT",
    INPROGRESS = "INPROGRESS",
    PAUSE = "PAUSE",
    EXTENSION = "EXTENSION",
    FINISHED = "FINISHED"
}

export interface IApiRoundCreationModel {
    order: number;
    redFighterExternalId?: number;
    whiteFighterExternalId?: number;
    redFighterId?: number;
    whiteFighterId?: number;
    combatTime: number;
}

export interface IApiRoundModel {
    id: number;
    order: number;
    whiteFighter: IApiFighterModel;
    whiteFighterId: number;
    redFighter: IApiFighterModel;
    redFighterId: number;
    winner?: IApiFighterModel;
    result: number;
    status: ERoundStatus;
    startDate: number;
    endDate: number;
    events?: Array<IApiRoundEventModel>;
    combatTime: number;
    encho:boolean;
    reloadKey?:number;
}

export const emptyIApiRoundModel = {
    id: 0,
    order: 0,
    whiteFighter: noneFighter,
    whiteFighterId: 0,
    redFighter: noneFighter,
    redFighterId: 0,
    winner: undefined,
    result: 0,
    status: ERoundStatus.WAIT,
    startDate: 0,
    endDate: 0,
    events: [],
    combatTime: 0,
    encho: false
}

export interface IApiRoundResult extends IApiResult {
    data: IApiRoundModel
}

