import React, { useEffect, useRef, useState } from 'react';
import { Container, Overlay, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IServiceTableNotification } from '../Util/ErrorMessage';

interface IContent {
    title: string,
    content: React.ReactNode
}

export type TPopOverAction<T> = (action: "display" | "hide" | "shortdisplay", target: any, params: T | undefined) => void

export function usePopOver<T>(props: {
    dispatchNotification: React.Dispatch<IServiceTableNotification>
    , loader: (params: T | undefined) => Promise<IContent | undefined>
}): [JSX.Element, TPopOverAction<T>] {

    const [show, setShow] = useState(false);
    //const [ftimeout, setFTimeout] = useState<any>(undefined);
    const [target, setTarget] = useState<HTMLElement | undefined>(undefined);
    const ref = useRef(undefined);
    const [content, setContent] = useState<IContent | undefined>(undefined)
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (content === undefined) {
            setShow(false);
        }
    }, [content])



    let display = (action: "display" | "hide" | "shortdisplay", target: any, params: T | undefined) => {
        /*if (!show) {
            if (ftimeout) {
                clearTimeout(ftimeout);
            }
            setFTimeout(setTimeout(() => {
                setShow(false);
            }, 3000));
        }*/


        setTarget(target);
        if (params && action === "shortdisplay") {
            props.loader(params).then((node) => {
                setShow(true);
                setContent(node);
                setTimeout(() => {
                    setShow(false);
                }, 5000)
            })
        }
        else if (params && action === "display") {
            props.loader(params).then((node) => {
                setShow(true);
                setContent(node);
            })
        }
        else {
            setShow(false);
            setContent(undefined);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    };
    let element;
    if (target) {
        element = <Overlay
            show={show}
            target={target as HTMLElement}
            placement="bottom"
            container={ref.current}
        >
            <Popover id="popover-contained" className="shiaijo-popover-content">
                {content && <><Popover.Header as="h3">{content.title}</Popover.Header>
                    <Popover.Body>
                        <Container>
                            {content.content}
                        </Container>
                    </Popover.Body>
                </>
                }
            </Popover>
        </Overlay >
    } else {
        element = <></>;
    }

    return [element, display];
}