import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EFightType } from '../../util/ApiModel/ApiFightModel';
import { EFightGroupStatus, IApiFightGroupModel } from '../../util/ApiModel/ApiFightgroupModel';
import { useCompetitionLoader } from '../Competition/CompetitionLoader';
import { FightScreen } from './FightScreen';
import { IShiajoScreenConfig } from './ShiajoScreen';



var debug = require('debug')('ServiceTablesMinimalFrontend:FightGroupScreen')

export function FightGroupScreen(props: { fg: IApiFightGroupModel, config: IShiajoScreenConfig, active: boolean }) {
    const { t, i18n } = useTranslation();

    const [competition, loadCompetition] = useCompetitionLoader(undefined, props.fg.competitionId, false);

    useEffect(() => {
        loadCompetition(props.fg.competitionId);
    }, [loadCompetition, props.fg.competitionId])

    if (props.fg.status === EFightGroupStatus.CANCELED) {
        return <>
            <tr>
                {/*props.score && props.score.ranking.length > 0 && <></>*/}
                <td colSpan={7}>{t('label.fightgroup.cancelPleaseStopFight')}</td>
            </tr>
        </>;
    }
    else {
        //if at least one additionnal fight, display only it
        const onlyAdditionnal = (props.fg.fights.find(f => f.type === EFightType.ADDITIONAL) !== undefined);
        const sorteredFight = props.fg.fights.filter(f => onlyAdditionnal === false || f.type === EFightType.ADDITIONAL).sort((f1, f2) => {
            //si c'est le current fight et que l'on a plusieurs rounds, on l'affiche en haut pour éviter les problèmes d'affichage
            if (f1.id === props.fg.currentFightId && f2.rounds && f1.rounds && f1.rounds.length > 1) return -1;
            else if (f2.id === props.fg.currentFightId && f2.rounds && f2.rounds.length > 1) return 1;
            //sinon on affiche les combats dans leur ordre
            else {
                return f1.order - f2.order
            }
        });

        let fights = null;
        if (props.fg.fights) {
            fights = <>{sorteredFight.map((fight, key) => {
                
                if (competition !== undefined) {
                    //si le combat est le combat actif
                    if (props.fg.currentFight && props.fg.currentFight.id === fight.id) {
                        return <FightScreen key={key} fight={props.fg.currentFight} active={props.active} config={props.config} competition={competition} />
                    } else {                        
                        //Only display others fight if there is some room for
                        return <FightScreen key={key} fight={fight} active={false} config={props.config} competition={competition} />;
                    }
                } else {
                    if (props.active){
                        return <tr key={key}><td colSpan={7}>{t('label.fightgroup.competitionNotFound')} : id {props.fg.competitionId}</td></tr>;
                    }
                    else {
                        
                        return <></>;
                    }
                }

            })}</>
        } else {
            fights = <tr>
                {/*props.score && props.score.ranking.length > 0 && <></>*/}
                <td colSpan={7}>{t('label.noCurrentFight')}</td>
            </tr>
        }


        return <>
            {fights}
        </>;
    }
}

/*
            {props.fights.sort((a: IFightScreenState, b: IFightScreenState) => {
                if (a.id === props.currentFightId) return -1;
                if (b.id === props.currentFightId) return 1;
                if (a.id > b.id) return 1;
                return -1;
            }).map((fight: IFightScreenState, k) => {

                return <FightScreen key={fight.id} {...fight} />

            })}*/