import React from 'react';
import { ConnectDropTarget, useDrop } from 'react-dnd';
import { IApiCompetitionModel } from '../../Model/ApiCompetitionModel';
import { IApiFighterModel } from '../../util/ApiModel/ApiFighterModel';
import { ERoundStatus, IApiRoundModel } from '../../util/ApiModel/ApiRoundModel';
import { updateEvent } from '../Operation/EventOperation';
import { IShiajoScreenConfig } from '../Screen/ShiajoScreen';
import { IServiceTableNotification } from '../Util/ErrorMessage';
import { StrikeDropItem, StrikeSvg, StrikeType, warnSvg } from '../Util/IpponSvg';
import { convertEvents } from '../Util/roundUtil';
import { ApiDataString, IApiDataString } from './ApiDataString';


export interface IIpponsProps {

  round: IApiRoundModel,
  showAllWarns: boolean,
  showEncho: boolean,
  redFirst?: boolean,
  config?: IShiajoScreenConfig,
  reload?: () => void
  competition: IApiCompetitionModel,
  dispatchNotification?: React.Dispatch<IServiceTableNotification>
  interactive: boolean
}

export interface IIpponProps extends IApiDataString {
}




export function Ippons(props: IIpponsProps) {


  let displayEvents = convertEvents(props.round.redFighterId, props.round.whiteFighterId, props.round.events ?? []);

  const crossSvg = <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 0 512.001 512.001" preserveAspectRatio="none">

    <path d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717
     L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859
     c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287
     l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285
     L284.286,256.002z"/>

  </svg>

  let redCells: Array<JSX.Element | null> = [];
  let whiteCells: Array<JSX.Element | null> = [];

  let finished = (props.round.status === ERoundStatus.FINISHED)

  let leftFirst: boolean = (props.config?.whiteLeft === true);


  //IAIDO is displayed on 3 points
  if (props.competition.martialArt === "IAIDO") {
    redCells = displayEvents.redEvents.ippons.map(i => <StrikeSvg label={i.label} event={i.ref} />);
    for (let index = redCells.length; index < 3; index++) redCells.push(null);
    whiteCells = displayEvents.whiteEvents.ippons.map(i => <StrikeSvg label={i.label} event={i.ref} />);
    for (let index = whiteCells.length; index < 3; index++) whiteCells.push(null);
  }

  //Standard display
  if (props.competition.martialArt === "KENDO"
    || props.competition.martialArt === "SPORT CHANBARA"
    || props.competition.martialArt === "NAGINATA") {
    //RED CELLS
    //first row
    if (leftFirst) redCells.push(displayWarn(displayEvents.redEvents.warns, 2, 2));

    redCells.push(displayEvents.redEvents.ippons.length > 0 ? <StrikeSvg
      event={displayEvents.redEvents.ippons[0].ref}
      label={displayEvents.redEvents.ippons[0].label}
      circle={displayEvents.redEvents.ippons[0].label === " " || displayEvents.redFirstStrike}
      postNumber={(finished && displayEvents.whiteEvents.ippons.length === 0 && displayEvents.redEvents.ippons.length === 1) ? 1 : undefined}
    /> : <div> </div>);
    if (!leftFirst) redCells.push(displayWarn(displayEvents.redEvents.warns, 2, 2));

    //second row
    if (leftFirst) redCells.push(displayEvents.redEvents.ippons.length > 1 ? <StrikeSvg
      event={displayEvents.redEvents.ippons[1].ref}
      label={displayEvents.redEvents.ippons[1].label}
      circle={displayEvents.redEvents.ippons[1].label === " "}
    /> : <div> </div >);

    redCells.push(displayWarn(displayEvents.redEvents.warns, 0, 2));

    if (!leftFirst) redCells.push(displayEvents.redEvents.ippons.length > 1 ? <StrikeSvg
      event={displayEvents.redEvents.ippons[1].ref}
      label={displayEvents.redEvents.ippons[1].label}
      circle={displayEvents.redEvents.ippons[1].label === " "}

    /> : <div> </div>);

    //WHITE CELLS
    //first row
    if (!leftFirst) whiteCells.push(displayWarn(displayEvents.whiteEvents.warns, 2, 2));

    whiteCells.push(displayEvents.whiteEvents.ippons.length > 0 ? <StrikeSvg
      event={displayEvents.whiteEvents.ippons[0].ref}
      label={displayEvents.whiteEvents.ippons[0].label}
      circle={displayEvents.whiteEvents.ippons[0].label === " " || !displayEvents.redFirstStrike}
      postNumber={(finished && displayEvents.redEvents.ippons.length === 0 && displayEvents.whiteEvents.ippons.length === 1) ? 1 : undefined}
    /> : <div> </div>);

    if (leftFirst) whiteCells.push(displayWarn(displayEvents.whiteEvents.warns, 2, 2));

    //second row
    if (!leftFirst) whiteCells.push(displayEvents.whiteEvents.ippons.length > 1 ? <StrikeSvg
      event={displayEvents.whiteEvents.ippons[1].ref}
      label={displayEvents.whiteEvents.ippons[1].label}
      circle={displayEvents.whiteEvents.ippons[1].label === " "}
    /> : <div> </div>);

    whiteCells.push(displayWarn(displayEvents.whiteEvents.warns, 0, 2));

    if (leftFirst) whiteCells.push(displayEvents.whiteEvents.ippons.length > 1 ? <StrikeSvg
      event={displayEvents.whiteEvents.ippons[1].ref}
      label={displayEvents.whiteEvents.ippons[1].label}
      circle={displayEvents.whiteEvents.ippons[1].label === " "}
    /> : <div> </div>);
  }

  let className = "shiajo_ippon_container";

  if (props.round.encho && props.showEncho) {
    className += " shiajo_ippon_container_encho";
  }

  switch (props.round.status) {
    case ERoundStatus.FINISHED:
      if (displayEvents.whiteEvents.ippons.length === displayEvents.redEvents.ippons.length) {
        className += " shiajo_ippon_container_ikiwake"
      }
      else if (displayEvents.whiteEvents.ippons.length > displayEvents.redEvents.ippons.length) {
        if (leftFirst) {
          className += " shiajo_ippon_container_leftwin"
        }
        else {
          className += " shiajo_ippon_container_rightwin"

        }

      } else if (displayEvents.whiteEvents.ippons.length < displayEvents.redEvents.ippons.length) {
        if (leftFirst) {
          className += " shiajo_ippon_container_rightwin"
        }
        else {

          className += " shiajo_ippon_container_leftwin"
        }
      }
      break;
  }

  const buildIpponRowPart = (cell: JSX.Element | null, key: number, fighter: IApiFighterModel, className: string) => {
    if (props.interactive) {
      return <IpponRowPartDragDrop
        key={key}
        className={className}
        fighter={fighter}
        reload={props.reload}
        dispatchNotification={props.dispatchNotification}
      >
        {cell}
      </IpponRowPartDragDrop>
    }
    else {
      return <IpponRowPart
        key={key}
        className={className}
        fighter={fighter}
        reload={props.reload}
        dispatchNotification={props.dispatchNotification}
      >
        {cell}
      </IpponRowPart>
    }
  }

  //console.log(whiteCells);
  //console.log(redCells);
  //backgroundImage=""
  //https://egghead.io/lessons/react-reorder-a-list-with-react-beautiful-dnd
  return <div style={{ width: '100%' }}>

    <div className={className}>

      {whiteCells.length === 2 && <>
        <div >
          <div className="shiajo_ippon_row">

            {//2 cellules de gauches, ligne 1
              (leftFirst ? whiteCells : redCells).slice(0, 2).map((cell, key) => {
                return buildIpponRowPart(cell, key, leftFirst ? props.round.whiteFighter : props.round.redFighter, "shiajo_ippon_2")
              })}


            {//2 cellules de droite, ligne 1
              (leftFirst ? redCells : whiteCells).slice(0, 2).map((cell, key) => {
                return buildIpponRowPart(cell, key, leftFirst ? props.round.redFighter : props.round.whiteFighter, "shiajo_ippon_2")
              })
            }
          </div>
        </div>
      </>}

      {whiteCells.length === 3 && <>
        <div >
          <div className="shiajo_ippon_row">
            {//2 cellules de gauches, ligne 1
              (leftFirst ? whiteCells : redCells).slice(0, 3).map((cell, key) => {
                return buildIpponRowPart(cell, key, leftFirst ? props.round.whiteFighter : props.round.redFighter, "shiajo_ippon_3")
              })}
            {//2 cellules de droite, ligne 1
              (leftFirst ? redCells : whiteCells).slice(0, 3).map((cell, key) => {
                return buildIpponRowPart(cell, key, leftFirst ? props.round.redFighter : props.round.whiteFighter, "shiajo_ippon_3")
              })}
          </div>
        </div>
      </>}
      {whiteCells.length === 4 && <>
        <div >
          <div className="shiajo_ippon_row">

            {//2 cellules de gauches, ligne 1
              (leftFirst ? whiteCells : redCells).slice(0, 2).map((cell, key) => {
                return buildIpponRowPart(cell, key, leftFirst ? props.round.whiteFighter : props.round.redFighter, "shiajo_ippon")
              })}

            {//2 cellules de droite, ligne 1
              (leftFirst ? redCells : whiteCells).slice(0, 2).map((cell, key) => {
                return buildIpponRowPart(cell, key, leftFirst ? props.round.redFighter : props.round.whiteFighter, "shiajo_ippon")
              })}

          </div>
          <div className="shiajo_ippon_row">

            {//2 cellules de droite, ligne 2
              (leftFirst ? whiteCells : redCells).slice(2, 4).map((cell, key) => {
                return buildIpponRowPart(cell, key, leftFirst ? props.round.whiteFighter : props.round.redFighter, "shiajo_ippon")
              })}

            {//2 cellules de droite, ligne 2
              (leftFirst ? redCells : whiteCells).slice(2, 4).map((cell, key) => {
                return buildIpponRowPart(cell, key, leftFirst ? props.round.redFighter : props.round.whiteFighter, "shiajo_ippon")
              })}
          </div>
        </div>
      </>}
    </div>
  </div >
}

function IpponRowPartDragDrop(props: {
  dispatchNotification?: React.Dispatch<IServiceTableNotification>,
  fighter: IApiFighterModel,
  children: JSX.Element | null,
  className: string,
  reload?: () => void
}) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: StrikeType.STRIKE,
    drop: (item: StrikeDropItem, monitor) => {
      let e = { ...item.event };//do not change the original item
      console.log(`Move event to figther ${props.fighter.firstName}`)
      if (props.fighter && e.fighterId) {
        e.fighterId = props.fighter.id;
        updateEvent(e, props.dispatchNotification).then((updated) => {
          if (updated && props.reload) props.reload();
        })
      }
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      source: monitor.getItem()
    }),
  }), []);

  return <IpponRowPart
    {...props}
    divref={drop}
    isOver={isOver}
  />
}

function IpponRowPart(props: {
  dispatchNotification?: React.Dispatch<IServiceTableNotification>,
  fighter: IApiFighterModel,
  children: JSX.Element | null,
  className: string,
  reload?: () => void,
  isOver?: boolean,
  divref?: ConnectDropTarget
}) {
  return <div className={props.className} ref={props.divref}
    style={props.isOver ? {
      opacity: 0.5,
      backgroundColor: 'yellow',
    } : undefined}>
    {props.children}

  </div>
}
/*<Row>
<Col md={6}>
{props.warns[0] && (!props.warns[1] || props.showAllWarns) ? warn : ""}

</Col>
<Col md={6}>
{props.warns[1] && props.showAllWarns ? warn : ""}
</Col>
</Row>*/


function Ippon(props: IApiDataString) {
  return <div>
    <ApiDataString {...props} />
  </div>
}

function displayWarn(warns: Array<any>, begin: number, size: number): JSX.Element {
  return <>
    {warns.slice(begin, begin + size).map((warn, key) => {
      //nombre de warning > (pos / 2 + 1) * 2
      /*console.log("k" + key);
      console.log("c" + (Math.floor(key / 2) + 1) * 2);
      console.log("w" + displayEvents.redEvents.warns.length);*/

      let barred = (warns.length >= (Math.floor((key + begin) / 2) + 1) * 2);
      return <div className="shiajo_ippon_hansoku" key={key}>{warnSvg(barred)}</div>
    })
    }</>
}