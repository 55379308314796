export const BRODCAST_OFFLINESCREEN_CHANNEL_NAME = "offlineScreen";

export type TStrikeType = null | 'H' | 'I' | 'M' | 'K' | 'HH' | 'cancel';

export type TStrike = {
    strike: TStrikeType,
    circle: boolean,
    number: number | null
}
export const roundStatusList = ['ikiwake', 'rightwin', 'leftwin' , 'none'] as const;
export type TRoundStatus = 'ikiwake' | 'rightwin' | 'leftwin' | 'none';

export interface TOfflineRound {
    fighter1: string;
    fighter2: string;
    fighter1Strikes: Array<TStrike>,
    fighter2Strikes: Array<TStrike>,
    roundStatus?: TRoundStatus
}

export interface TOfflineScreen {
    shortname: string,
    rounds: Array<TOfflineRound>,
    backUrl?: string,
    backUrlScreen?: string,
    action?: 'GO_BACK',
    whiteFirst?: boolean
}

export function getStoredData() {
    let stored = localStorage.getItem("offlineScreenData");
    let data: TOfflineScreen = stored ? JSON.parse(stored) as TOfflineScreen : {
        shortname: "A",
        rounds: []
    };
    return data;
}

export function storeData(data: TOfflineScreen) {
    localStorage.setItem("offlineScreenData", JSON.stringify(data));
}