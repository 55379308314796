import { Chip, ThemeProvider } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EFightGroupStatus } from '../../util/ApiModel/ApiFightgroupModel';
import { theme } from '../../util/ChipTheme';



export function fightGroupStatusLabel(status: EFightGroupStatus) {
    let statusLabel = "label.status.undefined";
    switch (status) {
        case EFightGroupStatus.FINISHED:
            statusLabel = "label.fight.status.finished";
            break;
        case EFightGroupStatus.INPROGRESS:
            statusLabel = "label.fight.status.inprogress";
            break;
        case EFightGroupStatus.UNDEFINED:
            statusLabel = "label.fight.status.undefined";
            break;
        case EFightGroupStatus.CANCELED:
            statusLabel = "label.fight.status.canceled";
            break;
        case EFightGroupStatus.WAIT:
            statusLabel = "label.fight.status.wait";
            break;
        case EFightGroupStatus.COMMITTED:
            statusLabel = "label.fightgroup.status.commit";
            break;
    }
    return statusLabel;
}


export function FightGroupStatus(props: { status: EFightGroupStatus }) {
    const { t, i18n } = useTranslation();
    //let variant: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark" | undefined = undefined;
    let variant: "canceled" | "finished" | "inprogress" | "commited" | "undefined" | "wait" | "error" | undefined = undefined;
    switch (props.status) {
        case EFightGroupStatus.FINISHED: variant = "finished"; break;
        case EFightGroupStatus.INPROGRESS: variant = "inprogress"; break;
        case EFightGroupStatus.COMMITTED: variant = "commited"; break;
        case EFightGroupStatus.UNDEFINED: variant = "undefined"; break;
        case EFightGroupStatus.CANCELED: variant = "canceled"; break;
        case EFightGroupStatus.WAIT: variant = "wait"; break;
        default: variant = "error"; break;
    }
    return <ThemeProvider theme={theme}>
        <Chip color={variant as any} label={t(fightGroupStatusLabel(props.status))}/>
    </ThemeProvider>
}