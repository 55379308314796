import { apiget, apiput, IApiResult } from "./ApiCall";

interface INotificationCheck extends IApiResult {
    data: string
};

interface INotificationEnable extends IApiResult {
    data: boolean
};

export function apiNotificationCheck(): Promise<boolean> {
    return apiget<INotificationCheck>("/check", (window as any)['REACT_APP_API_NOTIFICATION']).then(result => {
        return result.succeed && (result as INotificationCheck).data === "READY"
    })
}

export function apiDiscordStatus(): Promise<boolean> {
    return apiget<INotificationEnable>("/connectors/discord/FR/enable", (window as any)['REACT_APP_API_NOTIFICATION']).then(result => {
        return result.succeed && (result as INotificationEnable).data
    })
}

export function apiSetDiscordStatus(status: boolean): Promise<boolean> {
    return apiput<INotificationEnable>(`/connectors/discord/FR/enable/${status}`, null, (window as any)['REACT_APP_API_NOTIFICATION']).then(result => {
        return result.succeed && (result as INotificationEnable).data
    })
}

export function apiSetTwitterStatus(status: boolean): Promise<boolean> {
    return apiput<INotificationEnable>(`/connectors/twitter/FR/enable/${status}`, null, (window as any)['REACT_APP_API_NOTIFICATION']).then(result => {
        return result.succeed && (result as INotificationEnable).data
    })
}

export function apiTwitterStatus(): Promise<boolean> {
    return apiget<INotificationEnable>("/connectors/twitter/FR/enable", (window as any)['REACT_APP_API_NOTIFICATION']).then(result => {
        return result.succeed && (result as INotificationEnable).data
    })
}

//"MongoDb connection health check"
interface INotificationHealth {
    succeed: boolean,
    data: {
        "status": "UP" | "DOWN",
        "checks": [
            {
                "name": string,
                "status": "UP" | "DOWN",
                "data": {
                    "Enable": boolean
                }
            }
        ]
    }
}
export function apiMongoDBStatus(): Promise<boolean> {
    return apiget<INotificationHealth>("/q/health", (window as any)['REACT_APP_API_NOTIFICATION']).then(result => {
        if (result.succeed) {
            let check = (result as INotificationHealth).data.checks.find(c => c.name === 'MongoDb connection health check');
            if (check){
                return check.status === "UP" && check.data.Enable === true;
            }
        }
        return false;
    })
}