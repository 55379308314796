import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FigthCardModal } from '../Fight/FightCardModal';
import useShiajoModal from "./UseShiajoModal";

export interface IShowScoreModalProps {
    fightGroupId: number;
    nodeId?: boolean
}

export function FightGroupScoreModalButton(props: IShowScoreModalProps) {
    const [isShowing, toggle] = useShiajoModal();
    const { t, i18n } = useTranslation();
    
    let afterToggle = useCallback(() => {
        toggle();

    }, [isShowing, props, toggle])


    return <>
        <FigthCardModal
            showing={isShowing}
            toggle={afterToggle}
            fightGroupId={props.fightGroupId}
            nodeId={props.nodeId}
        />
        <Button size="sm" variant="success" onClick={() => toggle()}>{t('action.fightgroup.card')}</Button>
    </>
}