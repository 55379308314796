import { useEffect, useState } from "react";
export type TProfile = "none" | "commissaire" | "admin";

enum E_SSO_LEVEL {
  SSO_LEVEL_USER = 1,
  SSO_LEVEL_COACH = 2,
  SSO_LEVEL_REFEREE = 3,
  SSO_LEVEL_SCOREKEEPER = 4,
  SSO_LEVEL_ORGANIZER = 5,
  SSO_LEVEL_ADMIN = 6,
  SSO_LEVEL_SUPERADMIN = 7,
}

let SSO_SCOPES: Map<string, number> = new Map();
SSO_SCOPES.set("cnkdr_user", 1);
SSO_SCOPES.set("cnkdr_coach", 2);
SSO_SCOPES.set("cnkdr_referee", 3);
SSO_SCOPES.set("cnkdr_scorekeeper", 4);
SSO_SCOPES.set("cnkdr_organizer", 5);
SSO_SCOPES.set("cnkdr_admin", 6);
SSO_SCOPES.set("cnkdr_superadmin", 7);

export interface IAuthentication {
  authenticated: boolean;
  loaded: boolean;
  scopes: Array<string>;
  name: '';
}

export function useAuthentication(): [IAuthentication, () => void, () => void] {
  const [authentication, setAuthentication] = useState<IAuthentication>({
    authenticated: false,
    loaded: false,
    scopes: [],
    name: ''
  });

  useEffect(() => {
    if (!authentication.loaded) {
      loadUser();
    }
  }, [authentication.loaded]);

  let loadUser: () => Promise<void> = async () => {
    //@ts-ignore
    let user = await cnkdrSsoLoadUser();

    if (user) {
      
      //@ts-ignore
      cnkdrOnAccessTokenExpiring(() => {
        setAuthentication({
          authenticated: false,
          loaded: false,
          scopes: [],
          name: '',
        });
      });
      //@ts-ignore
      let userScopes: Array<string> = user.profile.scope; //await cnkdrSsoGetScopes();
      let calculatedScope: Array<string> = [];
      for (let userScope of userScopes) {
        if (SSO_SCOPES.has(userScope)) {
          for (let scope of Array.from(SSO_SCOPES.keys())) {
            if (calculatedScope.find((s) => s === scope) === undefined) {
              //@ts-ignore
              if (SSO_SCOPES.get(userScope) >= SSO_SCOPES.get(scope)) {
                calculatedScope.push(scope);
              }
            }
          }
        }
      }

      setAuthentication({
        authenticated: true,
        loaded: true,
        //@ts-ignore
        scopes: calculatedScope,
        name: user.profile.name
      });
    } else {
      setAuthentication({
        authenticated: false,
        loaded: true,
        //@ts-ignore
        scopes: [],
        name: ''
      });
    }
  };

  let logIn = () => {
    //@ts-ignore
    cnkdrSsoLogIn();
  };

  let logOut = () => {
    //@ts-ignore
    cnkdrSsoLogOut().then(
      setAuthentication({
        authenticated: false,
        loaded: true,
        //@ts-ignore
        scopes: [],
        name: ''
      })
    );
  };

  return [authentication, logIn, logOut];
}
