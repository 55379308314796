
import { Autocomplete, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { getShiajos } from '../../Backend/ApiCompetition';
import { IApiCompetitionModel, IApiCompetitionShiajoModel, ICompetitionFilter } from '../../Model/ApiCompetitionModel';
import { IAuthentication } from "../Auth/auth";
import { AutocompleteCompetition } from "../Event/UseAutocompleteCompetition";
import { IEventNotification } from "../Util/ErrorMessage";

export function useSearchShiaijo(props: {
    authentication: IAuthentication,
    dispatchNotification: React.Dispatch<IEventNotification>,
    competitionSelected: undefined | IApiCompetitionModel,
    competitionFilter: ICompetitionFilter
}): [JSX.Element, undefined | IApiCompetitionShiajoModel] {
    const [shiaijo, setShiaijo] = useState<undefined | IApiCompetitionShiajoModel>(undefined)
    const [shiaijoList, setShiaijoList] = useState<Array<IApiCompetitionShiajoModel>>([]);
    const [competitionSelected, setCompetitionSelected] = useState<undefined | IApiCompetitionModel>(props.competitionSelected);

    useEffect(() => {
        setCompetitionSelected(props.competitionSelected)
    }, [props.competitionSelected])

    useEffect(() => {
        if (competitionSelected) {
            setShiaijo(undefined);
            getShiajos(props.dispatchNotification, competitionSelected.id).then(r => {
                setShiaijoList(r);
            })
        }
    }, [competitionSelected, props.dispatchNotification])


    return [
        <>
            <Grid item xs={6}>

                <AutocompleteCompetition
                    authentication={props.authentication}
                    dispatchNotification={props.dispatchNotification}
                    competitionFilter={props.competitionFilter}
                    competitionSelected={competitionSelected}
                    onSelect={(c) => {
                        setCompetitionSelected(c);
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <Autocomplete
                    id="competitionSearch"
                    value={shiaijo}
                    onChange={(event: any, newValue: IApiCompetitionShiajoModel | null) => setShiaijo(newValue ?? undefined)}
                    getOptionLabel={(option) => `${option.fullname}`}
                    options={shiaijoList}
                    loading={shiaijoList === undefined}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} fullWidth={true} label="Shiaijo" />}
                />
            </Grid>
        </>
        , shiaijo
    ]

}