import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { IFightTeamState } from "../../util/ApiModel/ApiFightModel";
import { TPopOverAction } from "../Use/UsePopOver";

export interface IFightTeamVS {
    redFightTeam: IFightTeamState,
    whiteFightTeam: IFightTeamState,
    displayPopOver: TPopOverAction<IFightTeamState>
}
export function FightTeamVS(props: IFightTeamVS) {
    const ref = useRef(null);
    const { t } = useTranslation();

    return <div ref={ref}>
        <b onMouseEnter={() => {
            props.displayPopOver("display",ref, props.redFightTeam);
        }}
            onMouseLeave={() => {
                props.displayPopOver("hide",ref, undefined);
            }}

        >{props.redFightTeam.name} </b>
        {t('label.word.versus')}
        <b onMouseEnter={() => {
            props.displayPopOver("display", ref, props.whiteFightTeam);
        }}
            onMouseLeave={() => {
                props.displayPopOver("hide", ref, undefined);
            }}
        > {props.whiteFightTeam.name} </b>
    </div>
}

