import { TEventStatus } from "../EventComponent/Event/EventStatus";
import { IEventNotification } from "../EventComponent/Util/ErrorMessage";
import { emptyCompetitionModel, emptyCompetitionShiajoModel, IApiCompetitionCreateShiajoModel, IApiCompetitionModel, IApiCompetitionShiajoModel, IApiEditCompetitionModel, IServiceCompetitionAdvancedOrdonancementRules, IServiceCompetitionAdvancedTableRules } from "../Model/ApiCompetitionModel";
import { deleteCompApiObject, getCompApiObject, patchCompApiObject, postCompApiObject, putCompApiObject } from "../UtilEvent/GenericOperation";



export async function postCompetition(dispatchNotification: React.Dispatch<IEventNotification> | undefined
    , data: IApiEditCompetitionModel
    , host?: string
    , token?: string): Promise<IApiCompetitionModel> {


    return postCompApiObject<{
        succeed: boolean,
        data: IApiCompetitionModel
    }>("/competitions", data, {
        succeed: false,
        data: emptyCompetitionModel
    }, dispatchNotification,
        host ? host : (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            return result.data;
        })
}

export async function patchCompetition(dispatchNotification: React.Dispatch<IEventNotification> | undefined
    , data: IApiEditCompetitionModel
    , host?: string
    , token?: string): Promise<IApiCompetitionModel | undefined> {
    return patchCompApiObject<{
        succeed: boolean,
        data: IApiCompetitionModel
    }>("/competitions/" + data.id, data, {
        succeed: false,
        data: emptyCompetitionModel
    }, dispatchNotification,
        host ? host : (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            if (result.succeed) {
                return result.data;
            }
            else return undefined;
        })
}

export async function deleteCompetition(dispatchNotification: React.Dispatch<IEventNotification> | undefined
    , data: IApiCompetitionModel
    , host?: string
    , token?: string): Promise<IApiCompetitionModel | undefined> {
    return deleteCompApiObject<{
        succeed: boolean,
        data: IApiCompetitionModel
    }>("/competitions", data.id, {
        succeed: false,
        data: emptyCompetitionModel
    }, dispatchNotification,
        host ? host : (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            if (result.succeed) {
                return result.data;
            }
            else return undefined;
        })
}

export async function getCompetition(dispatchNotification: React.Dispatch<IEventNotification> | undefined, id?: number, withParent?: boolean, host?: string, token?: string): Promise<IApiCompetitionModel> {
    return getCompApiObject<{
        succeed: boolean,
        data: IApiCompetitionModel
    }>(`/competitions/${id}?withParent=${withParent ?? true}`, null, {
        succeed: false,
        data: emptyCompetitionModel
    }, dispatchNotification,
        host ? host : (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            return result.data;
        })
}

export async function getDocumentPath(dispatchNotification: React.Dispatch<IEventNotification> | undefined, id?: number, host?: string, token?: string): Promise<string> {
    return getCompApiObject<{
        succeed: boolean,
        data: {
            getPath: string,
            name: string,
            putPath: string
        }
    }>(`/competitions/${id}/document/servicestables/ExportTirage${id}.pdf`, null, {
        succeed: false,
        data: {
            getPath: "",
            name: "",
            putPath: ""
        }
    }, dispatchNotification,
        host ? host : (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            return result.data.getPath;
        })
}

export async function deleteCompetitionShiaijos(dispatchNotification: React.Dispatch<IEventNotification> | undefined, id: number, host?: string, token?: string): Promise<boolean> {
    return deleteCompApiObject<{
        succeed: boolean,
        data: any
    }>(`/competitions/${id}/shiaijos`, null, {
        succeed: false,
        data: null
    }, dispatchNotification,
        host ? host : (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            return result.succeed;
        })
}

export async function deleteCompetitionSettings(dispatchNotification: React.Dispatch<IEventNotification> | undefined, id: number, host?: string, token?: string): Promise<boolean> {
    return deleteCompApiObject<{
        succeed: boolean,
        data: any
    }>(`/competitions/${id}/settings`, null, {
        succeed: false,
        data: null
    }, dispatchNotification,
        host ? host : (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            return result.succeed;
        })
}

export type TGetCompetitionType = "competition" | "group" | "competitionANDgroup"

export async function getCompetitions(dispatchNotification: React.Dispatch<IEventNotification>, withParent: boolean
    , params?: {
        username?: String
        type?: TGetCompetitionType,
        fromDate?: Date,
        withClosed?: Boolean,
        parent?: number,
        competitionIds?: number[]
    }): Promise<IApiCompetitionModel[]> {
    return getCompApiObject<{
        succeed: boolean,
        data: Array<IApiCompetitionModel>
    }>(`/competitions?${(params && params.username) ? "username=" + params.username + "&" : ''}withParent=${withParent}${(params?.competitionIds && params?.competitionIds.length > 0) ? `&competitionIds=${params?.competitionIds.join(',')}` : ''}${params?.parent ? `&parent=${params?.parent}` : ''}${params?.type ? `&type=${params?.type}` : ''}${params?.withClosed ? `&withClosed=${params?.withClosed}` : ''}${params?.fromDate ? `&competitionDateFrom=${params.fromDate.getFullYear()}-${(params.fromDate.getMonth() + 1) <= 10 ? '0' : ''}${params.fromDate.getMonth() + 1}-${(params.fromDate.getDate() + 1) <= 10 ? '0' : ''}${params.fromDate.getDate()}` : ''}`, null, {
        succeed: false,
        data: []
    }, dispatchNotification,
        (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions").then((result) => {
            return result.data;
        })
}

export async function patchCompetitionsFreeze(dispatchNotification: React.Dispatch<IEventNotification>, id: number, freeze: boolean, host?: string, token?: string): Promise<IApiCompetitionModel | undefined> {
    return patchCompApiObject<{
        succeed: boolean,
        data: IApiCompetitionModel | undefined
    }>(`/competitions/${id}/online`, (freeze ? 1 : 0) as number
        , {
            succeed: false,
            data: undefined
        }, dispatchNotification,
        host ?? (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            return result.data;
        })
}

export async function patchCompetitionsStatus(dispatchNotification: React.Dispatch<IEventNotification>, id: number,
    status: "Created" | "RegistrationOpen" | "RegistrationClosed" | "DrawingsDone" | "DrawingsPublished" | "Ongoing" | "Finished" | "Next", host?: string, token?: string): Promise<{}> {
    return patchCompApiObject<{
        succeed: boolean,
        data: {}
    }>(`/competitions/${id}/status`, status
        , {
            succeed: false,
            data: {}
        }, dispatchNotification,
        host ?? (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            return result.data;
        })
}

export async function getCompetitionsAdvancedAll(dispatchNotification?: React.Dispatch<IEventNotification>, id?: number, host?: string, token?: string): Promise<Array<{
    advancedSettings: string,
    serviceName: string
}>> {
    //NOTE : error are not display because it's normal if config does not exist
    return await getCompApiObject<{
        succeed: boolean,
        data: Array<{
            advancedSettings: string,
            serviceName: string
        }>
    }>(`/competitions/${id}/settings`,
        null, {
        succeed: false,
        data: []
    }, dispatchNotification,
        host ?? (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            return result.data;
        });
}

export type TConfigurationAdvancedService = "ServicesTables" | "ServiceOrdonancement"

//by defautl for tables
export async function getCompetitionsAdvancedTable<T extends IServiceCompetitionAdvancedTableRules | IServiceCompetitionAdvancedOrdonancementRules>(dispatchNotification?: React.Dispatch<IEventNotification>
    , id?: number
    , serviceName?: TConfigurationAdvancedService): Promise<T> {

    let sName: TConfigurationAdvancedService = "ServicesTables";
    if (serviceName) sName = serviceName;
    //NOTE : error are not display because it's normal if config does not exist
    let result = await getCompApiObject<{
        succeed: boolean,
        data: {
            advancedSettings: string,
            serviceName: string
        }
    }>(`/competitions/${id}/settings/${sName}`,
        null, {
        succeed: false,
        data: { advancedSettings: "", serviceName: sName }
    }, dispatchNotification,
        (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions").then((result) => {
            return result.data;
        });
    let decoded = atob(result.advancedSettings)
    return decoded.length > 0 ? JSON.parse(decoded) : {};
}

export async function updateCompetition(dispatchNotification: React.Dispatch<IEventNotification>, competition: IApiEditCompetitionModel): Promise<IApiCompetitionModel> {
    return await patchCompApiObject<{
        succeed: boolean,
        data: IApiCompetitionModel
    }>(
        `/competitions/${competition.id}`,
        competition
        , { succeed: false, data: emptyCompetitionModel }
        , dispatchNotification,
        (window as any)['REACT_APP_API_COMP_URL']).then((result) => {
            return result.data;
        })
}

export async function updateCompetitionStatus(dispatchNotification: React.Dispatch<IEventNotification>, competitionId: number, status: TEventStatus): Promise<boolean> {
    return await patchCompApiObject<{
        succeed: boolean
    }>(
        `/competitions/${competitionId}/status`,
        status
        , { succeed: false }
        , dispatchNotification,
        (window as any)['REACT_APP_API_COMP_URL']).then((result) => {
            return result.succeed
        })
}



export async function updateCompetitionAdvanced<T>(dispatchNotification: React.Dispatch<IEventNotification>, competition: IApiCompetitionModel, service: TConfigurationAdvancedService, advanced: T): Promise<IServiceCompetitionAdvancedTableRules> {
    let result = await putCompApiObject<{
        succeed: boolean,
        data: {
            advancedSettings: string,
            serviceName: string
        }
    }>(
        `/competitions/${competition.id}/settings/${service}`,
        null,
        btoa(JSON.stringify(advanced)),
        {
            succeed: false,
            data: { advancedSettings: "", serviceName: "ServicesTables" }
        }, dispatchNotification,
        (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions").then((result) => {
            return result.data;
        })
    console.log(result);
    let decoded = JSON.parse(atob(result.advancedSettings));
    return decoded;
}

export async function updateCompetitionAdvancedTableEncoded(dispatchNotification: React.Dispatch<IEventNotification>, competition: IApiCompetitionModel, advancedSettings: string, serviceName: string): Promise<IServiceCompetitionAdvancedTableRules> {
    let result = await postCompApiObject<{
        succeed: boolean,
        data: {
            advancedSettings: string,
            serviceName: string
        }
    }>(
        `/competitions/${competition.id}/settings/${serviceName}`,
        advancedSettings,
        {
            succeed: false,
            data: { advancedSettings: "", serviceName: serviceName }
        }, dispatchNotification,
        (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions").then((result) => {
            return result.data;
        })
    console.log(result);
    let decoded = JSON.parse(atob(result.advancedSettings));
    return decoded;
}

export async function getCompetitionsMap(dispatchNotification: React.Dispatch<IEventNotification>): Promise<Map<number, IApiCompetitionModel>> {
    let competitionsMap: Map<number, IApiCompetitionModel> = new Map();
    let competitionList = await getCompetitions(dispatchNotification, true, { type: "competition" });
    for (let index = 0; index < competitionList.length; index++) {
        let c = competitionList[index];
        competitionsMap.set(c.id, c);
    }
    return competitionsMap;
}

export async function getShiajos(dispatchNotification: React.Dispatch<IEventNotification>, compid: number, host?: string, token?: string): Promise<IApiCompetitionShiajoModel[]> {
    return getCompApiObject<{
        succeed: boolean,
        data: Array<IApiCompetitionShiajoModel>
    }>("/competitions/" + compid + "/shiaijos", null, {
        succeed: false,
        data: []
    }, dispatchNotification,
        host ?? (window as any)['REACT_APP_API_COMP_URL'], token).then((result) => {
            return result.data;
        })
}

export async function getShiajo(dispatchNotification: React.Dispatch<IEventNotification>, compid: number, shiaijoId: number, host?: string, token?: string): Promise<IApiCompetitionShiajoModel> {
    return getCompApiObject<{
        succeed: boolean,
        data: IApiCompetitionShiajoModel
    }>("/competitions/" + compid + "/shiaijos", shiaijoId, {
        succeed: false,
        data: emptyCompetitionShiajoModel
    }, dispatchNotification,
        host ?? (window as any)['REACT_APP_API_COMP_URL'], token).then((result) => {
            return result.data;
        })
}

export async function postShiajos(dispatchNotification: React.Dispatch<IEventNotification>, compid: number, shiaijo: IApiCompetitionCreateShiajoModel): Promise<IApiCompetitionShiajoModel[]> {
    return postCompApiObject<{
        succeed: boolean,
        data: Array<IApiCompetitionShiajoModel>
    }>("/competitions/" + compid + "/shiaijos", shiaijo,
        {
            succeed: false,
            data: []
        }, dispatchNotification,
        (window as any)['REACT_APP_API_COMP_URL']).then((result) => {
            return result.data;
        })
}

export async function patchShiajos(dispatchNotification: React.Dispatch<IEventNotification>, compid: number, shiaijoId: number, shiaijo?: IApiCompetitionShiajoModel): Promise<IApiCompetitionShiajoModel> {
    return patchCompApiObject<{
        succeed: boolean,
        data: IApiCompetitionShiajoModel
    }>("/competitions/" + compid + "/shiaijos/" + shiaijoId, shiaijo ?? {},
        {
            succeed: false,
            data: emptyCompetitionShiajoModel
        }, dispatchNotification,
        (window as any)['REACT_APP_API_COMP_URL']).then((result) => {
            return result.data;
        })
}

export async function deleteShiajos(dispatchNotification: React.Dispatch<IEventNotification>, compid: number, shiaijoId: number): Promise<boolean> {
    return deleteCompApiObject<{
        succeed: boolean
    }>("/competitions/" + compid + "/shiaijos", shiaijoId,
        {
            succeed: false
        }, dispatchNotification,
        (window as any)['REACT_APP_API_COMP_URL']).then((result) => {
            return result.succeed;
        })
}

export async function getShiajosMap(dispatchNotification: React.Dispatch<IEventNotification>, compid: number): Promise<Map<number, IApiCompetitionShiajoModel>> {
    let shiajoMaps: Map<number, IApiCompetitionShiajoModel> = new Map();
    let shiajoList = await getShiajos(dispatchNotification, compid);
    for (let index = 0; index < shiajoList.length; index++) {
        let c = shiajoList[index];
        shiajoMaps.set(c.id, c);
    }
    return shiajoMaps;
}



export async function postUserProfile(compId: number, uid: string, profile: string, dispatchNotification: React.Dispatch<IEventNotification>): Promise<boolean> {
    let result = await postCompApiObject<{
        succeed: boolean
    }>(`/users/${uid}/competitions/${compId}/${profile}`,
        {}, {
        succeed: false,
    }, dispatchNotification,
        (window as any)['REACT_APP_API_COMP_URL']
    );
    return result.succeed;
}

export async function deleteUserProfile(compId: number, uid: string, profile: string, dispatchNotification: React.Dispatch<IEventNotification>): Promise<boolean> {
    let result = await deleteCompApiObject<{
        succeed: boolean
    }>(`/users/${uid}/competitions/${compId}/${profile}`, null,
        {
            succeed: false,
        }, dispatchNotification,
        (window as any)['REACT_APP_API_COMP_URL']
    );
    return result.succeed;
}

