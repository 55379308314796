import { useEffect, useMemo } from 'react';
import uniqid from 'uniqid';

export const TAB_ID_KEY = 'tabId'

export function useTabId() {
  const id = useMemo(() => sessionStorage.getItem(TAB_ID_KEY) ?? uniqid(), [])

  useEffect(() => {
    if (typeof Storage !== 'undefined') {
      sessionStorage.setItem(TAB_ID_KEY, id)
    }
  }, [id])

  return id
}