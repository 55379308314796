import React from 'react';
import { IRoundScreenProps } from './RoundScreen';
import { ERoundStatus } from '../../util/ApiModel/ApiRoundModel';

export function RoundStateScreen(props: IRoundScreenProps) {
    let statusInfo = null;
    switch (props.status) {
        case ERoundStatus.WAIT:
        case ERoundStatus.PAUSE:
            //statusInfo = <img alt="yellow_flag" src={require("../../images/pause_icon-icon.png")} width="100%" />
            break;
        case ERoundStatus.FINISHED:
        default:
            ;
    };

    return <>{statusInfo}</>;
}