import { Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getFightersFighters, getFightersTeams } from "../../Backend/ApiFighter";
import { IApiFighterFightTeam } from "../../Model/ApiTeamModel";
import { IApiFighterFighter } from "../Team/LazyFightTeam";
import { IServiceTableNotification } from "../Util/ErrorMessage";

export interface IuseSelectFighterOrTeamProps {
    competitionId: number | undefined,
    dispatchNotification: React.Dispatch<IServiceTableNotification>,
    allowTeam: boolean,
    allowFighter: boolean
}

export interface TeamOrFighter {
    team: IApiFighterFightTeam | undefined,
    fighter: IApiFighterFighter | undefined
}

export function useSelectFighterOrTeam(props: IuseSelectFighterOrTeamProps): [TeamOrFighter | undefined, JSX.Element] {
    const [fighterList, setFighterList] = useState<Array<IApiFighterFighter>>([]);
    const [teamList, setTeamList] = useState<Array<IApiFighterFightTeam>>([]);
    const [selected, setSelected] = useState<TeamOrFighter | undefined>(undefined);
    const [key, setKey] = useState<string>("");


    useEffect(() => {
        if (!props.allowTeam) {
            setTeamList([])
            setSelected(undefined);
        }
        else {
            if (props.competitionId !== undefined) {
                getFightersTeams(
                    props.dispatchNotification,
                    props.competitionId
                ).then((result) => {
                    setTeamList(result);
                })
            } else {
                setTeamList([]);
            }
        }
    }, [props.competitionId, props.dispatchNotification, props.allowTeam]);

    useEffect(() => {
        if (!props.allowFighter) {
            setFighterList([])
            setSelected(undefined);
        }
        else {
            if (props.competitionId !== undefined) {
                getFightersFighters(
                    props.dispatchNotification,
                    props.competitionId
                ).then((result) => {
                    setFighterList(result);
                })
            }
            else {
                setFighterList([]);
            }
        }
    }, [props.allowFighter, props.competitionId, props.dispatchNotification]);

    const l1: Array<TeamOrFighter> = fighterList.map(f => {
        return {
            team: undefined,
            fighter: f
        }
    });
    const l2: Array<TeamOrFighter> = teamList.map(t => {
        return {
            team: t,
            fighter: undefined
        }
    })
    const flist: Array<TeamOrFighter> = l1.concat(l2);

    useEffect(() => {
        if (selected?.team) setKey("T" + selected?.team.id);
        else if (selected?.fighter) setKey("F" + selected?.fighter.id);
        else setKey("");
    }, [selected]);

    const comp = <Autocomplete
        disablePortal
        options={flist}
        value={selected}
        key={key}
        onChange={(event: any, newValue: TeamOrFighter | null) => {
            setSelected(newValue ?? undefined);
        }}
        getOptionLabel={(option) => {
            if (option.fighter) {
                const f: IApiFighterFighter = option.fighter;
                return `Fighter ${f.firstname} ${f.lastname}`
            }
            else if (option.team) {
                const t: IApiFighterFightTeam = option.team;
                return `Team ${t.name}`
            } else {
                return "";
            }

        }}
        renderInput={(params) => (
            <div ref={params.InputProps.ref}>
                <input type="text" {...params.inputProps} />
            </div>
        )}
    />
    return [selected, comp]
}