
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { searchUserPartial } from "../../Backend/ApiUser";
import { IApiUserModel } from "../../Model/ApiUserModel";
import { IEventNotification } from "../Util/ErrorMessage";

export function useUserSearch(props: {
    dispatchNotification: React.Dispatch<IEventNotification>
}): [JSX.Element, undefined | IApiUserModel] {
    const [user, setUser] = React.useState<undefined | IApiUserModel>(undefined);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<Array<IApiUserModel>>([]);
    const loading = open && options.length === 0;

    const onChangeHandle = async (value: string) => {
        if (value.length >= 3) {
            const response = await searchUserPartial(value, props.dispatchNotification);
            setOptions(response);
        }
        else {
            setOptions([]);
        }
    };


    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);



    return [
        <>
            Search user (at least 3 letters) <br/>
            <Autocomplete

                id="userSearch"

                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                onChange={(event, newValue) => {
                    setUser(newValue ?? undefined);
                }}
                getOptionLabel={(option) => option.name}
                options={options}
                filterOptions={(options, state) => options}

                loading={loading}
                style={{ width: 300 }}
                renderInput={params => (
                    <TextField
                        {...params}
                        label="Asynchronous"
                        variant="outlined"
                        onChange={ev => {
                            // dont fire API if the user delete or not entered anything
                            if (ev.target.value !== "" || ev.target.value !== null) {
                                onChangeHandle(ev.target.value);
                            }
                        }}

                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                    />
                )}
            />
        </>, user];

}