import { Alert, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Paper, Select, TextField } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import produce from "immer";
import React, { useEffect, useState } from "react";
import { ITeamWithFighters, completeFromLicencie, createFightersFighter, updateFightersFighter } from "../../Backend/ApiFighter";
import { TMartialArt } from "../../Model/ApiCompetitionModel";
import { IApiFighterFightTeam } from "../../Model/ApiTeamModel";
import { IApiFighterFighter } from "../Team/LazyFightTeam";
import { IServiceTableNotification } from "../Util/ErrorMessage";

export interface IFightersEdit extends IApiFighterFighter {
    unsaved?: boolean
}

export interface ITeamWithFightersEdit extends IApiFighterFightTeam {
    fighters: Array<IFightersEdit>;
    unsaved?: boolean;
}


export function EditFighter(props: {
    fighter: IFightersEdit,
    discipline: "" | TMartialArt,
    onSave: (fighter: IFightersEdit) => void,
    onChange: (fighter: IFightersEdit) => void,
    dispatchNotifications: React.Dispatch<IServiceTableNotification>
}) {
    /*const [fighterState, setFighterState] = useState<IFightersEdit>(props.fighter);

    useEffect(() => {
        setFighterState(props.fighter);
    }, [props.fighter])

    useEffect(() => {
        props.onChange({
            ...props.fighter,
            unsaved: true
        });
    }, [fighterState]);*/

    return <>
        {props.fighter.firstname} {props.fighter.lastname} {props.fighter.license}<br />
        License * <TextField value={props.fighter.license} onChange={(e) => {
            props.onChange({
                ...props.fighter,
                unsaved: true,
                license: e.target.value,
            }
            )
        }}
        /><br />
        {props.discipline !== "" && <>
            <Button variant="contained" size="small"
                onClick={() => {
                    if (props.discipline !== "") completeFromLicencie(props.dispatchNotifications, props.fighter, props.fighter.license, props.discipline, false).then((fighterCompleted) => {
                        if (fighterCompleted[1]) {
                            props.onChange({
                                ...props.fighter,
                                unsaved: true,
                                ...fighterCompleted[0]
                            })
                        }
                    })
                }}>
                Complete from licencie
            </Button><br />
            <Button variant="contained" size="small"
                onClick={() => {
                    if (props.discipline !== "") completeFromLicencie(props.dispatchNotifications, props.fighter, props.fighter.license, props.discipline, true).then((fighterCompleted) => {
                        if (fighterCompleted[1]) {
                            props.onChange({
                                ...props.fighter,
                                unsaved: true,
                                ...fighterCompleted[0]
                            })
                        }
                    })
                }}>
                Erase from licencie
            </Button><br />
        </>}
        Validation: <Chip onClick={() => {

            props.onChange({
                ...props.fighter,
                unsaved: true,
                valide: props.fighter.valide === 1 ? 0 : 1
            }
            )
        }}
            size="small" label={props.fighter.valide ? "Valide" : "Non valide"}
            color={props.fighter.valide ? "primary" : "error"} />
        <br />
        Firstname * < TextField value={props.fighter.firstname} onChange={(e) => {
            props.onChange({
                ...props.fighter,
                unsaved: true,
                firstname: e.target.value,
            }
            )
        }}
        />
        Lastname * <TextField value={props.fighter.lastname} onChange={(e) => {
            props.onChange({
                ...props.fighter,
                unsaved: true,
                lastname: e.target.value,
            }
            )
        }}
        />
        Birthdate * <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={props.fighter.birthdate}
                value={props.fighter.birthdate}
                onChange={(newValue: any) => {
                    let dateStr = '';
                    if (newValue) dateStr = `${newValue.getFullYear()}-${(newValue.getMonth() + 1) < 10 ? '0' : ''}${newValue.getMonth() + 1}-${(newValue.getDate() + 1) < 10 ? '0' : ''}${newValue.getDate()}`
                    props.onChange({
                        ...props.fighter,
                        unsaved: true,
                        birthdate: dateStr,
                    });
                }}
                renderInput={(params: any) => <TextField {...params} />
                }
                inputFormat="dd-MM-yyyy"
            />
        </LocalizationProvider>

        {/* <TextField value={props.fighter.birthdate} onChange={(e) => {
            props.onChange({
                ...props.fighter,
                unsaved: true,
                birthdate: e.target.value,
            }
            )
        }} 
        />*/}<br />
        Club < TextField value={props.fighter.club} onChange={(e) => {
            props.onChange({
                ...props.fighter,
                club: e.target.value,
            }
            )
        }
        }
        />
        Region < TextField value={props.fighter.region} onChange={(e) => {
            props.onChange({
                ...props.fighter,
                unsaved: true,
                region: e.target.value,
            }
            )
        }}
        />
        Pays < TextField value={props.fighter.nationality} onChange={(e) => {
            props.onChange({
                ...props.fighter,
                unsaved: true,
                nationality: e.target.value,
            }
            )
        }}
        /><br />
        Gender < Select
            value={props.fighter.sex}
            onChange={(e) => {
                props.onChange({
                    ...props.fighter,
                    unsaved: true,
                    sex: parseInt(e.target.value.toString()),
                }
                )
            }}
        >
            <MenuItem value={1}>Homme</MenuItem >
            <MenuItem value={2}>Femme</MenuItem >
        </Select >
        <br />
        <Button variant="contained" size="small" onClick={() => props.onSave(props.fighter)}>Save Fighter</Button></>
}

export function EditFighterModal(props: {
    open: boolean,
    onClose: (edited: IFightersEdit | undefined) => void,
    fighter: IApiFighterFighter,
    discipline: "" | TMartialArt,
    dispatchNotifications: React.Dispatch<IServiceTableNotification>
}) {
    const [fighterState, setFighterState] = useState<IFightersEdit>(props.fighter);
    const [fighterStateSaved, setFighterStateSaved] = useState<IFightersEdit>(props.fighter);

    useEffect(() => {
        setFighterState(props.fighter);
        setFighterStateSaved(props.fighter);
    }, [props.fighter])

    useEffect(() => {
        if (!fighterState.unsaved) {
            setFighterStateSaved(fighterState);
        }
    }, [fighterState])

    return <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={props.open}
        onClose={() => props.onClose(undefined)}>
        <DialogTitle>Fighter {props.fighter.firstname}</DialogTitle>
        <DialogContent>
            <Box className="modalBox" sx={{ overflowY: "scroll" }}>
                <Paper elevation={5} style={{
                    backgroundColor: fighterState.unsaved ? "salmon" : ""
                }}>
                    <EditFighter
                        dispatchNotifications={props.dispatchNotifications}
                        fighter={fighterState}
                        discipline={props.discipline}
                        onChange={(f) => {
                            setFighterState(f);
                        }}
                        onSave={(f) => {
                            if (f.id === 0 || f.id === undefined) {
                                createFightersFighter(props.dispatchNotifications, f).then(r => {
                                    if (r.data > 0) {
                                        setFighterState(f => {
                                            return {
                                                ...f,
                                                id: r.data,
                                                unsaved: false
                                            }
                                        }
                                        );
                                    }
                                })
                            }
                            else {
                                updateFightersFighter(props.dispatchNotifications, f).then(r => {
                                    if (r) {
                                        setFighterState(f => {
                                            return {
                                                ...f,
                                                unsaved: false
                                            }
                                        });
                                    }
                                })
                            }
                        }}
                    />
                </Paper>
                <Button variant="contained" onClick={() => props.onClose(fighterStateSaved)}>Close</Button>
            </Box>
        </DialogContent>
    </Dialog>
}


export function EditTeamModal(props: {
    open: boolean,
    onClose: (edited: ITeamWithFightersEdit | undefined) => void,
    team: ITeamWithFighters,
    discipline: "" | TMartialArt,
    dispatchNotifications: React.Dispatch<IServiceTableNotification>
}) {
    const [teamState, setTeamState] = useState<ITeamWithFightersEdit>(props.team);
    const [teamStateSavedFighters, setTeamStateSavedFighters] = useState<ITeamWithFightersEdit>(props.team);

    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    useEffect(() => {
        setTeamState(props.team);
        setTeamStateSavedFighters(props.team);
        setErrorMessages([]);
    }, [props.team])

    // const onFighterChange = (fighter: IFightersEdit) => {
    //     setTeamState(
    //         {
    //             ...teamState,
    //             fighters: produce(teamState.fighters, (draft) => {
    //                 draft[teamState.fighters.indexOf(fighter)] = fighter;
    //                 draft[teamState.fighters.indexOf(fighter)].unsaved = true;
    //             })
    //         })
    // }

    const onFighterChange = (f: IFightersEdit) => {
        setTeamState(
            {
                ...teamState,
                fighters: produce(teamState.fighters, (draft) => {
                    const index = teamState.fighters.findIndex(f2 => f2.id === f.id);
                    if (index >= 0) {
                        draft[index] = {
                            ...f
                        }
                    }
                })
            }
        )
        if (f.unsaved === false) {
            setTeamStateSavedFighters(
                {
                    ...teamStateSavedFighters,
                    fighters: produce(teamStateSavedFighters.fighters, (draft) => {
                        draft[teamStateSavedFighters.fighters.findIndex(f2 => f.id === f2.id)] = {
                            ...f,
                            unsaved: false
                        }
                    })
                });
        }
    }

    const checkAndSave = (edited: ITeamWithFightersEdit | undefined) => {
        if (edited) {
            if (edited.name.length === 0) {
                setErrorMessages(["Name is required"]);
            }
            else {
                props.onClose(edited);
            }
        }
        else {
            props.onClose(edited);
        }
    };

    return <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={props.open}
        onClose={() => props.onClose(undefined)}>
        <DialogTitle>TEAM {props.team.name}</DialogTitle>
        <DialogContent>
            <Box className="modalBox" sx={{ overflowY: "scroll" }}>
                {errorMessages.length > 0 && <Alert severity="error">
                    {errorMessages.map((e) => <div>{e}</div>)}
                </Alert>
                }
                <div style={{ backgroundColor: teamState.unsaved ? "salmon" : "" }}>

                    Name <TextField value={teamState.name} onChange={(e) => setTeamState({ ...teamState, name: e.target.value, unsaved: true })} /><br />
                    Validation <Chip
                        onClick={() => setTeamState({ ...teamState, valide: teamState.valide === 1 ? 0 : 1, unsaved: true })}
                        size="small" label={teamState.valide ? "Valide" : "Non valide"}
                        color={teamState.valide ? "primary" : "error"}
                    /><br />
                    Club <TextField value={teamState.club} onChange={(e) => setTeamState({ ...teamState, club: e.target.value, unsaved: true })} /><br />
                    Region <TextField value={teamState.region} onChange={(e) => setTeamState({ ...teamState, region: e.target.value, unsaved: true })} /><br />
                    Nationality <TextField value={teamState.nationality} onChange={(e) => setTeamState({ ...teamState, nationality: e.target.value, unsaved: true })} /><br />
                </div>
                {teamState.fighters.map((f) =>
                    <Paper key={f.id} elevation={5} style={{
                        backgroundColor: f.unsaved ? "salmon" : ""
                    }}>
                        <EditFighter
                            dispatchNotifications={props.dispatchNotifications}
                            fighter={f}
                            discipline={props.discipline}
                            onSave={(f) => {
                                updateFightersFighter(props.dispatchNotifications, f).then(r => {
                                    if (r) {
                                        onFighterChange({
                                            ...f,
                                            unsaved: false
                                        });
                                    }
                                })
                            }
                            }
                            onChange={onFighterChange}
                        />
                    </Paper>
                )}
            </Box>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={() => checkAndSave(teamState)}>Save team & fighters</Button>
            <Button variant="contained" onClick={() => {
                if (props.team.id) {
                    checkAndSave({
                        ...props.team,
                        fighters: teamStateSavedFighters.fighters
                    })
                }
                else {
                    props.onClose(undefined);
                }
            }
            }>Cancel</Button>
        </DialogActions>

    </Dialog>

}