import { Delete, ModeEdit } from '@mui/icons-material';
import ReorderIcon from '@mui/icons-material/Reorder';
import { Box, IconButton } from '@mui/material';
import { t } from 'i18next';
import { MRT_ColumnDef, MaterialReactTable } from 'material-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { EventProcess, EventProcessGroup, TCreateCompetition } from '../../EventComponent/Event/EventProcess';
import { IEventNotification } from '../../EventComponent/Util/ErrorMessage';
import { IApiCompetitionModel } from '../../Model/ApiCompetitionModel';
import { getCompetitionGroupList } from '../Util/CompetitionOperations';

var debug = require('debug')('ServiceTablesMinimalFrontend:configuration')

interface ICompetitionEditTable {
    competitionList: Array<IApiCompetitionModel>
    edit: (id: number) => Promise<void>,
    delete: (id: number) => Promise<void>,
    dispatchNotification: React.Dispatch<IEventNotification>,
    setCreateCompetition: TCreateCompetition,
    onChange: () => void,
    small: boolean
}

interface ICompetitionEditTableData extends IApiCompetitionModel { competitions: Array<ICompetitionEditTableData> | undefined }

export function CompetitionEditTable(props: ICompetitionEditTable) {


    const [competitionGroupList, setCompetitionGroupList] = useState(getCompetitionGroupList(props.competitionList));

    useEffect(() => {
        setCompetitionGroupList(getCompetitionGroupList(props.competitionList));
    }, [props.competitionList])

    const columns = useMemo<MRT_ColumnDef<ICompetitionEditTableData>[]>(() => {
        return [
            {
                accessorKey: 'id', header: 'id',
                
                //, width: 100, sortComparator: childrenSortComparator('id')
            },
            {
                accessorKey: 'type', header: 'type'
                /*, minWidth: 150, editable: true
                , sortComparator: childrenSortComparator('type')
                , renderCell: (c) => {
                    const competitionRow = c.row;
                    const competition = competitionConfiguration.selection.selectedCompetition.find(c => c.id === competitionRow.id);
                    const isAdvancedDefined = competition && (!!competition.advancedTables?.configurationDone);
                    if (competition !== undefined) {
                        return <>
                            {competition['type']}
                            <Tooltip title={t('action.configuration.configurationRun')}>
                                <SettingsIcon onClick={() => {
     
                                    if (competition) {
                                        //Configuration has been seen once
                                        if (!competition.advancedTables?.configurationDone) {
                                            competitionEdit.updateCompetitionTableAdvanced(competition.id, "configurationDone", "true");
                                        }
                                        competitionConfiguration.showCompetitionConfiguration(competition.id)
                                    }
                                }}
                                    color={isAdvancedDefined ? "primary" : "warning"}
                                />
                            </Tooltip>
                            {(!isAdvancedDefined) &&
                                <Tooltip title={t('action.configuration.configurationRunTodo')}>
                                    <WarningIcon color="warning" />
                                </Tooltip>
                            }
                        </>
     
                    }
                    else {
                        return <Button onClick={() => {
                            selectCompetition(competitionRow.id, true);
                        }}>{competitionRow['type']}</Button>
                    }
     
                }*/
            },
            {
                accessorKey: 'fullname', header: 'fullname'
                /*, minWidth: 200, editable: true,
            renderCell: (params: GridRenderCellParams) => {
                if (params.row.parentId) {
                    return <><Remove />{params.value}</>
                }
                else if (params.row.type === "group") {
                    if (competitionFilter.selectedGroup === params.row.id) {
                        return <><ArrowDropDown />{params.value}</>
                    }
                    else {
                        return <><ArrowRight />{params.value}</>
    
                    }
                }
                else {
                    return <>{params.value}</>
                }
            }
            , sortComparator: childrenSortComparator('fullname')*/
            },
            {
                accessorKey: 'category'
                , header: 'category'
                , accessorFn: (row) => `${row.category} - ${row.teamSize > 1 ? 'team' : 'indiv'}`,

                /*, minWidth: 150, editable: true
                
            , sortComparator: childrenSortComparator('category')
            , renderCell: (params: GridRenderCellParams) => {
                const competitionRow = params.row;
                return <>{params.value} - {competitionRow['teamSize'] > 1 ? 'team' : 'indiv'}</>
            }*/
            },
            
           
            {
                accessorKey: 'status', header: 'status'
                , accessorFn: (row) => `${t(`label.competition.status.${row.status.toLowerCase()}`)}`
                /*, minWidth: 150, editable: true,
            renderCell: (c: any) => {
                const competition = c.row;
                return <>{t(`label.competition.status.${competition.status.toLowerCase()}`)}</>
                // {t(`label.competition.status.${competition.status.toLowerCase()}`)}
            }
            , sortComparator: childrenSortComparator('status')*/
            },
            {
                accessorKey: 'competitionDate', header: 'competitionDate'/*, minWidth: 150, editable: true
            , type: 'date'
            , sortComparator: childrenSortComparator('competitionDate')*/
            },
            {
                id: 'process',
                header: 'process',
                columnDefType: 'display', //turns off data column features like sorting, filtering, etc.
                //enableColumnOrdering: true, //but you can turn back any of those features on if you want like this
                Cell: ({ row }) => {
                    if (row.original.type === "group") {
                        return <EventProcessGroup
                            competition={row.original}
                            setCreateCompetition={props.setCreateCompetition}
                            currentStatus={row.original.status}
                            event={row.original}
                            dispatchNotification={props.dispatchNotification}
                            onChange={props.onChange}
                            small={true}
                        />
                    }
                    else {
                        return <EventProcess
                            currentStatus={row.original.status}
                            event={row.original}
                            dispatchNotification={props.dispatchNotification}
                            onChange={props.onChange}
                            small={props.small}
                        />;


                    }
                }
            },
            {
                accessorKey: 'place', header: 'place',
                /*minWidth: 150, editable: true*/
            },
            {
                accessorKey: 'martialArt', header: 'martialArt',
                /*minWidth: 150,
                sortComparator: childrenSortComparator('martialArt')*/
            },
        ]
    }, [props.dispatchNotification, props.onChange, props.setCreateCompetition, props.small]);



    //reload group
    useEffect(() => {
        setCompetitionGroupList(getCompetitionGroupList(props.competitionList))
    }, [props.competitionList])

    return <>
        <MaterialReactTable
            enableRowActions
            columns={columns}
            defaultColumn={{
                minSize: 20, //allow columns to get smaller than default
                maxSize: 900, //allow columns to get larger than default
                size: 30, //make columns wider by default
              }}
            data={competitionGroupList ?? []}
            enableRowSelection={false} //enable some features
            enableColumnOrdering
            //enableGlobalFilter={false} //turn off a feature
            enableExpanding
            getSubRows={(originalRow) => originalRow.competitions}
            paginateExpandedRows={false}
            filterFromLeafRows
            initialState={{ expanded: true }} //expand all rows by default
            icons={{
                ExpandMoreIcon: (props: any) => {
                    return <ReorderIcon {...props} color={
                        props.style.transform === "rotate(-90deg)" ? undefined : "primary"
                    } />
                },
            }}
            displayColumnDefOptions={{
                'mrt-row-actions': {
                    header: 'Actions', //change header text
                    size: 120, //make actions column wider
                },
            }}
            renderRowActions={({ row }) => [
                <Box>
                    <IconButton onClick={() => {
                        props.edit(row.original.id)
                    }}>
                        <ModeEdit />
                    </IconButton>
                    <IconButton onClick={() => {
                        props.delete(row.original.id)
                    }}>
                        <Delete />
                    </IconButton>
                </Box>
            ]}
        />
    </>;

}