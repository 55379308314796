import { getFightersFighter, getFightersTeam } from "../Backend/ApiFighter";
import { IAuthentication } from "../components/Auth/auth";
import { deleteApiObject, getApiObject, patchApiObject, postApiObject, putApiObject } from "../components/Operation/GenericOperation";
import { IApiFighterFighter } from "../components/Team/LazyFightTeam";
import { IServiceTableNotification } from "../components/Util/ErrorMessage";
import { emptyCompetitionModel, IApiCompetitionModel, IServiceCompetitionAdvancedOrdonancementRules, IServiceCompetitionAdvancedTableRules, TCompetitionStatus } from "../Model/ApiCompetitionModel";
import { IApiFighterFightTeam } from "../Model/ApiTeamModel";


export const resultList = ["first", "second", "third", "fighting spirit"] as const;
export type TResult = typeof resultList[number];


export interface IApiCompetitionResultModel {
    id: number,
    competitionId: number,
    result: TResult,
    teamId: number | null,
    fighterId: number | null
}

export interface IApiCompetitionResultWithFighter extends IApiCompetitionResultModel {
    fightTeam: IApiFighterFightTeam | undefined;
    fighter: IApiFighterFighter | undefined;
}


export const emptyIApiCompetitionResultModel: IApiCompetitionResultModel = {
    id: 0,
    competitionId: 0,
    result: "first",
    teamId: null,
    fighterId: null
}
export interface IApiCompetitionShiajoModel {
    fullname: string,
    comment: string,
    shortname: string,
    competitionId: Array<number>,
    id: number,
    order: number
}

export const emptyApiCompetitionShiajoModel: IApiCompetitionShiajoModel = {
    fullname: "",
    comment: "",
    shortname: "",
    competitionId: [],
    id: 0,
    order: 0
}

export async function postCompetition(dispatchNotification: React.Dispatch<IServiceTableNotification> | undefined
    , data: IApiCompetitionModel
    , host?: string
    , token?: string): Promise<IApiCompetitionModel> {
    return postApiObject<{
        succeed: boolean,
        status: number,
        data: IApiCompetitionModel
    }>("/competitions", data, {
        succeed: false,
        status: 400,
        data: emptyCompetitionModel
    }, dispatchNotification,
        host ? host : (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            return result.data;
        })
}

export async function getCompetition(dispatchNotification: React.Dispatch<IServiceTableNotification> | undefined
    , id?: number, host?: string, token?: string): Promise<IApiCompetitionModel> {
    return getApiObject<{
        succeed: boolean,
        data: IApiCompetitionModel
    }>("/competitions", id ?? null, {
        succeed: false,
        data: emptyCompetitionModel
    }, dispatchNotification,
        host ? host : (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            return result.data;
        })
}

export async function getDocumentPath(dispatchNotification: React.Dispatch<IServiceTableNotification> | undefined, id?: number, host?: string, token?: string): Promise<string> {
    return getApiObject<{
        succeed: boolean,
        data: {
            getPath: string,
            name: string,
            putPath: string
        }
    }>(`/competitions/${id}/document/servicestables/ExportTirage${id}.pdf`, null, {
        succeed: false,
        data: {
            getPath: "",
            name: "",
            putPath: ""
        }
    }, dispatchNotification,
        host ? host : (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            return result.data.getPath;
        })
}

export async function deleteCompetitionShiaijos(dispatchNotification: React.Dispatch<IServiceTableNotification> | undefined, id: number, host?: string, token?: string): Promise<boolean> {
    return deleteApiObject<{
        succeed: boolean,
        data: any
    }>(`/competitions/${id}/shiaijos`, null, {
        succeed: false,
        data: null
    }, dispatchNotification,
        host ? host : (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            return result.succeed;
        })
}



export async function deleteCompetitionShiaijo(dispatchNotification: React.Dispatch<IServiceTableNotification> | undefined, competitionId: number, shiaijoId: number, host?: string, token?: string): Promise<boolean> {
    return deleteApiObject<{
        succeed: boolean,
        data: any
    }>(`/competitions/${competitionId}/shiaijos/${shiaijoId}`, null, {
        succeed: false,
        data: null
    }, dispatchNotification,
        host ? host : (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            return result.succeed;
        })
}

export async function patchCompetitionShiaijo(dispatchNotification: React.Dispatch<IServiceTableNotification> | undefined, competitionId: number, shiaijoId: number, host?: string, token?: string): Promise<boolean> {
    return patchApiObject<{
        succeed: boolean,
        data: any
    }>(`/competitions/${competitionId}/shiaijos/${shiaijoId}`, {}, {
        succeed: false,
        data: null
    }, dispatchNotification,
        host ? host : (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            return result.succeed;
        })
}

export async function deleteCompetitionSettings(dispatchNotification: React.Dispatch<IServiceTableNotification> | undefined, id: number, host?: string, token?: string): Promise<boolean> {
    return deleteApiObject<{
        succeed: boolean,
        data: any
    }>(`/competitions/${id}/settings`, null, {
        succeed: false,
        data: null
    }, dispatchNotification,
        host ? host : (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            return result.succeed;
        })
}

export async function getCompetitionsDeprecated(dispatchNotification: React.Dispatch<IServiceTableNotification>
    , type: "competition" | "competitionANDgroup" = "competition"
    , filter: {
        fromDate: Date | undefined,
        username: string | undefined
    },
    host?: string,
    token?: string): Promise<IApiCompetitionModel[]> {

    let param = "?type=" + type;
    if (filter.fromDate) {
        param += `&competitionDateFrom=${filter.fromDate.getFullYear()}-${(filter.fromDate.getMonth() + 1) < 10 ? '0' : ''}${filter.fromDate.getMonth() + 1}-${(filter.fromDate.getDate() + 1) < 10 ? '0' : ''}${filter.fromDate.getDate()}`;
    }
    if (filter.username) {
        param += "&username=" + filter.username;
    }
    return getApiObject<{
        succeed: boolean,
        data: Array<IApiCompetitionModel>
    }>("/competitions" + param, null, {
        succeed: false,
        data: []
    }, dispatchNotification,
        host ? host : (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            return result.data;
        })
}

export async function patchCompetitionsFreeze(dispatchNotification: React.Dispatch<IServiceTableNotification>, id: number, freeze: boolean, host?: string, token?: string): Promise<IApiCompetitionModel | undefined> {
    return patchApiObject<{
        succeed: boolean,
        data: IApiCompetitionModel | undefined
    }>(`/competitions/${id}/online`, (freeze ? 1 : 0) as number
        , {
            succeed: false,
            data: undefined
        }, dispatchNotification,
        host ?? (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            return result.data;
        })
}

export async function patchCompetitionsStatus(dispatchNotification: React.Dispatch<IServiceTableNotification>, id: number,
    status: TCompetitionStatus | "Next", host?: string, token?: string): Promise<{}> {
    return patchApiObject<{
        succeed: boolean,
        data: {}
    }>(`/competitions/${id}/status`, status
        , {
            succeed: false,
            data: {}
        }, dispatchNotification,
        host ?? (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            return result.data;
        })
}

export async function getCompetitionsAdvancedAll(dispatchNotification?: React.Dispatch<IServiceTableNotification>, id?: number, host?: string, token?: string): Promise<Array<{
    advancedSettings: string,
    serviceName: string
}>> {
    //NOTE : error are not display because it's normal if config does not exist
    return await getApiObject<{
        succeed: boolean,
        data: Array<{
            advancedSettings: string,
            serviceName: string
        }>
    }>(`/competitions/${id}/settings`,
        null, {
        succeed: false,
        data: []
    }, dispatchNotification,
        host ?? (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            return result.data;
        });
}

export type TConfigurationAdvancedService = "ServicesTables" | "ServiceOrdonancement"


export async function getCompetitionsListAdvanced<T extends IServiceCompetitionAdvancedTableRules | IServiceCompetitionAdvancedOrdonancementRules>(dispatchNotification: React.Dispatch<IServiceTableNotification> | undefined
    , ids: Array<number>
    , serviceName?: TConfigurationAdvancedService): Promise<
        Array<{
            competitionId: number,
            settings: Array<{ current: T, parent: T , serviceName: TConfigurationAdvancedService}>,
            
        }>> {
    let sName: TConfigurationAdvancedService = "ServicesTables";
    if (serviceName) sName = serviceName;
    type TAdvancedSetting = {
        advancedSettings: string | undefined,
        advancedParentSettings: string | undefined,
        serviceName: string
    };
    let result = await getApiObject<{
        succeed: boolean,
        data: Array<{
            competitionId: number,
            settings: Array<TAdvancedSetting>
        }>
    }>(`/settings?competitionIds=${ids.join(',')}&serviceName=${sName}`,
        null, {
        succeed: false,
        data: []
    }, dispatchNotification,
        (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions").then((result) => {
            return result.data;
        });
    const toReturn = result.map((competitionSettings) => {
        return {
            competitionId: competitionSettings.competitionId,
            settings: competitionSettings.settings.map((competitionSetting) => {
                let decoded = (competitionSetting.advancedSettings && competitionSetting.advancedSettings?.length > 0) ? atob(competitionSetting.advancedSettings) : "{}";
                let decodedParent = (competitionSetting.advancedParentSettings && competitionSetting.advancedParentSettings?.length > 0) ? atob(competitionSetting.advancedParentSettings) : undefined;
                return {
                    current: (decoded.length > 0 ? JSON.parse(decoded) : {}) as T,
                    parent: (decodedParent && decodedParent.length > 0 ? JSON.parse(decodedParent) : undefined) as T,
                    serviceName: sName
                }
            })
        }
    });

    return toReturn;
}

//by defautl for tables
export async function getCompetitionsAdvancedTable<T extends IServiceCompetitionAdvancedTableRules | IServiceCompetitionAdvancedOrdonancementRules>(dispatchNotification?: React.Dispatch<IServiceTableNotification>
    , id?: number
    , serviceName?: TConfigurationAdvancedService): Promise<{ current: T, parent: T }> {

    let sName: TConfigurationAdvancedService = "ServicesTables";
    if (serviceName) sName = serviceName;
    //NOTE : error are not display because it's normal if config does not exist
    type TAdvancedSetting = {
        advancedSettings: string | undefined,
        advancedParentSettings: string | undefined,
        serviceName: string
    };
    let result = await getApiObject<{
        succeed: boolean,
        data: TAdvancedSetting
    }>(`/competitions/${id}/settings/${sName}`,
        null, {
        succeed: false,
        data: {
            advancedSettings: "",
            advancedParentSettings: "",
            serviceName: sName
        }
    }, dispatchNotification,
        (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions").then((result) => {
            return result.data;
        });
    let decoded = (result.advancedSettings && result.advancedSettings?.length > 0) ? atob(result.advancedSettings) : "{}";
    let decodedParent = (result.advancedParentSettings && result.advancedParentSettings?.length > 0) ? atob(result.advancedParentSettings) : undefined;

    return {
        current: decoded.length > 0 ? JSON.parse(decoded) : {},
        parent: decodedParent && decodedParent.length > 0 ? JSON.parse(decodedParent) : undefined,

    }
}

export async function updateCompetition(dispatchNotification: React.Dispatch<IServiceTableNotification>, competition: IApiCompetitionModel, host?: string, token?: string): Promise<IApiCompetitionModel> {
    return await patchApiObject<{
        succeed: boolean,
        data: IApiCompetitionModel
    }>(
        `/competitions/${competition.id}`,
        competition
        , { succeed: false, data: emptyCompetitionModel }
        , dispatchNotification,
        host ? host : (window as any)['REACT_APP_API_COMP_URL'], undefined, token).then((result) => {
            return result.data;
        })
}



export async function updateCompetitionAdvanced<T>(dispatchNotification: React.Dispatch<IServiceTableNotification>
    , competitionId: number
    , service: TConfigurationAdvancedService
    , advanced: T): Promise<IServiceCompetitionAdvancedTableRules> {
    let result = await putApiObject<{
        succeed: boolean,
        data: {
            advancedSettings: string,
            serviceName: string
        }
    }>(
        `/competitions/${competitionId}/settings/${service}`,
        null,
        btoa(JSON.stringify(advanced)),
        {
            succeed: false,
            data: { advancedSettings: "", serviceName: "ServicesTables" }
        }, dispatchNotification,
        (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions").then((result) => {
            return result.data;
        })
    console.log(result);
    let decoded = JSON.parse(atob(result.advancedSettings));
    return decoded;
}

export async function updateCompetitionAdvancedTableEncoded(
    dispatchNotification: React.Dispatch<IServiceTableNotification>,
    competition: IApiCompetitionModel,
    advancedSettings: string,
    serviceName: string
    , host?: string, token?: string
): Promise<IServiceCompetitionAdvancedTableRules> {
    let result = await postApiObject<{
        succeed: boolean,
        data: {
            advancedSettings: string,
            serviceName: string
        },
        status: number
    }>(
        `/competitions/${competition.id}/settings/${serviceName}`,
        advancedSettings,
        {
            succeed: false, status: 400,
            data: { advancedSettings: "", serviceName: serviceName }
        }, dispatchNotification,
        host ? host : (window as any)['REACT_APP_API_COMP_URL'], "error.backend.competitions", token).then((result) => {
            return result.data;
        })
    console.log(result);
    let decoded = JSON.parse(atob(result.advancedSettings));
    return decoded;
}

export async function getCompetitionsMap(dispatchNotification: React.Dispatch<IServiceTableNotification>
    , fromDate: Date | undefined,
    authentication: IAuthentication): Promise<Map<number, IApiCompetitionModel>> {
    let competitionsMap: Map<number, IApiCompetitionModel> = new Map();
    let competitionList = await getCompetitionsDeprecated(dispatchNotification, "competitionANDgroup", {
        fromDate: fromDate,
        username: (authentication.scopes.includes("cnkdr_admin") || authentication.scopes.includes("cnkdr_superadmin")) ? undefined : authentication.name
    });
    for (let index = 0; index < competitionList.length; index++) {
        let c = competitionList[index];
        competitionsMap.set(c.id, c);
    }
    return competitionsMap;
}

export async function getShiajos(dispatchNotification: React.Dispatch<IServiceTableNotification>, compid: number, host?: string, token?: string): Promise<IApiCompetitionShiajoModel[]> {
    return getApiObject<{
        succeed: boolean,
        data: Array<IApiCompetitionShiajoModel>
    }>("/competitions/" + compid + "/shiaijos", null, {
        succeed: false,
        data: []
    }, dispatchNotification,
        host ?? (window as any)['REACT_APP_API_COMP_URL'], undefined, token).then((result) => {
            return result.data;
        })
}

export async function getShiajo(dispatchNotification: React.Dispatch<IServiceTableNotification>, shiaijoid: number, host?: string, token?: string)
    : Promise<IApiCompetitionShiajoModel | undefined> {
    return getApiObject<{
        succeed: boolean,
        data: IApiCompetitionShiajoModel | undefined
    }>("/shiaijos", shiaijoid, {
        succeed: false,
        data: undefined
    }, dispatchNotification,
        host ?? (window as any)['REACT_APP_API_COMP_URL'], undefined, token).then((result) => {
            return result.data;
        })
}

export async function patchShiajo(dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number, shiaijo: IApiCompetitionShiajoModel)
    : Promise<IApiCompetitionShiajoModel | undefined> {
    return patchApiObject<{
        succeed: boolean,
        data: IApiCompetitionShiajoModel | undefined
    }>("/competitions/" + competitionId + "/shiaijos/" + shiaijo.id, shiaijo,
        {
            succeed: false,
            data: undefined
        }, dispatchNotification,
        (window as any)['REACT_APP_API_COMP_URL']).then((result) => {
            //TODO  : voir pourquoi pas de resultat (204)
            return shiaijo;
        })
}

export async function getResults(dispatchNotification: React.Dispatch<IServiceTableNotification>, compid: number, host?: string, token?: string): Promise<IApiCompetitionResultModel[]> {
    return getApiObject<{
        succeed: boolean,
        data: Array<IApiCompetitionResultModel>
    }>("/competitions/" + compid + "/results", null, {
        succeed: false,
        data: []
    }, dispatchNotification,
        host ?? (window as any)['REACT_APP_API_COMP_URL'], undefined, token).then((result) => {
            return result.data;
        })
}

export async function postResult(dispatchNotification: React.Dispatch<IServiceTableNotification>, compid: number, result: TResult, teamId: number | undefined
    , fighterId: number | undefined, host?: string, token?: string): Promise<IApiCompetitionResultModel> {
    return postApiObject<{
        succeed: boolean,
        status: number,
        data: IApiCompetitionResultModel
    }>("/competitions/" + compid + "/results", {
        result: result,
        teamId: teamId ? parseInt(teamId.toString()) : undefined, //is not a number, don't knwow why
        fighterId: fighterId ? parseInt(fighterId.toString()) : undefined //is not a number, don't knwow why
    }, {
        succeed: false,
        status: 400,
        data: emptyIApiCompetitionResultModel
    }, dispatchNotification,
        host ?? (window as any)['REACT_APP_API_COMP_URL'], undefined, token).then((result) => {
            return result.data;
        })
}

export async function deleteResults(dispatchNotification: React.Dispatch<IServiceTableNotification>, compid: number, host?: string, token?: string): Promise<void> {
    return deleteApiObject<{
        succeed: boolean
    }>("/competitions/" + compid + "/results", null, {
        succeed: false
    }, dispatchNotification,
        host ?? (window as any)['REACT_APP_API_COMP_URL'], undefined, token).then((result) => {
        })
}

export async function deleteResult(dispatchNotification: React.Dispatch<IServiceTableNotification>, resultId: number, host?: string, token?: string): Promise<void> {
    return deleteApiObject<{
        succeed: boolean
    }>("/results", resultId, {
        succeed: false
    }, dispatchNotification,
        host ?? (window as any)['REACT_APP_API_COMP_URL'], undefined, token).then((result) => {
        })
}

export async function postShiajos(dispatchNotification: React.Dispatch<IServiceTableNotification>, compid: number, shiaijo: IApiCompetitionShiajoModel, host?: string, token?: string): Promise<IApiCompetitionShiajoModel> {
    return postApiObject<{
        succeed: boolean,
        data: IApiCompetitionShiajoModel,
        status: number
    }>("/competitions/" + compid + "/shiaijos", shiaijo,
        {
            succeed: false,
            data: emptyApiCompetitionShiajoModel,
            status: 400
        }, dispatchNotification,
        host ? host : (window as any)['REACT_APP_API_COMP_URL'], undefined, token).then((result) => {
            return result.data;
        })
}

export async function getShiajosMap(dispatchNotification: React.Dispatch<IServiceTableNotification>, compid: number): Promise<Map<number, IApiCompetitionShiajoModel>> {
    let shiajoMaps: Map<number, IApiCompetitionShiajoModel> = new Map();
    let shiajoList = await getShiajos(dispatchNotification, compid);
    for (let index = 0; index < shiajoList.length; index++) {
        let c = shiajoList[index];
        shiajoMaps.set(c.id, c);
    }
    return shiajoMaps;
}

export interface ICompTreeRank {
    first: IApiFighterFightTeam | undefined,
    second: IApiFighterFightTeam | undefined,
    third: Array<IApiFighterFightTeam>,
    fightingSpirit: Array<IApiFighterFighter>
}

export async function getTreeRank(dispatchNotification: React.Dispatch<IServiceTableNotification>, compid: number): Promise<ICompTreeRank> {
    return getResults(dispatchNotification, compid).then(async (res) => {
        let rank: ICompTreeRank = {
            first: undefined,
            second: undefined,
            third: [],
            fightingSpirit: [],
        }
        for (let index = 0; index < res.length; index++) {
            const result = res[index];

            const fightTeam = res[index].teamId ? await getFightersTeam(dispatchNotification, res[index].teamId as number) : undefined;
            const fighter = res[index].fighterId ? await getFightersFighter(dispatchNotification, res[index].fighterId as number) : undefined;
            const rankResult = {
                ...res[index],
                fightTeam: fightTeam,
                fighter: fighter
            };
            switch (result.result) {
                case "fighting spirit":
                    if (rankResult.fighter) rank.fightingSpirit.push(rankResult.fighter);
                    break;
                case "first":
                    if (rankResult.fightTeam) rank.first = rankResult.fightTeam;
                    break;
                case "second":
                    if (rankResult.fightTeam) rank.second = rankResult.fightTeam;
                    break;
                case "third":
                    if (rankResult.fightTeam) rank.third.push(rankResult.fightTeam);
                    break;
            }

        }
        return rank;
    })
}
