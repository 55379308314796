import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { IApiCompetitionModel } from '../../Model/ApiCompetitionModel';
import { ERoundEventTypeModel } from '../../util/ApiModel/ApiEventModel';
import { EFightType } from '../../util/ApiModel/ApiFightModel';
import { ERoundStatus, IApiRoundModel } from '../../util/ApiModel/ApiRoundModel';
import { ChronoDisplay, ChronoState } from '../Util/Chronometer';
import { IServiceTableNotification } from '../Util/ErrorMessage';
import { RoundAutomaticResolveButton } from './RoundAutomaticResolveButton';
import { RoundFighterButtons } from './RoundFighterButtons';
import { EFighterColor, IActionEvent } from './Shiajo';

var debug = require('debug')('ServiceTablesMinimalFrontend:RoundAction')

export interface RoundActionProps {
    competition: IApiCompetitionModel,
    round: IApiRoundModel,
    addEvent(event: IActionEvent, loadRound?: boolean): Promise<[IApiRoundModel, boolean]>,
    fightType: EFightType,
    withEncho: boolean,
    reload?: () => void,
    dispatchNotification: React.Dispatch<IServiceTableNotification>,
    chronoState?: ChronoState,
    simpleActions: boolean
}






export function RoundAction(props: RoundActionProps) {
    let actionRed = function (event: ERoundEventTypeModel) {
        if (props.simpleActions) {
            props.addEvent(

                { fighterColor: null, type: ERoundEventTypeModel.PAUSE }, false).then(([round, succeed]) => {
                    props.addEvent({ fighterColor: EFighterColor.red, type: event }, false).then(([round, succeed]) => {
                        props.addEvent(
                            { fighterColor: null, type: ERoundEventTypeModel.START }, true).then(([round, succeed]) => {
                                //if not succeed, reload because not reloaded previously
                                if ((!succeed) && props.reload) {
                                    props.reload();
                                }
                            })
                    });
                });
        }
        else {
            props.addEvent({ fighterColor: EFighterColor.red, type: event })
        }

    }
    let actionWhite = function (event: ERoundEventTypeModel) {
        if (props.simpleActions) {
            props.addEvent(
                { fighterColor: null, type: ERoundEventTypeModel.PAUSE }, false).then(([round, succeed]: [IApiRoundModel, boolean]) => {
                    props.addEvent({ fighterColor: EFighterColor.white, type: event }, false).then(([round, succeed]: [IApiRoundModel, boolean]) => {
                        props.addEvent(
                            { fighterColor: null, type: ERoundEventTypeModel.START }, true).then(([round, succeed]) => {
                                //if not succeed, reload because not reloaded previously
                                if ((!succeed) && props.reload) {
                                    props.reload();
                                }
                            })
                    });
                });
        }
        else {
            props.addEvent({ fighterColor: EFighterColor.white, type: event })
        }
    }

    let roundAction = null;
    let startButton = <Button onClick={(e: any) => props.addEvent(
        { fighterColor: null, type: ERoundEventTypeModel.START })}>Start</Button>;
    let pauseButton = <Button onClick={(e: any) => props.addEvent(
        { fighterColor: null, type: ERoundEventTypeModel.PAUSE })}>Pause</Button>;
    let enchoButton = <Button onClick={(e: any) => props.addEvent(
        { fighterColor: null, type: ERoundEventTypeModel.ENCHO })}>Encho</Button>;
    let ipponShobuButton = <Button onClick={(e: any) => props.addEvent(
        { fighterColor: null, type: ERoundEventTypeModel.ENCHO })}>Ippon Shobu</Button>;
    let endButton = <Button onClick={(e: any) => props.addEvent(
        { fighterColor: null, type: ERoundEventTypeModel.END })}>End</Button>;
    let pauseEndButton = <Button onClick={(e: any) => {
        props.addEvent(
            { fighterColor: null, type: ERoundEventTypeModel.PAUSE }, false).then(([round, succeed]) => {
                if (succeed) {
                    props.addEvent(
                        { fighterColor: null, type: ERoundEventTypeModel.END }, true)
                }
                else if (props.reload){
                    props.reload();
                }
            });
    }
    }> End</Button >;


    let isEncho = (props.round?.events?.filter(event => { return event.type === ERoundEventTypeModel.ENCHO }).length ?? 0) > 0;
    let reloadButton = (props.reload ? <img className="shiajo-reload" src={require('../../images/reload.png')} onClick={props.reload} alt="reload" /> : <></>);

    switch (props.round.status) {
        case ERoundStatus.WAIT:
            if (props.competition.martialArt !== 'IAIDO' && props.fightType === EFightType.ADDITIONAL && !isEncho) {
                roundAction = <Col md={4}>{ipponShobuButton}</Col>
            }
            else {

                roundAction = <Row><Col md={12}>{startButton}</Col></Row>;
            }
            break;
        case ERoundStatus.INPROGRESS:
            if (!props.simpleActions) {
                roundAction = <Row>
                    <Col md={4}></Col>
                    <Col md={4}>{pauseButton}</Col>
                    <Col md={2}></Col>
                    <Col md={2}>{reloadButton}</Col>
                </Row>;
            }
            else {

                roundAction = <Row>
                    <Col md={4}></Col>
                    <Col md={4}>{pauseEndButton}</Col>
                    <Col md={2}></Col>
                    <Col md={2}>{reloadButton}</Col>
                </Row>;
            }
            break;
        case ERoundStatus.PAUSE:
            if (isEncho || !props.withEncho) {
                roundAction = <Row>
                    <Col md={2}></Col>
                    <Col md={4}>{startButton}</Col>
                    <Col md={4}>{endButton}</Col>
                    <Col md={2}>{reloadButton}</Col>
                </Row>;
            }
            else {
                roundAction = <Row>

                    <Col md={3}>{startButton}</Col>
                    <Col md={4}>{enchoButton}</Col>
                    <Col md={3}>{endButton}</Col>
                    <Col md={2}>{reloadButton}</Col>
                </Row>;
            }

            break;
    }

    let redFighterButton = null;



    let whiteFighterButton = null;


    switch (props.round.status) {
        case ERoundStatus.WAIT:
            if ((props.round.events?.length ?? 0) === 0) {
                redFighterButton = (props.round.redFighterId > 0) ? null : <RoundAutomaticResolveButton
                    reload={async (round: IApiRoundModel) => {
                        if (props.reload) props.reload();
                    }}
                    dispatchNotification={props.dispatchNotification}
                    round={props.round} />;
                whiteFighterButton = (props.round.whiteFighterId > 0) ? null : <RoundAutomaticResolveButton
                    dispatchNotification={props.dispatchNotification}
                    reload={async (round: IApiRoundModel) => {
                        if (props.reload) props.reload();
                    }}
                    round={props.round} />;
            }
            break;
        case ERoundStatus.INPROGRESS:
            if (props.simpleActions) {

                redFighterButton = <RoundFighterButtons dispatchNotification={props.dispatchNotification}
                    round={props.round} key="red" fighterColor={EFighterColor.red}
                    reload={() => { if (props.reload) props.reload() }}
                    action={actionRed}
                    competition={props.competition} />;


                whiteFighterButton = <RoundFighterButtons dispatchNotification={props.dispatchNotification}
                    round={props.round} key="white" fighterColor={EFighterColor.white}
                    reload={() => { if (props.reload) props.reload() }}
                    action={actionWhite} competition={props.competition} />;
            }
            break;
        case ERoundStatus.PAUSE:

            redFighterButton = <RoundFighterButtons dispatchNotification={props.dispatchNotification}
                round={props.round} key="red" fighterColor={EFighterColor.red}
                reload={() => { if (props.reload) props.reload() }}
                action={actionRed}
                competition={props.competition} />;


            whiteFighterButton = <RoundFighterButtons dispatchNotification={props.dispatchNotification}
                round={props.round} key="white" fighterColor={EFighterColor.white}
                reload={() => { if (props.reload) props.reload() }}
                action={actionWhite} competition={props.competition} />;

            break;
    }
    return <>
        <Row style={
            {
                margin: "2px"
            }
        }>
            <Col md={12}>
                {roundAction}
            </Col>
        </Row>
        <Row>
            <Col md={5}>
                {redFighterButton}
            </Col>
            <Col md={2}>
                {props.chronoState && <ChronoDisplay {...props.chronoState} />}
            </Col>
            <Col md={5}>
                {whiteFighterButton}
            </Col>
        </Row>
    </>
}