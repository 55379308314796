import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";
import { PulseLoader } from 'react-spinners';
export function LoadingIndicator(props: { area?: string }): JSX.Element | null {
  const { promiseInProgress } = usePromiseTracker({ delay: 500, area: props.area });

  /*return <>Loading...</>*/
  return (
    promiseInProgress ? <div
      style={{
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <PulseLoader  color="#2BAD60" size={15} />
    </div> : null
  );
}