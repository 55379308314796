import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IApiCompetitionShiajoModel, getShiajos } from '../../util/ApiCompetition';
import { IFightTeamState } from '../../util/ApiModel/ApiFightModel';
import { translateFightgroupLabel } from '../Fight/FightGroupNodeLabel';
import { getApiObject } from '../Operation/GenericOperation';
import { IApiShiajoScoredModel, IApiShiajoScoredResult, emptyFightgroupsScored } from "../Table/SummaryFightGroupTable";
import { IServiceTableNotification } from '../Util/ErrorMessage';
import { useSseSecureClient } from '../Util/SseSecureClient';


function ScreenPoolListFigthGroupRow(props: {
    first: boolean,
    fg: IApiShiajoScoredModel,
    style: CSSProperties
}) {
    const teamList: Array<IFightTeamState> = props.fg.fights.reduce((accu, f) => {
        if (!accu.find(t => t.id === f.redFightTeam.id)) accu.push(f.redFightTeam);
        if (!accu.find(t => t.id === f.whiteFightTeam.id)) accu.push(f.whiteFightTeam);
        return accu;
    }, [] as Array<IFightTeamState>);
    
    const label = translateFightgroupLabel(props.fg);
    
    const content = <>
        <TableCell className='screen-poollist-cell' style={props.style}>
            {
                teamList.map((t, k) =>
                    <React.Fragment key={t.name}>
                        {k > 0 ? " VS " : undefined}
                        <strong>{t.name}</strong>
                    </React.Fragment>)
            }
        </TableCell>
        <TableCell className='screen-poollist-cell' style={props.style}>
            {label.pos.length === 0 && <>{props.fg.label}</>}
            {label.pos.length > 0 && <>{label.pos}/{label.depthToShow}</>}
        </TableCell>
    </>;
    if (props.first) {
        return content;

    }
    else {
        return <TableRow className='screen-poollist-row'>{content}</TableRow>
    }
}



export function ScreenPoolList(props: {
    competitionIds: Array<number>,
    dispatchNotification: React.Dispatch<IServiceTableNotification>
}) {
    const [shiajos, setShiajos] = useState<IApiCompetitionShiajoModel[] | undefined>()
    const [fightGroups, setFightgroups] = useState<IApiShiajoScoredModel[] | undefined>()
    const [reloadTs, setReloadTs] = useState<number>(Date.now());
    const onMessage = useCallback((data: any) => {
        setReloadTs(Date.now());
    }, []);
    const arrayRef = useRef(null);
    const totalLines = fightGroups?.reduce((count, fg) => {
        return count + (fg.commit ? 1 : 0)
    }, 0) ?? 0;

    const [setSseFilter, sseConnected, connectedIcon] = useSseSecureClient(
        "screenPoolList",
        "competitions",
        onMessage,
        props.dispatchNotification,
        true
    );

    const [arrayTransform, setArrayTransform] = useState<{
        scroll: number,
    }>({
        scroll: 0
    });

    useEffect(() => {
        setSseFilter(null);
    }, [setSseFilter])

    const SVG_TRANSFORM_INTERVAL = 2000;
    useEffect(() => {
        let i: NodeJS.Timer | undefined = undefined;
        if (i === undefined && arrayRef.current && window.innerHeight < (arrayRef?.current as any)?.clientHeight) {
            i = setInterval(() => {
                setArrayTransform(s => {
                    let newScroll = (s?.scroll ?? 0) + 50;
                    if (newScroll > ((window.innerHeight - (arrayRef?.current as any)?.clientHeight + 100) )) newScroll = 0;
                    return {
                        ...s,
                        scroll: newScroll
                    };
                })
            }, SVG_TRANSFORM_INTERVAL)
        }
        else {
            if (arrayTransform.scroll > 0) {
                setArrayTransform((s) => {
                    return {
                        ...s,
                        scrool: 0
                    }
                });
            }
        }
        return () => {
            if (i !== undefined) {
                clearInterval(i);
                i = undefined;
            }
        }
    }, [arrayTransform.scroll, totalLines]);

    useEffect(() => {
        (async () => {
            let allFgs: Array<IApiShiajoScoredModel> = [];

            for (const id of props.competitionIds) {
                //TODO : boucle pas necessaire ?
                const fgs = await getApiObject<IApiShiajoScoredResult>("/FightGroups?score=false&competitionIds="
                    + id//+ getSelection().selectedCompetition.join(",")
                    , null, emptyFightgroupsScored
                    , props.dispatchNotification)
                    .then((result: IApiShiajoScoredResult) => {

                        return result.data;
                    })
                allFgs.push(...fgs);
            }
            setFightgroups(allFgs);
        })();

    }, [props.competitionIds, props.dispatchNotification, reloadTs]);

    useEffect(() => {
        (async () => {
            let allShiaijos: Array<IApiCompetitionShiajoModel> = [];
            for (const id of props.competitionIds) {
                let shs = await getShiajos(props.dispatchNotification, id).then(r => {
                    return r;
                })
                allShiaijos.push(...shs);
            }
            setShiajos(allShiaijos)
        })();
    }, [props.competitionIds, props.dispatchNotification])

    const fgMap: Map<number, Array<IApiShiajoScoredModel>> = (fightGroups?.reduce((accu, f) => {
        if (!accu.get(f.shiajo.externalId)) {
            accu.set(f.shiajo.externalId, []);
        }
        accu.get(f.shiajo.externalId)?.push(f);

        return accu;
    }, new Map() as Map<Number, Array<IApiShiajoScoredModel>>)) ?? new Map();


    const lineStyle: CSSProperties = useMemo(() => {
        return {
            fontSize: 16 - Math.floor(totalLines / 30),
            textAlign: 'center'
        }
    }, [totalLines]);

    const lineStyleTop: CSSProperties = useMemo(() => {
        return {
            ...lineStyle,
            borderTop: 'solid'
        }
    }, [lineStyle]);
    return <div style={{
        'overflow': 'hidden'
    }}>
        <Table className='screen-poollist-table' padding='none' ref={arrayRef} style={{
            borderBottom: 'solid',
            borderRight: 'solid',
            marginTop: `${-1 * arrayTransform.scroll}px`
        }}>
            <TableBody>
                <TableRow className='screen-poollist-row' style={{
                    textAlign: 'center',
                    border: 'solid'
                }}>
                    <TableCell className='screen-poollist-cell' >
                        {<Typography variant="h5">
                            Shiaijo
                            {connectedIcon}
                        </Typography>}
                    </TableCell>
                    <TableCell className='screen-poollist-cell' >
                        <Typography variant="h5">
                            Combattants
                        </Typography>
                    </TableCell>
                    <TableCell className='screen-poollist-cell' >
                        <Typography variant="h5">
                            Combat
                        </Typography>

                    </TableCell>
                </TableRow>

                {[...fgMap.entries()]?.sort(([id1, item1], [id2, item2]) => {
                    const sh1 = shiajos?.find(s => s.id === id1);
                    const sh2 = shiajos?.find(s => s.id === id2);
                    return (sh1?.order ?? 0) - (sh2?.order ?? 0);
                })?.map(([key, fglist]) => {
                    //Todo : utiliser le fightgroupnodeid très moyen, mais fait pour ne pas prendre de risque pour l'open de france 2023
                    //a remplacer par un filtre sur le fightorder (mais qui n'est pas à date présent dans l'objet fightgroup)
                    const fglistFiltererd = fglist.filter(fg => fg.commit === null).sort((f1, f2) => f1.depth === f2.depth ? f1.fightGroupNodeId - f2.fightGroupNodeId : (f2?.depth ?? 0) - (f1?.depth ?? 0));

                    return <React.Fragment key={key}>
                        <TableRow className='screen-poollist-row' >
                            <TableCell className='screen-poollist-cell'
                                rowSpan={fglistFiltererd.length > 0 ? fglistFiltererd.length : 1}
                                style={{
                                    border: 'solid'
                                }}
                            >
                                <Typography variant="h3">
                                    &nbsp;{shiajos?.find(s => s.id === key)?.shortname}
                                </Typography>
                            </TableCell>
                            {fglistFiltererd.length > 0 && <ScreenPoolListFigthGroupRow
                                key={fglistFiltererd[0].id}
                                first={true}
                                fg={fglistFiltererd[0]}
                                style={lineStyleTop}

                            />}
                            {fglistFiltererd.length === 0 && <>
                                <TableCell
                                    className='screen-poollist-cell' colSpan={2}></TableCell>
                            </>}
                        </TableRow>
                        {fglistFiltererd.slice(1).map((fg, k) => {
                            return <ScreenPoolListFigthGroupRow
                                key={fg.id}
                                style={lineStyle}
                                first={false}
                                fg={fg} />
                        })}
                    </React.Fragment>
                })
                }
            </TableBody>
        </Table >
    </div>;
}