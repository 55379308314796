import React, { MenuItem, Select, SelectChangeEvent } from "@mui/material";

export function SelectDiscipline(props: {
    onChange: (e:SelectChangeEvent<any>) => void,
    value: string
}) {
    return <Select id="importFromLicencie" name="importFromLicencie" value={props.value}
        onChange={props.onChange}>
        <MenuItem value=""></MenuItem>
        <MenuItem value="KENDO">KENDO</MenuItem>
        <MenuItem value="IAIDO">IAIDO</MenuItem>
        <MenuItem value="NAGINATA">NAGINATA</MenuItem>
        <MenuItem value="SPORT CHANBARA">SPORT CHANBARA</MenuItem>
        <MenuItem value="KYUDO">KYUDO</MenuItem>
    </Select>
}