import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IApiCompetitionModel } from "../../Model/ApiCompetitionModel";
import { SummaryFightGroupTable } from '../Table/SummaryFightGroupTable';
import { useSelectCompetition } from '../Use/UseSelectCompetition';
import { useErrorMessage } from '../Util/ErrorMessage';

var debug = require('debug')('ServiceTablesMinimalFrontend:MainTable')




export interface ICompetitionArchiveResult {
    competitionId: number,
    fightGroupsArchived: number;
    fightGroupTotal: number;
    fightGroupeArchiveLaunched: number;
    fightGroupArchiveRemoteSync: number; //is fightgroup remote synchronized
    completed: boolean;
    loaded: boolean;
    competition: IApiCompetitionModel
    //interval: undefined | NodeJS.Timeout;
}




export function MainTable() {
    const { t, i18n } = useTranslation();
    const [dispatchNotification, errorComponent] = useErrorMessage();
    
    
    const [selectCompetition, competitionSummaryId, fightFinished] = useSelectCompetition({ dispatchNotification: dispatchNotification });
    
    
    const [showActions, setShowActions] = useState(false);

    //alert((window as any)['REACT_APP_API_NOTIFICATION'] + `/register/sse/fights/stream`);
    /*
    let shiajoList:Array<{
        id: number,
        fightGroupNodeIdList: Array<number>
    }> = [];
    
    if (schedullerData) {
        schedullerData.shiaijo.forEach((item, key) => {
            Object.getOwnPropertyNames(item).forEach((value:string) => {
                let shiajo:{ 
                    id: number,
                    fightGroupNodeIdList: Array<number>} = {
                        id:Number(value),
                        fightGroupNodeIdList:item[value]
                }
                shiajoList.push(shiajo);
            });
        })
        debug(shiajoList);
    }*/

    

    return <Container fluid>

        <Row>
            <Col md={12}>
                {errorComponent}
            </Col>
        </Row>
        <Row>
            <Col md={12}>
                <Typography variant="h3" component="div" gutterBottom>
                    {t("label.title.maintable")}
                </Typography>
            </Col>
        </Row>


        <Row>
            <Col md={12}>
                {selectCompetition}
            </Col>
        </Row>

        {/* #### ACTIONS ###*/}
        {(competitionSummaryId !== undefined && competitionSummaryId > 0) && <>        
            <Row>
                <Col md={4}>
                    <h2>Fights groups</h2>
                </Col>
                <Col md={10}></Col>
            </Row>
            <Row>
                <Col md={12}>
                    {(competitionSummaryId > 0) && <SummaryFightGroupTable dispatchNotification={dispatchNotification} competitionId={competitionSummaryId} />}
                </Col>
            </Row>
        </>
        }
    </Container >
}