import { BroadcastChannel } from 'broadcast-channel';
import { useEffect, useState } from "react";

export type TScreenMessage = {

}


export interface IShiajoScreenBroadcastEvent<T> {
    shiajoId: number | 'OFFLINE' | undefined;
    onMessage: (data: T) => void;
}

export function useShiajoScreenBroadcastEvent<T>(params: IShiajoScreenBroadcastEvent<T>) {
    const [channel, setChannel] = useState<BroadcastChannel<TScreenMessage> | undefined>(undefined);

    useEffect(() => {
        let mounted = true;
        const channelName = `${params.shiajoId ?? 'OFFLINE'}`
        if (channel?.name !== channelName) {
            (async () => {
                if (channel) {
                    await channel.close();
                }
                if (mounted) {
                    setChannel(new BroadcastChannel(channelName))
                }
                else {
                    setChannel(undefined);
                }
            })();
        }
        return () => {
            mounted = false;
        }
    }, [channel, params?.shiajoId]);

    return channel;
}