import React from 'react';
import { useTranslation } from 'react-i18next';
import { IApiFighterModel } from '../../util/ApiModel/ApiFighterModel';
import { FighterLabel, FighterLabelStr } from '../Fighter/FighterLabel';

export interface IFighterProps {
  data: IApiFighterModel;
  editeable?: boolean;
  fighterSelecteableList?: Array<IApiFighterModel>;
  changeFighter: (fighterId: number, previousFighterId: number) => void
  //dispatchFight: React.Dispatch<IFightAction>,
}


export function Fighter(props: IFighterProps) {
  const { t, i18n } = useTranslation();

  /*
  //https://developer.snapappointments.com/bootstrap-select/examples/
  useEffect(() => {
    //bug actuellement quand on met /renleve
    //$("#fighter_select_" + props.data.id).selectpicker();
    //$('#fighter_select_' + props.data.id).css("width", "100%");
    return function cleanup() {
      //$("#fighter_select_" + props.data.id).selectpicker('destroy');
    }
  })*/

 

  let content = <div>{t('label.fighter.none')}</div>;
  if (props.data) {
    if (props.data.id) {
      content = <div><FighterLabel fighter={props.data} /></div>;
    }
    else {
      content = t('label.fighter.none');
    }
  }
  if (props.editeable && props.fighterSelecteableList) {

    content = <div>{content}
      <select
        value={props.data ? props.data.id : 0}
        id={"fighter_select_" + (props.data ? props.data.id : 0)}
        onChange={(event) => {
          /*props.dispatchFight({
            type: "CHANGE_FIGHTER", data: {
              fighterId: event.target.value,
              previousFighterId: props.data ? props.data.id : 0
            }
          });*/
          props.changeFighter(Number(event.target.value), props.data ? props.data.id : 0);
        }}>
        <option key={0} value={Number(0)} >{t('label.fighter.none')}</option>
        {props.fighterSelecteableList.map((f: IApiFighterModel, k: any) => {
          return <option key={k} value={f.id}>{FighterLabelStr(f, false, t)}</option>;
        })}
      </select>
    </div >;
  }
  return content;
}
// className="selectpicker" data-live-search="true"