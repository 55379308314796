
import { Alert, Button, Chip, MenuItem, Select, TextField } from '@mui/material';
import produce from 'immer';
import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { createFightersFighter, createFightersTeams, deleteFightersClear, getFightersFighters, getFightersTeams } from '../../Backend/ApiFighter';
import { IApiCompetitionModel, emptyCompetitionModel } from '../../Model/ApiCompetitionModel';
import { deleteCompetitionSettings, deleteCompetitionShiaijos, deleteResults, getCompetition, getCompetitionsAdvancedAll, getCompetitionsDeprecated, getResults, getShiajos, patchCompetitionsFreeze, postCompetition, postResult, postShiajos, updateCompetition, updateCompetitionAdvancedTableEncoded } from '../../util/ApiCompetition';
import { getDrawExport, importDraw } from '../../util/ApiDraw';
import { TApiFollowingTrees } from '../../util/ApiFollowing';
import { IApiFightGroupModel } from '../../util/ApiModel/ApiFightgroupModel';
import { IApiRoundCreationModel } from '../../util/ApiModel/ApiRoundModel';
import { IApiShiajoModel } from '../../util/ApiModel/ApiShiajoModel';
import { IApiString } from '../../util/ApiModel/Following/ApiCompetitionTree';
import { IAuthentication } from '../Auth/auth';
import { FighterLabelStr } from '../Fighter/FighterLabel';
import { deleteApiObject, getApiObject, postApiObject } from '../Operation/GenericOperation';
import { useReport } from '../Use/UseReport';
import { useSelectedCompetitions } from '../Use/UseSelectCompetitions';
import { getConfigurationSelection, storeSelection } from '../Util/Configuration';
import { useErrorMessage } from '../Util/ErrorMessage';

interface IApiFightgroupModelWithShiaijo extends IApiFightGroupModel { shiajo: IApiShiajoModel };

export function CompetitionImport(props: {
    authentication: IAuthentication
}) {
    const { t, i18n } = useTranslation();
    const [localCompetitions, setLocalCompetitions] = useState<Array<IApiCompetitionModel> | undefined>(undefined);
    const [remoteCompetitions, setRemoteCompetitions] = useState<Array<IApiCompetitionModel> | undefined>(undefined);
    const [competition, setCompetition] = useState<IApiCompetitionModel | undefined>(emptyCompetitionModel);
    const [remoteCompetition, setRemoteCompetition] = useState<IApiCompetitionModel | undefined>(undefined);
    const [dispatchNotification, errorComponent] = useErrorMessage();
    const [repport, dispatchRepport, setPercent] = useReport();
    const [selectedCompetitionsComponent, selectedCompetitions] = useSelectedCompetitions({dispatchNotification: dispatchNotification});
    const dateFilter = new Date((new Date()).getDate() - 30);
    const [remoteAccess, setRemoteAccess] = useState<{ host: string, token: string, tokenStr: string, test: boolean | undefined }>(
        {
            host: window.location.protocol + '//' + window.location.host + "/remote/api"
            , token: ""
            , tokenStr: ""
            , test: undefined
        });
    const [importConfig, setImportConfig] = useState<{
        direction: "toRemote" | "fromRemote",
    }>({
        direction: "fromRemote"
    });
    const [showSummary, setShowSummary] = useState<boolean>(false);
    const [importData, setImportData] = useState<{
        teamMapId?: Map<number, number>,
        fighterMapId?: Map<number, number>,
        shiaijoMapId?: Map<number, number>,
        fightgroupNodeMapId?: Map<number, number>,
        fightgroupTable?: boolean
    }>({

    });


    let addRepportLine = useCallback((line: string, succeed?: boolean) => {
        if (succeed !== undefined) {
            line = line + " : " + (succeed ? "OK" : "FAILED");
        }
        dispatchRepport({ type: 'add', value: line, ts: Date.now() })
    }, [dispatchRepport]);

    let setFrozen = useCallback((freeze: boolean) => {
        if (remoteCompetition) {
            //@ts-ignore
            let host = remoteAccess.host + ((window as any)["REACT_APP_API_COMP_SERVICE"] as string);
            patchCompetitionsFreeze(dispatchNotification, remoteCompetition.id, freeze, host, remoteAccess.token).then(() => {
                getCompetition(dispatchNotification, remoteCompetition.id, host, remoteAccess.token).then(async (data) => {
                    //should be only one here
                    setRemoteCompetition(data);
                });
                getCompetitionsDeprecated(dispatchNotification, "competitionANDgroup", {
                    fromDate: dateFilter,
                    username: (props.authentication.scopes.includes("cnkdr_admin") || props.authentication.scopes.includes("cnkdr_superadmin")) ? undefined : props.authentication.name
                }
                    , remoteAccess.host + (window as any)["REACT_APP_API_COMP_SERVICE"] as string, remoteAccess.token).then(clist => {
                        setRemoteCompetitions(clist);
                    })
            })
        }
    }, [dateFilter, dispatchNotification, remoteAccess.host, remoteAccess.token, remoteCompetition]);

    const checkToken = async (palert: boolean) => {
        return getApiObject<IApiString>("/version", null, {
            succeed: false,
            data: ""
        }, undefined, remoteAccess.host + ((window as any)["REACT_APP_API_COMP_SERVICE"] as string), undefined, remoteAccess.token, "string").then((res) => {
            if (res.succeed) {
                setRemoteAccess(Object.assign({}, remoteAccess, { test: true }));
            }
            else {
                setRemoteAccess(Object.assign({}, remoteAccess, { test: false }));
                if (palert) {
                    alert("Le token n'est plus valide, créez en un autre");
                }
            }
            return res.succeed;
        });
    }

    let checkImportCompetitionStatus = (remoteCompetition: IApiCompetitionModel, localCompetition: IApiCompetitionModel, direction: "fromRemote" | "toRemote") => {
        let result = true;

        if (direction === "fromRemote") {
            switch (remoteCompetition.status) {
                case 'offline':
                    break;
                default:
                    result = false;
            }

            addRepportLine(`Checkin competition remote status`, result);
            if (result) {
                switch (localCompetition.status) {
                    case 'Created':
                    case 'RegistrationOpen':
                    case 'RegistrationClosed':
                    case 'DrawingsDone':
                        break;
                    default:
                        result = false;
                }
                addRepportLine(`Checkin competition local status`, result);
            }
        }
        //TODO check toRemote
        return result;
    };

    let importFighterDrawData = async (
        remoteCompetition: IApiCompetitionModel | undefined
        , localCompetition: IApiCompetitionModel | undefined
        , direction: "fromRemote" | "toRemote") => {

        if (remoteCompetition && localCompetition && await checkToken(true)) {
            setPercent(0);
            dispatchRepport({ type: 'clear', value: "", ts: Date.now() });
            addRepportLine(`Begin fighter import from remote id ${remoteCompetition.id} to local id ${localCompetition.id}`);

            if (remoteCompetition.id > 0 && localCompetition.id > 0 && checkImportCompetitionStatus(remoteCompetition, localCompetition, direction)) {
                setPercent(1);
                //0-29

                //@ts-ignore
                let host = remoteAccess.host + (window["REACT_APP_API_COMBATTANTS_SERVICE"] as string);
                //supprimer les combattants et équipes
                addRepportLine('Clear teams and fighters',
                    await deleteFightersClear(dispatchNotification
                        , direction === "fromRemote" ? localCompetition.id : remoteCompetition.id
                        , direction === "fromRemote" ? undefined : host
                        , direction === "fromRemote" ? undefined : remoteAccess.token));

                let teams = await getFightersTeams(dispatchNotification
                    , direction === "fromRemote" ? remoteCompetition.id : localCompetition.id
                    , direction === "fromRemote" ? host : undefined
                    , direction === "fromRemote" ? remoteAccess.token : undefined);
                addRepportLine('Extracting teams');

                let teamMapId = new Map<number, number>();
                teamMapId.set(0, 0); //none team
                for (let index = 0; index < teams.length; index++) {
                    const element = teams[index];
                    element.competition = String(direction === "fromRemote" ? localCompetition.id : remoteCompetition.id);
                    addRepportLine(`Loading team ${element.name}`);
                    let id = (await createFightersTeams(
                        dispatchNotification
                        , element
                        , direction === "fromRemote" ? undefined : host
                        , direction === "fromRemote" ? undefined : remoteAccess.token
                    )).data;
                    if (id) {
                        teamMapId.set(Number(element.id), id);
                        addRepportLine(`Succeed, old id ${element.id} -> new id ${id}`);
                    } else {
                        addRepportLine(`FAILED !!!!`);
                        return;
                    }
                    //1-10
                    setPercent((index + 1) * 29 / teams.length + 1);
                }

                let fighterMapId = new Map<number, number>();
                //30-59
                addRepportLine('Extracting fighters');
                let fighters = await getFightersFighters(dispatchNotification
                    , direction === "fromRemote" ? remoteCompetition.id : localCompetition.id
                    , direction === "fromRemote" ? host : undefined
                    , direction === "fromRemote" ? remoteAccess.token : undefined);

                for (let index = 0; index < fighters.length; index++) {
                    const element = fighters[index];
                    element.competition = String(direction === "fromRemote" ? localCompetition.id : remoteCompetition.id);
                    let newTeam = teamMapId.get(Number(element.team));
                    if (newTeam) {
                        element.team = newTeam ?? 0;
                        addRepportLine(`Loading fighter ${FighterLabelStr(element, false, t)}`);
                        let id = (await createFightersFighter(
                            dispatchNotification
                            , element
                            , direction === "fromRemote" ? undefined : host
                            , direction === "fromRemote" ? undefined : remoteAccess.token
                        )).data;
                        if (id) {
                            fighterMapId.set(Number(element.id), id);
                            addRepportLine(`Succeed, new id ${id}`);
                        } else {
                            addRepportLine(`FAILED !!!!`);
                            return;
                        }
                    } else {
                        addRepportLine(`No new team found for fighter ${FighterLabelStr(element, false, t)} : team ${element.team}`);
                    }
                    //30-59
                    setPercent((index + 1) * 29 / fighters.length + 30);
                }
                setImportData((i) => {
                    return {
                        ...i,
                        fighterMapId: fighterMapId,
                        teamMapId: teamMapId
                    }
                });
                //change competition status to enable push:
                //direction === "fromRemote" ? localCompetition.status = 'RegistrationClosed' : remoteCompetition.status = 'RegistrationClosed';
                //direction === "fromRemote" ? localCompetition.frozen = false : remoteCompetition.frozen = false;
                //@ts-ignore
                const comphost = remoteAccess.host + (window["REACT_APP_API_COMP_SERVICE"] as string);
                await updateCompetition(dispatchNotification,
                    direction === "fromRemote" ? {
                        ...localCompetition,
                        frozen: false,
                        status: 'RegistrationClosed'
                    } : {
                        ...remoteCompetition,
                        frozen: false,
                        status: 'RegistrationClosed'
                    }
                    , direction === "fromRemote" ? undefined : comphost
                    , direction === "fromRemote" ? undefined : remoteAccess.token
                );

                await patchCompetitionsFreeze(dispatchNotification,
                    direction === "fromRemote" ? localCompetition.id : remoteCompetition.id
                    , false
                    , direction === "fromRemote" ? undefined : comphost
                    , direction === "fromRemote" ? undefined : remoteAccess.token);

                //60-90
                addRepportLine('Extracting draw');
                if (remoteCompetition.id > 0) {
                    //@ts-ignore
                    let host = remoteAccess.host + (window["REACT_APP_API_DRAW_SERVICE"] as string);

                    let drawexport = await getDrawExport(dispatchNotification
                        , direction === "fromRemote" ? remoteCompetition.id : localCompetition.id
                        , direction === "fromRemote" ? host : undefined
                        , direction === "fromRemote" ? remoteAccess.token : undefined);
                    setPercent(70);
                    for (let index = 0; index < drawexport.repartition.length; index++) {
                        const element = drawexport.repartition[index];
                        if (element.idFighter > 0) {
                            let newId = teamMapId.get(element.idFighter)
                            element.idFighter = newId ? newId : 0;
                        }
                    }
                    const imported = await importDraw(
                        dispatchNotification,
                        direction === "fromRemote" ? localCompetition.id : remoteCompetition.id
                        , drawexport
                        , direction === "fromRemote" ? undefined : host
                        , direction === "fromRemote" ? undefined : remoteAccess.token
                    );
                    if (!imported) {
                        addRepportLine(`FAILED !!!!`);
                        return;
                    }
                    // else {
                    //     setPercent(90);
                    //     if (drawexport.tirage.valide) {
                    //         addRepportLine('Tirage validation');
                    //         const validated = await validTirage(dispatchNotification, competition.id);
                    //         if (!validated) {
                    //             addRepportLine(`FAILED !!!!`);
                    //             return;
                    //         }
                    //     }
                    //     else {
                    //         addRepportLine('Tirage is not validated');
                    //     }
                    //     setPercent(100);
                    // }
                }
                //Relock competition
                await patchCompetitionsFreeze(dispatchNotification,
                    direction === "fromRemote" ? localCompetition.id : remoteCompetition.id
                    , true
                    , direction === "fromRemote" ? undefined : comphost
                    , direction === "fromRemote" ? undefined : remoteAccess.token);
            } else {
                addRepportLine(`No local and remote competition provided`, false);
            }

            setPercent(100);

        }
    };



    let importCompetitionData = async (remoteCompetition: IApiCompetitionModel | undefined
        , localCompetitionOrigin: IApiCompetitionModel | undefined
        , direction: "fromRemote" | "toRemote") => {
        if (remoteCompetition && localCompetitionOrigin && await checkToken(true)) {
            setPercent(0);
            //@ts-ignore
            let host = remoteAccess.host + (window["REACT_APP_API_COMP_SERVICE"] as string);
            dispatchRepport({ type: 'clear', value: "", ts: Date.now() });

            addRepportLine(`Begin competition import from remote id ${remoteCompetition.id} to local id ${localCompetitionOrigin.id}`)

            let localCompetition = Object.assign({}, competition);

            //create local competition
            if (remoteCompetition.id > 0 && localCompetition.id === 0) {
                addRepportLine(`Create new local competition`);
                localCompetition = await postCompetition(dispatchNotification, remoteCompetition);
                let config = await getConfigurationSelection();
                config?.selectedCompetition.push(localCompetition.id);
                if (config) storeSelection(config);
            }
            //create remote competition
            if (remoteCompetition.id === 0 && localCompetition.id > 0) {
                addRepportLine(`Create new remote competition`);
                remoteCompetition = await postCompetition(dispatchNotification
                    , localCompetition
                    , host
                    , remoteAccess.token);
            }

            if (remoteCompetition.id > 0 && localCompetition.id > 0 && checkImportCompetitionStatus(remoteCompetition, localCompetition, direction)) {
                //supprimer les shiaijos
                addRepportLine(`Cleaning shiaijos`,
                    await deleteCompetitionShiaijos(dispatchNotification
                        , direction === "fromRemote" ? localCompetition.id : remoteCompetition.id
                        , direction === "fromRemote" ? undefined : host
                        , direction === "fromRemote" ? undefined : remoteAccess.token));

                //supprimer les règles avancées
                addRepportLine(`Cleaning advanced rules`,
                    await deleteCompetitionSettings(dispatchNotification
                        , direction === "fromRemote" ? localCompetition.id : remoteCompetition.id
                        , direction === "fromRemote" ? undefined : host
                        , direction === "fromRemote" ? undefined : remoteAccess.token));

                //récupérer la competition cible
                let targetCompetition = await getCompetition(dispatchNotification
                    , direction === "fromRemote" ? localCompetition.id : remoteCompetition.id
                    , direction === "fromRemote" ? undefined : host
                    , direction === "fromRemote" ? undefined : remoteAccess.token);
                //10

                setPercent(10);
                //Récupérer le status offline et le mettre sur la cible
                direction === "fromRemote" ? targetCompetition.status = targetCompetition.offlineStatus : targetCompetition.status = "offline";

                addRepportLine(`Posting competition data`);
                await updateCompetition(dispatchNotification
                    , targetCompetition
                    , direction === "fromRemote" ? undefined : host
                    , direction === "fromRemote" ? undefined : remoteAccess.token);

                setPercent(20);
                addRepportLine(`Loading remote competition advanced data`);
                let sourceCompetitionAdvanced = await getCompetitionsAdvancedAll(dispatchNotification
                    , direction === "fromRemote" ? remoteCompetition.id : localCompetition.id
                    , direction === "fromRemote" ? host : undefined
                    , direction === "fromRemote" ? remoteAccess.token : undefined);
                setPercent(30);

                for (let index = 0; index < sourceCompetitionAdvanced.length; index++) {
                    const element = sourceCompetitionAdvanced[index];
                    addRepportLine(`Loading advanced for ${element.serviceName}`);
                    await updateCompetitionAdvancedTableEncoded(dispatchNotification
                        , targetCompetition
                        , element.advancedSettings
                        , element.serviceName
                        , direction === "fromRemote" ? undefined : host
                        , direction === "fromRemote" ? undefined : remoteAccess.token
                    );
                    setPercent((index + 1) * 29 / sourceCompetitionAdvanced.length + 30);
                }

                addRepportLine(`Loading shiajos`);
                let nremoteShiajo = await getShiajos(dispatchNotification
                    , direction === "fromRemote" ? remoteCompetition.id : localCompetition.id
                    , direction === "fromRemote" ? host : undefined
                    , direction === "fromRemote" ? remoteAccess.token : undefined);

                let shiaijoMapId: Map<number, number> = new Map();
                for (let index = 0; index < nremoteShiajo.length; index++) {
                    const element = nremoteShiajo[index];
                    addRepportLine(`Loading shiajo ${element.fullname} (${element.id})`);
                    const newShiaijo = await postShiajos(dispatchNotification,
                        direction === "fromRemote" ? localCompetition.id : remoteCompetition.id
                        , element
                        , direction === "fromRemote" ? undefined : host
                        , direction === "fromRemote" ? undefined : remoteAccess.token);
                    shiaijoMapId.set(element.id, newShiaijo.id)
                    setPercent((index + 1) * 29 / nremoteShiajo.length + 60);
                }
                setImportData((i) => {
                    return {
                        ...i,
                        shiaijoMapId: shiaijoMapId
                    }
                });
                setPercent(80);
                //addRepportLine(`Reload local configuration`);
                //addRepportLine(`-TODO`);
                addRepportLine(`Refresh competition list`);

                //update
                if (direction === "fromRemote") {
                    setLocalCompetitions(undefined);
                    setCompetition(localCompetition)
                }
                else {
                    getCompetitionsDeprecated(dispatchNotification, "competitionANDgroup", {
                        fromDate: dateFilter,
                        username: (props.authentication.scopes.includes("cnkdr_admin") || props.authentication.scopes.includes("cnkdr_superadmin")) ? undefined : props.authentication.name
                    }
                        , remoteAccess.host + (window as any)["REACT_APP_API_COMP_SERVICE"] as string, remoteAccess.token).then(clist => {
                            setRemoteCompetitions(clist);
                        })
                    setRemoteCompetition(remoteCompetition);
                }

                setPercent(100);
            } else {
                addRepportLine(`No local and remote competition provided`, false);
            }

        }


    };



    let importFollowing = async (remoteCompetition: IApiCompetitionModel | undefined
        , localCompetition: IApiCompetitionModel | undefined
        , direction: "fromRemote" | "toRemote") => {
        if (remoteCompetition && localCompetition) {
            setPercent(1);
            dispatchRepport({ type: 'clear', value: "", ts: Date.now() });
            addRepportLine(`Begin export tree from remote id ${remoteCompetition.id} to local id ${localCompetition.id}`);
            //remplacer les valeurs des id par les id locaux
            const treeResultSource = await getApiObject<{
                succeed: boolean,
                data: TApiFollowingTrees
            }>(`/export/json?competition=` +
                (direction === "fromRemote" ? remoteCompetition.id : localCompetition.id)
                , null, {
                succeed: false,
                data: []
            }, dispatchNotification,
                direction === "fromRemote" ? remoteAccess.host + (window as any)['REACT_APP_API_SUIVI_SERVICE'] : (window as any)['REACT_APP_API_SUIVI_URL'],
                "error.backend.following",
                direction === "fromRemote" ? remoteAccess.token : undefined);

            if (treeResultSource.succeed) {
                setPercent(30);
                addRepportLine(`Prepare new tree with fighter ids ${remoteCompetition.id} to local id ${localCompetition.id}`);
                //recréer l'abre à partir des informations du suivi
                const newTreeResultTarget = produce(treeResultSource.data, (draft: TApiFollowingTrees) => {
                    for (const treeItem of draft) {
                        //treeItem.fightGroupNodeId = -1;// importData.fightgroupMapId?.get(treeItem.fightGroupNodeId) ?? -1;
                        for (const fightGroupData of treeItem.fightGroupData) {
                            fightGroupData.redTeamId = importData.teamMapId?.get(fightGroupData.redTeamId) ?? -1;
                            fightGroupData.whiteTeamId = importData.teamMapId?.get(fightGroupData.whiteTeamId) ?? -1;
                        }
                        for (const [key, rank] of treeItem.ranking.entries()) {
                            treeItem.ranking[key] = importData.teamMapId?.get(rank) ?? -1;
                        }
                    }
                })
                setPercent(50);
                addRepportLine(`Clear existing competition tree`);
                //supprimer l'abre existant
                await deleteApiObject(`/clear`,
                    (direction === "fromRemote" ? localCompetition.id : remoteCompetition.id),
                    { succeed: false },
                    dispatchNotification,
                    direction === "fromRemote" ? (window as any)['REACT_APP_API_SUIVI_URL'] : remoteAccess.host + (window as any)['REACT_APP_API_SUIVI_SERVICE'],
                    "error.backend.following"
                    , direction === "fromRemote" ? undefined : remoteAccess.token);
                setPercent(70);
                addRepportLine(`Import the new tree`);
                //envoyer le nouvel arbre
                const importResult = await postApiObject(`/create/import?competition=` +
                    (direction === "fromRemote" ? localCompetition.id : remoteCompetition.id), {
                    fights: newTreeResultTarget
                }, {
                    succeed: false,
                    status: 0,
                }, dispatchNotification,
                    direction === "fromRemote" ? (window as any)['REACT_APP_API_SUIVI_URL'] : remoteAccess.host + (window as any)['REACT_APP_API_SUIVI_SERVICE'],
                    "error.backend.following"
                    , direction === "fromRemote" ? undefined : remoteAccess.token);
                setPercent(900);
                addRepportLine(`End of import, getting result`);

                if (importResult.succeed) {
                    await getApiObject<{
                        succeed: boolean,
                        data: TApiFollowingTrees,
                        status: number
                    }>(`/all?competition=` +
                        (direction === "fromRemote" ? localCompetition.id : remoteCompetition.id), null, {
                        succeed: false,
                        data: [],
                        status: 0,
                    }, dispatchNotification,
                        direction === "fromRemote" ? (window as any)['REACT_APP_API_SUIVI_URL'] : remoteAccess.host + (window as any)['REACT_APP_API_SUIVI_SERVICE'],
                        "error.backend.following"
                        , direction === "fromRemote" ? undefined : remoteAccess.token).then((result) => {
                            if (result.succeed) {
                                setImportData((i) => {
                                    return {
                                        ...i,
                                        fightgroupNodeMapId: result.data.reduce((newMap: Map<number, number>, newfg) => {
                                            const sourceFg = treeResultSource.data.find(fg => fg.depth === newfg.depth && fg.fightOrder === newfg.fightOrder);
                                            if (sourceFg) {
                                                newMap.set(sourceFg?.fightGroupNodeId, newfg.fightGroupNodeId);
                                            }
                                            return newMap;
                                        }, new Map())
                                    }
                                })
                            }
                        })
                    setPercent(100);
                    addRepportLine(`End of getting result`);
                }
                else {
                    setImportData((i) => {
                        return {
                            ...i,
                            fightgroupNodeMapId: undefined
                        }
                    });
                }
            }
        }
    };

    let importTableData = async (remoteCompetition: IApiCompetitionModel | undefined
        , localCompetition: IApiCompetitionModel | undefined
        , direction: "fromRemote" | "toRemote") => {
        if (remoteCompetition && localCompetition && await checkToken(true)) {
            //@ts-ignore
            const tableHost = remoteAccess.host + (window as any)['REACT_APP_API_SERVICE'];

            setPercent(0);
            dispatchRepport({ type: 'clear', value: "", ts: Date.now() });
            addRepportLine(`Begin fighter import from remote id ${remoteCompetition.id} to local id ${localCompetition.id}`);

            if (remoteCompetition.id > 0 && localCompetition.id > 0 && checkImportCompetitionStatus(remoteCompetition, localCompetition, direction)) {
                setPercent(1);
                //clear local data
                await deleteApiObject(`/competitions`,
                    (direction === "fromRemote" ? localCompetition.id : remoteCompetition.id)
                    , { succeed: false }
                    , dispatchNotification
                    , direction === "fromRemote" ? undefined : tableHost
                    , undefined
                    , direction === "fromRemote" ? undefined : remoteAccess.token
                );
                setPercent(5);
                //create local shiaijos from update
                const newShiaijos = (await getApiObject<{
                    succeed: boolean,
                    data: Array<IApiShiajoModel>
                }>(`/shiajos?competitionId=${(direction === "fromRemote" ? localCompetition.id : remoteCompetition.id)}`, null, { succeed: false, data: [] }
                    , dispatchNotification
                    , direction === "fromRemote" ? undefined : tableHost
                    , undefined
                    , direction === "fromRemote" ? undefined : remoteAccess.token

                )).data;
                setPercent(10);

                setPercent(20);
                //get export from table
                const tableData = (await getApiObject<{
                    succeed: boolean,
                    data: undefined | {
                        shiaijos: Array<IApiShiajoModel>,
                        fightgroups: Array<IApiFightgroupModelWithShiaijo>
                    }
                }>(`/competitions/${(direction === "fromRemote" ? remoteCompetition.id : localCompetition.id)}/export`, null, {
                    succeed: false,
                    data: undefined
                }, dispatchNotification
                    , direction === "fromRemote" ? tableHost : undefined
                    , direction === "fromRemote" ? remoteAccess.token : undefined
                , undefined)).data;
                //TODO
                setPercent(30);
                let fightgroupMapId = new Map<number, number>();
                let error = false;
                const nbFightGroups = tableData?.fightgroups.length ?? 1
                const nextPercent = 100;
                for (const [fgkey, fg] of tableData?.fightgroups.entries() ?? []) {

                    if (!error) {
                        setPercent(30 + (fgkey * (nextPercent - 30)) / nbFightGroups);

                        //Get localfightgroypeNodeId
                        /*const targetFightgroupNodeId = await getApiObject(`/create/import?competition=` +
                            (direction === "fromRemote" ? localCompetition.id : remoteCompetition.id), {
                            fights: newTreeResult
                        }, {
                            succeed: false,
                            status: 0,
                        }, dispatchNotification,
                            direction === "fromRemote" ? (window as any)['REACT_APP_API_SUIVI_SERVICE'] : remoteAccess.host + (window as any)['REACT_APP_API_SUIVI_SERVICE'],
                            "error.backend.following"
                            , direction === "fromRemote" ? undefined : remoteAccess.token);*/

                        //create local fightgroups from update
                        const shiajoExternalId = importData.shiaijoMapId?.get(fg.shiajo.externalId) ?? -1;
                        const newFg = produce(fg, draft => {
                            //@ts-ignore
                            draft.id = undefined;
                            draft.fightGroupNodeId = importData.fightgroupNodeMapId?.get(fg.fightGroupNodeId) ?? -1;
                            draft.shiajoId = newShiaijos?.find(s => s.externalId === shiajoExternalId)?.id ?? -1;
                            draft.competitionId = (direction === "fromRemote" ? localCompetition.id : remoteCompetition.id);

                            for (const fight of draft.fights) {
                                //@ts-ignore
                                fight.id = undefined;
                                //@ts-ignore
                                fight.redFightTeamExternalId = importData.teamMapId?.get(fight?.redFightTeam.externalId) ?? -1;
                                //@ts-ignore
                                fight.redFightTeamId = undefined;
                                //@ts-ignore
                                fight.redFightTeam = undefined;
                                //red team
                                //fight.redFightTeam.externalId = importData.teamMapId?.get(fight?.redFightTeam.externalId) ?? -1;
                                //@ts-ignore
                                //fight.redFightTeam.id = undefined;
                                //@ts-ignore
                                //fight.redFightTeam.fighters = undefined;
                                //white team
                                //@ts-ignore
                                fight.whiteFightTeamExternalId = importData.teamMapId?.get(fight?.whiteFightTeam.externalId) ?? -1;
                                //fight.whiteFightTeam.externalId = importData.teamMapId?.get(fight?.whiteFightTeam.externalId) ?? -1;
                                //@ts-ignore
                                //fight.whiteFightTeam.id = undefined;
                                //@ts-ignore
                                //fight.whiteFightTeam.fighters = undefined;
                                //@ts-ignore
                                fight.whiteFightTeamId = undefined;
                                //@ts-ignore
                                fight.whiteFightTeam = undefined;
                                fight.order = fight.order ?? 0;

                                fight.rounds = fight.rounds?.map(r => {
                                    const d:IApiRoundCreationModel= {
                                        order: r.order,
                                        combatTime: r.combatTime,
                                        redFighterExternalId : importData.fighterMapId?.get(r.redFighter?.externalId) ?? undefined,
                                        whiteFighterExternalId : importData.fighterMapId?.get(r.whiteFighter?.externalId) ?? undefined,                                        
                                    } 
                                    return d as any //trick to not define a new model for creation;
                                }) ?? undefined;
                            }

                        });

                        //CHECK
                        for (const newF of newFg.fights) {
                            //@ts-ignore
                            if (newF.redFightTeamExternalId === -1) {
                                addRepportLine(`Team not found for fightgroup`, false);
                                error = true;
                            }
                            //@ts-ignore
                            if (newF.whiteFightTeamExternalId === -1) {
                                addRepportLine(`Team  not found for fightgroup`, false);
                                error = true;
                            }
                        }
                        if (newFg.shiajoId < 0) {
                            addRepportLine(`Shiajo ${fg.shiajoId} not found for fightgroup`, false);
                            error = true;
                        }

                        addRepportLine(`Create fightgroup ${newFg.label} (sourceid ${fg.id})`);

                        //POST THE FIGHTGROUP
                        const fgResult = await postApiObject<{
                            succeed: boolean,
                            status: number,
                            data: IApiFightGroupModel | undefined
                        }>(`/shiajos/${shiajoExternalId}/fightgroups`, newFg, { succeed: false, status: 1, data: undefined }
                            , dispatchNotification
                            , direction === "fromRemote" ? undefined : tableHost
                            , undefined
                            , direction === "fromRemote" ? undefined : remoteAccess.token
                        );

                        if (fgResult.succeed) {
                            addRepportLine(`Created fightgroup ${fgResult.data?.id ?? 'undefined'}`);
                            const newFightGroup = fgResult.data;
                            fightgroupMapId.set(fg.id, fgResult.data?.id ?? -1);

                            //create events
                            //for (const fg of tableData?.fightgroups ?? []) {
                            for (const fight of fg.fights) {
                                const newFight = newFightGroup?.fights.find(f => {
                                    return f.redFightTeam.externalId === (importData.teamMapId?.get(fight.redFightTeam.externalId) ?? -1)
                                        && f.whiteFightTeam.externalId === (importData.teamMapId?.get(fight.whiteFightTeam.externalId) ?? -1)
                                        //&& (fight.order === undefined || f.order === fight.order)
                                        && f.rounds?.length === fight.rounds?.length
                                        && (fight.type === undefined || f.type === fight.type)
                                });
                                if (newFight) {
                                    for (const round of fight.rounds ?? []) {


                                        //get the current round
                                        const newRound = newFight?.rounds?.find(r => {
                                            const newRedFighter = newFight.redFightTeam.fighters.find(fi => fi.id === r.redFighterId);
                                            const newWhiteFighter = newFight.whiteFightTeam.fighters.find(fi => fi.id === r.whiteFighterId);

                                            return newRedFighter?.externalId === (round.redFighter ? importData.fighterMapId?.get(round.redFighter.externalId) ?? -1 : undefined)
                                                && newWhiteFighter?.externalId === (round.whiteFighter ? importData.fighterMapId?.get(round.whiteFighter.externalId) ?? -1 : undefined)
                                                //&& (round.order === undefined || r.order === round.order)
                                                && r.events?.length === 0 //ensure not already receive events
                                        })
                                        if (newRound) {
                                            //post the event without check
                                            for (const event of round.events ?? []) {

                                                const newe = await postApiObject(
                                                    `/RoundEvents?noCheck=true`,
                                                    {
                                                        fighterId: event.fighterId ?
                                                            (event.fighterId === round.redFighter?.id ? newRound.redFighterId : newRound.whiteFighterId)
                                                            : undefined,
                                                        type: event.type,
                                                        date: event.date,
                                                        roundId: newRound.id,
                                                    }, { succeed: false, status: 1, data: undefined }, dispatchNotification
                                                    , direction === "fromRemote" ? undefined : tableHost
                                                    , undefined
                                                    , direction === "fromRemote" ? undefined : remoteAccess.token

                                                );

                                                if (newe.succeed) {
                                                    if (!newRound.events) newRound.events = [];
                                                    if (newe.data) newRound.events.push(newe.data);
                                                }
                                                else {
                                                    addRepportLine(`Error while creating event for fightgroup`, false);
                                                    error = true;
                                                }
                                            }
                                        }
                                        else {
                                            addRepportLine(`Error round not found for fightgroup`, false);
                                            error = true;
                                        }


                                    }
                                }
                                //TODO : else error
                                else {
                                    addRepportLine(`Fight not found for fightgroup`, false);
                                    error = true;
                                }

                            }
                            //}
                        }
                    }
                };
                //fin d'import des groupes
                if (!error) {
                    setPercent(100);
                    addRepportLine(`Import succeed`);
                    setImportData((i) => {
                        return {
                            ...i,
                            fightgroupTable: true
                        }
                    });
                }
                else {
                    addRepportLine(`Some import failed`, false);
                    setImportData((i) => {
                        return {
                            ...i,
                            fightgroupTable: false
                        }
                    });
                }
            }
        }
    }

    const importCompetitionEnd = async (remoteCompetition: IApiCompetitionModel | undefined
        , localCompetition: IApiCompetitionModel | undefined
        , direction: "fromRemote" | "toRemote") => {
        if (localCompetition && remoteCompetition) {
            setPercent(0);
            addRepportLine(`Clearing score on target`, true);
            const comphost = remoteAccess.host + ((window as any)["REACT_APP_API_COMP_SERVICE"] as string);
            await deleteResults(dispatchNotification
                , direction === "fromRemote" ? localCompetition.id : remoteCompetition.id
                , direction === "fromRemote" ? undefined : comphost
                , direction === "fromRemote" ? undefined : remoteAccess.token
            );
            setPercent(10);
            addRepportLine(`Geting result from source`, true);
            const listResult = await getResults(dispatchNotification
                , direction !== "fromRemote" ? localCompetition.id : remoteCompetition.id
                , direction !== "fromRemote" ? undefined : comphost
                , direction !== "fromRemote" ? undefined : remoteAccess.token
            )
            setPercent(20);
            addRepportLine(`Importing result on target`, true);
            for (const result of listResult) {
                await postResult(dispatchNotification
                    , direction === "fromRemote" ? localCompetition.id : remoteCompetition.id
                    , result.result
                    , result.teamId ? importData.teamMapId?.get(result.teamId) : undefined
                    , result.fighterId ? importData.fighterMapId?.get(result.fighterId) : undefined
                    , direction === "fromRemote" ? undefined : comphost
                    , direction === "fromRemote" ? undefined : remoteAccess.token
                );
            }
            setPercent(100);
            addRepportLine(`Ending importing result on target`, true);
        }

    }

    useEffect(() => {
        if (localCompetitions === undefined && remoteAccess.test) {

            getCompetitionsDeprecated(dispatchNotification, "competitionANDgroup", {
                fromDate: dateFilter,
                username: (props.authentication.scopes.includes("cnkdr_admin") || props.authentication.scopes.includes("cnkdr_superadmin")) ? undefined : props.authentication.name
            }).then(clist => {
                setLocalCompetitions(clist.filter(c1 => getConfigurationSelection()?.selectedCompetition.find(c => c === c1.id)));
            })
            getCompetitionsDeprecated(dispatchNotification, "competitionANDgroup", {
                fromDate: dateFilter,
                username: (props.authentication.scopes.includes("cnkdr_admin") || props.authentication.scopes.includes("cnkdr_superadmin")) ? undefined : props.authentication.name
            }
                , remoteAccess.host + (window as any)["REACT_APP_API_COMP_SERVICE"] as string, remoteAccess.token).then(clist => {
                    setRemoteCompetitions(clist);//.filter(c1 => getSelection().selectedCompetition.find(c => c.id === c1.id))
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchNotification, localCompetitions, remoteAccess.test])

    const remoteTokenLocalUrl = ((window as any)['CNKDR_MAIN_HOST_TOKEN_LOCAL'])?.length > 0 ? (window as any)['CNKDR_MAIN_HOST_TOKEN_LOCAL'] : "";

    return <>

        <Row>
            <Col md={12}>
                <h1>Import / Export</h1>
            </Col>
        </Row>
        <Row>
            <Col md={12}>
                {selectedCompetitionsComponent}
            </Col>
        </Row>
        {remoteTokenLocalUrl && <>
            <Row>

                <Col md={12}>
                    <Button
                        variant='contained'
                        onClick={() => setShowSummary(!showSummary)}
                    >{t('action.competition.showSummary')}
                    </Button>
                    {showSummary && <p>
                        La page d'import / export vous permet d'import sur votre serveur des competitions issues du serveur central<br />
                        Est désigné par remote le serveur central qui possède la competition à exporter.<br />
                        Au lancement de votre serveur local, celui-ci a normalement été configuré pour que l'api /remote proxifie les appels vers le serveur remote.<br />
                        Voici la procédure :<br />
                        <ul>
                            <li>La compétition remote ne doit pas avoir atteind le status 'drawing published'</li>
                            <li>Allez sur la page import / export sur le serveur remote et copier le token d'accès</li>
                            <li>Allez sur la page import / export sur le serveur local et copiez le token d'accès (attention, il a une durée d'utilisation limitée)</li>
                            <li>Selectionnez l'id de la competition sur le serveur remote (vous pouvez le voir sur la page competition)</li>
                            <li>La compétition remote doit être en status 'offline', changez le en cliquant le bouton si besoin</li>
                            <li>Indiquez la competition qui va être écrasée avec les informations remote, laissez vide pour qu'une nouvelle competition soit créée. Si une competition est selectionée elle ne doit pas être en status plus loin que drawing published<br />ATTENTION : l'import va écraser la compétition locale</li>
                            <li>Lancer l'import competition data pour que la configuration de la competiton et ses shiaijos soit importée</li>
                            <li>Lancer l'import fighter, team et tirage pour que les informations combattant et tirage soient importer</li>
                            <li>A la fin de ces deux imports, vous pouvez désormais utiliser la competition locale</li>
                        </ul>
                    </p>}
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    {errorComponent}
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <h2>Export</h2>
                </Col>
            </Row>
            <Col md={12}>

            </Col>
            <Row>
                <Col md={2}>
                    Key
                </Col>
                <Col md={10}>
                    Aller sur le site hatanado central pour obtenir un token de 30 min pour exporter les competitions : <br />
                    <a href={remoteTokenLocalUrl} rel="noreferrer" target="_blank">{remoteTokenLocalUrl}</a><br />
                    <br />
                    Copiez le token obtenu ci dessous
                    Note : seules les competitions des 7 derniers jours sont exportables
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <h2>Import</h2>
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                    Direction
                </Col>
                <Col md={4}>
                    <Select value={importConfig.direction}
                        onChange={(e) => {
                            setImportConfig({
                                ...importConfig,
                                direction: e.target.value as "toRemote" | "fromRemote"
                            });
                            setImportData({
                                fighterMapId: undefined,
                                fightgroupNodeMapId: undefined,
                                shiaijoMapId: undefined,
                                teamMapId: undefined,
                                fightgroupTable: undefined
                            });
                        }}>
                        <MenuItem value="toRemote">Vers le central</MenuItem>
                        <MenuItem value="fromRemote">Depuis le central</MenuItem>
                    </Select>
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                    Remote Token
                </Col>
                <Col md={4}>
                    <TextField id="outlined-basic" label="Token" variant="outlined" value={remoteAccess.tokenStr}
                        onChange={(e) => {
                            let parsed;
                            try {
                                parsed = JSON.parse(e.target.value);
                            }
                            catch (e) {
                                parsed = undefined;
                            }
                            if (parsed) setRemoteAccess(Object.assign({}, remoteAccess, { tokenStr: e.target.value, token: parsed.organizer, test: undefined }))
                            else {
                                setRemoteAccess(Object.assign({}, remoteAccess, { tokenStr: e.target.value, token: "", test: undefined }))
                                alert("Token invalide");
                            }
                        }}
                        type="password"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                    Remote Hostname
                </Col>
                <Col md={4}>
                    <TextField id="outlined-basic" variant="outlined" value={remoteAccess.host}
                        onChange={(e) => setRemoteAccess(Object.assign({}, remoteAccess, { host: e.target.value, test: undefined }))} />
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                    Tester le token

                </Col>
                <Col md={4}>
                    {remoteAccess.test === undefined && <Chip label="Non testé" color='default' />}
                    {remoteAccess.test && <Chip label="Valide" color='success' />}
                    {remoteAccess.test === false && <Chip label="Erreur" color='error' />}
                    <Button variant='contained' onClick={() => checkToken(false)}>Tester le token</Button>
                </Col>

            </Row>


            {
                remoteAccess.test && <>
                    <Row>
                        <Col md={2}>
                            Hatanado Competition (central)
                        </Col>
                        <Col md={4}>
                            <select value={remoteCompetition ? remoteCompetition.id : 0} onChange={(e) => {
                                let c = remoteCompetitions?.find(c => c.id === Number(e.target.value));
                                setRemoteCompetition(c ?? (importConfig.direction === "fromRemote" ? undefined : emptyCompetitionModel));

                            }} >
                                <option value=""></option>
                                {importConfig.direction === 'toRemote' && <option value="0">Créer une nouvelle competition</option>}
                                {
                                    remoteCompetitions?.map((competition: IApiCompetitionModel, k: any) => {
                                        return <option key={k} value={competition.id}> {competition.fullname} (comp id {competition.id})</option>
                                    })
                                }
                            </select>

                        </Col>
                        <Col md={2}>
                            Name : {remoteCompetition?.fullname}
                        </Col>

                    </Row>
                    {remoteCompetition && <>
                        <Row>
                            <Col md={2}>
                                Status Hatanado
                            </Col>
                            <Col md={4}>
                                <Badge color={remoteCompetition.status === 'offline' ? 'secondary' : 'primary'}>
                                    {remoteCompetition.status}
                                </Badge>
                            </Col>
                            {remoteCompetition.id > 0 && <Col md={2}>
                                {remoteCompetition.status !== 'offline' && <Button variant='contained' color="success" onClick={() => setFrozen(true)}>{t("action.competition.freeze")}</Button>}
                                {remoteCompetition.status === 'offline' && <Button variant='contained' color="warning" onClick={() => setFrozen(false)}>{t("action.competition.unfreeze")}</Button>}
                            </Col>}
                        </Row>
                    </>}
                    <Row>
                        <Col md={12}>

                        </Col>
                    </Row>

                    {localCompetitions && <Row>
                        <Col md={2}>
                            Competition serveur local
                        </Col>

                        <Col md={4}>
                            <select value={competition ? competition.id : 0} onChange={(e) => {
                                let c = localCompetitions?.find(c => c.id === Number(e.target.value));
                                setCompetition(c ?? (importConfig.direction === "fromRemote" ? emptyCompetitionModel : undefined));
                            }
                            } >
                                {importConfig.direction === 'fromRemote' && <option value="0">Créer une nouvelle competition</option>}
                                {importConfig.direction === 'toRemote' && <option value="0"></option>}
                                {
                                    localCompetitions.map((competition: IApiCompetitionModel, k: any) => {
                                        return <option key={k} value={competition.id}> {competition.fullname} (comp id {competition.id})</option>
                                    })
                                }
                            </select>
                        </Col>
                    </Row>}
                    {competition && remoteCompetition && <>
                        {(remoteCompetition.id === 0 || remoteCompetition.status === 'offline') && <>
                            <Row>
                                <hr />
                            </Row>
                            <Row>
                                <Col md={2}>Importer les données competition</Col>
                                <Col md={4}>
                                    <Button variant='contained' size="small" onClick={() => importCompetitionData(remoteCompetition, competition, importConfig.direction)}>{t('action.competition.importCompetitionData')}</Button>
                                </Col>
                            </Row>


                            <Row>
                                <Col md={2}>
                                    Importer les combattants
                                </Col>
                                <Col md={4}>
                                    <Button variant='contained' size="small" onClick={() => importFighterDrawData(remoteCompetition, competition, importConfig.direction)}>{t('action.competition.importFighterDrawData')}</Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={2}>Importer les données arbre de suivi</Col>
                                <Col md={4}>
                                    <Button variant='contained' size="small"
                                        disabled={importData.teamMapId === undefined}
                                        color={importData.teamMapId === undefined ? 'error' : 'primary'}
                                        onClick={() => importFollowing(remoteCompetition, competition, importConfig.direction)}>
                                        {t('action.competition.importFollowing')}
                                    </Button>
                                </Col>
                                <Col md={4}>
                                    {importData.teamMapId === undefined && <Alert severity="warning">Il faut importer les combattants avant</Alert>}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={2}>
                                    Importer les combats
                                </Col>
                                <Col md={4}>
                                    <Button variant='contained' size="small"
                                        disabled={importData.teamMapId === undefined || importData.shiaijoMapId === undefined}
                                        color={(importData.teamMapId === undefined || importData.shiaijoMapId === undefined) ? 'error' : 'primary'}
                                        onClick={() => importTableData(remoteCompetition, competition, importConfig.direction)}>{t('action.competition.importTableData')}</Button>
                                </Col>
                                <Col md={4}>
                                    {(importData.teamMapId === undefined || importData.shiaijoMapId === undefined || importData.fightgroupNodeMapId === undefined) && <Alert severity="warning">Il faut importer les shiaijos, combattants et arbres avant</Alert>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    Importer la cloture
                                </Col>
                                <Col md={4}>
                                    <Button variant='contained' size="small"
                                        disabled={importData.fightgroupTable !== true}
                                        color={(importData.fightgroupTable !== true) ? 'error' : 'primary'}
                                        onClick={() => importCompetitionEnd(remoteCompetition, competition, importConfig.direction)}>{t('action.competition.importCompetitionEnd')}</Button>
                                </Col>
                                <Col md={4}>
                                    {(importData.teamMapId === undefined || importData.shiaijoMapId === undefined || importData.fightgroupNodeMapId === undefined) && <Alert severity="warning">Il faut importer combats</Alert>}
                                </Col>
                            </Row>

                        </>
                        }
                        {remoteCompetition.status !== 'offline' && <>
                            <Alert severity="warning">La competition doit être offline pour réaliser l'importation</Alert>
                        </>}
                    </>
                    }
                    <Row>
                        <Col md={12}>
                            {repport}
                        </Col>
                    </Row>
                </>
            }
        </>
        }
    </>
}