import React, { useEffect, useState } from "react";
import { IFightTeamState } from "../../util/ApiModel/ApiFightModel";
import { FighterLabel } from "../Fighter/FighterLabel";
import { getApiObject } from "../Operation/GenericOperation";
import { IFightTeamStateApiResult } from "../Table/Fight";
import { IServiceTableNotification } from "../Util/ErrorMessage";
import { emptyFightTeamState } from "./FightTeam";
import { TeamLabel } from "./TeamLabel";

export interface ITeamCardProps {
    team: IFightTeamState,
    dispatchNotification: React.Dispatch<IServiceTableNotification>
};

export function TeamCard(props: ITeamCardProps) {
    const [team, setTeam] = useState(props.team);

    useEffect(() => {
        setTeam(props.team);
    }, [props.team])

    useEffect(() => {
        if (team.fighters === undefined) {
            getApiObject<IFightTeamStateApiResult>("/FightTeam", props.team.externalId, { succeed: false, data: emptyFightTeamState }, props.dispatchNotification).then((res) => {
                if (res.succeed) {
                    setTeam(res.data);
                };
            })
        }
    }, [team.fighters, props.dispatchNotification, props.team.externalId]);


    return <>
        <TeamLabel team={team} /><br/>
        {team.fighters && team.fighters.sort((a,b) => a.order - b.order).map((fighter, k) => {
            return <React.Fragment key={k}>{fighter.order} - <FighterLabel fighter={fighter} /><br/></React.Fragment>
        })}
    </>
}