import { Chip, ThemeProvider } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ERoundStatus, IApiRoundModel } from '../../util/ApiModel/ApiRoundModel';
import { theme } from '../../util/ChipTheme';


export function getStatusLabel(round: IApiRoundModel) {
    return roundStatusLabel(round.status);
}

export function roundStatusLabel(status: ERoundStatus) {
    let statusLabel = "label.status.undefined";
    switch (status) {
        case ERoundStatus.FINISHED:
            statusLabel = "label.round.status.finished";
            break;
        case ERoundStatus.INPROGRESS:
            statusLabel = "label.round.status.inprogress";
            break;
        case ERoundStatus.EXTENSION:
            statusLabel = "label.round.status.extension";
            break;
        case ERoundStatus.WAIT:
            statusLabel = "label.round.status.wait";
            break;
        case ERoundStatus.PAUSE:
            statusLabel = "label.round.status.pause";
            break;
    }
    return statusLabel;
}

export function RoundStatus(props: { status: ERoundStatus }) {
    const { t, i18n } = useTranslation();
    //let variant: "wait" | "primary" | "secondary" | "success" | "warning" | "info" | "default" | "error" | undefined = undefined;
    let variant: "finished" | "inprogress" | "ready" | "undefined" | "wait" | "error" | undefined = undefined;
    switch (props.status) {
        case ERoundStatus.FINISHED: variant = "finished"; break;
        case ERoundStatus.INPROGRESS: variant = "inprogress"; break;
        case ERoundStatus.EXTENSION: variant = "ready"; break;
        case ERoundStatus.PAUSE: variant = "undefined"; break;
        case ERoundStatus.WAIT: variant = "wait"; break;
        default: variant = "error"; break;
    }

    return <ThemeProvider theme={theme}>
        <Chip color={variant as any} label={t(roundStatusLabel(props.status))} />
    </ThemeProvider >
}