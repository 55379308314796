import { IEventNotification } from "../EventComponent/Util/ErrorMessage";
import { IApiUserModel } from "../Model/ApiUserModel";
import { getCompApiObject, postCompApiObject } from "../UtilEvent/GenericOperation";

export const registration_status_list = ["register", "confirm", "check", "active", "refuse"] as const;
export type TREGISTRATION_STATUS = typeof registration_status_list[number];


export interface IRegister {
    login: string,
    email?: string | null,
    licenseeId?: string,
    confirmationKey?: string,
    status: TREGISTRATION_STATUS
}

export async function getRegisters(status: Array<TREGISTRATION_STATUS> | undefined, dispatchNotification: React.Dispatch<IEventNotification>): Promise<IRegister[]> {
    return getCompApiObject<{
        succeed: boolean,
        data: Array<IRegister>
    }>(`/register?${status ? status.map(s => `status=${s}`).join('&') : ''}`,
        null, {
        succeed: false,
        data: []
    }, dispatchNotification, (window as any)['REACT_APP_API_USER_SERVICE']
    ).then((result) => {
        return result.data;
    })
        .catch(() => {
            return [];//result.data;
        })


}

export async function activateRegister(email: string, dispatchNotification: React.Dispatch<IEventNotification>): Promise<IApiUserModel | undefined> {
    return await postCompApiObject<{
        succeed: boolean,
        data: IApiUserModel | undefined
    }>(`/active`,
    
        {email:email}, {
        succeed: false,
        data: undefined
    }, dispatchNotification, (window as any)['REACT_APP_API_USER_SERVICE']
    ).then((result) => {
        return result.data
    }).catch(e => {
        return undefined;
    });


}