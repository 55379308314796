import { Alert, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, MenuItem, Modal, Paper, Select, TextField, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from 'react';

import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getCompetition } from "../../Backend/ApiCompetition";
import { IApiCompetitionModel, IServiceCompetitionAdvancedOrdonancementRules, IServiceCompetitionAdvancedTableRules, OrdonancementDispatchRules } from "../../Model/ApiCompetitionModel";
import { TConfigurationAdvancedService, getCompetitionsAdvancedTable, getShiajos, updateCompetitionAdvanced } from '../../util/ApiCompetition';
import { putApiObject } from "../Operation/GenericOperation";
import { IServiceTableNotification } from "../Util/ErrorMessage";

const style = {
    position: 'absolute' as 'absolute',
    top: '10%',
    left: '10%',
    //transform: 'translate(-90%, -90%)',
    width: document.body.clientWidth * 0.8,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
    height: 700,
};

export interface IShiaijoUpdateValues {
    shiaijoMessage: string,
    shiaijoDisplayMode: "welcome" | "poolsTree" | "standard" | "closing" | "noFightEmpty",
    resultMessage: string,
    success?: boolean,
}

export function useCompetitionModal(
    dispatchNotification: React.Dispatch<IServiceTableNotification>
):[JSX.Element, React.Dispatch<React.SetStateAction<number | undefined>>] {
    const [selectedCompetitionConfiguration, setSelectedCompetitionConfiguration] = useState<IApiCompetitionModel | undefined>(undefined); //competition edited
    const [competitionId, setCompetitionId] = useState<number | undefined>(undefined);
    const { t, i18n } = useTranslation();
    const [shiaijoUpdateValues, setShiaijoUpdateValues] = useState<IShiaijoUpdateValues>({
        shiaijoMessage: "",
        shiaijoDisplayMode: "standard",
        resultMessage: ""
    }) //values for shiaijo update

    const selectedRule = (selectedCompetitionConfiguration && selectedCompetitionConfiguration.advancedOrdonancement
        && selectedCompetitionConfiguration.advancedOrdonancement["dispatchRule"]) ?
        String(selectedCompetitionConfiguration.advancedOrdonancement["dispatchRule"])
        : "balance_next_tree_part";

    useEffect(() => {
        if (competitionId){
            (async () => {
                let c =  await getCompetition(dispatchNotification, competitionId);
                
                const advancedTables = await getCompetitionsAdvancedTable<IServiceCompetitionAdvancedTableRules>(undefined, c.id, "ServicesTables");
                c.advancedTables = advancedTables.current;
                c.advancedTablesParent = advancedTables.parent;
                const advancedOrdonancement = await getCompetitionsAdvancedTable<IServiceCompetitionAdvancedOrdonancementRules>(undefined, c.id, "ServiceOrdonancement")
                c.advancedOrdonancement = advancedOrdonancement.current;
                c.advancedOrdonancementParent = advancedOrdonancement.parent;
                setSelectedCompetitionConfiguration(c);
            })();
        }
        else {
            setSelectedCompetitionConfiguration(undefined);
        }
    }, [competitionId, dispatchNotification])

    let fupdateCompetitionAdvanced = useCallback((competitionId: number,
        advanced: keyof IApiCompetitionModel,
        attribute: keyof IServiceCompetitionAdvancedTableRules | keyof IServiceCompetitionAdvancedOrdonancementRules
        , value: any, service: TConfigurationAdvancedService) => {
        //let newCompetitionOptionList: Array<IApiCompetitionModel> = Object.assign([], competitionsSelection.selectedCompetition);

        getCompetition(dispatchNotification, competitionId).then(c => {
            if (c) {
                if (c[advanced] === undefined) {
                    //@ts-ignore
                    c[advanced] = {}
                }
                let a = c[advanced] as any

                console.debug(`Set attribute value for competition ${competitionId} : ${attribute} = ${value}`)
                if (value !== undefined) a[attribute] = value;
                else delete a[attribute];

                updateCompetitionAdvanced(dispatchNotification, c.id, service, a);
                setSelectedCompetitionConfiguration(c)
            }
        })
    }, [dispatchNotification]);

    let updateCompetitionTableAdvanced = useCallback((competitionId: number, attribute: keyof IServiceCompetitionAdvancedTableRules, value: string) => {
        fupdateCompetitionAdvanced(competitionId, 'advancedTables', attribute, value, "ServicesTables")
    }, [fupdateCompetitionAdvanced]);

    let updateCompetitionOrdonancementAdvanced = useCallback((competitionId: number, attribute: keyof IServiceCompetitionAdvancedOrdonancementRules, value: string) => {
        fupdateCompetitionAdvanced(competitionId, 'advancedOrdonancement', attribute, value, "ServiceOrdonancement")
    }, [fupdateCompetitionAdvanced]);

    return [<>
        {selectedCompetitionConfiguration && <Modal
            open={competitionId !== undefined}
            onClose={() => setCompetitionId(undefined)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {selectedCompetitionConfiguration.fullname}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <Container fluid>
                        {/* <Row>
                        <Col sm={3}>
                            {t('action.competition.showSchedullerRules')}
                            <Checkbox
                                value={show.showSchedullerRules}
                                onClick={() => setShow({ ...show, showSchedullerRules: !show.showSchedullerRules })} />
                        </Col>
                    </Row> */}
                        <Paper elevation={3} className={"competition-config-paper"}>
                            <Grid container >

                                <Grid item xs={4}>

                                    <FormLabel component="legend">Shiaijos configuration</FormLabel>
                                    <TextField label={t("label.shiaijo.message")} onChange={(e) => {
                                        setShiaijoUpdateValues({ ...shiaijoUpdateValues, shiaijoMessage: e.target.value })
                                    }}
                                        value={shiaijoUpdateValues.shiaijoMessage}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <p>{t("label.shiaijo.displayMode")}</p>
                                    <Select onChange={(e) => {
                                        setShiaijoUpdateValues({ ...shiaijoUpdateValues, shiaijoDisplayMode: e.target.value as "welcome" | "poolsTree" | "standard" | "closing" | "noFightEmpty" })

                                    }}
                                        value={shiaijoUpdateValues.shiaijoDisplayMode}
                                        label={t("label.shiaijo.displayMode")}
                                    >
                                        <MenuItem value="welcome">{t("label.shiaijo.welcome")}</MenuItem>
                                        <MenuItem value="poolsTree">{t("label.shiaijo.poolsTree")}</MenuItem>
                                        <MenuItem value="standard">{t("label.shiaijo.standard")}</MenuItem>
                                        <MenuItem value="noFightEmpty">{t("label.shiaijo.noFightEmpty")}</MenuItem>
                                        <MenuItem value="closing">{t("label.shiaijo.closing")}</MenuItem>


                                    </Select>
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={2}>
                                    <Button variant='contained' onClick={() => {
                                        const f = async () => {

                                            //warning, get from competition service
                                            const ss = await getShiajos(dispatchNotification,
                                                selectedCompetitionConfiguration.id);

                                            let succeed: boolean = ss.length > 0 ? true : false;
                                            let message = ss.length > 0 ? "Updated succeed" : "No shiaijo to update";
                                            for (const shiaijo of ss) {
                                                //id from competition service is external id for table
                                                const r = await putApiObject("/Shiajos", shiaijo.id, {
                                                    displayMode: shiaijoUpdateValues.shiaijoDisplayMode,
                                                    displayMessage: shiaijoUpdateValues.shiaijoMessage
                                                }, { succeed: false }, dispatchNotification).then(res => {
                                                    if (!res.succeed) {
                                                        return {
                                                            succeed: false,
                                                            message: "Failed to update shiaijo " + shiaijo.id + " : " + shiaijo.fullname
                                                        }
                                                    }
                                                    else {
                                                        return {
                                                            succeed: true,
                                                            message: ""
                                                        }
                                                    }
                                                })
                                                succeed = succeed && r?.succeed;
                                                if (!succeed) {
                                                    message = r?.message;
                                                }
                                            }

                                            setShiaijoUpdateValues({
                                                ...shiaijoUpdateValues,
                                                success: succeed,
                                                resultMessage: message
                                            })
                                        };
                                        f();
                                    }
                                    }>{t('label.shiaijo.apply')}</Button>
                                </Grid>

                            </Grid>
                        </Paper>
                        <Paper elevation={3} className={"competition-config-paper"}>
                            <Grid item xs={12}>
                                {/* {show.showSchedullerRules && <> */}
                                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">Distribution rules</FormLabel>
                                    <FormGroup>
                                        {advancedConfiguration(selectedCompetitionConfiguration.id, selectedCompetitionConfiguration.advancedOrdonancement, selectedCompetitionConfiguration.advancedOrdonancementParent, 'retainSemiFinal', updateCompetitionOrdonancementAdvanced, t, "boolean")}

                                        {advancedConfiguration(selectedCompetitionConfiguration.id, selectedCompetitionConfiguration.advancedOrdonancement, selectedCompetitionConfiguration.advancedOrdonancementParent, 'retainFinal', updateCompetitionOrdonancementAdvanced, t, "boolean")}

                                        {advancedConfiguration(selectedCompetitionConfiguration.id, selectedCompetitionConfiguration.advancedOrdonancement, selectedCompetitionConfiguration.advancedOrdonancementParent, 'retainTree', updateCompetitionOrdonancementAdvanced, t, "boolean")}

                                        {advancedConfiguration(selectedCompetitionConfiguration.id, selectedCompetitionConfiguration.advancedTables, selectedCompetitionConfiguration.advancedTablesParent, 'resolveNone', updateCompetitionTableAdvanced, t, "boolean")}

                                    </FormGroup>

                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>


                                {t("label.competition.dispatchRule")}
                            </Grid>
                            <Grid item xs={12}>
                                <Select
                                    value={selectedRule}
                                    label={t(`label.competition.advanced.dispatchRule`)}
                                    onChange={(e: any) => {

                                        updateCompetitionOrdonancementAdvanced(selectedCompetitionConfiguration.id, "dispatchRule", String(e.target.value));
                                    }}
                                >
                                    {OrdonancementDispatchRules.map((i, k) => {

                                        return <MenuItem key={k} value={i}>{t(`label.competition.advanced.dispatchRule_${i}`)}</MenuItem>
                                    })}

                                </Select>

                                <Alert severity="info">{t(`label.competition.advanced.dispatchRule_${selectedRule}`)} : {t(`label.competition.advanced.dispatchRule_${selectedRule}_detail`)}</Alert>
                            </Grid>
                        </Paper>
                        <Paper elevation={3} className={"competition-config-paper"}>
                            {/* </>
                    } */}
                            {/* <Row>
                        <Col sm={3}>
                            {t('action.competition.showCompetitionRules')}
                            <Checkbox
                                value={show.showCompetitionRules}
                                onClick={() => setShow({ ...show, showCompetitionRules: !show.showCompetitionRules })} />
                        </Col>
                    </Row>
                    {show.showCompetitionRules && <> */}
                            <Grid container item xs={12}>

                                <Grid item xs={3}>
                                    {t("label.competition.combatTime")} : <b>{selectedCompetitionConfiguration.combatTime}</b> (Configurée sur l'interface compétition, future évolution)
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">Display modes</FormLabel>
                                    <FormGroup></FormGroup>
                                    {advancedConfiguration(selectedCompetitionConfiguration.id, selectedCompetitionConfiguration.advancedTables, selectedCompetitionConfiguration.advancedTablesParent, 'showCountry', updateCompetitionTableAdvanced, t, "boolean")}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                {/* {show.showSchedullerRules && <> */}
                                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">Assign responsibility</FormLabel>
                                    <FormGroup></FormGroup>
                                    {advancedConfiguration(selectedCompetitionConfiguration.id, selectedCompetitionConfiguration.advancedTables, selectedCompetitionConfiguration.advancedTablesParent, 'canChangeBeforeFirstFight', updateCompetitionTableAdvanced, t, "boolean")}

                                    {advancedConfiguration(selectedCompetitionConfiguration.id, selectedCompetitionConfiguration.advancedTables, selectedCompetitionConfiguration.advancedTablesParent, 'canChangeInPool', updateCompetitionTableAdvanced, t, "boolean")}

                                    {advancedConfiguration(selectedCompetitionConfiguration.id, selectedCompetitionConfiguration.advancedTables, selectedCompetitionConfiguration.advancedTablesParent, 'canChangeInTree', updateCompetitionTableAdvanced, t, "boolean")}

                                </FormControl>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={4}>
                                    {advancedConfiguration(selectedCompetitionConfiguration.id, selectedCompetitionConfiguration.advancedTables, selectedCompetitionConfiguration.advancedTablesParent, 'defaultMaxScore', updateCompetitionTableAdvanced, t)}
                                </Grid>
                                <Grid item xs={4}>
                                    {advancedConfiguration(selectedCompetitionConfiguration.id, selectedCompetitionConfiguration.advancedTables, selectedCompetitionConfiguration.advancedTablesParent, 'poolMaxScore', updateCompetitionTableAdvanced, t)}
                                </Grid>
                                <Grid item xs={4}>
                                    {advancedConfiguration(selectedCompetitionConfiguration.id, selectedCompetitionConfiguration.advancedTables, selectedCompetitionConfiguration.advancedTablesParent, 'eliminationMaxScore', updateCompetitionTableAdvanced, t)}
                                </Grid>
                                <Grid item xs={4}>
                                    {advancedConfiguration(selectedCompetitionConfiguration.id, selectedCompetitionConfiguration.advancedTables, selectedCompetitionConfiguration.advancedTablesParent, 'quarterFinalMaxScore', updateCompetitionTableAdvanced, t)}
                                </Grid>
                                <Grid item xs={4}>
                                    {advancedConfiguration(selectedCompetitionConfiguration.id, selectedCompetitionConfiguration.advancedTables, selectedCompetitionConfiguration.advancedTablesParent, 'semiFinalMaxScore', updateCompetitionTableAdvanced, t)}
                                </Grid>
                                <Grid item xs={4}>

                                    {advancedConfiguration(selectedCompetitionConfiguration.id, selectedCompetitionConfiguration.advancedTables, selectedCompetitionConfiguration.advancedTablesParent, 'finalMaxScore', updateCompetitionTableAdvanced, t)}
                                </Grid>



                            </Grid>
                        </Paper>
                        {/* </>
                    } */}
                    </Container>
                </Typography>
            </Box>
        </Modal >
        }</>, setCompetitionId]
}

export function advancedConfiguration<T>(id: number
    , advanced: undefined | T
    , advancedParent: undefined | T
    , configName: keyof T
    , updateCompetitionAdvanced: any
    , t: any
    , type: "boolean" | "number" = "number",
): JSX.Element {
    const value = advanced ? advanced[configName] : undefined;
    const ValueWithInherit = value !== undefined ? value : (advancedParent ? advancedParent[configName] : undefined);

    const inheritInfo = <>{(advancedParent && value === undefined) && <Tooltip
        title={t('label.competition.parent.inheritedFromParent')}
    >
        <SubdirectoryArrowRightIcon />
    </Tooltip>}
        {(advancedParent && value !== undefined) && <Tooltip
            title={t('label.competition.parent.resetToParent')}
        >
            <DoNotDisturbIcon
                onClick={(e) => {
                    updateCompetitionAdvanced(id, configName, undefined);
                    e.preventDefault();
                }}
            />
        </Tooltip>}
    </>

    return <React.Fragment>
        {/* <Form.Group as={Row} controlId={`competition-formgroup-${id}-advanced-${configName.toString()}`}>
            {type === "number" && <Form.Label column sm="6">
                
            </Form.Label>} */}
        {/* <Col sm="6"> */}


        {type === "number" && <div>
            <p>{t(`label.competition.advanced.${configName.toString()}`)}{inheritInfo}</p>
            <TextField
                value={ValueWithInherit ? String(ValueWithInherit) : ""}
                onChange={(e: any) => {
                    let avalue = isNaN(e.target.value) ? undefined : e.target.value;
                    updateCompetitionAdvanced(id, configName, avalue);
                    e.preventDefault();
                }
                }
            /></div>}
        {type === "boolean" && <FormControlLabel
            control={
                <>
                    <Checkbox
                        checked={(ValueWithInherit !== undefined) ? Boolean(ValueWithInherit) : false}
                        onChange={(e: any) => {
                            updateCompetitionAdvanced(id, configName, e.target.checked);
                            e.preventDefault();
                        }}
                    />
                    {inheritInfo}
                    &nbsp;
                </>
            }
            label={t(`label.competition.advanced.${configName.toString()}`)}
        />}

    </React.Fragment>
};