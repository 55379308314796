import { Button } from '@mui/material';
import produce from 'immer';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IApiFighterFightTeam } from '../../Model/ApiTeamModel';
import { putFightGroupRanks } from '../../util/ApiFightgroup';
import { extractTeamsFromApiCompetitionTreeModel, getFollowingFightGroup } from '../../util/ApiFollowing';
import { IApiCompetitionTreeModel } from '../../util/ApiModel/Following/ApiCompetitionTree';
import { lazyLoadFightTeams } from '../Team/LazyFightTeam';
import { IServiceTableNotification } from '../Util/ErrorMessage';


export function FollowingSelectRank(props: {
    fightGroupNodeId: number,
    dispatchNotification: React.Dispatch<IServiceTableNotification>,
    onPutted: (ranks: string[]) => void
}) {
    const { t, i18n } = useTranslation();
    const [followingSelectRankProps, setFollowingSelectRankProps] = useState<undefined | {
        fightGroup: IApiCompetitionTreeModel,
        teamList: Map<number, IApiFighterFightTeam>

    }>(undefined);
    const [comp, ranks] = useFollowingSelectRank(followingSelectRankProps?.fightGroup, followingSelectRankProps?.teamList);

    useEffect(() => {
        let display = true;
        if (props.fightGroupNodeId > 0) {
            getFollowingFightGroup(props.fightGroupNodeId, props.dispatchNotification).then(d => {
                if (display) {
                    if (d) {
                        lazyLoadFightTeams({
                            id: d.competitionId,
                            dispatchNotification: props.dispatchNotification
                        }).then((fightTeams: Array<IApiFighterFightTeam>) => {

                            let newteamList: Map<number, IApiFighterFightTeam> = new Map();//teamList);
                            for (let index = 0; index < fightTeams.length; index++) {
                                const fightTeam = fightTeams[index];
                                newteamList.set(Number(fightTeam.id), fightTeam);
                            }
                            setFollowingSelectRankProps({
                                fightGroup: d,
                                teamList: newteamList
                            })
                        });

                    }
                    else {
                        setFollowingSelectRankProps(undefined);
                    }
                }
            })
        }
        else {
            setFollowingSelectRankProps(undefined);
        }
        return () => {
            display = false;
        }
    }, [props.dispatchNotification, props.fightGroupNodeId])


    return <>
        {comp}
        <Button onClick={() => {
            putFightGroupRanks(props.fightGroupNodeId, ranks, props.dispatchNotification).then(r => {
                if (r.succeed) {
                    props.onPutted(ranks)
                }
            });
        }}>{t("action.following.forceranking")}</Button>
        <Button onClick={() => {
            props.onPutted([])
        }}>{t("action.following.cancel")}</Button>
    </>;
}

export function useFollowingSelectRank(
    fg: IApiCompetitionTreeModel | undefined
    , teamMap: Map<number, IApiFighterFightTeam> | undefined): [JSX.Element, Array<string>] {
    const { t, i18n } = useTranslation();
    const [rank, setRank] = useState<Array<string>>([]);
    const fgteamList = (fg && teamMap) ? extractTeamsFromApiCompetitionTreeModel(fg, teamMap) : [];

    const lengthArray = useMemo( () => {
        let lengthArray:Array<number> = new Array(fg?.nextFightGroupNodeIdColored.length).fill(0)
        if (lengthArray.length === 0) lengthArray.push(0);
        return lengthArray;
    }, [fg?.nextFightGroupNodeIdColored.length]);

    

    useEffect(() => {
        setRank(lengthArray.map(r => String(r)));
    }, [lengthArray])

    const comp = <>
        {lengthArray.map((i, k) => {
            return <React.Fragment key={k}>
                {k < 6 ? t(`label.fightgroup.rank_${k}`) : `Rank ${k + 1}`} : <select value={rank.length > k ? rank[k] : 0}
                    onChange={(e) => {
                        let newRank = produce(rank, (draft) => {
                            draft[k] = e.target.value;

                        });
                        setRank(newRank);
                    }}

                >
                    <option key={k} value={0}>{t('label.word.none')}</option>
                    {fgteamList.map((fgteam, k) => {
                        return <option key={k} value={fgteam.id}>{fgteam.name}</option>
                    })}
                </select>
            </React.Fragment>
        })}</>;
    return [comp, rank];
}