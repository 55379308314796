import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { IApiCompetitionModel } from '../../Model/ApiCompetitionModel';
import { IApiResult } from '../../util/ApiCall';
import { EFightStatus, IApiFightModel, IApiFightResult, IFightTeamState, emptyApiFightModel } from '../../util/ApiModel/ApiFightModel';
import { IApiFighterModel, noneFighter } from '../../util/ApiModel/ApiFighterModel';
import { ERoundStatus, IApiRoundModel } from '../../util/ApiModel/ApiRoundModel';
import { FighterLabel } from '../Fighter/FighterLabel';
import { ConfirmModal } from '../Modal/ConfirmModal';
import { getApiObject, patchApiObject, putApiObject } from '../Operation/GenericOperation';
import { TSizeType, useScreenSize } from '../Use/UseScreenSize';
import { ChronoState, TChronoCommand } from '../Util/Chronometer';
import { IServiceTableNotification } from '../Util/ErrorMessage';
import { shouldReloadFightGroup } from '../Util/ReloadRules';
import { FightAutomaticResolveButton } from './FightAutomaticResolveButton';
import { FightStatus } from './FightStatus';
import { Round } from './Round';
import { TeamOrderModal } from './TeamOrderModal';
var debug = require('debug')('ServiceTablesMinimalFrontend:Fight')



export interface IFightTeamStateApiResult extends IApiResult {
  data: IFightTeamState
}





export interface IFightProps {
  dispatchChrono: (command: TChronoCommand) => void,
  dispatchNotification: React.Dispatch<IServiceTableNotification>,
  //dispatchFightGroup: React.Dispatch<IFightGroupAction>,
  fightGroupLoad: (pid?: number) => void,
  fightGroupSelectFight: (pid: number) => void,
  //dispatchModal: React.Dispatch<IModalAction>,
  data: IApiFightModel,
  active: boolean,
  competition: IApiCompetitionModel,
  chronoState?: ChronoState,
  simpleActions: boolean
}

export interface IFightAction {
  type: "CHANGE_FIGHTER" | "POST_ROUND_UPDATE" | "UPDATE" | "READY" | "RELOAD"
  id?: number,
  data?: any
}

/*
checkUpdateFight={
    (newRoundState: IApiRoundModel) => {
      return checkUpdateFight(fight, newRoundState, setFight, props.dispatchFightGroup, props.dispatchNotification);
    }
  }(newRoundState: IApiRoundModel) => Promise<IApiFightModel>
  */


/*
function fightReducerBuilder(
  //dispatchFightGroup: React.Dispatch<IFightGroupAction>,
  fightGroupLoad: () => void,
  dispatchNotification: React.Dispatch<IServiceTableNotification>) {
  return async function fightReducer(fight: IFightState, action: IFightAction): Promise<IFightState> {
    let fightResult: IFightState = { ...fight };
    switch (action.type) {

      case "READY":
        let ready = action.data;
        debug("Ready fight click");
        let data = {
          ready: ready,
          rounds: new Array<{
            id: number,
            order: number,
            redFighterId: number,
            whiteFighterId: number
          }>()
        };

        fight.data.rounds.forEach((round: IApiRoundModel, order: number) => {
          data.rounds.push({
            id: round.id,
            order: order,
            redFighterId: round.redFighterId ? round.redFighterId : 0,
            whiteFighterId: round.whiteFighterId ? round.whiteFighterId : 0,
          });
        });

        debug("Update fight : " + JSON.stringify(fight));

        let apiData = await putApiObject<IApiFightResult>('/fights', fight.data.id, data, {
          data: fight.data,
          succeed: false
        }, dispatchNotification);
        if (apiData.succeed) {
          fightResult = { data: apiData.data };
        }
        break;
      case "UPDATE":
        fightResult.data = action.data;
        break;
      case "CHANGE_FIGHTER":

        let fighterId = Number(action.data.fighterId);
        let previousFighterId = Number(action.data.previousFighterId);
        debug("CHANGE_FIGHTER " + JSON.stringify(action));
        let index = fightResult.data.rounds.findIndex((round: IApiRoundModel) => {
          return round.id === action.id
        });

        if (index >= 0) {

          if (fighterId === 0) {
            let fighter = fight.data.redFightTeam.fighters.find((fighter: IApiFighterModel) => {
              return fighter.id === previousFighterId
            });
            if (fighter) {
              fightResult.data.rounds[index].redFighterId = 0;
              fightResult.data.rounds[index].redFighter = noneFighter;
            }
            fighter = fight.data.whiteFightTeam.fighters.find((fighter: IApiFighterModel) => {
              return fighter.id === previousFighterId
            });
            if (fighter) {
              fightResult.data.rounds[index].whiteFighterId = 0;
              fightResult.data.rounds[index].whiteFighter = noneFighter;
            }
          }
          else {
            let fighter = fight.data.redFightTeam.fighters.find((fighter: IApiFighterModel) => {
              return fighter.id === fighterId
            });
            if (fighter) {
              fightResult.data.rounds[index].redFighterId = fighterId;
              fightResult.data.rounds[index].redFighter = fighter;
            }
            fighter = fight.data.whiteFightTeam.fighters.find((fighter: IApiFighterModel) => {
              return fighter.id === fighterId
            });
            if (fighter) {
              fightResult.data.rounds[index].whiteFighterId = fighterId;
              fightResult.data.rounds[index].whiteFighter = fighter;
            }
          }
        }
        break;
      case "POST_ROUND_UPDATE":
        debug("POST_ROUND_UPDATE");
        //async call
        fightResult.data = await getApiObject<IApiFightResult>("/fights", fight.data.id, {
          succeed: false,
          data: fight.data
        }, dispatchNotification).then((res) => {

          if (shouldReloadFightGroup(fight.data, res.data)) {
            //dispatchFightGroup({ type: "LOAD" });
            fightGroupLoad();
          }
          return res.data;
        });
        for (let index = 0; index < fightResult.data.rounds.length; index++) {
          const element = fightResult.data.rounds[index];
          element.reloadKey = Date.now();
        }

        break;
      case "RELOAD":
        //dispatchFightGroup({ type: "LOAD" });
        fightGroupLoad();

        break;
    }
    return fightResult
  }
}
*/
function isRoundSelectLocked(fight: IApiFightModel) {

  let result = false;

  fight.rounds?.forEach(round => {
    switch (fight.status) {
      case EFightStatus.INPROGRESS:
      case EFightStatus.READY:

        switch (round.status) {
          case ERoundStatus.INPROGRESS:
          case ERoundStatus.PAUSE:
          case ERoundStatus.EXTENSION:
            result = true;
            break;
        }
        break;
      case EFightStatus.FINISHED:
      case EFightStatus.UNDEFINED:
      case EFightStatus.WAIT:
      default:
        result = true;
        break;
    }
  });
  debug("isRoundSelectLocked : " + result + " <- " + fight.status);
  //debug("isRoundSelectLocked : " + result);
  return result;
}

/*
async function selectRound(props: IFightProps, roundId: number, dispatchNotification: React.Dispatch<IServiceTableNotification>) {
  return await putApiObject<IApiFightResult>('/fights'
    , props.data.id, { currentRoundId: roundId }, { succeed: false, data: props.data }, dispatchNotification)
}*/


export interface IFightState {
  data: IApiFightModel;
}

export interface IFightActions {
  readyFight: (pdata: boolean) => void,
  update: (pdata: IApiFightModel) => void,
  changeFighter: (roundid: number,
    fighterId: number,
    previousFighterId: number
  ) => void,
  postRoundUpdate: () => void,
  reload: () => void
}

/**
 * A fight
 */
export function Fight(props: IFightProps) {
  const { t, i18n } = useTranslation();
  //const [isShowing, toggle] = useShiajoModal();
  const sizeType: TSizeType = useScreenSize();
  /*const [fight, dispatch] = useAsyncReducer<IFightState, IFightAction>(fightReducerBuilder(props.fightGroupLoad, props.dispatchNotification),
    {
      data: props.data
    }
  );*/

  const [fight, setFight] = useState<IFightState>({
    data: props.data
  })



  let readyFight = async (pdata: boolean) => {
    let fightResult: IFightState = { ...fight };
    let ready = pdata;
    debug("Ready fight click");
    let data = {
      ready: ready,
      rounds: new Array<{
        id: number,
        order: number,
        redFighterId: number,
        whiteFighterId: number
      }>()
    };

    fight.data.rounds?.forEach((round: IApiRoundModel, order: number) => {
      data.rounds.push({
        id: round.id,
        order: order,
        redFighterId: round.redFighterId ? round.redFighterId : 0,
        whiteFighterId: round.whiteFighterId ? round.whiteFighterId : 0,
      });
    });

    debug("Update fight : " + JSON.stringify(fight));

    let apiData = await putApiObject<IApiFightResult>('/fights', fight.data.id, data, {
      data: fight.data,
      succeed: false
    }, props.dispatchNotification);
    if (apiData.succeed) {
      fightResult = { data: apiData.data };
    }
    setFight(fightResult);
  }



  let update = (pdata: IApiFightModel) => {
    let fightResult: IFightState = { ...fight };
    fightResult.data = pdata;
    setFight(fightResult);
  };


  let changeFighter = (roundid: number,
    pfighterId: number,
    ppreviousFighterId: number
  ) => {
    let fightResult: IFightState = { ...fight };
    let fighterId = Number(pfighterId);
    let previousFighterId = Number(ppreviousFighterId);
    let index = fightResult.data.rounds?.findIndex((round: IApiRoundModel) => {
      return round.id === roundid
    });

    if (index !== undefined && index >= 0) {

      if (fighterId === 0) {
        let fighter = fight.data.redFightTeam.fighters.find((fighter: IApiFighterModel) => {
          return fighter.id === previousFighterId
        });
        if (fighter && fightResult.data.rounds) {
          fightResult.data.rounds[index].reloadKey = Math.random();
          fightResult.data.rounds[index].redFighterId = 0;
          fightResult.data.rounds[index].redFighter = noneFighter;
        }
        fighter = fight.data.whiteFightTeam.fighters.find((fighter: IApiFighterModel) => {
          return fighter.id === previousFighterId
        });
        if (fighter && fightResult.data.rounds) {
          fightResult.data.rounds[index].reloadKey = Math.random();
          fightResult.data.rounds[index].whiteFighterId = 0;
          fightResult.data.rounds[index].whiteFighter = noneFighter;
        }
      }
      else {
        let fighter = fight.data.redFightTeam.fighters.find((fighter: IApiFighterModel) => {
          return fighter.id === fighterId
        });
        if (fighter && fightResult.data.rounds) {
          fightResult.data.rounds[index].reloadKey = Math.random();
          fightResult.data.rounds[index].redFighterId = fighterId;
          fightResult.data.rounds[index].redFighter = fighter;
        }
        fighter = fight.data.whiteFightTeam.fighters.find((fighter: IApiFighterModel) => {
          return fighter.id === fighterId
        });
        if (fighter && fightResult.data.rounds) {
          fightResult.data.rounds[index].reloadKey = Math.random();
          fightResult.data.rounds[index].whiteFighterId = fighterId;
          fightResult.data.rounds[index].whiteFighter = fighter;
        }
      }
    }
    setFight(fightResult);
  }



  let postRoundUpdate = useCallback(async () => {
    let fightResult: IFightState = { ...fight };
    debug("POST_ROUND_UPDATE");
    //async call
    fightResult.data = await getApiObject<IApiFightResult>("/fights", fight.data.id, {
      succeed: false,
      data: fight.data
    }, props.dispatchNotification).then((res) => {

      if (shouldReloadFightGroup(fight.data, res.data)) {
        //dispatchFightGroup({ type: "LOAD" });
        props.fightGroupLoad();
      }
      return res.data;
    });
    for (let index = 0; index < (fightResult.data.rounds?.length ?? 0); index++) {
      const element = fightResult.data.rounds ? fightResult.data.rounds[index] : undefined;
      if (element) element.reloadKey = Date.now();
    }
    setFight(fightResult);
  }, [fight, props])

  let reload = () => {
    //dispatchFightGroup({ type: "LOAD" });
    props.fightGroupLoad();
  }


  let fightActions: IFightActions = {
    readyFight: readyFight,
    update: update,
    changeFighter: changeFighter,
    postRoundUpdate: postRoundUpdate,
    reload: reload
  }

  //const [currentRound, setCurrentRound] = useState<IApiRoundModel | undefined>(props.data.rounds.find((round) => { return round.id === props.data.currentRoundId }));

  let firstLine;
  let cssClass = "border";
  if (props.active) {
    cssClass = "border border-primary";
  }
  let fightAction = null;
  let roundSelectLock = true;

  let roundEditeable = false;
  if (fight.data.status === EFightStatus.WAIT) {
    roundEditeable = true;
  }

  let clearFight = useCallback(() => {
    patchApiObject<IApiFightResult>('/fights/' + fight.data.id + "/clear", {}, {
      data: fight.data,
      succeed: false
    }, props.dispatchNotification).then(res => {
      if (res.succeed) {
        //dispatch({ type: "POST_ROUND_UPDATE" });
        postRoundUpdate();
        console.log("load");
      }
    })
  }, [fight.data, postRoundUpdate, props.dispatchNotification]);

  useEffect(() => {
    debug("UseEffect");
  }, [fight.data, fight.data.ready])

  const selectRound = useCallback(async (roundId: number): Promise<IApiFightModel> => {
    return putApiObject<IApiFightResult>('/fights'
      , fight.data.id, { currentRoundId: roundId }, { succeed: false, data: emptyApiFightModel }, props.dispatchNotification)
      .then((dataResult) => {
        //dispatch({ type: "UPDATE", data: dataResult.data });
        setFight({ ...fight, data: dataResult.data })
        return dataResult.data;
      });
  }, [fight, props.dispatchNotification]);

  useEffect(() => {
    if (fight.data.id) {
      if (fight.data.rounds?.length === 1 && fight.data.currentRoundId === null) {
        selectRound(fight.data.rounds ? fight.data.rounds[0].id : 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fight.data.id, selectRound]);







  if ((fight.data.status === EFightStatus.WAIT || fight.data.status === EFightStatus.READY)) {
    fightAction = <>
      <Button variant="success" size="sm" onClick={
        () => {
          //dispatch({ type: "READY", data: fight.data.status !== EFightStatus.READY });
          readyFight(fight.data.status !== EFightStatus.READY)
        }}>
        {fight.data.status !== EFightStatus.READY ? t('action.fight.ready') : t('action.fight.unready')}
      </Button>

    </>
  }

  if (!props.active) {

    fightAction = <>
      <Button size="sm" onClick={() => {
        /*props.dispatchFightGroup({ type: "SELECT_FIGHT", id: fight.data.id })*/
        props.fightGroupSelectFight(fight.data.id);
      }}>
        {t('action.fight.select')}
      </Button>
      {fightAction}
    </>
  } else {
    roundSelectLock = isRoundSelectLocked(fight.data);
  }



  if ((fight.data.rounds?.length ?? 0) > 0) {
    //TODO : ??
    if (props.active) {
      const isRedNoneFight = fight.data.rounds?.filter(r => r.redFighterId <= 0).length === fight.data.rounds?.length;
      const isWhiteNoneFight = fight.data.rounds?.filter(r => r.whiteFighterId <= 0).length === fight.data.rounds?.length;
      //className={cssClass}
      firstLine = <><tr >
        {/*Libelle du combat*/}
        {/* <td rowSpan={(sizeType >= 17) ? fight.data.rounds.length + 1 : 1}>
          {fight.data.name}
          {t('label.word.fight')} {fight.data.id} ({t('label.fightgroup.position')} {fight.data.order})
        </td> */}
        {/*Description du combat*/}
        <td rowSpan={(sizeType >= 17) ? (fight.data.rounds?.length ?? 0) + 1 : 1} colSpan={(sizeType >= 17) ? 1 : 2}>


          &nbsp;{fight.data.redFightTeam.name} ({fight.data.redFightTeam.externalId})
          {(sizeType >= 17) && <br />}
          &nbsp;{t('label.word.versus')}
          {(sizeType >= 17) && <br />}
          &nbsp;{fight.data.whiteFightTeam?.name} ({fight.data.whiteFightTeam.externalId})
          {(sizeType >= 17) && <br />}
          {fight.data.name}
          {fight.data.order} - {t('label.word.fight')} {fight.data.id}
        </td>
        {/*Status du combat*/}
        <td >
          <FightStatus status={fight.data.status} />
          {/* Not needed anymore for active fight
          {fight.data.status === EFightStatus.WAIT  && <TeamOrderModal
            fight={fight}
            onUpdate={reload}
          />} */}
        </td>
        {(sizeType >= 17) && <>
          {/*Action(ex activation) du combat*/}
          <td>
            {fightAction}
          </td>
          {/*Round Status*/}
          <td></td>
          {/*Red Team*/}
          <td className='red'>
            {t('label.word.team')} {fight.data.redFightTeam.name}
            {isRedNoneFight && fight.data.status === EFightStatus.WAIT && <FightAutomaticResolveButton
              fight={fight}
              reload={async (fight: IApiFightModel) => {
                reload();
              }}
              dispatchNotification={props.dispatchNotification}
            />}
          </td>
          {/*Ippon Screen*/}
          <td className="ippon" >{t('label.word.fight')} : {fight.data.name ? fight.data.name : fight.data.keyname} - id {fight.data.id} - Type {fight.data.type}</td>
          {/*White Screen*/}
          <td className='white'>
            {t('label.word.team')} {fight.data.whiteFightTeam.name}
            {isWhiteNoneFight && fight.data.status === EFightStatus.WAIT && <FightAutomaticResolveButton
              reload={async (fight: IApiFightModel) => {
                reload();
              }}
              dispatchNotification={props.dispatchNotification}
              fight={fight} />}
          </td>
          {/*Action and details Screen*/}
          <td >
            {t(`label.fight.status.${EFightStatus[fight.data.status]}`)}<br />
            <ConfirmModal label={t("action.fight.clear")} message={t("confirmation.fight.clear")} action={() => {
              clearFight();
            }} />
          </td>
        </>}
      </tr >
        {/*Small Screen*/}
        {(sizeType === 15) && <><tr>
          <td colSpan={3} style={{ textAlign: 'center' }}>{fightAction}</td>
        </tr><tr>
            <td className='red'>{t('label.word.team')}
              &nbsp;{fight.data.redFightTeam.name}
              {isRedNoneFight && fight.data.status === EFightStatus.WAIT && <FightAutomaticResolveButton
                fight={fight}
                reload={async (fight: IApiFightModel) => {
                  reload();
                }}
                dispatchNotification={props.dispatchNotification}
              />}
            </td>
            <td className="ippon">{t('label.word.fight')} : {fight.data.name ? fight.data.name : fight.data.keyname} - id {fight.data.id} - Type {fight.data.type} -&nbsp;
              {t(`label.fight.status.${EFightStatus[fight.data.status]}`)} - &nbsp;
              <ConfirmModal label={t("action.fight.clear")} message={t("confirmation.fight.clear")} action={() => {
                clearFight();
              }} /></td>
            <td className='white'>
              {t('label.word.team')}
              &nbsp;{fight.data.whiteFightTeam.name}
              {isWhiteNoneFight && fight.data.status === EFightStatus.WAIT && <FightAutomaticResolveButton
                reload={async (fight: IApiFightModel) => {
                  reload();
                }}
                dispatchNotification={props.dispatchNotification}
                fight={fight} />}
            </td>

          </tr></>
        }</>;
    }
    else {
      //NOT ACTIVE
      firstLine = <tr >
        {/* <td>
          {fight.data.name}
          {t('label.word.fight')} {fight.data.id}  ({t('label.fightgroup.position')} {fight.data.order})
        </td> */}
        <td colSpan={(sizeType >= 17) ? 1 : 2}>
          {fight.data.name}
          {t('label.word.fight')} {fight.data.id} ({t('label.fightgroup.position')} {fight.data.order})
          {(sizeType >= 17) ? <br /> : ' - '}
          <b>{fight.data.redFightTeam.name}</b>  ({fight.data.redFightTeam.externalId})
          {(sizeType >= 17) ? <br /> : ' - '}
          {t('label.word.versus')}
          {(sizeType >= 17) ? <br /> : ' - '}
          <b>{fight.data.whiteFightTeam?.name}</b> ({fight.data.whiteFightTeam.externalId})</td>
        <td>{fightAction}<br />
          <FightStatus status={fight.data.status} />
          {fight.data.status === EFightStatus.WAIT && <TeamOrderModal
            fight={fight}
            onUpdate={reload}
          />}
        </td>
        {(sizeType >= 17) && <>
          <td colSpan={2}></td>
          <td colSpan={1}>
            {([EFightStatus.READY, EFightStatus.WAIT].includes(fight.data.status)) && <><TeamOrderModal
              fight={fight}
              onUpdate={reload}
              color={'red'}
            /><br /></>}
            {fight.data.rounds?.sort((r1, r2) => r1.order - r2.order).map(r => {
              return <React.Fragment key={r.id}>
                <FighterLabel fighter={r.redFighter} /><br />
              </React.Fragment>
            })}
          </td>
          <td colSpan={1}></td>
          <td colSpan={1}>
            {([EFightStatus.READY, EFightStatus.WAIT].includes(fight.data.status)) && <><TeamOrderModal
              fight={fight}
              onUpdate={reload}
              color={'white'}
            /><br /></>}
            {fight.data.rounds?.sort((r1, r2) => r1.order - r2.order).map(r => {
              return <React.Fragment key={r.id}>
                <FighterLabel fighter={r.whiteFighter} /><br />
              </React.Fragment>
            })}
          </td>
          <td colSpan={3}></td>
        </>}
      </tr >;
    }

    let roundsEl = null;

    if (props.active) {
      roundsEl = fight.data.rounds?.sort((a: IApiRoundModel, b: IApiRoundModel) => {
        //if (a.id === fight.data.currentRoundId) return -1;
        //if (b.id === fight.data.currentRoundId) return 1;
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      }).map((round: IApiRoundModel, k: number) => {

        let active = false;
        if (fight.data.currentRoundId === undefined) {
          selectRound(round.id);
        }
        if (round.id === fight.data.currentRoundId) {
          active = true;
        }

        return <DndProvider
          backend={HTML5Backend}
          key={round.id + (round.reloadKey ? round.reloadKey : 0)}
        >
          <Round
            fightActions={fightActions}
            simpleActions={props.simpleActions}
            data={round}
            fightType={fight.data.type}
            withEncho={fight.data.withEncho}
            keyOrder={k}

            active={active}
            fightActive={props.active}
            dispatchChrono={props.dispatchChrono}
            dispatchNotification={props.dispatchNotification}
            /*dispatchModal={props.dispatchModal}*/
            selectRound={selectRound}
            editeable={roundEditeable}
            redFighterSelecteableList={fight.data.redFightTeam.fighters}
            whiteFighterSelecteableList={fight.data.whiteFightTeam.fighters}
            competition={props.competition}
            roundSelectLock={roundSelectLock}
            chronoState={props.chronoState}
          />
        </DndProvider>
      })

    }
    /*
        ( ID : {fight.data.id}  )
        */
    return (
      <>
        {firstLine ? firstLine : ""}
        {roundsEl}
      </>
    );
  }
  else return <div>{t('label.noround')}</div>;
}