import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { IAuthentication } from "../Auth/auth"
import { IConfiguration } from "../Util/Configuration"
import { IServiceTableNotification } from "../Util/ErrorMessage"

export interface IConfigurationProps {

    dispatchNotification: React.Dispatch<IServiceTableNotification>
    authentication: IAuthentication
}

export function loadConfigurationData(): IConfiguration {
    const data = Object.assign({
        selectedCompetitionIds: [],
        filter: {
            place: "",
            date: "",//date exact filter
            dateFrom: undefined,
        },
        withChrono: false,
        simplifiedAction: false
    },JSON.parse(localStorage.getItem('configuration') ?? '{}')) as IConfiguration;
    if (typeof data.filter.dateFrom === 'string') data.filter.dateFrom = new Date(data.filter.dateFrom);
    return data;
}

export function storeConfigurationData(configuration: IConfiguration) {
    localStorage.setItem('configuration', JSON.stringify(configuration));
}

export function useConfiguration(): [IConfiguration, Dispatch<SetStateAction<IConfiguration>>] {

    const [configuration, setConfiguration] = useState<IConfiguration>(loadConfigurationData());

    useEffect(() => {
        storeConfigurationData(configuration);
    }, [configuration])


    return [configuration, setConfiguration];
}