import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getCompetitions } from "../../Backend/ApiCompetition";
import { IApiCompetitionModel, IServiceCompetitionAdvancedOrdonancementRules, IServiceCompetitionAdvancedTableRules, emptyCompetitionModel } from "../../Model/ApiCompetitionModel";
import { getCompetition, getCompetitionsAdvancedTable, getCompetitionsListAdvanced } from "../../util/ApiCompetition";
import { SessionCache } from "../../util/SessionStorage";
import { IServiceTableNotification } from "../Util/ErrorMessage";

export function useCompetitionLoader(dispatchNotification: React.Dispatch<IServiceTableNotification> | undefined
    , competitionIdInit: number | undefined
    , withAdvanced: boolean): 
    [IApiCompetitionModel | undefined, Dispatch<SetStateAction<number | undefined>>, () => void] {
    const [competitionId, setCompetitionId] =
        useState<number | undefined>(competitionIdInit); //competition edited

    const [competition, setCompetition] =
        useState<IApiCompetitionModel | undefined>(undefined); //competition edited

    useEffect(() => {
        setCompetitionId(competitionIdInit);
    }, [competitionIdInit])

    useEffect(() => {
        if (competitionId) {
            if (competition?.id !== competitionId) {
                (async () => {
                    let c = await SessionCache.get('competition', competitionId, () => getCompetition(dispatchNotification, competitionId), 1000) ?? emptyCompetitionModel;
                    if (withAdvanced) {
                        const advancedTables = await getCompetitionsAdvancedTable<IServiceCompetitionAdvancedTableRules>(undefined, c.id, "ServicesTables");
                        c.advancedTables = advancedTables.current;
                        c.advancedTablesParent = advancedTables.parent;
                        const advancedOrdonancement = await getCompetitionsAdvancedTable<IServiceCompetitionAdvancedOrdonancementRules>(undefined, c.id, "ServiceOrdonancement")
                        c.advancedOrdonancement = advancedOrdonancement.current;
                        c.advancedOrdonancementParent = advancedOrdonancement.parent;
                    }
                    setCompetition(c);

                })();
            }
        }
        else {
            setCompetition(undefined);
        }
    }, [competition?.id, competitionId, dispatchNotification, withAdvanced])

    return [competition, setCompetitionId, () => {
        setCompetition(undefined);
    }]
}

export function useCompetitionsLoader(dispatchNotification: React.Dispatch<IServiceTableNotification>
    , competitionIdInit: Array<number>
    , withAdvanced: boolean): [Array<IApiCompetitionModel>, Dispatch<SetStateAction<Array<number>>>] {
    const [competitionIds, setCompetitionIds] =
        useState<Array<number>>(competitionIdInit); //competition edited

    const [competitions, setCompetition] =
        useState<Array<IApiCompetitionModel>>([]); //competition edited

    useEffect(() => {
        setCompetitionIds(competitionIdInit);
    }, [competitionIdInit])

    useEffect(() => {
        if (competitionIds) {
            (async () => {
                let newCompetitions = await getCompetitions(dispatchNotification, false, {
                    competitionIds: competitionIds
                });

                if (withAdvanced) {
                    let cas = await getCompetitionsListAdvanced(dispatchNotification, competitionIds);
                    for (const ca of cas) {
                        let c = newCompetitions.find(c => c.id === ca.competitionId);
                        if (c) {
                            for (const setting of ca.settings) {
                                switch (setting.serviceName) {
                                    case "ServiceOrdonancement":
                                        c.advancedOrdonancement = setting.current as IServiceCompetitionAdvancedOrdonancementRules;
                                        c.advancedOrdonancementParent = setting.parent as IServiceCompetitionAdvancedOrdonancementRules;
                                        break;
                                    case "ServicesTables":
                                        c.advancedTables = setting.current as IServiceCompetitionAdvancedTableRules;
                                        c.advancedTablesParent = setting.parent as IServiceCompetitionAdvancedTableRules;
                                        break;
                                }
                            }
                        }
                    }
                }
                setCompetition(newCompetitions);
            })();
        }
        else {
            setCompetition([]);
        }
    }, [competitionIds, dispatchNotification, withAdvanced])

    return [competitions, setCompetitionIds];
}