import React, { useEffect, useState } from "react";
import { IApiFighterFightTeam } from "../../Model/ApiTeamModel";
import { IApiResultStatus } from "../../util/ApiCall";
import { getApiObject, postApiObject } from "../Operation/GenericOperation";
import { IServiceTableNotification } from "../Util/ErrorMessage";

export interface ILazyFightTeamProps {
    id: number,
    dispatchNotification: React.Dispatch<IServiceTableNotification>
}



export interface IApiFighterFighter {
    id: number,
    firstname: string,
    lastname: string,
    date_creation: string,
    tstamp: string,
    license: string,
    firstname_alt: string,
    lastname_alt: string,
    nationality: string,
    club: string,
    birthdate: string ,
    grade: string,
    sex: number,
    valide: number,
    team: number,
    zekken_number: string,
    competition: string,
    region?: string
}

export const nonFighterFighter: IApiFighterFighter = {
    id: 0,
    firstname: "",
    lastname: "",
    date_creation: "",
    tstamp: "",
    license: "",
    firstname_alt: "",
    lastname_alt: "",
    nationality: "",
    club: "",
    birthdate: "2022-01-01",
    grade: "",
    sex: 1,
    valide: 0,
    team: 0,
    zekken_number: "",
    competition: ""
}


export interface IFighterFightTeamResult extends IApiResultStatus {
    data: {
        data: string
    },
}


export const nonFighterFightTeam: IApiFighterFightTeam = {
    id: 0,
    date_creation: 0,
    name: "none",
    competition: "",
    nationality: "",
    club: "",
    valide: 0,
    region: ""
}


export function LazyFightTeam(props: ILazyFightTeamProps) {
    const [fightTeam, setFightTeam] = useState<IApiFighterFightTeam>(nonFighterFightTeam);
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!loaded) {
            if (props.id) {
                getApiObject<IFighterFightTeamResult>("/team/read", props.id, {
                    succeed: false,
                    data: { data: JSON.stringify(nonFighterFightTeam) },
                    status: 400
                }, props.dispatchNotification, (window as any)['REACT_APP_API_COMBATTANTS_URL'])
                    .then((res: IFighterFightTeamResult) => {
                        let fightteam: IApiFighterFightTeam = JSON.parse(res.data.data)
                        setLoaded(true);
                        setFightTeam(fightteam);
                    });
            }
        } else {
            setFightTeam(nonFighterFightTeam);
        }
    }, [loaded, props.dispatchNotification, props.id]);

    return <>
        {fightTeam.name} {fightTeam.club} (id {fightTeam.id})
    </>
}


export async function lazyLoadFightTeams(props: ILazyFightTeamProps): Promise<Array<IApiFighterFightTeam>> {

    return postApiObject<IFighterFightTeamResult>(
        "/team/list"
        , {
            competition: props.id
        }, {
        status: 400,
        succeed: false,
        data: { data: JSON.stringify(nonFighterFightTeam) }
    }, props.dispatchNotification, (window as any)['REACT_APP_API_COMBATTANTS_URL'])
        .then((res: IFighterFightTeamResult) => {
            return JSON.parse(res.data.data) as Array<IApiFighterFightTeam>;
        })
}