import { emptyFightgroup, IApiFightGroupModel, IApiFightGroupResult } from "../../util/ApiModel/ApiFightgroupModel";
import { IApiCompetitionTreeModel } from "../../util/ApiModel/Following/ApiCompetitionTree";
import { IApiResult } from "../../UtilEvent/ApiCall";
import { ISchedullerFight } from "../Scheduller/SchedullerTable";
import { IServiceTableNotification } from "../Util/ErrorMessage";
import { deleteApiObject, getApiObject, patchApiObject, putApiObject } from "./GenericOperation";

/**
 * Reset calculated valye
 */
export async function clearCalculFightGroup(fightGroupId: number
    , dispatchNotification: React.Dispatch<IServiceTableNotification> | undefined): Promise<boolean> {
    return patchApiObject<IApiFightGroupResult>(`/FightGroups/${fightGroupId}/clearcalcul`, {}, emptyFightgroup, dispatchNotification).then((fightGroupRes) => {
        return fightGroupRes.succeed
    })
}

export async function getFightGroupNodeId(fightGroupNodeId: number, dispatchNotification: React.Dispatch<IServiceTableNotification> | undefined): Promise<IApiFightGroupModel | undefined> {
    return getApiObject<{
        data: IApiFightGroupModel,
        succeed: boolean;
    }
    >(`/FightGroups/fightGroupNodeId`, fightGroupNodeId, emptyFightgroup, dispatchNotification).then(d => {
        if (d.succeed) return d.data;
        else return undefined;
    });
}

export async function getFightGroup(fightGroupId: number, dispatchNotification: React.Dispatch<IServiceTableNotification> | undefined): Promise<IApiFightGroupModel | undefined> {
    return getApiObject<{
        data: IApiFightGroupModel,
        succeed: boolean;
    }
    >(`/FightGroups`, fightGroupId, emptyFightgroup, dispatchNotification).then(d => {
        if (d.succeed) return d.data;
        else return undefined;
    });
}

export async function deleteFightgroup(fightGroupId: number, dispatchNotification: React.Dispatch<IServiceTableNotification> | undefined): Promise<boolean> {
    return deleteApiObject<IApiResult>(`/fightgroups`, fightGroupId, {
        succeed: false
    }, dispatchNotification).then((res: IApiResult) => res.succeed);
}

export async function unCancelFightgroup(fightGroupId: number, dispatchNotification: React.Dispatch<IServiceTableNotification> | undefined): Promise<boolean> {
    return putApiObject<IApiResult>(`/fightgroups`, fightGroupId, {
        cancel:false
    }, {
        succeed: false
    }, dispatchNotification).then((res: IApiResult) => res.succeed);
}

export async function deleteFightgroupNodeId(fightGroupNodeId: number, dispatchNotification: React.Dispatch<IServiceTableNotification> | undefined, force?: boolean): Promise<boolean> {
    return deleteApiObject<IApiResult>(`/fightgroups?fightGroupNodeId=${fightGroupNodeId}${force ? '&force=true' : ''}`, null, {
        succeed: false
    }, dispatchNotification).then((res: IApiResult) => {
        return res.succeed
    })
}


export function isFollowedFightGroup(fightgroup: IApiFightGroupModel | ISchedullerFight | IApiCompetitionTreeModel) {
    return fightgroup.fightGroupNodeId >= 0
}