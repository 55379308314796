import { apipost, manageApiError } from "../../util/ApiCall";
import { ERoundEventTypeModel, IApiRoundEventCreationModel, IApiRoundEventModel, IApiRoundEventResult, emptyRoundEventResult } from "../../util/ApiModel/ApiEventModel";
import { IApiRoundModel, IApiRoundResult } from "../../util/ApiModel/ApiRoundModel";
import { IRoundEventState } from "../Table/Round";
import { EFighterColor, IActionEvent } from "../Table/Shiajo";
import { IServiceTableNotification } from "../Util/ErrorMessage";
import { deleteApiObject, postApiObject, putApiObject } from "./GenericOperation";

/**
 * @deprecated
 * @param previousRound 
 * @param event 
 * @returns 
 */
export function reduceRoundEvent(previousRound: IApiRoundModel, event: IActionEvent) {
    let newRound = Object.assign({}, previousRound);
    //traiter l'évènement
    let apiEventData: IApiRoundEventCreationModel | null = null;
    switch (event.type) {
        case ERoundEventTypeModel.DO:
        case ERoundEventTypeModel.MEN:
        case ERoundEventTypeModel.KOTE:
        case ERoundEventTypeModel.IPON:
        case ERoundEventTypeModel.FUSENGACHI:
        case ERoundEventTypeModel.TSUKI:
        case ERoundEventTypeModel.SUNE:
        case ERoundEventTypeModel.ASHI:
        case ERoundEventTypeModel.HANSOKU:
        case ERoundEventTypeModel.HANTEI:
            apiEventData = {
                fighterId: event.fighterColor === EFighterColor.white ? newRound.whiteFighterId : newRound.redFighterId,
                type: event.type,
                //apiSync: EApiDataSync.pending,
                date: new Date()
            }
            break;
        case ERoundEventTypeModel.PAUSE:
        case ERoundEventTypeModel.START:
        case ERoundEventTypeModel.END:
            apiEventData = {
                type: event.type,
                //apiSync: EApiDataSync.pending,
                date: new Date()
            }
            break;
        default:
            alert("other : " + event.type);
            break;
    }

    //chrono

    switch (event.type) {
        /* case ERoundEventTypeModel.PAUSE:
             if (newRound.chrono) newRound.chrono.pause();
             break;
         case ERoundEventTypeModel.START:
             if (newRound.chrono) {
                 newRound.chrono.start();
             }
             break;
         case ERoundEventTypeModel.END:
             if (newRound.chrono) newRound.chrono.end();
             break;*/
    }

    let resStatus = 0;
    //let tempstate = this.state;

    //data is prepared, transform it as event data
    if (apiEventData !== null) {
        /*let eventData: IRoundEventState = Object.assign({
            fighterId: 0,
            type: ERoundEventTypeModel.UNDEFINED,
            date: Date.now(),
            id: 0,
            roundId: 0,
            error: null,
            isLoaded: false,
            errorMessage: ""
        }, apiEventData)*/
        //tempstate.events.push(eventData);

        //persist local
        //tempstate.eventPushList.push(eventData);
        //this.persistStorage('eventPushList', round.eventPushList);
        //this.setState(tempstate);

        apipost<IApiRoundResult>('/rounds/' + newRound.id + "/events", apiEventData)
            .then(result => {
                return manageApiError<IApiRoundResult>(result)
            }).then(result => {

                //new state should have events because we had the new one before
                if ((newRound.events?.length ?? 0) > 0) {
                    //on remplace l'evenement du state par 
                    //@ts-ignore
                    let lastEvent: IRoundEventState = newstate.events.pop();
                    let newLastEvent = Object.assign(lastEvent, result);
                    //newRound.eventPushList.pop();

                    if (result.succeed) {
                        newRound.events?.push(newLastEvent);
                    }
                    if (!result.succeed) {
                        //newstate.eventPushList.push(newLastEvent);
                    }

                    //this.persistStorage('eventPushList', newstate.eventPushList);
                }

                if (result.succeed) {
                    //update round state
                    //@ts-ignore
                    let roundResult: IRoundState = result.round;



                    //it will impact fight group, reload
                    if (event.type === ERoundEventTypeModel.END) {
                        //round.reload()
                    }

                }
            });
    }
    return newRound;
}

export async function addEvent(
    round: IApiRoundModel,
    data: IApiRoundEventCreationModel
    , dispatchNotification: React.Dispatch<IServiceTableNotification>
    , noCheck?: boolean
): Promise<Boolean> {
    const eventResult = await postApiObject<IApiRoundEventResult>('/rounds/' + round.id + "/events" + (noCheck ? '?noCheck=true' : ''), data, {
        data: {
            type: ERoundEventTypeModel.UNDEFINED,
            date: new Date(),
            id: 0,
            roundId: 0,
            points: 0
        },
        succeed: false,
        status: 400
    }, dispatchNotification);
    return eventResult.succeed;

}

export async function deleteEvent(
    eventId: number
    , dispatchNotification: React.Dispatch<IServiceTableNotification>
    , noCheck?: boolean): Promise<Boolean> {


    let eventResult = await deleteApiObject<IApiRoundEventResult>('/RoundEvents/' + eventId + (noCheck ? '?noCheck=true' : ''), null, {
        data: {
            type: ERoundEventTypeModel.UNDEFINED,
            date: new Date(),
            id: 0,
            roundId: 0,
            points: 0
        },
        succeed: false,
        status: 400
    }, dispatchNotification);

    return eventResult.succeed;
}
export async function updateEvent(event:IApiRoundEventModel, dispatchNotification?: React.Dispatch<IServiceTableNotification>):Promise<boolean> {
    return putApiObject<IApiRoundEventResult>("/RoundEvents"
        , event.id
        , event
        , emptyRoundEventResult
        , dispatchNotification)
        .then((data: IApiRoundEventResult) => {
            if (data.succeed) {
                if (dispatchNotification) dispatchNotification({
                    operation: "update",
                    type: "success",
                    message: "info.round.edited"//JSON.stringify(data)
                });
            }
            return data.succeed
        })
}