import { ArrowDropDown, ArrowRight, Remove } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, ButtonGroup, Checkbox, FormControlLabel, FormGroup, Modal, Paper, Select, Tab, Tabs } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid, GridCellEditCommitParams, GridColDef, GridComparatorFn, GridRenderCellParams, GridRowParams, GridSortCellParams, GridSortItem, MuiBaseEvent, MuiEvent } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { deleteCompetition, getCompetitions, patchCompetition } from '../../Backend/ApiCompetition';
import { IApiCompetitionModel, ICompetitionFilter } from '../../Model/ApiCompetitionModel';
import { IAuthentication } from '../Auth/auth';
import { renderEventDateEditInputCell } from '../Event/EventDate';
import { EventForm } from '../Event/EventForm';
import { renderMartialArtEditInputCell } from '../Event/EventMartialArt';
import { EventProcess, renderEventProcessCell } from '../Event/EventProcess';
import { renderEventStatusEditInputCell } from '../Event/EventStatus';
import { EventUsers } from '../Event/EventUsers';
import { ShiaijosDataGrid } from '../Shiaijos/ShiaijosDataGrid';
import { useErrorMessage } from '../Util/ErrorMessage';

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '15%',
    left: '15%',
    //transform: 'translate(-15%, -15%)',
    width: '70%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
};

/*
const useStyles = makeStyles({
    table: {

    },
    tableRow: {
        "&$hover:hover": {
            backgroundColor: "blue"
        }
    },
    tableCell: {
        "$hover:hover &": {
            color: "pink"
        }
    },
    root: {
        //display: 'inline-flex',
        //alignItems: 'center',
    }
});*/

export default function TypeCompetitionInputCell(props: GridRenderCellParams) {
    const { id, value, api, field } = props;

    const handleChange = (event: any) => {
        api.setEditCellValue({ id, field, value: event.target.value }, event);
    };

    return (
        <div >
            <Select
                value={value}
                onChange={handleChange}>
                <MenuItem value={'competition'}>Competition</MenuItem>
                <MenuItem value={'grade'}>Grade</MenuItem>
            </Select>
        </div>
    );
}

function renderTypeCompetitionInputCell(params: any) {
    return <TypeCompetitionInputCell {...params} />;
}




type TGridSortItemReloadeable = {
    reload: number,
    sortItems: Array<GridSortItem>
}

export function Events(props: { authentication: IAuthentication }) {
    
    sessionStorage.setItem("module", "event");
    //const classes = useStyles();
    const [dispatchNotification, errorComponent] = useErrorMessage(true);
    const [competitionFilter, setCompetitionFilter] = useState<ICompetitionFilter>({
        pastEvent: false,
        closedEvent: false,
        ... (JSON.parse(localStorage.getItem("competitionFilter") ?? "{}"))
    })
    const [competitions, setCompetitions] = useState<Array<IApiCompetitionModel> | undefined>(undefined)
    const [createCompetition, setCreateCompetition] = useState<false | {
        type: "competition" | "group",
        parentId?: number
    }>(false);

    const [selectedCompetition, setSelectedCompetition] = useState<IApiCompetitionModel | undefined>(undefined)
    const { t } = useTranslation();
    const theader = 'label.header.competition';
    const [pageSize, setPageSize] = React.useState(parseInt(localStorage.getItem("pageSize") ?? "50"));
    const [reload, setReload] = useState<number>(Date.now);
    const [sortModel, setSortModel] = React.useState<TGridSortItemReloadeable>({
        reload: Date.now(),
        sortItems:
            [{
                field: 'id',
                sort: 'asc',
            }]
    }
    );
    const [selectedModalTab, setSelectedModalTab] = useState<number>(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [onLoadEventId, setOnLoadEventId] = useState<number | undefined>(undefined);


    useEffect(() => {
        localStorage.setItem("pageSize", pageSize.toString());
        localStorage.setItem("competitionFilter", JSON.stringify(competitionFilter));
    }, [pageSize, competitionFilter])

    useEffect(() => {

        const eventId = parseInt(searchParams.get("eventId") ?? "");
        if (!isNaN(eventId)) {
            setOnLoadEventId(eventId);
        }
    }, [searchParams]);

    useEffect(() => {
        if (onLoadEventId && competitions) {
            const competition = competitions?.find(c => c.id === onLoadEventId);
            if (competition) {
                if (competition?.type === "group") {
                    setCompetitionFilter({ ...competitionFilter, selectedGroup: competition.id })
                }
                setSelectedCompetition(competition);
            }
            else {
                alert("Competition non trouvée, vérifiez les filtres");
            }
            setOnLoadEventId(undefined);
        }
    }, [onLoadEventId, competitionFilter, competitions])

    console.log();
    //Competition list refresh
    useEffect(() => {
        let date = new Date();
        getCompetitions(dispatchNotification, false, {
            username: (props.authentication.scopes.includes("cnkdr_admin") || props.authentication.scopes.includes("cnkdr_superadmin")) ? undefined : props.authentication.name,
            type: "competitionANDgroup",
            fromDate: competitionFilter.pastEvent ? undefined : new Date(),
            withClosed: competitionFilter.closedEvent
        }).then((cs: IApiCompetitionModel[]) => {
            setCompetitions(cs.filter(c => {
                let valid = true;
                //let cdate = new Date(c.competitionDate);

                //if (!competitionFilter.pastEvent && c.competitionDate) valid = valid && Math.floor(cdate.getTime() / (1000 * 3600 * 24)) >= Math.floor(new Date().getTime() / (1000 * 3600 * 24))
                if (competitionFilter.closedEvent !== true && c.status) valid = valid && c.status !== 'Finished'

                return valid;
            }));
        })

    }, [competitionFilter.pastEvent, competitionFilter.closedEvent, dispatchNotification, reload]);


    useEffect(
        () => setSortModel({
            reload: Date.now(),
            sortItems: [...sortModel.sortItems]
        })
        , [competitions, competitionFilter.selectedGroup]);

    //competition list reset
    /*useEffect(() => {
        setCompetitions(undefined);
    }, [competitionFilter]);*/

    const actionCell = useCallback((props: any) => {
        const { api, id } = props;

        const handleDeleteClick = (event: any) => {
            event.stopPropagation();
            const options = {
                title: 'Competition delete confirmation',
                message: 'Do you confirm competition deletion ?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            let competition = competitions?.find(c => c.id === parseInt(id));
                            if (competition) {
                                deleteCompetition(dispatchNotification, competition).then(() => {
                                    api.updateRows([{ id, _action: 'delete' }]);
                                    setReload(Date.now);
                                })
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {

                        }
                    }
                ],
                childrenElement: () => <div />,
                closeOnEscape: true,
                closeOnClickOutside: true,
                willUnmount: () => { },
                afterClose: () => { },
                onClickOutside: () => { },
                onKeypressEscape: () => { },
                overlayClassName: "overlay-custom-class-name"
            };

            confirmAlert(options);

        };

        return <div >
            <SettingsIcon onClick={() => {
                setCreateCompetition(false);
                const competition = competitions?.find(c => c.id === parseInt(id));
                if (competition?.type === "group") {
                    setCompetitionFilter({ ...competitionFilter, selectedGroup: competition.id })
                }
                setSelectedCompetition(competition);
            }}
            />
            <IconButton
                onClick={handleDeleteClick}
            >
                <DeleteIcon fontSize="small" />
            </IconButton>
        </div>

    }, [competitions, dispatchNotification]);

    const childrenSortComparator: (field: keyof IApiCompetitionModel) => GridComparatorFn = (field: keyof IApiCompetitionModel) => {
        return (a: any, b: any, cellParams1: GridSortCellParams, cellParams2: GridSortCellParams) => {
            const arow = cellParams1.api.getRow(cellParams1.id);
            const brow = cellParams2.api.getRow(cellParams2.id);
            let aparentId = cellParams1.id;
            let bparentId = cellParams2.id;
            let sort = cellParams1.api.getSortModel().find((s: any) => s.field === cellParams1.field)?.sort ?? 'asc';
            if (arow.parentId) {
                const aparent = cellParams1.api.getRow(arow.parentId);
                if (aparent) {
                    a = aparent[field];
                    aparentId = arow.parentId;
                    if (cellParams2.id === aparentId) {
                        return sort === 'desc' ? -1 : 1
                    }
                }
                else {
                    return sort === 'desc' ? -1 : 1
                }
            }

            if (brow.parentId) {

                const bparent = cellParams2.api.getRow(brow.parentId);
                if (bparent) {
                    b = bparent[field];
                    bparentId = brow.parentId;
                    if (cellParams1.id === bparentId) {
                        return sort === 'desc' ? 1 : -1
                    }
                }
                else {
                    return sort === 'desc' ? -1 : 1
                }
            }
            if (a === b) {
                a = aparentId;
                b = bparentId;
            }
            if (a === b) {
                a = cellParams1.id;
                b = cellParams2.id;
            }

            if (typeof a === "number") return a - b;
            else return a.localeCompare(b);
        }
    }
    const competitionWidth = competitions ? competitions.reduce((e, c) => Math.min(500, Math.max(e, c.fullname?.length * 9)), 200) : 200;
    const maxcompetitionWidth = 400;

    const columns: GridColDef[] = [
        {
            field: 'id', headerName: 'id', width: 100
            , sortComparator: childrenSortComparator('id')
        },
        {
            field: '',
            headerName: '',
            renderCell: actionCell,
            sortable: false,
            width: 75,
            headerAlign: 'center',
            filterable: false,
            align: 'center',
            disableColumnMenu: true,
            disableReorder: true,

        },
        {
            field: 'type', headerName: t(`${theader}.type`), minWidth: 150, editable: true, renderEditCell: renderTypeCompetitionInputCell
            , renderCell: (params: GridRenderCellParams) => {
                return t("label.event." + params.value)
            }
            , sortComparator: childrenSortComparator('type')
        },
        {
            field: 'fullname', headerName: t(`${theader}.name`)
            , minWidth: competitionWidth
            , editable: true
            , renderCell: (params: GridRenderCellParams) => {
                if (params.row.parentId) {
                    return <><Remove />{params.value}</>
                }
                else if (params.row.type === "group") {
                    if (competitionFilter.selectedGroup === params.row.id) {
                        return <><ArrowDropDown />{params.value}</>
                    }
                    else {
                        return <><ArrowRight />{params.value}</>

                    }

                }
                else {
                    return <>{params.value}</>
                }
            }
            , sortComparator: childrenSortComparator('fullname')
        },
        {
            field: 'martialArt',
            headerName: t(`${theader}.martialArt`),
            minWidth: 150, editable: true,
            renderEditCell: renderMartialArtEditInputCell
            , sortComparator: childrenSortComparator('martialArt')
        },
        {
            field: 'category',
            headerName: t(`${theader}.category`),
            minWidth: 150, editable: true,
            sortComparator: childrenSortComparator('category')

        },
        { field: 'place', headerName: t(`${theader}.place`), minWidth: 150, editable: true },
        {
            field: 'status', headerName: t(`${theader}.status`), minWidth: 110, editable: true,
            renderEditCell: renderEventStatusEditInputCell,
            renderCell: (params: any) => {
                /*let color: undefined | 'primary' | 'secondary' | 'default' | 'error' | 'info' | 'success' | 'warning';
                switch (params.value as TEventStatus) {
                    case "Created": color = 'info'; break;
                    case "DrawingsDone": color = 'primary'; break;
                    case "DrawingsPublished": color = 'success'; break;
                    case "Ongoing": color = 'secondary'; break;
                    case "RegistrationOpen": color = 'error'; break;
                    case "RegistrationClosed": color = 'error'; break;
                    case "Finished": color = 'info'; break;
                }

                return <Tooltip title={t(`label.competition.status.${params.value}`)}>
                    <Chip size="small" color={color} label={t(`label.competition.status.${params.value}`)} />
                </Tooltip>*/
                return <>{t(`label.competition.status.${params.value}`)}</>
            }
            , sortComparator: childrenSortComparator('status')
        },


        {
            field: 'competitionDate', headerName: t(`${theader}.competitionDate`), minWidth: 150, editable: true
            , type: 'date'
            , renderEditCell: renderEventDateEditInputCell
            , sortComparator: childrenSortComparator('competitionDate')
        },
        {
            field: 'process',
            headerName: t(`${theader}.process`),
            valueGetter: (params: any) => params.getValue(params.id, 'status'),
            minWidth: 600,
            editable: false,
            renderCell: renderEventProcessCell((c) => {
                setSelectedCompetition(undefined);
                setCreateCompetition(c);
            }, dispatchNotification, () => setReload(Date.now), competitionWidth >= maxcompetitionWidth, t)
            , sortable: false,
            flex: 1,
        }
        //{ field: 'poolsize', headerName: t(`${theader}.poolsize`), width: 150, editable: true, type: 'number' },
        //{ field: 'teamSize', headerName: t(`${theader}.teamSize`), width: 150, editable: true },
        //{ field: 'teamSizeMax', headerName: t(`${theader}.teamSizeMax`), width: 150, editable: true },
        //{ field: 'combatTime', headerName: t(`${theader}.combatTime`), width: 150, editable: true },
        //{ field: 'rules', headerName: t(`${theader}.rules`), width: 150, editable: true },
        //{ field: 'teamOrderChangeAllowed', headerName: t(`${theader}.teamOrderChangeAllowed`), width: 150, editable: true },
    ];

    let handleCellEditCommit = (params: GridCellEditCommitParams, event: MuiEvent<MuiBaseEvent>, details?: any) => {
        console.log(params);
        let competition: IApiCompetitionModel | undefined = competitions?.find(c => c.id === params.id);
        if (competition) {
            let newCompetition: IApiCompetitionModel = { ...competition };
            let field: keyof IApiCompetitionModel = params.field as keyof IApiCompetitionModel;
            //@ts-ignore
            newCompetition[field] = params.value;
            patchCompetition(dispatchNotification, newCompetition).then(c => {

            })
            console.log(competition);
        }
        /*postCompetition(dispatchNotification, )
        let newCompetitions = await produce(additionnalData, async function (additionnalDataDraft) {
            additionnalDataDraft.products = await importCostFile(paths[0], additionnalDataDraft);
        });*/
    }

    const getRowClassName: ((params: GridRowParams, details?: any) => string) | undefined =
        (params) => {
            return `grid-row-${params.row.type}`
        };



    const competitionShowFilter = (c: IApiCompetitionModel) => {
        let valid = true;
        if (competitionFilter.selectedGroup) valid = valid && ((!c.parentId) || c.parentId === competitionFilter.selectedGroup)
        else valid = valid && (!c.parentId)
        return valid;
    }


    const a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return <>
        <Modal
            open={selectedCompetition !== undefined || createCompetition !== false}
            onClose={(event) => {
                setReload(Date.now);
                setSelectedCompetition(undefined);
                setCreateCompetition(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle} id="modal-box">
                {/* FORM CREATE */}
                {createCompetition !== false && <Grid item xs={12}>
                    <EventForm
                        type={createCompetition.type}
                        parentId={createCompetition.parentId}
                        competition={undefined}
                        dispatchNotification={dispatchNotification}
                        onChange={(c) => {
                            setReload(Date.now)
                            setCreateCompetition(false);
                        }}
                        onCancel={() => {
                            setCreateCompetition(false);
                        }}
                    />
                </Grid>}
                {selectedCompetition && <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectedModalTab} onChange={(event: React.SyntheticEvent, newValue: number) => {
                            setSelectedModalTab(newValue);
                        }} aria-label="basic tabs example">
                            <Tab label="Configuration" {...a11yProps(0)} />
                            <Tab label="Shiaijos" {...a11yProps(1)} />
                            <Tab label="Roles" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <Grid container spacing={2}>
                        {selectedModalTab === 0 && <Grid container item xs={12}>
                            <Grid item xs={12} id="modal-btn-top">
                                <EventProcess
                                    currentStatus={selectedCompetition.status}
                                    dispatchNotification={dispatchNotification}
                                    event={selectedCompetition}
                                    onChange={() => {
                                        setReload(Date.now);
                                        setSelectedCompetition(undefined);
                                    }}
                                    small={false}
                                />
                            </Grid>
                            <EventForm competition={selectedCompetition} dispatchNotification={dispatchNotification}
                                onChange={(c) => {
                                    setReload(Date.now);
                                    setSelectedCompetition(undefined);
                                }}
                                onCancel={(() => {
                                    setSelectedCompetition(undefined);
                                })}
                            />
                        </Grid>}

                        {selectedModalTab === 1 && selectedCompetition && <Grid container item xs={12}>
                            {/* SHIAIJOS */}
                            <ShiaijosDataGrid
                                authentication={props.authentication}
                                competition={selectedCompetition}
                                competitionFilter={competitionFilter}
                                dispatchNotification={dispatchNotification}
                                group={selectedCompetition.parentId ? competitions?.find(c => c.id === selectedCompetition.parentId) : undefined}
                            />
                        </Grid>}

                        {/* USERS */}
                        {selectedModalTab === 2 && selectedCompetition && <Grid container item xs={12} rowSpacing={2}>
                            <EventUsers
                                competitionId={selectedCompetition.id}
                                eventDispatcher={dispatchNotification}
                            />
                        </Grid>}
                    </Grid>


                </>}
            </Box>
        </Modal>

        <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item xs={12}>
                {errorComponent}
            </Grid>
            <Grid item xs={3}>
                <Paper elevation={3}>
                    <h3>{t("label.event.filter")} </h3>
                    <FormGroup row={true}>
                        <FormControlLabel control={<Checkbox
                            checked={competitionFilter.pastEvent}
                            onClick={() => setCompetitionFilter({ ...competitionFilter, pastEvent: !competitionFilter.pastEvent })}
                        />} label={t("label.event.pastEvent")} />
                        <FormControlLabel control={<Checkbox
                            checked={competitionFilter.closedEvent}
                            onClick={() => setCompetitionFilter({ ...competitionFilter, closedEvent: !competitionFilter.closedEvent })}
                        />} label={t("label.event.closedEvent")} />
                    </FormGroup>
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <ButtonGroup variant="contained">
                    <Button style={{ margin: '2px' }} onClick={() => {
                        setSelectedCompetition(undefined);
                        setCreateCompetition({
                            type: "group"
                        });
                    }}>
                        {t('action.event.createCompetitionGroup')}
                    </Button>

                    <Button style={{ margin: '2px' }} onClick={() => {
                        setSelectedCompetition(undefined);
                        setCreateCompetition({
                            type: "competition"
                        });
                    }}>
                        {t('action.event.createCompetition')}
                    </Button>
                </ButtonGroup>
            </Grid>

            <Grid item xs={12}>
                {competitions && <DataGrid
                    style={{ backgroundColor: 'white' }}
                    sortModel={sortModel.sortItems}
                    onSortModelChange={(model) => {
                        if (JSON.stringify(model) !== JSON.stringify(sortModel.sortItems)) {
                            setSortModel({
                                reload: Date.now(),
                                sortItems: model
                            });
                            setSelectedCompetition(undefined);
                            setCompetitionFilter({ ...competitionFilter, selectedGroup: undefined })

                        }

                    }}
                    rows={competitions.filter(competitionShowFilter)}
                    columns={columns}
                    onSelectionModelChange={(competitionIds: Array<string | number>) => {
                        setCreateCompetition(false);
                        const competition = competitions.find(c => c.id === competitionIds[0]);
                        if (competition?.type === "group") {
                            setCompetitionFilter({ ...competitionFilter, selectedGroup: competition.id })
                        }
                        //setSelectedCompetition(competition);
                    }}
                    onCellEditCommit={handleCellEditCommit}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20, 50]}

                    autoHeight
                    pagination
                    getRowClassName={getRowClassName}

                />}
            </Grid>




        </Grid>
    </>;
}