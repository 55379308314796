import { IApiRoundEventModel } from "../../util/ApiModel/ApiEventModel";
import { IApiFightModel } from "../../util/ApiModel/ApiFightModel";
import { IApiRoundModel } from "../../util/ApiModel/ApiRoundModel";

var debug = require('debug')('ServiceTablesMinimalFrontend:ReloadRules')

export function shouldReloadRound(currentValue:IApiRoundEventModel):boolean {
    
    return true;
}

export function shouldReloadFight(previousValue:IApiRoundModel, currentValue:IApiRoundModel):boolean {
    let previousStatus = previousValue.status;
    let currentStatus = currentValue.status;
    if (previousStatus !== currentStatus) debug("Should reload fight");
    return previousStatus !== currentStatus;
}

export function shouldReloadFightGroup(previousValue:IApiFightModel, currentValue:IApiFightModel):boolean {
    let previousStatus = previousValue.status;
    let currentStatus = currentValue.status;

    return previousStatus !== currentStatus;
}
