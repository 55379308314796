import { Alert, FormControlLabel, InputLabel, Select, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { ContentState, EditorState, convertFromHTML, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import React, { useCallback, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from 'react-i18next';
import { getCompetition, patchCompetition, patchCompetitionsFreeze, postCompetition, postShiajos } from '../../Backend/ApiCompetition';
import { IApiCompetitionModel, IApiEditCompetitionModel, emptyEditCompetitionModel } from '../../Model/ApiCompetitionModel';

import { IEventNotification } from '../Util/ErrorMessage';
import { EventDatePicker } from './EventDate';
import { EventMartialArt } from './EventMartialArt';
import { EventStatus, TEventStatus } from './EventStatus';
import { EventType } from './EventType';
import { GroupValue } from './GroupValue';

function ValidAlert(props: { error: boolean, helperText: string | undefined }): JSX.Element {
    if (props.error) {
        return <Alert color='error'>
            {props.helperText}
        </Alert>
    }
    else {
        return <></>;
    }
}

function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}

function getDateStringFromEvent(e: any) {
    const tim: number = e.getTime();
    const dat = new Date(tim);
    return `${dat.getFullYear()}-${padTo2Digits(dat.getMonth() + 1)}-${padTo2Digits(dat.getDate())}`;
}


const creationDefaultEvent: IApiEditCompetitionModel = {
    id: 0,
    martialArt: "KENDO",
    rules: 'TwoQualifiedPerPool',
    poolsize: 3,
    teamSize: 1,
    teamSizeMax: 1
}

/*id: number,
    combatTime: number,
    comment: string,
    fullname: string,
    place: string,
    poolsize: number,
    rules: string,
    status: string, "Created" | "RegistrationOpen" | "RegistrationClosed" | "DrawingsDone" | "DrawingsPublished" | "Ongoing" | "Finished"
    teamOrderChangeAllowed: number,
    teamSize: number,
    teamSizeMax: number,
    martialArt: "Kendo" | "Chambara" | "Jodo" | "Iaido" | "Naginata",
    advancedTables?: IServiceCompetitionAdvancedTableRules,
    advancedOrdonancement?: IServiceCompetitionAdvancedOrdonancementRules,
    offlineStatus: string,
    competitionDate: Date,
    type: "competition" | "grade"*/
export function EventForm(props: {
    type?: "competition" | "group",
    parentId?: number
    competition: IApiCompetitionModel | undefined,
    dispatchNotification: React.Dispatch<IEventNotification>,
    onChange: (competiton: IApiEditCompetitionModel) => void
    onCancel: () => void
}) {
    const { t } = useTranslation();
    const [competition, setCompetition] = useState<IApiEditCompetitionModel>(Object.assign(
        {}
        , props.competition ?? emptyEditCompetitionModel
        , props.competition ?? creationDefaultEvent
        , props.type ? { type: props.type } : {}
        , props.parentId ? { parentId: props.parentId } : {}
    ));
    const [parent, setParent] = useState<IApiCompetitionModel | undefined>(undefined);
    const [selectedFile, setSelectedFile] = useState<File | undefined>();
    const [pictureMessage, setPictureMessage] = useState<undefined | string>();
    const [message, setMessage] = useState<undefined | string>();
    const [editorState, setEditorState] = useState(() => {
        //const blocksFromHtml = htmlToDraft(this.props.content);
        const data = props.competition?.information ? convertFromHTML(props.competition?.information) : undefined;
        //const { contentBlocks, entityMap } = blocksFromHtml;
        if (data) {
            const contentState = ContentState.createFromBlockArray(data.contentBlocks, data.entityMap);
            return EditorState.createWithContent(contentState);
        }
        else {
            return EditorState.createEmpty();
        }
    }
    );

    const update = () => {
        if (competition.id) {
            patchCompetition(props.dispatchNotification, competition).then(d => {
                if (d) {
                    setCompetition(d);
                    props.onChange(d);
                }
            })
        }
        else {
            postCompetition(props.dispatchNotification, competition).then(d => {
                if (d.id > 0) {
                    setMessage(t(`label.competition.created`));
                    if (!d.parentId) {
                        postShiajos(props.dispatchNotification, d.id, {
                            fullname: "A",
                            shortname: "A",
                            comment: "",
                            order: 0
                        }).then(() => {

                        });
                    }
                    setCompetition(d);
                }
            })
        }
    }


    useEffect(() => {
        //setEditorState( props.competition?.information ? EditorState.create(convertFromHTML(props.competition.information)) :  EditorState.createEmpty());
    }, [props.competition])

    useEffect(() => {
        //https://joshtronic.com/2017/10/05/react-draft-wysiwyg-with-mongodb/

        //console.log( draftToHtml(convertToRaw(editorState.getCurrentContent())));
        //console.log(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
    }, [editorState]);

    useEffect(() => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            //@ts-ignore
            cnkdrSsoGetIdToken().then((token) => {
                if (competition?.id) {
                    fetch(
                        `${(window as any)['REACT_APP_API_COMP_URL']}/competitions/${competition.id}/document/publicupload`,
                        {
                            method: 'POST',
                            body: formData,
                            headers: {
                                'Authorization': 'Bearer ' + token
                            }
                        }
                    )
                        .then((response) => {
                            return response.json().then(json => [json, response.status === 201]);
                        })
                        .then(([result, succeed]) => {
                            if (succeed) {
                                setPictureMessage(undefined);

                                const picture = result.path;
                                patchCompetition(props.dispatchNotification, {
                                    id: competition.id,
                                    picture: picture
                                }).then(d => {
                                    if (d) {
                                        setCompetition(d);
                                    }
                                })
                                console.log('Success:', result);
                            }
                            else {
                                if (result['detail']) {
                                    setPictureMessage(result['detail']);
                                }
                                else {
                                    setPictureMessage("Upload error");
                                }
                            }
                            setSelectedFile(undefined)
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                            setSelectedFile(undefined)
                        });
                }
            })
        }
    }, [selectedFile, competition.id, props.dispatchNotification]);;


    useEffect(() => {
        setCompetition(Object.assign({}
            , props.competition ?? emptyEditCompetitionModel
            , props.competition ?? creationDefaultEvent
            , props.type ? { type: props.type } : {}
            , props.parentId ? { parentId: props.parentId } : {}
        ));
        const parentId = props.parentId ?? props.competition?.parentId;
        if (parentId) {
            getCompetition(props.dispatchNotification, parentId).then(d => {
                setParent(d);
            })
        }
        else {
            setParent(undefined);
        }


    }, [props.competition, props.type, props.parentId, props.competition?.parentId, props.dispatchNotification])



    const validField = useCallback((field: keyof IApiCompetitionModel,): { error: boolean, helperText: string | undefined } => {
        let result: { error: boolean, helperText: string | undefined } = { error: false, helperText: undefined }
        switch (field) {
            case 'fullname':
                if ((competition[field] ?? "").length <= 0) {
                    result = {
                        error: true,
                        helperText: "Field must not be empty"
                    }
                }
                break;


            case "competitionDate":
            case "place":
            case "martialArt":
            case "teamSize":
            case "teamSizeMax":
            case "poolsize":
            case "rules":
            case "type":
                const value = competition[field] ?? '';
                const nogroupRule = competition.parentId === undefined || competition.type === "group";
                if (nogroupRule && String(value).length <= 0) {
                    result = {
                        error: true,
                        helperText: "Field must not be empty"
                    }
                }
                break;
        }
        return result;
    }, [competition]);

    const isValid = useCallback((): Boolean => {

        for (const k in competition) {
            if (validField(k as keyof IApiCompetitionModel).error === true) {
                return false;
            }
        }
        return true;
    }, [validField, competition])

    const updateRowButton = message === undefined
        ? <Grid item xs={12}>
            <Button variant="contained"
                onClick={update}
                disabled={!isValid()}
            >

                {competition.id ? t(`action.event.update`) : t(`action.event.create`)}
            </Button>
        </Grid>
        :

        <Grid item xs={12}>
            <Alert color={'success'}>
                {message}
            </Alert>
            <Button variant="contained"
                onClick={() => {
                    props.onChange(competition)
                }}
            >
                {t(`action.event.close`)}
            </Button>
        </Grid>;

    return <form>
        <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            id="modal-form-event"
        >
            <Grid item xs={6}>
                {t(`label.competition.competitionId`)} : {competition.id}
            </Grid>
            <Grid item xs={6}>
                {t(`label.competition.parentId`)} : {competition.parentId}
            </Grid>
            <Grid item xs={6}>{t(`label.competition.picture`)} : <input type="file" id="picture" onChange={(event) => {
                if (event?.target?.files) setSelectedFile(event.target.files[0]);
            }} />
            </Grid>
            <Grid item xs={6}>
                {competition.picture && <img style={{ height: "100px" }} src={competition.picture} alt={competition.picture} />}
            </Grid>
            <Grid item xs={12}>
                {pictureMessage && <Alert color='warning'>{pictureMessage}</Alert>}
            </Grid>
            <Grid item xs={6}>
                <InputLabel id="poolsize-label">{(competition.type === "group") ? t(`label.event.fullname`) : t(`label.competition.fullname`)}</InputLabel>
                <TextField
                    fullWidth={true}
                    id="standard-basic"

                    {...validField('fullname')}
                    value={competition.fullname}
                    onChange={(e) => setCompetition({ ...competition, fullname: e.target.value })}
                />
            </Grid>
            <Grid item xs={3}>
                <InputLabel id="poolsize-label">{t(`label.competition.Type`)}</InputLabel>
                <EventType
                    value={competition.type}
                    onChange={(e) => setCompetition({ ...competition, type: (e.target.value.length > 0 ? e.target.value : undefined as any) })}
                />
                <ValidAlert {...validField('type')} />
            </Grid>
            {competition.id > 0 && <Grid item xs={3}>
                <InputLabel id="poolsize-label">{t(`label.competition.Status`)}</InputLabel>
                {competition.status !== "offline" && <EventStatus
                    value={competition.status}
                    onChange={(e) => {
                        confirmAlert({
                            title: "Changement de status",
                            message: `Attention, vous allez changer le status manuellement : les services peuvent ne pas être correctement initialisés.
                            Vous devriez utiliser les boutons en haut de cette fenêtre ou dans la colone processus pour dérouler les étapes de l'événement.
                            Voulez vous continuer ?
                            `,
                            buttons: [
                                {
                                    label: 'Yes',
                                    onClick: () => {
                                        setCompetition({ ...competition, status: e.target.value as TEventStatus })
                                    }
                                },
                                {
                                    label: 'No',
                                    onClick: () => {

                                    }
                                }
                            ],
                        });

                    }}
                />}
                {competition.status === "offline" && <Button onClick={() => {
                    patchCompetitionsFreeze(props.dispatchNotification, competition.id, false).then((result) => {
                        if (result){
                            setCompetition(result);
                        }
                    })
                }}>Passer online</Button>
                
                }
                <ValidAlert {...validField('status')} />
            </Grid>}
            <Grid item xs={6}>
                <InputLabel id="poolsize-label">{t(`label.competition.martialArt`)}</InputLabel>
                {parent && <GroupValue value={parent.martialArt} />}
                <EventMartialArt
                    dispatchNotification={props.dispatchNotification}
                    value={competition.martialArt}
                    onChange={(e) => setCompetition({ ...competition, martialArt: e.target.value.length > 0 ? e.target.value : undefined })}
                />
                <ValidAlert {...validField('martialArt')} />
            </Grid>


            <Grid item xs={6}>
                <InputLabel id="poolsize-label">{t(`label.competition.Category`)}</InputLabel>
                {parent && <GroupValue value={parent.category} />}
                <TextField
                    id="standard-basic"
                    label={t(`label.competition.Category`)}
                    {...validField('category')}
                    value={competition.category ?? ""}
                    onChange={(e) => {
                        setCompetition({ ...competition, category: e.target.value.length > 0 ? e.target.value : undefined }); return true;
                    }}

                />
            </Grid>
            {updateRowButton}
            {/* Second line */}

            <Grid item xs={3}>
                {parent && <GroupValue value={parent.competitionDate} />}
                {(parent === undefined || parent.competitionDate === undefined) && <br />}
                <EventDatePicker
                    value={competition.competitionDate ? new Date(competition.competitionDate) : null}
                    label={t(`label.competition.Date`)}
                    onChange={(e) => {
                        let newDate = getDateStringFromEvent(e);
                        setCompetition({
                            ...competition,
                            competitionDate: newDate/*new Date().toISOString().split('T')[0] */
                        })
                    }}
                />
                <ValidAlert {...validField('competitionDate')} />
            </Grid>
            <Grid item xs={3}>
                {parent && <GroupValue value={parent.competitionEndDate ?? ""} />}
                <EventDatePicker
                    value={competition.competitionEndDate ? new Date(competition.competitionEndDate) : null}
                    label={t(`label.competition.EndDate`)}
                    onChange={(e) => {
                        let newDate = getDateStringFromEvent(e);
                        setCompetition({
                            ...competition,
                            competitionEndDate: newDate/*new Date().toISOString().split('T')[0] */
                        })
                    }}
                />
                <ValidAlert {...validField('competitionEndDate')} />
            </Grid>
            <Grid item xs={6}>
                {parent && <GroupValue value={parent.place} />}
                <TextField
                    id="standard-basic"
                    fullWidth={true}
                    label={t(`label.competition.Place`)}
                    value={competition.place ?? ""}
                    {...validField('place')}
                    onChange={(e) => setCompetition({ ...competition, place: e.target.value.length > 0 ? e.target.value : undefined })}
                />

            </Grid>

            <Grid item xs={12}>
                {t(`label.competition.registering`)}
            </Grid>
            <Grid item xs={3}>
                {parent && parent.registeringDate && <GroupValue value={parent.registeringDate} />}
                {parent && <GroupValue value={parent.registeringDate ?? ""} />}
                <EventDatePicker
                    value={competition.registeringDate ? new Date(competition.registeringDate) : null}
                    label={t(`label.competition.registeringDate`)}
                    onChange={(e) => {
                        let newDate = getDateStringFromEvent(e);
                        setCompetition({
                            ...competition,
                            registeringDate: newDate/*new Date().toISOString().split('T')[0] */
                        })
                    }}
                />
                <ValidAlert {...validField('registeringDate')} />
            </Grid>
            <Grid item xs={3}>
                {parent && parent.registeringEndDate && <GroupValue value={parent.registeringEndDate} />}
                {parent && <GroupValue value={parent.registeringEndDate ?? ""} />}
                <EventDatePicker

                    value={competition.registeringEndDate ? new Date(competition.registeringEndDate) : null}
                    label={t(`label.competition.registeringEndDate`)}
                    onChange={(e) => {
                        let newDate = getDateStringFromEvent(e);
                        setCompetition({
                            ...competition,
                            registeringEndDate: newDate/*new Date().toISOString().split('T')[0] */
                        })
                    }}
                />
                <ValidAlert {...validField('registeringEndDate')} />
            </Grid>
            <Grid item xs={6}>
                <FormControl>
                    {parent && <GroupValue value={parent.registerOrganisation ? t("label.word.yes") : t("label.word.no")} />}
                    <FormControlLabel
                        control={<Checkbox
                            id="standard-basic"
                            checked={((competition.registerOrganisation ?? 0) > 0)}
                            onClick={(e) => {

                                setCompetition({
                                    ...competition,
                                    registerOrganisation: ((competition.registerOrganisation ?? 0) + 1) % 2
                                })
                            }}
                        />}
                        label={t(`label.competition.registerOrganisation`)}
                    />

                </FormControl>
            </Grid>
            {updateRowButton}
            {/* Third line */}

            <Grid item xs={12}>
                <InputLabel id="poolsize-label">{t(`label.competition.PoolSelection`)}</InputLabel>
                {parent && <GroupValue value={parent.rules} />}
                <Select
                    fullWidth={true}
                    label={t(`label.competition.PoolSelection`)}
                    value={competition.rules}
                    onChange={(e) => {
                        setCompetition({
                            ...competition,
                            rules: e.target.value.length > 0 ? e.target.value : undefined as any,
                            poolsize: e.target.value === "DirectDrawing" ? 2 : competition.poolsize
                        })
                    }
                    }
                >
                    <MenuItem value="TwoQualifiedPerPool">{t("label.rules.twoQualifiedPerPool")}</MenuItem>
                    <MenuItem value="OneQualifiedPerPool">{t("label.rules.oneQualifiedPerPool")}</MenuItem>
                    <MenuItem value="DirectDrawing">{t("label.rules.directDrawing")}</MenuItem>
                </Select>
                <ValidAlert {...validField('rules')} />
            </Grid>
            {/* Fourth line */}
            <Grid item xs={3}>
                <InputLabel id="poolsize-label">{t(`label.competition.Poolsize`)}</InputLabel>
                {parent && <GroupValue value={parent.poolsize.toString()} />}
                <Select
                    disabled={competition.rules === "DirectDrawing"}
                    fullWidth={true}
                    value={competition.poolsize ?? "undefined"}
                    onChange={(e) => {
                        let valueField: number | undefined = parseInt(e.target.value.toString());
                        if (isNaN(valueField)) valueField = undefined;
                        setCompetition({ ...competition, poolsize: valueField });
                    }
                    }
                >
                    <MenuItem value="undefined">{t('label.group.groupvalue')}</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                </Select>
                <ValidAlert {...validField('poolsize')} />

            </Grid>
            <Grid item xs={3}>
                {parent && <GroupValue value={parent.teamSize.toString()} />}
                <InputLabel id="teamSize-label">{t(`label.competition.TeamSize`)}</InputLabel>
                <Select
                    fullWidth={true}
                    value={competition.teamSize ?? "undefined"}
                    onChange={(e) => {
                        let valueField: number | undefined = parseInt(e.target.value.toString());
                        if (isNaN(valueField)) valueField = undefined;
                        setCompetition({ ...competition, teamSize: valueField });
                    }
                    }
                >
                    <MenuItem value="undefined">{t('label.group.groupvalue')}</MenuItem>
                    <MenuItem value="1">Individuel</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                </Select>
                <ValidAlert {...validField('teamSize')} />
            </Grid>
            <Grid item xs={3}>
                {parent && <GroupValue value={parent.teamSizeMax.toString()} />}
                <InputLabel id="teamSizeMax-label">{t(`label.competition.TeamSizeMax`)}</InputLabel>
                <Select
                    fullWidth={true}
                    value={competition.teamSizeMax ?? "undefined"}
                    onChange={(e) => {
                        let valueField: number | undefined = parseInt(e.target.value.toString());
                        if (isNaN(valueField)) valueField = undefined;
                        setCompetition({ ...competition, teamSizeMax: valueField });
                    }
                    }
                >
                    <MenuItem value="undefined">{t('label.group.groupvalue')}</MenuItem>
                    <MenuItem value="1">Individuel</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                </Select>
                <ValidAlert {...validField('teamSizeMax')} />
            </Grid>

            {/*
            <Grid item xs={3}>
                {parent && <GroupValue value={parent.combatTime.toString()} />}
                <TextField
                    id="standard-basic"
                    label={t(`label.competition.CombatTime`)}
                    value={competition.combatTime ?? ''}
                    onChange={(e) => setCompetition({ ...competition, combatTime: e.target.value.length > 0 ? parseInt(e.target.value) : undefined })}
                />
            </Grid>
             <Grid item xs={6}>
                {parent && <LabelChangeRule rules={parent.teamOrderChangeAllowed}/>}
                <FormControl component="fieldset" >
                    <FormLabel component="legend">
                        {t(`label.competition.Orderchangerules`)}
                    </FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox
                                id="standard-basic"
                                checked={(((competition.teamOrderChangeAllowed ?? 0) % 2) === 1)}
                                onClick={(e) => {

                                    setCompetition({
                                        ...competition,
                                        teamOrderChangeAllowed:
                                            ((((competition.teamOrderChangeAllowed ?? 0) >> 2) % 2) << 2)
                                            + ((((competition.teamOrderChangeAllowed ?? 0) >> 1) % 2) << 1)
                                            + ((((competition.teamOrderChangeAllowed ?? 0) + 1) % 2))
                                    })

                                }}
                            />}
                            label={t(`label.competition.beforeFirstFight`)}
                        />
                        <FormControlLabel
                            control={<Checkbox
                                id="standard-basic"
                                checked={(((competition.teamOrderChangeAllowed ?? 0) >> 1) % 2 === 1)}
                                onClick={(e) => {

                                    setCompetition({
                                        ...competition,
                                        teamOrderChangeAllowed:
                                            ((((competition.teamOrderChangeAllowed ?? 0) >> 2) % 2) << 2)
                                            + (((((competition.teamOrderChangeAllowed ?? 0) >> 1) + 1) % 2) << 1)
                                            + ((((competition.teamOrderChangeAllowed ?? 0)) % 2))
                                    })

                                }}
                            />}
                            label={t(`label.competition.beforeFightInPool`)}
                        />
                        <FormControlLabel
                            control={<Checkbox
                                id="standard-basic"
                                checked={(((competition.teamOrderChangeAllowed ?? 0) >> 2) % 2 === 1)}
                                onClick={(e) => {

                                    setCompetition({
                                        ...competition,
                                        teamOrderChangeAllowed:
                                            (((((competition.teamOrderChangeAllowed ?? 0) >> 2) + 1) % 2) << 2)
                                            + (((((competition.teamOrderChangeAllowed ?? 0) >> 1)) % 2) << 1)
                                            + ((((competition.teamOrderChangeAllowed ?? 0)) % 2))
                                    })

                                }}
                            />}
                            label={t(`label.competition.beforeFightInTree`)}
                        />
                    </FormGroup>
                    <FormHelperText></FormHelperText>
                </FormControl>
            </Grid> */}
            <Grid item xs={6}>
                <FormControl>
                    {parent && <GroupValue value={parent.publish ? t("label.word.yes") : t("label.word.no")} />}
                    <FormControlLabel
                        control={<Checkbox
                            id="standard-basic"
                            checked={((competition.publish ?? 0) > 0)}
                            onClick={(e) => {

                                setCompetition({
                                    ...competition,
                                    publish: ((competition.publish ?? 0) + 1) % 2
                                })
                            }}
                        />}
                        label={t(`label.competition.publish`)}
                    />

                </FormControl>
            </Grid>
            {updateRowButton}
            <Grid item xs={12}>
                {parent && <GroupValue value={parent.comment} />}
                <TextField
                    fullWidth={true}
                    id="standard-basic"
                    label={t(`label.competition.Comment`)}
                    value={competition.comment ?? ''}
                    {...validField('comment')}
                    onChange={(e) => setCompetition({ ...competition, comment: e.target.value.length > 0 ? e.target.value : undefined })}
                />
            </Grid>
            <Grid item xs={12}>
                <InputLabel id="teamSizeMax-label">{t(`label.competition.information`)}</InputLabel>
                <div style={{ border: "1px solid black", padding: '2px', minHeight: '400px' }}>
                    {/*https://www.sanity.io/guides/top-5-rich-text-react-components*/}
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={(state) => {
                            setCompetition({
                                ...competition,
                                information: draftToHtml(convertToRaw(state.getCurrentContent())) //JSON.stringify(convertToRaw(editorState.getCurrentContent()))
                            });
                            setEditorState(state);
                        }}
                    />
                </div>
            </Grid>
            <Grid item xs={12} id="modal-btn-bottom">
                {message && <><Grid item xs={12}>
                    <Alert color={'success'}>
                        {message}
                    </Alert>
                    <Button variant="contained"
                        onClick={() => {
                            props.onChange(competition)
                        }}
                    >
                        {t(`action.event.close`)}
                    </Button>
                </Grid></>
                }
                {message === undefined && <><Button variant="contained"
                    onClick={update}
                    disabled={!isValid()}
                >

                    {competition.id ? t(`action.event.update`) : t(`action.event.create`)}
                </Button>

                    <Button variant="contained"
                        onClick={() => {
                            props.onCancel()
                        }}
                    >
                        {t(`action.event.cancel`)}
                    </Button>
                </>}
            </Grid>

        </Grid>
    </form >
}