import { IApiCompetitionModel } from "../../Model/ApiCompetitionModel";


export interface ICompetitionTableData extends IApiCompetitionModel { competitions: Array<ICompetitionTableData> | undefined };

export function getCompetitionGroupList(competitionList: IApiCompetitionModel[]) {
    const competitionGroupList: Array<ICompetitionTableData> =
        competitionList.filter(c => c.type === "group" || c.parentId === null).map(c => ({ ...c, competitions: [] }))

    for (const c of competitionList) {
        if (c.parentId) {
            competitionGroupList.find(c2 => c2.id === c.parentId)?.competitions?.push({ ...c, competitions: undefined });
        }
    }
    return competitionGroupList
}