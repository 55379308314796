import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCompetitions } from "../../Backend/ApiCompetition";
import { EventProcess } from "../../EventComponent/Event/EventProcess";
import { IApiCompetitionModel } from "../../Model/ApiCompetitionModel";
import { getCompetition } from "../../util/ApiCompetition";
import { getFollowingCompetitionStatus } from "../../util/ApiFollowing";
import { getConfigurationSelection } from "../Util/Configuration";
import { IServiceTableNotification } from "../Util/ErrorMessage";

export const STORAGE_SELECT_COMPETITION_ID = "maintable.competitionsummaryid";

/**
 * Select a competition amout list of available competitions (defined in configuration)
 * @param props 
 * @returns 
 */
export function useSelectCompetition(props: {
    dispatchNotification: React.Dispatch<IServiceTableNotification>
}): [JSX.Element, number | undefined, boolean | undefined, () => void /*reload */] {
    const { t, i18n } = useTranslation();
    const [competitionId, setCompetitionId] = useState<number | undefined>(
        parseInt(localStorage.getItem(STORAGE_SELECT_COMPETITION_ID) ?? "0"));
    const [followingFinished, setFollowingFinished] = useState<boolean | undefined>(undefined);
    const [selectedCompetitions, setSelectedCompetitions] = useState(getConfigurationSelection()?.selectedCompetition ?? []);
    const [currentSelectedCompetition, setCurrentSelectedCompetition] = useState<IApiCompetitionModel | undefined>(undefined);

    const [loadedCompetitions, setLoadedCompetitions] = useState<{
        selectList: Array<number>,
        loaded : Array<IApiCompetitionModel>
    }>({
        selectList: [],
        loaded: []
    });
    const [reload, setReload] = useState<number>(0);

    useEffect(() => {
        let display = true;
        let loadedCompSorted = [...loadedCompetitions.selectList].sort().join(';');
        const selectedCompSorted = [...selectedCompetitions ?? []].sort().join(';');
        if (loadedCompSorted !== selectedCompSorted)
            getCompetitions(props.dispatchNotification, false, {
                competitionIds: selectedCompetitions,
                withClosed:true,
                type: 'competitionANDgroup'
            }).then(d => {
                if (display) setLoadedCompetitions({
                    selectList: selectedCompetitions,
                    loaded: d
                });
                setCompetitionId(c2 => {
                    if (!d.find(c => c.id === c2)) {
                        return undefined;
                    }
                    else {
                        return c2;
                    }    
                })
                if ([...d].map(c => c.id).sort().join(';') !== [...selectedCompetitions ?? []].sort().join(';')){
                    console.error(`Loading incomplete : got ${[...d].map(c => c.id).join(',')} for selected ${ [...selectedCompetitions ?? []].sort().join(';')}`)
                }
                
            })
        return () => { display = false }
    }, [reload, loadedCompetitions, props.dispatchNotification, selectedCompetitions])

    useEffect(() => {
        if (competitionId) {
            getCompetition(props.dispatchNotification, competitionId).then(d => {
                if (d.id){
                    setCurrentSelectedCompetition(d);
                }
                else {
                    setCurrentSelectedCompetition(undefined);
                    setCompetitionId(undefined);
                }
            })
        }
        else {
            setCurrentSelectedCompetition(undefined);
        }
    }, [reload, competitionId, props.dispatchNotification])

    /**Get status for following service */
    useEffect(() => {
        if (followingFinished === undefined && competitionId !== undefined && competitionId >= 0) {
            getFollowingCompetitionStatus(competitionId, props.dispatchNotification).then((res: string) => {
                setFollowingFinished(res === "COMPLETED");
            })
        }
    }, [reload, competitionId, followingFinished, props.dispatchNotification])

    useEffect(() => {
        if (!selectedCompetitions.find(c => c === competitionId)) {
            setCompetitionId(undefined);
        }
    }, [competitionId, selectedCompetitions])



    return [<>
        <p style={{ fontWeight: "bold" }}>{t("label.title.selectedcompetition")}:</p>
        <select value={competitionId}
            onChange={(e) => {
                const newId = isNaN(Number(e.target.value)) ? undefined : Number(e.target.value);
                localStorage.setItem(STORAGE_SELECT_COMPETITION_ID, e.target.value)

                if (newId !== competitionId) {
                    setCompetitionId(newId)
                }
                setFollowingFinished(undefined);
            }
            } >
            <option value={undefined}></option>
            {
                loadedCompetitions.loaded.filter(c => c.type === "competition").map((competition: IApiCompetitionModel, k: any) => {
                    return <option key={k} value={competition.id}> {competition.fullname} (comp id {competition.id})</option>
                })

            }
        </select><br />
        {currentSelectedCompetition && <EventProcess
            currentStatus={currentSelectedCompetition.status}
            dispatchNotification={props.dispatchNotification}
            event={currentSelectedCompetition}
            onChange={() => {
                setReload(Date.now());
            }}
            small={false}
        />}
    </>, competitionId, followingFinished, () => {
        setReload(Date.now());
    }];

}