import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IApiFightGroupModel } from '../../util/ApiModel/ApiFightgroupModel';
import { IApiFightModel } from '../../util/ApiModel/ApiFightModel';
import { IApiFightScoreModel, IApiFightScoresModel } from '../../util/ApiModel/ApiFightScore';
import { FightTeam } from '../Team/FightTeam';

export function FightGroupScore(props: { fightGroup: IApiFightGroupModel, score: IApiFightScoresModel }) {
    const { t, i18n } = useTranslation();

    let showRank = props.score.ranking.length > 0;
    let displayScore: Array<IApiFightScoreModel> = []
    props.score.ranking.forEach(s => {
        displayScore.push(s);
    })
    props.score.scores.forEach(s => {
        if (undefined === displayScore.find(s2 => s.teamId === s2.teamId)) {
            displayScore.push(s);
        }
    })




    return <Table>
        <thead>
            <tr>

                <td>{t('label.score.team')}</td>
                <td>{t('label.score.rank')}</td>
                <td><Tooltip title={t('detail.score.score') ?? ''}><div>{t('label.score.score')}</div></Tooltip></td>
                <td><Tooltip title={t('detail.score.victories') ?? ''}><div>{t('label.score.victories')}</div></Tooltip></td>
                <td><Tooltip title={t('detail.score.ippons') ?? ''}><div>{t('label.score.ippons')}</div></Tooltip></td>
                <td><Tooltip title={t('detail.score.draws') ?? ''}><div>{t('label.score.draws')}</div></Tooltip></td>
                <td><Tooltip title={t('detail.score.ipponGets') ?? ''}><div>{t('label.score.ipponGets')}</div></Tooltip></td>
            </tr>
        </thead>
        <tbody>

            {


                displayScore.map((sc, key) => {

                    let fightTeam = undefined;

                    for (let index = 0; fightTeam === undefined && index < props.fightGroup.fights.length; index++) {
                        const f = props.fightGroup.fights[index];
                        if (f.redFightTeam.id === sc.teamId) fightTeam = f.redFightTeam;
                        if (f.whiteFightTeam.id === sc.teamId) fightTeam = f.whiteFightTeam;
                    }


                    //0 if not defined, rank else
                    //let rank = 1 + props.score.ranking.findIndex(score => score.teamExternalId === sc.teamExternalId);

                    return <tr key={key}>
                        <td>{fightTeam?.name} (ext id {fightTeam?.externalId})</td>
                        <td>{showRank && (key + 1)}</td>
                        <td>{sc.score}</td>
                        <td>{sc.victories}</td>
                        <td>{sc.ippons}</td>
                        <td>{sc.draws}</td>
                        <td>{sc.ipponGets}</td></tr>;
                })
            }
        </tbody>

    </Table >
}