import { Sync } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Grid, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IApiCompetitionModel, IServiceCompetitionAdvancedOrdonancementRules } from '../../Model/ApiCompetitionModel';
import { resync } from '../../util/ApiFollowing';
import { IApiCompetitionTreeModel } from '../../util/ApiModel/Following/ApiCompetitionTree';
import { allocateAllPostponed, sortSchedullerFight } from '../../util/ApiScheduller';
import { TPopOverAction } from '../Use/UsePopOver';
import { IServiceTableNotification } from '../Util/ErrorMessage';
import { ISchedullerActions, ISchedullerFight, ISchedullerList, ISchedullerWithMissing } from './SchedullerTable';
import { FightScheduller } from './ShiaijoScheduller';

export interface ISchedullerCompetitition {
    competition: ISchedullerWithMissing,
    selectedCompetitions: Array<IApiCompetitionModel>,
    syncSchedulerCompetition(competition: ISchedullerWithMissing | IApiCompetitionModel,
        dispatchNotification: React.Dispatch<IServiceTableNotification>): Promise<boolean>,
    dispatchNotification: React.Dispatch<IServiceTableNotification>,
    reload: () => void,
    actions: ISchedullerActions,
    schedullerData: {
        data: ISchedullerList | null
    },
    addCompetitionShiaijo: (competition: ISchedullerWithMissing | IApiCompetitionModel, value?: string) => Promise<void>,
    displayPopOver: TPopOverAction<ISchedullerFight | IApiCompetitionTreeModel>,
    displayFightGroupDetails: boolean
}

interface ISchedullerTableDisplayDetails {
    reported: "full" | "summary",
    closed: "full" | "summary",
    unsync: "full" | "summary"
}

export function SchedullerCompetitition(props: ISchedullerCompetitition) {
    const { t, i18n } = useTranslation();

    const [displayDetails, setDisplayDetails] = useState<ISchedullerTableDisplayDetails>({
        reported: "full",
        closed: "summary",
        unsync: "full"
    })

    let postponed = props.competition.fight.filter(f => f.status === "postponed").sort(sortSchedullerFight);
    let closed = props.competition.fight.filter(f => f.status === null || f.status === "finished").sort(sortSchedullerFight);
    let competitionInfo: IApiCompetitionModel | undefined =
        props.selectedCompetitions.find(c => c.id === Number(props.competition.competition));

    return <Table style={{ border: "1px solid black" }}>
        <TableBody>
            <TableRow>
                <TableCell colSpan={3}>
                    <Typography variant="h5" component="h5">
                        {competitionInfo?.fullname}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell >
                    <Tooltip title={t("detail.competition.syncCompetition") ?? ""}>
                        <SyncAltIcon onClick={() => {
                            props.syncSchedulerCompetition(props.competition, props.dispatchNotification).then(succeed => {
                                props.reload();
                            });
                        }} />
                    </Tooltip>
                    <p>{t("label.scheduler.shiajoOrderDefinedInCompetition")}</p>
                </TableCell>

                <TableCell>
                    <Typography variant="h5">
                        {t("label.title.configuration")}
                    </Typography>
                </TableCell>

                <TableCell>
                    List of shiaijos <Tooltip title={t('action.following.addCompetitionShiaijo') ?? ""}>
                        <AddCircleIcon color="primary" onClick={() => {
                            props.addCompetitionShiaijo(props.competition);
                        }} /></Tooltip>
                    <Typography variant="body1" >
                        {props.competition.shiaijo.map((s, k) => {
                            return <React.Fragment key={s.ext_id}>
                                {props.schedullerData.data?.shiaijosMap.get(s.ext_id)?.fullname} ;&nbsp;
                            </React.Fragment>

                        })}
                    </Typography>


                    {competitionInfo?.advancedOrdonancement && Object.keys(competitionInfo?.advancedOrdonancement).map(
                        (i, k) => {
                            let val = undefined;
                            if (competitionInfo?.advancedOrdonancement) {
                                val = competitionInfo?.advancedOrdonancement[i as keyof IServiceCompetitionAdvancedOrdonancementRules]
                            }
                            return <Typography variant="body1" key={k}>

                                {t(`label.competition.advanced.${i}`)} : {t(`label.competition.advanced.${val}`) /*t('label.word.' + (val ? "yes" : "no"))*/}

                            </Typography>
                        })}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={2}>
                    <Typography variant="h5" component="h5">
                        {t('label.scheduler.postponed')}
                        <Tooltip title={t("action.fightgroup.allocateAllPostponed")}>
                            <ArrowUpwardIcon onClick={() => {
                                if (competitionInfo) {
                                    allocateAllPostponed(competitionInfo?.id, props.dispatchNotification).then(r => {
                                        props.reload();
                                    })
                                }
                            }} />
                        </Tooltip>
                    </Typography>

                    {postponed.map((f, k) => {
                        return <FightScheduller key={k} fightgroup={f} actions={{
                            scheduleAllocate: props.actions.scheduleAllocate,
                            changeNewShiajo: props.actions.changeNewShiajo
                        }}
                            displayFightGroupDetails={props.displayFightGroupDetails}
                            displayPopOver={(e, p) => props.displayPopOver(e, p, f)}
                        />

                    })}
                </TableCell>

                <TableCell>
                    <Typography variant="h5" component="h5">
                        {t('label.scheduler.closed')}
                    </Typography>


                    {displayDetails.closed === "full" && closed.map((f, k) => {
                        return <React.Fragment key={k} >
                            <FightScheduller fightgroup={f} actions={{}} displayPopOver={(e, p) => props.displayPopOver(e, p, f)}
                                displayFightGroupDetails={props.displayFightGroupDetails} />
                            <br />
                        </React.Fragment>
                    })}
                    {displayDetails.closed === "summary" && <Grid item xs={12}>{closed.length} {t("label.word.fights")}</Grid>}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={3}>
                    <Typography variant="h5" component="h5">
                        {t('label.scheduler.followingSyncError')}
                    </Typography>
                    <Tooltip title={t("action.following.resync")}>
                        <Sync onClick={() => {
                            if (competitionInfo) {
                                resync(props.dispatchNotification,
                                    props.competition.unschedulledFights.map((f, k) => f.fightGroupNodeId)
                                ).then(r => {
                                    props.reload();
                                })
                            }
                        }} />
                    </Tooltip>
                </TableCell>
                {props.competition.unschedulledFights.map((f, k) => {
                    return <React.Fragment key={f.fightGroupNodeId}>
                        <FightScheduller fightgroup={f} actions={{
                            changeNewShiajo: props.actions.changeNewShiajo,
                            scheduleCreateFight: props.actions.scheduleCreateFight

                        }}
                            displayFightGroupDetails={props.displayFightGroupDetails}
                            displayPopOver={(e, p) => props.displayPopOver(e, p, f)} />

                    </React.Fragment>
                })
                }
            </TableRow>
        </TableBody>
    </Table >

}