import { Button } from '@mui/material';
import { BroadcastChannel } from 'broadcast-channel';
import { enableMapSet } from 'immer';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AppBroadcastContext, IAppMessage } from './AppContext';
import { AdminUsers } from './EventComponent/Admin/user';
import { IAuthentication } from './EventComponent/Auth/auth';
import { EventConfiguration } from './EventComponent/Root/EventConfiguration';
import { Events } from './EventComponent/Root/Events';
import ScoreKeeperScreen from './ScoreKeeperScreen';
import { useAuthentication } from './components/Auth/auth';
import { DrawingTable } from './components/Drawing/DrawingTable';
import { FightGroupPage } from './components/Fight/FightGroupPage';
import { TeamTable } from './components/Fighter/TeamTable';
import { FollowingArray } from './components/Following/followingArray';
import { FollowingScreen } from './components/Following/followingScreen';
import { AffichageTable } from './components/Root/AffichageTable';
import { CentraleTable } from './components/Root/CentraleTable';
import { CompetitionEnd } from './components/Root/CompetitionEnd';
import { CompetitionImport } from './components/Root/CompetitionImport';
import { HomeTable } from './components/Root/HomeTable';
import { MainTable } from './components/Root/MainTable';
import { ParametreTable } from './components/Root/ParametreTable';
import { TableCompetitions } from './components/Root/TableCompetitions';
import { MainScheduller } from './components/Scheduller/MainScheduller';
import { ShiajoScreen } from './components/Screen/ShiajoScreen';
import { TreeGroupSvg } from './components/Tree/TreeGroupSvg';
import { TSizeType, useScreenSize } from './components/Use/UseScreenSize';
import { STORAGE_SELECT_COMPETITION_ID } from './components/Use/UseSelectCompetition';
import { useTabId } from './components/Util/TabId';
import './i18n';
import { TSvgParam } from './util/ApiFollowing';

var debug = require('debug')('ServiceTablesMinimalFrontend:App')

enableMapSet()

export interface INavBar {
    visible: boolean
}

export interface IApp {
    navBar: INavBar
}

export interface IAppAction {
    type: 'NAVBAR_SHOW' | 'NAVBAR_HIDE'
}

// const DebugRouter = ({ children }: { children: any }) => {
//     if (process.env.NODE_ENV === 'development') {
//         console.log(
//             `Route: ${location.pathname}${location.search}}`,
//         )
//     }

//     return children
// }

//export const LangageContext = React.createContext<TFunction>(() => {});

function getModule(module: string) {
    return sessionStorage.getItem("module") === module ? true : false;
}



const BRODCAST_APP_CHANNEL_NAME = 'app';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function App() {
    const [fullscreen, setFullScreen] = useState<boolean>((window.screen.availHeight || window.screen.height) - 50 <= window.innerHeight);
    const { t, i18n } = useTranslation();
    const [authentication, logIn, logOut] = useAuthentication();
    const [langage, setLangage] = useState<string>('fr');
    const sizeType: TSizeType = useScreenSize();
    const [sideBarCollapsed, setSidebarCollapsed] = useState(false);

    useEffect(() => {
        i18n.changeLanguage(langage);
        if (window !== null) {
            window.onresize = () => {
                //debug("Test resize : " + window.innerHeight + " vs " + (window.screen.height));

                // eslint-disable-next-line no-restricted-globals
                if (!window.screenTop && !window.screenY) {
                    setFullScreen(false);
                } else {
                    setFullScreen(true);
                    
                }
            };

        }
        /*if (! authentication.loaded){
            authentication.loadUser();
        }*/
    }, [i18n, langage, authentication]);

    let navBar = null;
    if (getModule('tablecentrale') || getModule('affichage')) {
        if (!fullscreen) navBar = <div id="global-menu" className="inside background-blue">
            <nav className="navbar background-transparent" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    &nbsp;&nbsp;&nbsp;<img src="assets/img/france-kendo.png" height="16px" alt="Hatanado" />
                </div>
                <div className="navbar-menu">
                    <div className="navbar-start">
                        {/*<a className="navbar-item" href="/home">{t('label.title.home')}</a>
                    {authentication.authenticated && <>
                        <div className="navbar-item has-dropdown is-hoverable">
                            <a className="navbar-link">{t('label.title.events')}</a>
                            <div className="navbar-dropdown">
                                <a className="navbar-item navbar-item-dropdown" href="/competitions">{t('label.title.competitionslist')}</a>
                                {authentication.scopes.includes('cnkdr_admin') && <a className="navbar-item navbar-item-dropdown" href="/users">{t('label.title.users')}</a>}
                                <a className="navbar-item navbar-item-dropdown" href="/teams">{t('label.title.teams')}</a>
                                
                                <a className="navbar-item navbar-item-dropdown" href="/drawing">{t('label.title.drawing')}</a>
                                
                                
                                
                            </div>
                        </div>
                        {authentication.scopes.includes('cnkdr_organizer') && <>
                            
                            <div className="navbar-item has-dropdown is-hoverable">

                                <a className="navbar-link">{t('label.title.maintable')}</a>
                                <div className="navbar-dropdown">
                                    <a className="navbar-item navbar-item-dropdown" href="/mainscheduller">{t('label.title.mainscheduller')}</a>
                                    <a className="navbar-item navbar-item-dropdown" href="/tree">{t('label.title.rankInput')}</a>
                                    <a className="navbar-item navbar-item-dropdown" href="/following">{t('label.title.following')}</a>
                                    <a className="navbar-item" href="/mainTable">{t('label.title.scoresFollowing')}</a>
                                    <a className="navbar-item navbar-item-dropdown" href="/competitionEnd">{t('label.title.competitionEnd')}</a>

                                    {authentication.scopes.includes('cnkdr_admin') && <a className="navbar-item navbar-item-dropdown" href="/import">{t('label.title.localWork')}</a>}
                                </div>
                            </div>
                        </>}

                        <a className="navbar-item" href="/tables">{t('label.title.scorekeeper')}</a>
                        <div className="navbar-item has-dropdown is-hoverable"></div>
                        <div className="navbar-item has-dropdown is-hoverable">
                            <a className="navbar-link">{t('label.title.display')}</a>
                            <div className="navbar-dropdown">
                                <a className="navbar-item" href="/treeSvg">{t('label.title.suivitree')}</a>
                                <a className="navbar-item" href="/screen">{t('label.title.screentable')}</a>
                            </div>
                        </div>
                        {authentication.scopes.includes('cnkdr_organizer') && <a className="navbar-item" href={(window as any)['REACT_APP_UI_COMP_URL']}><i className="fas fa-undo"></i>{t('label.title.eventsback')}</a>}

                    </>}*/}
                        {authentication.authenticated && <>
                            {authentication.scopes.includes('cnkdr_organizer') && getModule('tablecentrale') && <>
                                <a className="navbar-item" href="/mainscheduller">{t('label.title.mainscheduller')}</a>
                                <a className="navbar-item" href="/tree">{t('label.title.rankInput')}</a>
                                <a className="navbar-item" href="/following">{t('label.title.following')}</a>
                                <a className="navbar-item" href="/mainTable">{t('label.title.scoresFollowing')}</a>
                                <a className="navbar-item" href="/competitionEnd">{t('label.title.competitionEnd')}</a>
                                {authentication.scopes.includes('cnkdr_admin') && <a className="navbar-item navbar-item-dropdown" href="/import">{t('label.title.localWork')}</a>}
                            </>}
                            {getModule('affichage') && <>
                                <a className="navbar-item" href="/treeSvg">{t('label.title.suivitree')}</a>
                                <a className="navbar-item" href="/screen">{t('label.title.screentable')}</a>
                            </>}
                        </>}
                    </div>
                    <div className="navbar-end">
                        <div className="control">
                            <div className="select" >
                                <select onChange={(e) => setLangage(e.target.value)} value={langage}>
                                    <option value="en">English</option>
                                    <option value="fr">Français</option>
                                    <option value="ja">日本</option>
                                </select>
                            </div>
                        </div>

                    </div>
                </div>
            </nav >
        </div >
    }





    // basename=""
    return <React.StrictMode>

        <div id={fullscreen ? "" : "template-page-sidebar"} className={sideBarCollapsed ? 'sidebar-collapsed' : ''}>
            {fullscreen !== true && navBar && <header id="header">
                <div id="header-nav" className="inside background-blue">
                </div>
                {navBar}
                <div id="banner-title" className="container">

                </div>
            </header>

            }
            <BrowserRouter>

                {fullscreen !== true && <aside id="menu-sidebar" className="menu">
                    <ul className="menu-list">
                        <li>
                            <a title="Home" href="/home">
                                <img src="assets/img/logo.png" alt="logo Hatanado" />
                            </a>
                        </li>
                        <li>
                            {!getModule('home') && <a title="Home" href="/home"><i className="fas fa-home"></i><span className="nav-item-name">&nbsp;{t('label.title.home')}</span></a>}
                            {getModule('home') && <a title="Home" className="is-active" ><i className="fas fa-home"></i><span className="nav-item-name">&nbsp;{t('label.title.home')}</span></a>}
                        </li>
                        <li>
                            {!getModule('competition') && <a title="Competitions" href="/competitions"><i className="fas fa-list"></i><span className="nav-item-name">&nbsp;{t('label.title.competitionslist')}</span></a>}
                            {getModule('competition') && <a title="Competitions" className="is-active"><i className="fas fa-list"></i><span className="nav-item-name">&nbsp;{t('label.title.competitionslist')}</span></a>}
                        </li>
                        <li>
                            {!getModule('parametre') && <a title="Parametres" href="/parametre"><i className="fas fa-cogs"></i><span className="nav-item-name">&nbsp;{t('label.title.parametre')}</span></a>}
                            {getModule('parametre') && <a title="Parametres" className="is-active"><i className="fas fa-cogs"></i><span className="nav-item-name">&nbsp;{t('label.title.parametre')}</span></a>}
                        </li>
                        <li>
                            {authentication.scopes.includes('cnkdr_admin') && !getModule('user') && <a title="Users" href="/users"><i className="fas fa-user"></i><span className="nav-item-name">&nbsp;{t('label.title.users')}</span></a>}
                            {authentication.scopes.includes('cnkdr_admin') && getModule('user') && <a title="Users" className="is-active"><i className="fas fa-user"></i><span className="nav-item-name">&nbsp;{t('label.title.users')}</span></a>}
                        </li>
                        <li>
                            {!getModule('team') && <a title="Teams" href="/teams"><i className="fas fa-users"></i><span className="nav-item-name">&nbsp;{t('label.title.teams')}</span></a>}
                            {getModule('team') && <a title="Teams" className="is-active" ><i className="fas fa-users"></i><span className="nav-item-name">&nbsp;{t('label.title.teams')}</span></a>}
                        </li>
                        <li>
                            {!getModule('drawing') && <a title="Drawing" href="/drawing"><i className="fas fa-magic"></i><span className="nav-item-name">&nbsp;{t('label.title.drawing')}</span></a>}
                            {getModule('drawing') && <a title="Drawing" href="/drawing" className="is-active"><i className="fas fa-magic"></i><span className="nav-item-name">&nbsp;{t('label.title.drawing')}</span></a>}
                        </li>
                        <li>
                            <div className="navbar-item has-dropdown is-hoverable">
                                <a title="Table centrale" className="navbar-link"><i className="fas fa-sitemap"></i><span className="nav-item-name">&nbsp;Table centrale</span></a>
                                <div className="navbar-dropdown">
                                    {authentication.scopes.includes('cnkdr_organizer') && <>
                                        <a className="navbar-item" href="/mainscheduller"><span className="nav-item-name">{t('label.title.mainscheduller')}</span></a>
                                        <a className="navbar-item" href="/tree"><span className="nav-item-name">{t('label.title.rankInput')}</span></a>
                                        <a className="navbar-item" href="/following"><span className="nav-item-name">{t('label.title.following')}</span></a>
                                        <a className="navbar-item" href="/mainTable"><span className="nav-item-name">{t('label.title.scoresFollowing')}</span></a>
                                        <a className="navbar-item" href="/competitionEnd"><span className="nav-item-name">{t('label.title.competitionEnd')}</span></a>
                                        {authentication.scopes.includes('cnkdr_admin') && <a className="navbar-item navbar-item-dropdown" href="/import"><span className="nav-item-name">{t('label.title.localWork')}</span></a>}
                                    </>}
                                </div>
                            </div>
                        </li>
                        {/* <li>
                            {!getModule('tablecentrale') && <a title="Table centrale" href="/tablecentrale"><i className="fas fa-sitemap"></i><span className="nav-item-name">&nbsp;Table centrale</span></a>}
                            {getModule('tablecentrale') && <a title="Table centrale" className="is-active"><i className="fas fa-sitemap"></i><span className="nav-item-name">&nbsp;Table centrale</span></a>}
                        </li> */}
                        <li>
                            {!getModule('table') && <a title="Score Keeper" href="/tables"><i className="fas fa-flag"></i><span className="nav-item-name">&nbsp;{t('label.title.scorekeeper')}</span></a>}
                            {getModule('table') && <a title="Score Keeper" className="is-active"><i className="fas fa-flag"></i><span className="nav-item-name">&nbsp;{t('label.title.scorekeeper')}</span></a>}
                        </li>
                        {/* <li>
                            {!getModule('affichage') && <a title="Affichage" href="/affichage"><i className="fas fa-info-circle"></i><span className="nav-item-name">&nbsp;Affichage</span></a>}
                            {getModule('affichage') && <a title="Affichage" className="is-active"><i className="fas fa-info-circle"></i><span className="nav-item-name">&nbsp;Affichage</span></a>}
                        </li> */}
                        <li>
                            {!getModule('arbre') && <a title="Arbre" href="/treeSvg"><i className="fas fa-tree"></i><span className="nav-item-name">&nbsp;{t('label.title.suivitree')}</span></a>}
                            {getModule('arbre') && <a className="is-active" title="Arbre" ><i className="fas fa-tree"></i><span className="nav-item-name">&nbsp;{t('label.title.suivitree')}</span></a>}
                        </li>
                        <li>
                            {!getModule('screen') && <a title="Ecran" href="/screen"><i className="fas fa-expand"></i><span className="nav-item-name">&nbsp;{t('label.title.screentable')}</span></a>}
                            {getModule('screen') && <a className="is-active" title="Ecran" ><i className="fas fa-expand"></i><span className="nav-item-name">&nbsp;{t('label.title.screentable')}</span></a>}
                        </li>

                        <li>
                            {!authentication.authenticated && <a title={t('label.title.authenticate')} href="#" onClick={() => logIn()}>
                                <i className="fas fa-sign-in-alt"></i><span className="nav-item-name">&nbsp;Se connecter</span>
                            </a>}
                            {authentication.authenticated && <a title={t('label.title.logout')} href="#" onClick={() => logOut()}>
                                <i className="fas fa-sign-out-alt"></i><span className="nav-item-name">&nbsp;Se deconnecter</span>
                            </a>
                            }
                        </li>
                    </ul>
                    <br /><br />
                    <p className="centered-element">
                        <div className="select is-small" >
                            <select onChange={(e) => setLangage(e.target.value)} value={langage}>
                                <option value="en">EN</option>
                                <option value="fr">FR</option>
                                <option value="ja">JP</option>
                            </select>
                        </div>
                    </p>

                    <Button role="button" className="menu-burger" aria-label="menu" aria-expanded="false"
                        title="Afficher/Masquer la barre latérale"
                        href={undefined}
                        onClick={() => {
                            setSidebarCollapsed(s => !s)
                        }}
                        style={{ color: 'white' }}
                    >
                        <i className="fas fa-angle-double-left expanded"></i>
                        <i className="fas fa-angle-double-right collapsed"></i>
                        {!sideBarCollapsed && <span className="nav-item-name">&nbsp;Masquer la barre latérale</span>}
                    </Button>
                </aside>
                }

                <div className={`${fullscreen ? 'cnkdr_content_fullscreen hero is-fullheight' : 'cnkdr_content_notfullscreen hero is-fullheight'}`}>
                    <AppRoutes
                        authentication={authentication}
                        logIn={logIn}
                        fullscreen={fullscreen}
                        setFullScreen={setFullScreen}
                    />
                </div>
            </BrowserRouter>
        </div >
    </React.StrictMode >


}

export function AppRoutes(params: {
    authentication: IAuthentication
    , logIn: () => void
    , fullscreen: boolean
    , setFullScreen: (fullScreen: boolean) => void
}) {

    const [channel, setChannel] = useState<BroadcastChannel<IAppMessage> | undefined>(undefined);
    const [navigateTo, setNavigateTo] = useState<string | undefined>(undefined);
    const navigate = useNavigate();
    const tabId = useTabId();
    //const [configuration, setConfiguration] = useConfiguration();
    useEffect(() => {

        if (channel === undefined) {
            const newChannel = new BroadcastChannel(BRODCAST_APP_CHANNEL_NAME);
            newChannel.onmessage = (ev: IAppMessage) => {
                if (ev.sourceTabId !== tabId) {
                    switch (ev.action) {
                        case 'TABS_NAVIGATE':
                            if (ev.params.length > 0) {
                                setNavigateTo(ev.params[0]);
                            }
                            break;
                    }
                }
            }
            setChannel(newChannel);
        }
    }, [channel]);

    useEffect(() => {
        if (navigateTo) {
            //navigate(navigateTo);
            window.location.href = navigateTo;
            setNavigateTo(undefined);
        }
    }, [navigateTo, navigate]);

    const onParamChanged = useCallback((params: TSvgParam) => {
        localStorage.setItem("maintTreeSvgParams", JSON.stringify(params));
    }, []);

    const svgparams = useMemo((): TSvgParam => {
        return localStorage.getItem("maintTreeSvgParams") ? JSON.parse(localStorage.getItem("maintTreeSvgParams") ?? "") : {
            displayType: 2,
            displayLevel: true,
            countryFlag: false,
            displayIppons: true,
            displayPools: true,
            resizeHeight: false
        }
    }, []);

    const queryParams = useQuery();

    useEffect(() => {
        const fullScreen = queryParams.get('fullscreen');
        if (fullScreen !== null) {
            params.setFullScreen(fullScreen === '1');
        }
        const eventId = queryParams.get('eventId');
        if (eventId) {
            localStorage.setItem(STORAGE_SELECT_COMPETITION_ID, eventId);
        }
    }, [params, queryParams]);

    return <AppBroadcastContext.Provider value={channel}>
        <Routes>
            <Route path='/' element={<HomeTable authentication={params.authentication} login={params.logIn} />} />
            <Route path='/home' element={<HomeTable authentication={params.authentication} login={params.logIn} />} />
            <Route path='/parametre' element={<ParametreTable />} />
            <Route path='/affichage' element={<AffichageTable />} />
            <Route path='/tablecentrale' element={<CentraleTable authentication={params.authentication} />} />
            {params.authentication.authenticated && <>
                <Route path='/events' element={<Events authentication={params.authentication} />} />
                <Route path='/competitions' element={<TableCompetitions
                    authentication={params.authentication}
                />} />
                <Route path='/teams' element={<TeamTable authentication={params.authentication} />} />

                <Route path='/drawing' element={<DrawingTable authentication={params.authentication} />} />

                <Route path='/configuration' element={<EventConfiguration />} />
                <Route path='/users' element={<AdminUsers />} />
                <Route path='/import' element={<CompetitionImport
                    authentication={params.authentication}
                />} />
                <Route path='/mainTable' element={<MainTable />} />
                <Route path='/tables' element={<ScoreKeeperScreen fullscreen={params.fullscreen} />} />
                <Route path='/tree' element={<FollowingArray />} />
                <Route path='/following' element={<FollowingScreen />} />
                <Route path='/treeSvg' element={
                    <TreeGroupSvg
                        fullscreen={params.fullscreen}
                        onParamChanged={onParamChanged}
                        params={svgparams}
                        status="Ongoing"
                    />
                } />
                <Route path='/screen' element={
                    <ShiajoScreen
                        shiaijoId={parseInt(queryParams.get('shiaijoid') ?? '')}
                        fullscreen={params.fullscreen}
                        eventMode='SSE'//{queryParams.get('shiaijoid') === 'offline' ? 'OFFLINE' : 'SSE'}
                    />
                } />

                <Route path='/mainscheduller' element={<MainScheduller
                    authentication={params.authentication}
                />} />
                <Route path='/fightcard/:fightGroupId' element={<FightGroupPage />} />
                <Route path='/competitionEnd' element={<CompetitionEnd />} />

            </>
            }

        </Routes>
    </AppBroadcastContext.Provider>;
}
/*
<LangageContext.Provider value={t}>
</LangageContext.Provider>*/
