
import { IApiResult, IApiResultStatus } from "../ApiCall";
import { IApiFighterModel } from "./ApiFighterModel";
import { IApiFightCreationModel, IApiFightModel, IFightTeamState } from "./ApiFightModel";
import { IApiFightScoresModel } from "./ApiFightScore";

export enum EFightGroupType {
  DIRECT_ELIMINATION = 0,
  POOL = 1
}

export enum EFightGroupStatus {
  WAIT = 0,
  INPROGRESS,
  FINISHED,
  UNDEFINED, //when fights are not loaded
  COMMITTED, //synchronisé avec le suivi des combats
  CANCELED
}

export interface IApiFightGroupCreationModel {
  competitionId: number,
  fights: Array<IApiFightCreationModel>,
  type: EFightGroupType,
  label: string,
  qualifying:number,

  depth: number,
  fightGroupNodeId: number //-1 for local
}

export const emptyApiFightGroupCreationModel:IApiFightGroupCreationModel = {
  competitionId: 0,
  fights: [],
  type: EFightGroupType.DIRECT_ELIMINATION,
  label: "",
  qualifying: 1,
  depth: 0,
  fightGroupNodeId: -1
}

export interface IApiFightGroupModel {
  id: number,
  type: EFightGroupType,
  shiajoId: number,
  label: string,
  currentFightId: number,
  fightGroupNodeId: number,
  commit: string | null,
  qualifying: number,
  createdAt: string,
  updatedAt: string,
  status: EFightGroupStatus,
  currentFight?: IApiFightModel,
  fights: Array<IApiFightModel>,
  beginDate: undefined | Date,
  score?: IApiFightScoresModel,
  competitionId: number,
  cancel:boolean,
  fightOrder: number,
  depth?: number
}

export const emptyApiFightGroupModel: IApiFightGroupModel = {
  id: 0,
  type: 0,
  shiajoId: 0,
  label: "",
  currentFightId: 0,
  fightGroupNodeId: 0,
  commit: null,
  qualifying: 0,
  createdAt: "",
  updatedAt: "",
  status: EFightGroupStatus.UNDEFINED,
  fights: [],
  beginDate: undefined,
  competitionId: 0,
  cancel: false,
  fightOrder: 0
}

export interface IApiFightGroupResult extends IApiResultStatus {

  data: IApiFightGroupModel;
}

export interface IApiFightGroupResults extends IApiResult {
  data: Array<IApiFightGroupModel>;
}

export const emptyFightgroups: IApiFightGroupResults = {
  succeed: false,
  data: []
}

export const emptyFightgroup: IApiFightGroupResult = {
  succeed: false,
  data: {
    id: 0,
    type: 0,
    shiajoId: 0,
    label: "undefined",
    currentFightId: 0,
    fightGroupNodeId: 0,
    commit: null,
    qualifying: 0,
    createdAt: "",
    updatedAt: "",
    status: EFightGroupStatus.UNDEFINED,
    fights: new Array<IApiFightModel>(),
    beginDate: undefined,
    competitionId: 0,
    cancel:false,
    fightOrder: 0
  },
  status: 400
}

export function fgTypeLabel(type: number): string {
  switch (type) {
    case 0: return "label.fightgroup.directelim";
    case 1: return "label.fightgroup.pool";
  }
  return "label.word.unknown";
}

export function fgNameLabel(name: string): {
  pos: string,
  label: string
} {
  const re = new RegExp("(?:^(Pool|Final) ?([0-9]+)?$)|(?:^\\(([0-9]+)\\) ([0-9]+\\/[0-9]+)$)");
  //debug(fightgroup.label);
  //(1) 1/2 
  //Pool 1
  //Final
  const mat = name.match(re);
  //debug(mat);
  const pos = mat && mat[2] ? mat[2] : mat && mat[3] ? mat[3] : "";
  const label = mat && mat[1] ? mat[1].toLowerCase() : mat && mat[4] ? 'depth_' + mat[4].replace("/", "_") : "";
  return { pos: pos, label: label };
}

export function searchFighterInFight(fight: IApiFightModel, fighterId: number): [undefined | IApiFighterModel, undefined | 'red' | 'white', undefined | IFightTeamState] {
  let fighter: undefined | IApiFighterModel = undefined;
  let color: undefined | 'red' | 'white' = undefined;
  let team: undefined | IFightTeamState = undefined;

  fighter = fight.redFightTeam.fighters.find(fi => fi.id === fighterId);
  if (fighter) {
    color = 'red';
    team = fight.redFightTeam;
  }
  else {
    fighter = fight.whiteFightTeam.fighters.find(fi => fi.id === fighterId);
    if (fighter) {
      color = 'white';
      team = fight.whiteFightTeam;
    }
  }
  return [fighter, color, team];
}

export function searchFighterInTeams(figthGroup: IApiFightGroupModel, fighterId: number): [undefined | IApiFighterModel, undefined | 'red' | 'white', undefined | IFightTeamState] {
  let fighter: undefined | IApiFighterModel = undefined;
  let color: undefined | 'red' | 'white' = undefined;
  let team: undefined | IFightTeamState = undefined;
  for (let index = 0; fighter === undefined && index < figthGroup.fights.length; index++) {
    const fight = figthGroup.fights[index];
    [fighter, color, team] = searchFighterInFight(fight, fighterId);
  }
  return [fighter, color, team];
}