import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { IApiResult } from '../../util/ApiCall';
import { useErrorMessage } from '../Util/ErrorMessage';

import { Button, Checkbox, Grid } from '@mui/material';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { TCompetitionStatus } from '../../Model/ApiCompetitionModel';
import { TSvgParam } from '../../util/ApiFollowing';
import { ScreenPoolList } from '../Screen/ScreenPoolList';
import { useSelectedCompetitions } from '../Use/UseSelectCompetitions';
import { getConfigurationSelection } from '../Util/Configuration';
import { CompetitionTreeSvg, ISvgTreeConf } from './CompetitionTreeSvg';
import "/node_modules/flag-icons/css/flag-icons.min.css";

var debug = require('debug')('ServiceTablesMinimalFrontend:TreeSvg')



/*let content = { __html: '<div class="mxgraph" style="max-width:100%;border:1px solid transparent;" data-mxgraph="{&quot;highlight&quot;:&quot;#0000ff&quot;,&quot;nav&quot;:true,&quot;resize&quot;:true,&quot;toolbar&quot;:&quot;zoom layers lightbox&quot;,&quot;edit&quot;:&quot;_blank&quot;,&quot;xml&quot;:&quot;&lt;mxfile host=\\&quot;app.diagrams.net\\&quot; modified=\\&quot;2020-11-14T20:13:34.765Z\\&quot; agent=\\&quot;5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/86.0.4240.198 Safari/537.36\\&quot; etag=\\&quot;xklJrg0_CuizLlrMy5lo\\&quot; version=\\&quot;13.9.8\\&quot; type=\\&quot;device\\&quot;&gt;&lt;diagram id=\\&quot;DyhExnW4NiIMV8jbsSu0\\&quot; name=\\&quot;Page-1\\&quot;&gt;' + encodeDrawioFile(mx, doc) + '&lt;/diagram&gt;&lt;/mxfile&gt;&quot;}"></div>' };
    return <div dangerouslySetInnerHTML={content}></div>;*/


interface ITreeSvgState {
    loaded: boolean,
    svg: Array<{ name: string, svg: string | JSX.Element, id: number }>
}

export interface ISvgFightgroup {
    fightGroupNodeId: number,
    events: Array<{
        type: string,
        date: Date,
        color: 'red' | 'white'
    }>
}

export interface ICompetitionEventsResult extends IApiResult {
    data: {
        fightgroups: Array<ISvgFightgroup>
    }
}

const spanStyle = {
    padding: '20px',
    background: '#efefef',
    color: '#000000'
}

const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
}

/**
 * 
 * @param props 
 * @returns 
 */
export function TreeGroupSvg(props: {
    
    fullscreen: boolean,
    params?: TSvgParam | undefined,
    status?: TCompetitionStatus,
    onParamChanged?: (svgParams: TSvgParam) => void
}) {


    //SVG CONF gor api call
    //const [svgProp, setSvgProp] = useState<TSvgParams>(new Map());

    sessionStorage.setItem("module", "screen");
    const [dispatchNotification, errorComponent] = useErrorMessage(false, []);//Error messages
    const { t } = useTranslation();
    const svgRef = useRef(null);//for print
    const [print, setPrint] = useState<number | undefined>(undefined); //print status
    const [selectedCompetitionsComponent, selectedCompetitions, selectedCompetitionsString] = useSelectedCompetitions({
        selectionName: "treeSelection",
        init: getConfigurationSelection()?.selectedCompetition,
        label: t("label.competition.selectedTreeCompetitions"),
        maxSelect: 6,
        status: props.status,
        dispatchNotification:dispatchNotification
    });
    //SVG CONF for local modifs
    const [svgTreeConf, setSvgTreeConf] = useState<ISvgTreeConf>({
        replaceFighter: true,
        replaceIppons: true,
        width: 200,
        fullscreen: false
    })
    //Size of svg displaying
    const [confSvg, setConfSvg] = useState<{
        splitSize: number,
        competitonIds: Array<{ id: number }>,
        slideMode: boolean

    }>({
        splitSize: 3,
        competitonIds: selectedCompetitions,
        slideMode: true
    });
    const [svgParams, setSvgParams] = useState<TSvgParam>(props.params ?? {
        displayType: 2,
        displayLevel: true,
        displayIppons: true,
        displayPools: false,
        countryFlag: false,
        resizeHeight: true
    });

    const [compList, setCompList] = useState<Array<number>>(confSvg.competitonIds.map(c => c.id))

    useEffect(() => {
        if (props.onParamChanged) props.onParamChanged(svgParams);
    }, [props, svgParams])

    // const onMessage = useCallback((data: Array<IApiFightGroupModel>) => {
    //     console.log("Message receive, should refresh");
    // }, []);

    /**
     * Handle print
     */
    const handlePrint = useReactToPrint({
        content: () => {
            return svgRef.current ?? null
        },
        onBeforeGetContent: () => {

        },
        onAfterPrint: () => {
            setPrint(undefined)
        }
    });
    /**
    * 
    */
    //  const [setSseFilter, sseConnected, connectedIcon] = useSseSecureClient(
    //     "treeSvg",
    //     "competitions",
    //     onMessage,
    //     dispatchNotification
    // );

    /**
     * Set filters
     */
    // useEffect(() => {
    //     setSseFilter(confSvg.competitonIds.map(c => c.id).join(','));
    // }, [confSvg.competitonIds, setSseFilter]);

    useEffect(() => {
        if (confSvg.competitonIds.map(c => c.id).join(',') !== compList.join(',')) {
            setCompList(confSvg.competitonIds.map(c => c.id));
        }
    }, [compList, confSvg.competitonIds]);

    useEffect(() => {
        if (confSvg.competitonIds.map(s => s.id).join() !== selectedCompetitions.map(s => s.id).join()) {
            setConfSvg({
                ...confSvg,
                competitonIds: selectedCompetitions
            })
        }
    }
        , [confSvg, selectedCompetitions]);


    useEffect(() => {
        if (svgTreeConf.fullscreen !== props.fullscreen) {
            setSvgTreeConf({
                ...svgTreeConf,
                fullscreen: props.fullscreen
            })
        }
    }, [props.fullscreen, svgTreeConf])

    useEffect(() => {
        if (print !== undefined) {
            if (confSvg.splitSize === 1) {
                if (handlePrint) handlePrint();
            } else {
                if (1 !== confSvg.splitSize) {
                    setConfSvg({
                        ...confSvg,
                        splitSize: 1
                    });
                }
            }
        } else if (confSvg.splitSize !== 3) {
            if (3 !== confSvg.splitSize) {
                setConfSvg({
                    ...confSvg,
                    splitSize: 3
                });
            }
        }
    }, [confSvg, print, handlePrint]);

    const configSvgTreeConfHalf = useMemo(() => {
        return {
            ...svgTreeConf,
            width: window.innerWidth / 2,
            maxHeight: window.innerHeight * 0.8
        };
    }, [svgTreeConf])

    let svgs: JSX.Element;

    //SLIDEMODE
    if (confSvg.slideMode && !print && confSvg.competitonIds.length > 1) {
        svgs = <Slide
            autoplay={true}
            duration={10000}
            defaultIndex={0}
            infinite={true}
        >
            {confSvg.competitonIds.map((competitionId, key) => {
                return <div className='slide-tree-svg' key={competitionId.id}>
                    <div style={{ ...divStyle }}>
                        <span style={spanStyle}>
                            <CompetitionTreeSvg
                                competitionId={competitionId.id}
                                selectionName="treeSelection"
                                dispatchNotification={dispatchNotification}
                                svgParam={svgParams}
                                svgTreeConf={configSvgTreeConfHalf}
                                init={getConfigurationSelection()?.selectedCompetition}
                            />
                        </span>
                    </div>
                </div>
            })
            }
        </Slide>
    }
    else {
        let baseWidth = window.innerWidth;

        if (svgParams.displayPools) baseWidth = baseWidth * 14 / 20;
        svgs = <>
            {confSvg.competitonIds.map((_, key) => {
                const i = Math.floor(key / confSvg.splitSize);
                const splitted = confSvg.competitonIds.slice(i * confSvg.splitSize, (i + 1) * confSvg.splitSize);
                return <React.Fragment key={key}>
                    {(key % confSvg.splitSize) === 0 && <div style={{ pageBreakAfter: "always" }}>
                        <Grid container spacing={0} >
                            {splitted.map((competitionId, key2) => {
                                return <Grid item xs={12 / splitted.length} key={competitionId.id}>
                                    <CompetitionTreeSvg
                                        competitionId={competitionId.id}
                                        selectionName="treeSelection"
                                        dispatchNotification={dispatchNotification}
                                        svgParam={svgParams}
                                        svgTreeConf={
                                            {
                                                ...svgTreeConf,
                                                width: baseWidth / splitted.length,
                                                maxHeight: window.innerHeight * 0.8
                                            }
                                        }
                                        init={getConfigurationSelection()?.selectedCompetition}
                                    />
                                </Grid>
                            })
                            }
                        </Grid>
                    </div>
                    }
                </React.Fragment>
            })}
        </>;
    }

    return <>
        {props.fullscreen !== true && <Grid container spacing={0} >
            <Grid item xs={12} className='no-print'>
                {errorComponent}
            </Grid>


            <Grid item xs={12} className='no-print'>
                <Button variant="contained" onClick={() => setPrint(0)}>Print</Button>
            </Grid>
            <Grid item xs={12} className='no-print'>
                {selectedCompetitionsComponent}
            </Grid>
            <Grid item xs={12} className='no-print'>
                <Checkbox
                    checked={svgParams.displayLevel}
                    onChange={(e: any) => {
                        setSvgParams({
                            ...svgParams,
                            displayLevel: !svgParams.displayLevel
                        })
                    }}
                /> {t("label.competition.displayLevel")}
                <Checkbox
                    checked={svgParams.displayIppons}
                    onChange={(e: any) => {
                        setSvgParams({
                            ...svgParams,
                            displayIppons: !svgParams.displayIppons
                        })
                    }}
                /> {t("label.competition.displayIppons")}
                <Checkbox
                    checked={svgParams.displayType === 2}
                    onChange={(e: any) => {
                        setSvgParams({
                            ...svgParams,
                            displayType: svgParams.displayType === 1 ? 2 : 1
                        })
                    }}
                /> {t("label.competition.displayTypeDefaultDouble")}
                <Checkbox
                    checked={confSvg.slideMode}
                    onChange={(e: any) => {
                        setConfSvg({
                            ...confSvg,
                            slideMode: !confSvg.slideMode
                        })
                    }}
                /> {t("label.competition.displaySlideMode")}
                <Checkbox
                    checked={svgParams.resizeHeight}
                    onChange={(e: any) => {
                        setSvgParams({
                            ...svgParams,
                            resizeHeight: !svgParams.resizeHeight
                        })
                    }}
                /> {t("label.competition.resizeHeight")}
                <Checkbox
                    checked={svgParams.displayPools}
                    onChange={(e: any) => {
                        setSvgParams({
                            ...svgParams,
                            displayPools: !svgParams.displayPools
                        })
                    }}
                /> {t("label.competition.displayPools")}
                <Checkbox
                    checked={svgParams.countryFlag}
                    onChange={(e: any) => {
                        setSvgParams({
                            ...svgParams,
                            countryFlag: !svgParams.countryFlag
                        })
                    }}
                /> {t("label.competition.countryFlag")}
            </Grid>
        </Grid>}


        <Grid container spacing={0} >
            {svgParams.displayPools && <Grid item xs={4}>
                <ScreenPoolList
                    dispatchNotification={dispatchNotification}
                    competitionIds={compList} />
            </Grid>
            }

            <Grid item xs={svgParams.displayPools ? 8 : 12}>
                <div ref={svgRef}>

                    {svgs}
                </div>
            </Grid>
        </Grid>
    </>;
}