import { Alert, Box, Link, Paper } from '@mui/material';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { IAuthentication } from '../Auth/auth';
import { DrawingExcel } from '../Drawing/DrawingExcel';
import { useSelectedCompetitions } from '../Use/UseSelectCompetitions';
import { useErrorMessage } from '../Util/ErrorMessage';






export function HomeTable(props: { authentication: IAuthentication, login: () => void }) {
    const [dispatchNotification, errorComponent] = useErrorMessage();
    const [selectedCompetitionsComponent, selectedCompetitions] = useSelectedCompetitions({ 
        dispatchNotification:dispatchNotification,
        size: 'normal'
        , label: ""
        , listlink: false });
    sessionStorage.setItem("module", "home");
    
    return <Container fluid>
        <Row>
            <Col md={12}>
                {errorComponent}
            </Col>
        </Row>

        <Row>
            <Col md={6}>
                <div className="content">
                    <DrawingExcel dispatchNotification={dispatchNotification} authentication={props.authentication} login={props.login}/>
                    <Link href={`/offline/index.html`} download>Fichier d'écran de commissaire hors ligne </Link>
                </div>
            </Col>
            <Col md={6}>
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1,
                        width: '90%',
                        height: '90%',
                    },
                }}>
                    <Paper elevation={1} >
                        <div className="content">
                            <h1 className='title'>Créer et gérer la compétition sur le site</h1>
                            {props.authentication.scopes.includes('cnkdr_organizer') && <>
                                <Alert color='warning'>N'utilisez cette méthode que si vous devez gérer une grosse compétition avec un déploiement informatique local</Alert>
                                <p>Lorsque vous travaillez sur des compétitions, vous devez les selectionner dans la liste : <br />
                                    <u><a href="/competitions">Liste des competitions</a></u></p>
                                <p>Competitions actuellement sélectionnées :<br />
                                    {selectedCompetitionsComponent}<br />
                                    <u><a href="/competitions">Créer une nouvelle competition</a></u><br />
                                </p>

                                <p>Rendez vous sur l'onglet adéquat :<br />
                                    <ul className="text-gauche">
                                        <li>Evenements : gestion des compétitions : combattants, tirage, utilisateurs </li>
                                        <li>Table centrale : gestion du déroullement de la compétition : distribution des combats, résultats, planification des combats, suivi, cloture </li>
                                        <li>Commissaire : affichage de gestion d'une table</li>
                                        <li>Affichage : affichage d'écran de shiaijo à l'attention des arbitres</li>
                                    </ul>
                                </p>
                            </>
                            }
                            {props.authentication.scopes.includes('cnkdr_organizer') === false && <>
                                {<Alert color='warning'>Le profil organisateur est necessaire pour gérer une compétition sur le site</Alert>}
                            </>}
                        </div>
                    </Paper>
                </Box>
            </Col>

        </Row>
        {/* <Row>
            <Col md={12}>
                <BackendConfig />
            </Col>
        </Row> */}
    </Container>
}