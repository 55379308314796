import { IEventNotification } from "../EventComponent/Util/ErrorMessage";
import { IApiCompetitionModel } from "../Model/ApiCompetitionModel";
import { postCompApiObject } from "../UtilEvent/GenericOperation";



export type TNotificationSubject =
    | "CUSTOM"
    | "COMPETITION_WILL_BEGIN"
    | "COMPETITION_BEGIN"
    | "COMPETITION_WILL_END"
    | "COMPETITION_END"
    | "REGISTRATION_WILL_BEGIN"
    | "REGISTRATION_BEGIN"
    | "REGISTRATION_WILL_END"
    | "REGISTRATION_END"
    | "DRAW_WILL_BEGIN"
    | "DRAW_BEGIN"
    | "DRAW_WILL_END"
    | "DRAW_END"
    | "POOLS_DISPLAYED"
    | "POOL_DEFINITION"
    | "DRAWING_DISPLAYED"
    | "NEXT_FIGHT"
    | "BREAK_WILL_BEGIN"
    | "BREAK_BEGIN"
    | "BREAK_WILL_END"
    | "BREAK_END"
    | "SEMI_FINALE"
    | "FINALE"
    | "FIGHT_POSTPONED"
    | "FIGHT_CANCELLED"
    | "FIGHT_BEGIN"
    | "FIGHT_EVENT"
    | "FIGHT_OVERTIME"
    | "FIGHT_END";

//ex : { "eventUuid": "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx", "eventDescription": "Historia2020", "competitionId": 0, "competitionDescription": "category 1", "shortName": "HKC2020", "id": 0, "subject": "FIGHT_EVENT", "details": "** COUCOU FRANCK, CA A L AIR DE MARCHE CHEZ MOI** white", "team1": "Tokugawa", "team2": "Huang Shi-Yuan", "progress": "finale", "location": "shiaijo A", "duration": 120 }
interface IServiceNotification {
    eventUuid: string;
    eventDescription: string; //Competition name
    competitionId: number;
    competitionDescription: string;
    shortName: string; //Competition name
    id?: number;
    subject?: TNotificationSubject; //title
    details?: string; //detail of event
    team1?: string; //team2 desc
    team2?: string; //team1 desc
    progress?: string; //event level, final, ...
    location?: string; //place, shiajo
    duration?: number;
}

export const announcementList = ["CUSTOM", "COMPETITION_WILL_BEGIN", "REGISTRATION_WILL_BEGIN", "REGISTRATION_WILL_END", "DRAW_WILL_BEGIN", "DRAW_WILL_END", "BREAK_WILL_BEGIN", "BREAK_WILL_END", "COMPETITION_WILL_END"] as const;
export type TannouncementSubject = typeof announcementList[number];

export type TDateType = "date" | "duration" | "noDate";

export interface IEventAnnoucement {
    subject: TannouncementSubject;
    details?: string;
    hour?: number;
    min?: number;
    dateType: TDateType;
}

export function eventDescription(
    competition: IApiCompetitionModel
): IServiceNotification {
    return {
        eventUuid: "xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx",
        eventDescription: competition.fullname,
        competitionId: competition.id,
        competitionDescription: `${competition.category}`,
        shortName: `${competition.competitionDate} - ${competition.category}`,
        location: competition.place
    };
}

export async function postCompetitionNotification(
    dispatchNotification: React.Dispatch<IEventNotification> | undefined,
    competition: IApiCompetitionModel,
    event: IEventAnnoucement,
    token?: string
) {
    let date = new Date();
    let start = new Date(date.getFullYear()
        , date.getMonth()
        , date.getDate()
        , event.hour
        , event.min)
    let data = Object.assign({}, eventDescription(competition), event, {
        start: event.dateType === 'date' ? start.getTime() : undefined,
        duration: event.dateType === 'duration' ? event.min : undefined
    });
    return postNotification(dispatchNotification, data, token);
}

export async function postNotification(
    dispatchNotification: React.Dispatch<IEventNotification> | undefined,
    notification: IServiceNotification,
    token?: string
): Promise<string> {
    return postCompApiObject<{
        succeed: boolean,
        data: string
    }>("/announcement", notification, {
        succeed: false,
        data: ""
    }, dispatchNotification,
        (window as any)['REACT_APP_API_NOTIFICATION'], "error.backend.notifications", token).then((result) => {
            return result.data;
        })
}
