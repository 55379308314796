import React, { useCallback, useState } from 'react';
import { getFightersFighter, getFightersTeam } from '../../Backend/ApiFighter';
import { getResults, IApiCompetitionResultWithFighter } from '../../util/ApiCompetition';
import { IServiceTableNotification } from '../Util/ErrorMessage';



export interface IuseCompResultProps {
    dispatchNotification: React.Dispatch<IServiceTableNotification>,
    competitionId: number | undefined
}

export function useCompResult(props: IuseCompResultProps): [IApiCompetitionResultWithFighter[]
    , () => void] {
    const [compResults, setCompResults] = useState<Array<IApiCompetitionResultWithFighter>>([]);

    const loadResults = useCallback(() => {
        if (props.competitionId) {
            getResults(props.dispatchNotification, props.competitionId).then(async (res) => {
                let compResultDraft: Array<IApiCompetitionResultWithFighter> = [];
                for (let index = 0; index < res.length; index++) {
                    const fightTeam = res[index].teamId ? await getFightersTeam(props.dispatchNotification, res[index].teamId as number) : undefined;
                    const fighter = res[index].fighterId ? await getFightersFighter(props.dispatchNotification, res[index].fighterId as number) : undefined;
                    compResultDraft.push({
                        ...res[index],
                        fightTeam: fightTeam,
                        fighter: fighter
                    })
                }
                setCompResults(compResultDraft);
            })
        } else {
            setCompResults([]);
        }
    }, [props.competitionId, props.dispatchNotification]);
    return [
        compResults,
        loadResults
    ]
}