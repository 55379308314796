import { Typography } from '@mui/material';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IAuthentication } from '../Auth/auth';
import { SchedullerTable } from '../Scheduller/SchedullerTable';
import { useErrorMessage } from '../Util/ErrorMessage';

export function MainScheduller(props: {
    authentication: IAuthentication
}) {
    const [dispatchNotification, errorComponent] = useErrorMessage();
    const { t, i18n } = useTranslation();

    return <Container fluid>
        <Row>
            <Col md={12}>
                {errorComponent}
            </Col>
        </Row>
        <Row>
            <Col md={12}>
                <Typography variant="h3" component="div" gutterBottom>
                    {t("label.title.mainscheduller")}
                </Typography>
            </Col>
        </Row>

        <Row>
            <Col md={12}>
                <SchedullerTable
                    dispatchNotification={dispatchNotification}
                    authentication={props.authentication}
                />
            </Col>
        </Row>
    </Container>
}