import React from 'react';
import { useTranslation } from 'react-i18next';
import { IApiFightModel } from '../../util/ApiModel/ApiFightModel';
import { CountryFlagScreen } from './CountryFlagScreen';
import { IShiajoScreenConfig } from './ShiajoScreen';

var debug = require('debug')('ServiceTablesMinimalFrontend:FightNotReadyScreen')

export function FightNotReadyScreen(props: { fight: IApiFightModel, config: IShiajoScreenConfig }) {
    const { t, i18n } = useTranslation();


    return <tr>
        <td colSpan={3} className="fighterteamName">
            {props.config.countryFlag && <CountryFlagScreen style={{}} country={props.config.whiteLeft ? props.fight.whiteFightTeam.nationality : props.fight.redFightTeam.nationality} />}
            {props.config.whiteLeft ? props.fight.whiteFightTeam.name : props.fight.redFightTeam.name}
        </td>
        <td colSpan={1}>
            {(props.fight.rounds?.length ?? 0) > 1 ? t("info.fight.notReadyYet") : t("label.word.wait")}
        </td>
        <td colSpan={3} className="fighterteamName">
            {props.config.countryFlag && <CountryFlagScreen style={{}}  country={props.config.whiteLeft ? props.fight.redFightTeam.nationality : props.fight.whiteFightTeam.nationality} />}
            {props.config.whiteLeft ? props.fight.redFightTeam.name : props.fight.whiteFightTeam.name}
        </td>
    </tr>
}
