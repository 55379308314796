import { createTheme } from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
        finished: Palette['primary'];
        inprogress: Palette['primary'];
        ready: Palette['primary'];
        undefined: Palette['primary'];
        wait: Palette['primary'];
        commited: Palette['primary'];
        canceled: Palette['primary'];
    }
    interface PaletteOptions {
        finished: PaletteOptions['primary'];
        inprogress: PaletteOptions['primary'];
        ready: PaletteOptions['primary'];
        undefined: PaletteOptions['primary'];
        wait: PaletteOptions['primary'];
        commited: PaletteOptions['primary'];
        canceled: PaletteOptions['primary'];
    }
}

export const theme = createTheme({
    palette: {
        finished: {
            main: '#cfd8dc',
            contrastText: '#000',
        },
        inprogress: {
            main: '#98FB98',
            contrastText: '#006400',
        },
        ready:
        {
            main: '#00acc1',
            contrastText: '#fff',
        },
        undefined: {
            main: '#FFC300',
            contrastText: '#fff',
        },
        wait: {
            main: '#B0E0E6',
            contrastText: '#000',
        },
        commited:
        {
            main: '#228B22',
            contrastText: '#fff',
        },
        canceled: {
            main: '#FF4500',
            contrastText: '#fff',
        },
    },
});
