import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import produce from "immer"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { IApiCompetitionModel } from "../../Model/ApiCompetitionModel"
import { IApiFighterFightTeam } from "../../Model/ApiTeamModel"
import { postApiObject } from "../Operation/GenericOperation"
import { IFighterFightTeamResult, nonFighterFightTeam } from "../Team/LazyFightTeam"
import { IServiceTableNotification } from "../Util/ErrorMessage"

export interface IDrawingForm {
    display: boolean,
    open: boolean,
    competition: IApiCompetitionModel
    actionLabel: string,
    onAction: (params: IDrawParams) => void,
    onCancel: (params: IDrawParams) => void,
    dispatchNotifications: React.Dispatch<IServiceTableNotification>,
    drawParams?: IDrawParams
}

export interface IDrawParams {
    size: number,
    type: number,
    ecart: number,
    ecartTete: number,
    tete1: number,
    tete2: number,
    tete3: number,
    tete4: number,
    force: boolean,
    preferedlastsize: number
}

export function DrawingForm(props: IDrawingForm) {
    const { t, i18n } = useTranslation();
    const [drawParams, setDrawParams] = useState<IDrawParams>({
        size: 3,
        type: 0,
        ecart: 0,
        ecartTete: 0,
        tete1: 0,
        tete2: 0,
        tete3: 0,
        tete4: 0,
        force: false,
        preferedlastsize: 0
    })
    const [listFighter, setListFighter] = useState<Array<IApiFighterFightTeam>>([]);

    //set props params or default if not defined
    useEffect(() => {
        setDrawParams(props.drawParams ?? {
            size: props.competition.poolsize,
            type: props.competition.rules === "DirectDrawing" ? 0 : 1,
            ecart: 0,
            ecartTete: 0,
            tete1: 0,
            tete2: 0,
            tete3: 0,
            tete4: 0,
            force: false,
            preferedlastsize: 0
        })
    }, [props.drawParams, props.competition.poolsize, props.competition.rules]);

    useEffect(() => {
        if (props.competition.id > 0) {
            /*
            listFightersCompetition(props.dispatchNotifications, props.competition.id).then(list => {
                setListFighter(list ?? {})
            })*/
            postApiObject<IFighterFightTeamResult>(
                "/team/list"
                , {
                    competition: props.competition.id
                }, {
                status: 400,
                succeed: false,
                data: { data: JSON.stringify(nonFighterFightTeam) }
            }, props.dispatchNotifications, (window as any)['REACT_APP_API_COMBATTANTS_URL'])
                .then((res: IFighterFightTeamResult) => {
                    setListFighter(JSON.parse(res.data.data) as Array<IApiFighterFightTeam>);
                })
        }
    }, [props.competition.id, props.dispatchNotifications]);


    return <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={props.open}
        onClose={() => props.onCancel(drawParams)}>
        <DialogTitle>Tirage {props.competition.fullname}</DialogTitle>
        <DialogContent>
            <Box className="modalBox" sx={{ overflowY: "scroll" }}>

                <Grid container spacing={2} padding={2}>
                    <Grid item xs={6}>
                        <FormControl >
                            <InputLabel id="input-drawing-type">{t("label.draw.type")}</InputLabel>
                            <Select
                                labelId="input-drawing-type"
                                id="input-drawing-type-select"
                                value={drawParams.type}
                                label={t("label.draw.type")}
                                onChange={(e) => {
                                    setDrawParams((p) => {
                                        return {
                                            ...p,
                                            type: parseInt(e.target.value.toString())
                                        }
                                    })
                                }}
                            >
                                <MenuItem value={1}>{t("action.draw.create.pool")}</MenuItem>
                                <MenuItem value={0}>{t("action.draw.create.table")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl style={{ minWidth: '200px' }}>
                            <InputLabel id="input-drawing-size" >{t("label.draw.size")} </InputLabel>
                            <Select

                                labelId="input-drawing-size"
                                id="input-drawing-size-select"
                                value={drawParams.size}
                                label={t("label.draw.size")}
                                onChange={(e) => {
                                    setDrawParams((p) => {
                                        return {
                                            ...p,
                                            size: parseInt(e.target.value.toString())
                                        }
                                    })
                                }}

                            >
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl style={{ minWidth: '200px' }}>
                            <InputLabel id="input-drawing-ecart" >{t("label.draw.ecart")} </InputLabel>
                            <Select

                                labelId="input-drawing-ecart"
                                id="input-drawing-ecart-select"
                                value={drawParams.ecart}
                                label={t("label.draw.ecart")}
                                onChange={(e) => {
                                    setDrawParams((p) => {
                                        return {
                                            ...p,
                                            ecart: parseInt(e.target.value.toString())
                                        }
                                    })
                                }}

                            >
                                <MenuItem value={0}>{t("action.draw.create.none")}</MenuItem>
                                <MenuItem value={1}>{t("action.draw.create.ecartClub")}</MenuItem>
                                <MenuItem value={3}>{t("action.draw.create.ecartRegion")}</MenuItem>
                                <MenuItem value={2}>{t("action.draw.create.ecartPays")}</MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl style={{ minWidth: '200px' }}>
                            <InputLabel id="input-drawing-preferedLastSize" >{t("label.draw.preferedLastSize")} </InputLabel>
                            <Select
                                labelId="input-drawing-preferedLastSize"
                                id="input-drawing-preferedLastSize-select"
                                value={drawParams.preferedlastsize}
                                label={t("label.draw.preferedLastSize")}
                                onChange={(e) => {
                                    setDrawParams((p) => {
                                        return {
                                            ...p,
                                            preferedlastsize: parseInt(e.target.value.toString())
                                        }
                                    })
                                }}

                            >
                                <MenuItem value={0}>{t("label.draw.preferedLastSizeNone")}</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl style={{ minWidth: '200px' }}>
                            <InputLabel id="input-drawing-ecarttete" >{t("label.draw.ecartTete")} </InputLabel>
                            <Select

                                labelId="input-drawing-ecarttete"
                                id="input-drawing-ecartTete-select"
                                value={drawParams.ecartTete}
                                label={t("label.draw.ecart")}
                                onChange={(e) => {
                                    setDrawParams((p) => {
                                        return {
                                            ...p,
                                            ecartTete: parseInt(e.target.value.toString())
                                        }
                                    })
                                }}

                            >
                                <MenuItem value={0}>{t("action.draw.create.no")}</MenuItem>
                                <MenuItem value={1}>{t("action.draw.create.yes")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {[1, 2, 3, 4].map(teteNum => {
                        return <Grid item xs={6}>
                            <FormControl style={{ minWidth: '200px' }}>
                                <InputLabel id={"input-drawing-ecarttete" + teteNum} >{t("label.draw.ecartTete" + teteNum)} </InputLabel>
                                <Select

                                    labelId={"input-drawing-ecarttete" + teteNum}
                                    id={"input-drawing-ecarttete-select" + teteNum}
                                    value={drawParams[('tete' + teteNum) as keyof IDrawParams] as number}
                                    label={t("label.draw.ecart" + teteNum)}
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value.toString());
                                        setDrawParams((p) => {
                                            return produce(p, draft => {
                                                switch (teteNum) {
                                                    case 1: draft.tete1 = value; break;
                                                    case 2: draft.tete2 = value; break;
                                                    case 3: draft.tete3 = value; break;
                                                    case 4: draft.tete4 = value; break;
                                                }

                                            })
                                        })

                                    }}

                                >
                                    <MenuItem value={0}>{t("action.draw.create.none")}</MenuItem>
                                    {listFighter.map(f => {
                                        return <MenuItem value={f.id}>{f.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    })}

                </Grid>
                <Grid item xs={6}>

                    <FormControlLabel control={
                        <Checkbox

                            id="input-drawing-force-ckb"
                            value={drawParams.force}
                            onChange={(e, checked) => {
                                setDrawParams((p) => {
                                    return {
                                        ...p,
                                        force: checked
                                    }
                                })
                            }}

                        />
                    } label={t("action.draw.force")} />

                </Grid>
                <Button variant="contained" onClick={() => props.onAction(drawParams)}>{props.actionLabel}</Button>
                <Button variant="contained" onClick={() => props.onCancel(drawParams)}>{t("label.word.cancel")}</Button>
            </Box>
        </DialogContent>
    </Dialog >
}