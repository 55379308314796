import { Chip } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { trackPromise } from 'react-promise-tracker';
import { IApiResult } from '../../util/ApiCall';
import { IApiFightModel, IFightTeamState } from '../../util/ApiModel/ApiFightModel';
import { IApiFightScoreModel, IApiFightScoresModel } from '../../util/ApiModel/ApiFightScore';
import { IApiFightGroupModel, IApiFightGroupResult, emptyFightgroup, fgTypeLabel } from '../../util/ApiModel/ApiFightgroupModel';
import { IApiShiajoModel } from '../../util/ApiModel/ApiShiajoModel';
import { IApiCompetitionTreeModel } from '../../util/ApiModel/Following/ApiCompetitionTree';
import { FightGroupNodeLabel } from '../Fight/FightGroupNodeLabel';
import { FightGroupTeamsCard } from '../Fight/FightGroupTeamsCard';
import { ConfirmModal } from '../Modal/ConfirmModal';
import { FightGroupScoreModalButton } from '../Modal/FightGroupScoreModalButton';
import { isFollowedFightGroup } from '../Operation/FightGroupOperation';
import { getApiObject } from '../Operation/GenericOperation';
import { deleteNotPostpone } from '../Operation/ShiajoOperation';
import { TeamCard } from '../Team/TeamCard';
import { TeamLabel } from '../Team/TeamLabel';
import { usePopOver } from '../Use/UsePopOver';
import { IServiceTableNotification } from '../Util/ErrorMessage';
import { LoadingIndicator } from '../Util/LoadingIndicator';
import { FightGroupStatus } from './FightGroupStatus';

const SUMMARY_TABLE_REFRESH_MS = 60000;

export const emptyFightgroupsScored: IApiShiajoScoredResult = {
    succeed: false,
    data: []
}

export interface IApiFightGroupScoredModel extends IApiFightGroupModel {
    score: IApiFightScoresModel
}

export interface IApiShiajoScoredModel extends IApiFightGroupScoredModel {
    shiajo: IApiShiajoModel
}

export interface IApiShiajoScoredResult extends IApiResult {
    data: Array<IApiShiajoScoredModel>
}



export function SummaryFightGroupTable(props: { dispatchNotification: React.Dispatch<IServiceTableNotification>, competitionId: number }) {
    const [fightGroupsData, setFightGroupsData] = useState<Array<IApiShiajoScoredModel> | null>(null);
    const [loaded, setLoaded] = useState<number | undefined>();
    const { t, i18n } = useTranslation();
    //const [overlayFightElement, displayFightOverlay] = UseFightOverlay(props.dispatchNotification);
    const [tout, setTout] = useState<any>(null);
    const [refreshDate, setRefreshDate] = useState<Date | undefined>(undefined);
    const [popover, displayPopOver] = usePopOver<IFightTeamState>({
        dispatchNotification: props.dispatchNotification,
        loader: async (params: IFightTeamState | undefined) => {
            return params ? {
                title: params.name,
                content: <TeamCard team={params} dispatchNotification={props.dispatchNotification} />
            } : undefined
        }
    });
    const [popoverFight, displayPopOverFight] = usePopOver<IApiCompetitionTreeModel>({
        dispatchNotification: props.dispatchNotification,
        loader: async (params: IApiCompetitionTreeModel | undefined) => {
            let result = {
                title: "",
                content: <></>
            };
            if (params) {
                let data = await getApiObject<IApiFightGroupResult>('/fightgroups/fightGroupNodeId', params.fightGroupNodeId, emptyFightgroup, props.dispatchNotification).then(
                    (result: IApiFightGroupResult) => {
                        if (result.succeed) {
                            return result.data;
                        } else {
                            return undefined;
                        }
                    }
                );
                if (data) {
                    result = {
                        title: `${data.fightGroupNodeId} - ${data.label}`,
                        content: <FightGroupTeamsCard f={data} />
                    }
                }
            }
            return result;
        }
    }
    );

    useEffect(() => {
        if (loaded !== props.competitionId) {
            setRefreshDate(undefined);
            trackPromise(
                getApiObject<IApiShiajoScoredResult>("/FightGroups?score=true&competitionIds="
                    + props.competitionId//+ getSelection().selectedCompetition.join(",")
                    , null, emptyFightgroupsScored
                    , props.dispatchNotification)
                    .then((result: IApiShiajoScoredResult) => {
                        setLoaded(props.competitionId);
                        if (result.succeed) {

                            setFightGroupsData(result.data);
                        }
                        if (tout) {
                            clearTimeout(tout);
                        }
                        setTout(setTimeout(() => {
                            setLoaded(undefined);
                        }, SUMMARY_TABLE_REFRESH_MS));
                        setRefreshDate(new Date());
                    })
                , "SummaryFightGroupTable.fightGroups");
        }

    }, [loaded, props.competitionId, props.dispatchNotification, tout]);

    let deleteFightGroup = (fightGroupNodeId: number) => {
        deleteNotPostpone(fightGroupNodeId, props.dispatchNotification)
            .then((result: IApiFightGroupResult) => {
                if (result.succeed) {
                    setFightGroupsData(null); //force reload
                }
            });
    }

    /*let figthgroupBadge = useCallback((fightGroup: IApiShiajoScoredModel) => {
        let variant: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark" = "dark";
        switch (fightGroup.score.status) {
            case "COMMITTED":
                variant = "success";
                break;
            case "WAIT":
                variant = "warning"
                break;
            case "CANCELED":
            case "UNDEFINED":
                variant = "danger"
                break;
            case "INPROGRESS":
                variant = "primary"
                break;
            case "FINISHED":
                variant = "secondary";
                break;
            default:

        }
        return <Badge color={variant}>{fightGroup.score ? t("label.fight.status." + fightGroup.score.status.toLowerCase()) : ""}</Badge>
    }, [t]);*/


    return <>
        {popover}
        {popoverFight}
        {refreshDate !== undefined && <p>{t('label.fightgroup.lastRefresh')} : {refreshDate.toLocaleString()}  <Button size="sm" onClick={() => {
            setLoaded(undefined);
        }
        }>{t("action.fightgroup.refresh")}</Button></p>}

        <LoadingIndicator area="SummaryFightGroupTable.fightGroups" />

        {fightGroupsData && <Table striped bordered hover>
            <thead>
                <tr>
                    <th>{t('label.word.label')}</th>
                    <th>{t('label.word.shiaijo')}</th>
                    <th>{t('label.word.id')}</th>
                    <th>{t('label.word.currentFight')}</th>
                    <th>{t('label.word.status')}</th>
                    <th>{t('label.word.beginDate')}</th>
                    <th colSpan={7}>{t('label.word.score')}</th>
                    <th>{t('label.word.actions')}</th>
                </tr>
            </thead>
            <tbody>
                <tr>

                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        {t('label.word.team')}
                    </td>
                    <td>
                        {t('label.word.ranking')}
                    </td>
                    <td>
                        {t('label.score.score')}
                    </td>
                    <td>
                        {t('label.score.victories')}
                    </td>
                    <td>
                        {t('label.score.draws')}
                    </td>
                    <td>
                        {t('label.score.ippons')}
                    </td>
                    <td>
                        {t('label.score.ipponGets')}
                    </td>

                    <td></td>
                </tr>
                {

                    fightGroupsData.sort((f1, f2) => f1.id - f2.id).map((fightGroup, key) => {
                        let showRank = fightGroup.score.ranking.length > 0;
                        let displayScore: Array<IApiFightScoreModel> = []
                        fightGroup.score.ranking.forEach(s => {
                            displayScore.push(s);
                        })
                        fightGroup.score.scores.forEach(s => {
                            if (undefined === displayScore.find(s2 => s.teamId === s2.teamId)) {
                                displayScore.push(s);
                            }
                        })

                        return displayScore.map((score, k) => {
                            let scoreTeamFight: IApiFightModel | undefined = fightGroup.fights.find((fight) => {
                                return fight.redFightTeam.externalId === score.teamExternalId
                                    || fight.whiteFightTeam.externalId === score.teamExternalId
                            })
                            let scoreTeam: IFightTeamState | undefined = scoreTeamFight ? (scoreTeamFight.redFightTeam.externalId === score.teamExternalId ? scoreTeamFight.redFightTeam : scoreTeamFight.whiteFightTeam) : undefined;

                            let rowSpan = fightGroup.score?.scores.length;
                            let currentFightDescription = `${fightGroup.currentFightId ?? t("label.word.none")}`;
                            if (!fightGroup.currentFight) {
                                fightGroup.currentFight = fightGroup.fights.find(f => f.id === fightGroup.currentFightId);
                            }
                            if (fightGroup.currentFight) {
                                currentFightDescription = `${fightGroup.currentFight.redFightTeam.name} ${t('label.word.red')} ${t('label.word.versus')} ${fightGroup.currentFight.whiteFightTeam.name}  ${t('label.word.white')} `
                            }


                            return <tr key={k}>
                                {k === 0 && <td rowSpan={rowSpan}> {`${fightGroup.label ? fightGroup.label : ""}  (${t(fgTypeLabel(fightGroup.type) ?? "")})`}</td>}
                                {k === 0 && <td rowSpan={rowSpan}> {`${fightGroup.shiajo.shortname} (${fightGroup.shiajoId})`}</td>}
                                {k === 0 && <td rowSpan={rowSpan}>
                                    <FightGroupNodeLabel fg={fightGroup.fightGroupNodeId} displayPopOver={displayPopOverFight} />
                                </td>}

                                {k === 0 && <td rowSpan={rowSpan}> {currentFightDescription}</td>}
                                {k === 0 && <td rowSpan={rowSpan}> {<FightGroupStatus status={fightGroup.status} />}</td>}
                                {k === 0 && <td rowSpan={rowSpan}> {fightGroup.beginDate ? moment(fightGroup.beginDate).format("HH:mm:ss") : ""}</td>}
                                <td>
                                    {scoreTeam && <TeamLabel team={scoreTeam} displayPopOver={displayPopOver} />}
                                    {/*{scoreTeam?.name} ({score?.teamExternalId}*/}
                                </td>
                                <td>
                                    {showRank && `${k + 1}`}
                                </td>
                                <td>
                                    {score?.score}
                                </td>
                                <td>
                                    {score?.victories}
                                </td>
                                <td>
                                    {score?.draws}
                                </td>
                                <td>
                                    {score?.ippons}
                                </td>
                                <td>
                                    {score?.ipponGets}
                                </td>


                                {k === 0 && <td rowSpan={rowSpan}>

                                    <FightGroupScoreModalButton fightGroupId={fightGroup.id} />

                                    {isFollowedFightGroup(fightGroup) && <ConfirmModal label={t(`action.fightgroup.${fightGroup.cancel ? 'postpone' : 'askpostpone'}`)} message={t(`confirmation.following.${fightGroup.cancel ? 'postpone' : 'askpostpone'}`)} action={() => {
                                        deleteFightGroup(fightGroup.fightGroupNodeId);
                                    }} />}
                                    {!isFollowedFightGroup(fightGroup) && <Chip label={t('label.fightgroup.notFollowedPostpone')} />}

                                </td>}
                            </tr>
                        })

                    })

                }
            </tbody>
        </Table>
        }
        {!fightGroupsData && <div>{t("label.word.loading")}…</div>}
    </>

}