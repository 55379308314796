import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import '@mui/lab';
import { TextField } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

/*
 <TextField
                    id="standard-basic"
                    label="Date"
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}

                />*/
export function EventDatePicker(props: {
    value?: Date | null,
    label: string,
    onChange: (date: Date | null) => void
}) {
    const { t } = useTranslation();
    return <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={props.label}
                value={props.value}
                onChange={(newValue:any) => {
                    props.onChange(newValue ?? null);
                }}
                renderInput={(params:any) => <TextField {...params}/>
                }
                inputFormat="dd-MM-yyyy"
            />
        </LocalizationProvider>
    ;
}

export function EventDateEditInputCell(props: GridRenderCellParams) {
    const { id, value, api, field } = props;
    const { t } = useTranslation();
    const handleChange = (event: Date | null) => {
        api.setEditCellValue({ id, field, value: event ? event.toISOString().split('T')[0] : undefined }, event);
        // Check if the event is not from the keyboard
        // https://github.com/facebook/react/issues/7407
        //if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
        api.commitCellChange({ id, field });
        api.setCellMode(id, field, 'view');
        //}
    };

    return (
        <div>
            <EventDatePicker
                value={new Date(value as string)}
                label={t(`label.competition.EndDate`)}
                onChange={handleChange}
            />
        </div>
    );
}

export function renderEventDateEditInputCell(params: any) {
    return <EventDateEditInputCell {...params} />;
}