import React from "react";
import { IApiRoundEventModel } from "../../util/ApiModel/ApiEventModel";

export function EventLabel(props: {
    event: IApiRoundEventModel
    style: "normal" | "short"
}) {
    switch (props.style) {
        case "normal":
            return <>{props.event.type}</> 
        case "short":
            return <>{props.event.type[0]}</>
    }
    return <></>
}