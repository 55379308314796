import React from 'react';
import { IApiCompetitionModel } from '../../Model/ApiCompetitionModel';
import { IApiFighterModel, noneFighter } from '../../util/ApiModel/ApiFighterModel';
import { IApiRoundModel } from '../../util/ApiModel/ApiRoundModel';
import { Ippons } from '../Table/Ippons';
import { CountryFlagScreen } from './CountryFlagScreen';
import { RoundStateScreen } from './RoundStateScreen';
import { IShiajoScreenConfig } from './ShiajoScreen';


export interface IRoundScreenProps extends IApiRoundModel {
    active: boolean,
    additionnal: boolean,
    config: IShiajoScreenConfig,
    competition: IApiCompetitionModel
}

export function FighterScreen(props: { fighter: IApiFighterModel, countryFlag: boolean }) {


    return <>
        {props.countryFlag && <CountryFlagScreen style={{}} country={props.fighter.nationality} />}
        {props.fighter.firstName[0]}. {props.fighter.lastName.toUpperCase()}
    </>;
}

export function RoundScreen(props: IRoundScreenProps) {
    const redFighter: IApiFighterModel = props.redFighter ? props.redFighter : noneFighter;
    const whiteFighter: IApiFighterModel = props.whiteFighter ? props.whiteFighter : noneFighter;

    return <tr key={props.id} className={props.active ? "current" : "notcurrent"}>
        <td colSpan={3} className="fighterName">
            <FighterScreen fighter={props.config.whiteLeft ? whiteFighter : redFighter} countryFlag={props.config.countryFlag} />
        </td>

        <td colSpan={1} className="shiajo_screen_row">
            <div className="shiajo_screen_ippons">
                <Ippons
                    showEncho={props.additionnal === false}
                    showAllWarns={false}
                    round={props}
                    config={props.config}
                    competition={props.competition}  
                    interactive={false}/>
            </div>
            <div className="shiajo_screen_roundstate">
                {props.active && <RoundStateScreen {...props} />}
            </div>
        </td>

        <td colSpan={3} className="fighterName">
            <FighterScreen fighter={props.config.whiteLeft ? redFighter : whiteFighter} countryFlag={props.config.countryFlag} />
        </td>

    </tr>
}