import React from 'react';
import { IApiRoundEventModel } from '../../util/ApiModel/ApiEventModel';

export function warnSvg(barred: boolean): JSX.Element {
    return <svg width="100%" height="100%" viewBox="0 0 16 16" className="bi bi-caret-up-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
        {barred && <line id="svg_3" y2="4" x2="13" y1="13" x1="2" stroke="#000" fill="none" strokeWidth="1.5" />}
    </svg>
}

export const StrikeType = {
    STRIKE: 'strike'
}

export interface StrikeDropItem { 
    event: IApiRoundEventModel
}

export function StrikeSvg(props: { label: string, circle?: boolean, postNumber?: number, event?: IApiRoundEventModel }): JSX.Element {
    /*const [{ isDragging }, drag] = useDrag(() => ({
        type: StrikeType.STRIKE,
        item: {
            event: props.event
        },
        collect: (monitor) => {
            //console.log(monitor.isDragging());
            return ({

                isDragging: !!monitor.isDragging()
            })
        }
    }));*/

    return <div 
    //ref={drag}
        style={{
            //opacity: isDragging ? 0.5 : 1,
            fontWeight: 'bold',
            cursor: 'move',
            width:"100%",
            height:"100%",
            verticalAlign:"center",
            overflow:"hidden",
            //backgroundColor: 'red',
        }}>
        <svg
            style={{
                //opacity: isDragging ? 0.5 : 1,
                fontSize: 25,
                fontWeight: 'bold',
                cursor: 'move',
            }}
            width="100%"
            height="100%" viewBox="0 0 22 23" className="bi bi-caret-up-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <ellipse ry="10" rx="10" id="svg_1" cy="11" cx="11" strokeWidth={props.circle ? "2" : "0"} stroke="#000" fill="#fff" />
            <text stroke="#000" textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="14" id="svg_2" y="15" x={props.label === 'I' ? "8" : "5"} strokeWidth="0" fill="#000000">{props.label}</text>


            {props.postNumber && <>
                <rect height="8" width="5" y="15" x="7" stroke="#000" fill="#FFF" />
                <text stroke="#000" textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="6" id="svg_2" y="21" x="8" fill="#000000">{props.postNumber}</text>
            </>}
        </svg >
    </div >
}