import { patchApiObject } from "../components/Operation/GenericOperation";
import { IServiceTableNotification } from "../components/Util/ErrorMessage";
import { emptyApiFightModel, IApiFightModel } from "./ApiModel/ApiFightModel";

/**
 * Solve round with none fighter
 * @param dispatchNotification 
 * @param competitionId 
 * @param host 
 * @param token 
 * @returns 
 */
 export async function patchFightSolve(dispatchNotification: React.Dispatch<IServiceTableNotification>, fightId: number): Promise<IApiFightModel|undefined> {
    return patchApiObject<{
        succeed: boolean,
        data: IApiFightModel
    }>(`/fights/${fightId}/resolve`, {} , {
        succeed: false,
        data: emptyApiFightModel
    },  dispatchNotification, undefined).then((result) => {
        if (result.succeed) return result.data;
        return undefined;
    });
}
