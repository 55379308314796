import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IApiRoundEventModel } from '../../util/ApiModel/ApiEventModel';
import { IApiRoundModel } from '../../util/ApiModel/ApiRoundModel';
import { IServiceTableNotification } from '../Util/ErrorMessage';
import { RoundEventListItem } from './RoundEventListItem';

export interface IRoundEventListProps {
    round: IApiRoundModel,
    deleteEvent(eventId: number): void,
    dispatchNotification: React.Dispatch<IServiceTableNotification>,
    reloadRound: () => void
    //dispatchModal: React.Dispatch<IModalAction>
}

export function RoundEventList(props: IRoundEventListProps) {
    const { t, i18n } = useTranslation();
    const [collapse, setCollapse] = useState(true);

    return <>
        {t('label.title.events')}

        {!collapse && <ArrowUpwardIcon onClick={() => setCollapse(true)} />}
        {

            //round.eventPushList.slice(0).reverse() .concat(
            props.round.events?.slice(collapse && (props.round.events?.length ?? 0) > 2 ? props.round.events.length - 2 : 0).reverse().map((e: IApiRoundEventModel, k) => {
                return <RoundEventListItem key={k}
                    reloadRound={props.reloadRound}
                    redFighter={props.round.redFighter}
                    whiteFighter={props.round.whiteFighter}
                    event={e}
                    deleteEvent={props.deleteEvent}
                    dispatchNotification={props.dispatchNotification}
                        /*dispatchModal={props.dispatchModal}*/ />
            })

        }
        {collapse && <>...{collapse && <ArrowDownwardIcon onClick={() => setCollapse(false)} />}</>}
    </>
}