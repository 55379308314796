import React from 'react';
import { useTranslation } from 'react-i18next';
import { IApiFighterModel } from '../../util/ApiModel/ApiFighterModel';
import { IApiRoundModel } from '../../util/ApiModel/ApiRoundModel';
import { patchRoundSolve } from '../../util/ApiRound';
import { IServiceTableNotification } from '../Util/ErrorMessage';

export interface IRoundAutomaticResolveButton {
    round: IApiRoundModel,
    winner?: IApiFighterModel,
    dispatchNotification: React.Dispatch<IServiceTableNotification>,
    reload: (round:IApiRoundModel) => Promise<void>
}

export function RoundAutomaticResolveButton(props: IRoundAutomaticResolveButton) {
    const { t } = useTranslation();

    return <button type="button" className="btn btn-outline-primary  btn-sm shiajo_button_fighter_action"
        onClick={() => {
            patchRoundSolve(props.dispatchNotification, props.round.id).then((res) => {
                if (res) props.reload(res);
            })
        }}>
        {t("action.round.autoresolve")}
    </button>
}