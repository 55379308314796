import React from "react";
import ShiajoModal from "../Modal/ShiajoModal";
import { FightCard, IFightCardState } from "./FightCard";

export interface IFightCardModalstate extends IFightCardState {
    showing: boolean,
    toggle: () => void,
}

export function FigthCardModal(props: IFightCardModalstate) {
    
    return <ShiajoModal

        isShowing={props.showing}
        hide={props.toggle}
        title="FightCard">
            
        {props.showing && <FightCard {...props}/>}
    </ShiajoModal>
}