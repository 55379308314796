import { Box, Button, Modal } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFightState } from './Fight';
import { TeamOrder } from './TeamOrder';

export interface ITeamOrderModalProps {
    fight: IFightState
    color?: 'red' | 'white' | undefined
    onUpdate: () => void
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export function TeamOrderModal(props: ITeamOrderModalProps) {
    const [open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let teamList = []
    if (props.color === 'white' || props.color === undefined){
        teamList.push(props.fight.data.whiteFightTeam);
    }
    
    if (props.color === 'red' || props.color === undefined){
        teamList.push(props.fight.data.redFightTeam);
    }
    const comp = <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        
        <Box sx={style}>
            <TeamOrder
                fight={props.fight}
                teams={teamList}
                orderFightLimit={props.fight.data.rounds?.length ?? 0}
                onUpdated={() => { handleClose(); props.onUpdate(); }}
                onCancel={() => { handleClose(); }}
            />
        </Box>
    </Modal>;
    return <>
        <Button variant='contained' size='small' onClick={handleOpen}>{t("action.fight.editorder")}</Button>
        {comp}
    </>
}