import React, { useRef } from "react";
import { IFightTeamState } from "../../util/ApiModel/ApiFightModel";
import { TPopOverAction } from "../Use/UsePopOver";

export function TeamLabel(props: {
    team: IFightTeamState
    displayPopOver?: TPopOverAction<IFightTeamState>
}) {
    const ref = useRef(null);

    return <div ref={ref}
        onMouseEnter={() => {
            if (props.displayPopOver) props.displayPopOver("display", ref, props.team);
        }}
        onMouseLeave={() => {
            if (props.displayPopOver) props.displayPopOver("hide", ref, undefined);
        }}
    >{props.team.name} ({props.team.id})</div>
}