
import { useState } from 'react';

var debug = require('debug')('ServiceTablesMinimalFrontend:useShiajoModal')

/*
export interface IModalAction {
    show?:boolean;
    element?: JSX.Element;
}

export interface IShiajoModalState {
    showing:boolean;
    element?: JSX.Element;
}

function modalReducer(state: IShiajoModalState, action: IModalAction): IShiajoModalState {
    let result = {...state};

    if (action.show !== undefined) result.showing = action.show;//action.show;
    result.element = action.element;
    
    return result;
}*/

export function useShiajoModal():[boolean, () => void] {
  /*const [isShowing, dispatch] = useReducer(modalReducer, {
    showing:false
  });*/

  const [isShowing, setIsShowing] = useState(false);

  function toggle() {
    setIsShowing(!isShowing);
  }

  return [isShowing, toggle];
};

export default useShiajoModal;