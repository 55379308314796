import React, { useReducer, useState } from 'react';
import { Card, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export type TuseCompetitionImportRepport = "add" | "clear";


function reportLineReducer(reportLines: Array<{ ts: number, line: string }>, action: { type: TuseCompetitionImportRepport, value: string, ts: number }) {
    let nreportLines = [...reportLines];
    switch (action.type) {
        case "add":
            nreportLines.push({
                ts: action.ts,
                line: action.value
            });
            break;
        case "clear":
            nreportLines = [];
            break;
    }
    return nreportLines;
}


export function useReport(): [JSX.Element, React.Dispatch<{
    type: TuseCompetitionImportRepport;
    value: string,
    ts: number
}>, (percent: number) => void] {
    const { t, i18n } = useTranslation();
    const [reportLines, dispatchReport] = useReducer(reportLineReducer, [] as Array<{ ts: number, line: string }>);
    const [title, setTitle] = useState<string>("");
    const [subTitle, setSubTitle] = useState<string>("");
    const [percent, setPercent] = useState<number>(-1);

    let component = <Card>
        <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">{subTitle}</Card.Subtitle>

            <Card.Text>
                {percent >= 0 && <ProgressBar now={percent} label={`${percent}%`} />}
                <ul className="list-unstyled">
                    {reportLines.sort((a, b) => b.ts - a.ts).map((value, k) => {
                        return <li key={k}>{value.line}</li>
                    })}
                </ul>
            </Card.Text>
        </Card.Body>
    </Card>;
    return [component, dispatchReport, setPercent];

}