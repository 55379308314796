import { IApiCompetitionModel } from "../../Model/ApiCompetitionModel";
import { ERoundEventTypeModel, IApiRoundEventModel } from "../../util/ApiModel/ApiEventModel";
var debug = require('debug')('ServiceTablesMinimalFrontend:RoundUtil')

export interface IIpponsDisplay {
  ippons: Array<{
    label: string,
    ref?: IApiRoundEventModel
  }>,
  warns: Array<{
    label: string,
    ref?: IApiRoundEventModel
  }>
}

export interface IDisplayEvents {
  redEvents: IIpponsDisplay,
  whiteEvents: IIpponsDisplay,
  redFirstStrike: boolean | undefined
}

export function allowedStrikeEvent(type: ERoundEventTypeModel, competition: IApiCompetitionModel): boolean {
  let result = false;
  switch (competition.martialArt) {
    case "KENDO":
      switch (type) {
        case ERoundEventTypeModel.DO:
        case ERoundEventTypeModel.MEN:
        case ERoundEventTypeModel.HANSOKU:
        case ERoundEventTypeModel.IPON:
        case ERoundEventTypeModel.FUSENGACHI:
        case ERoundEventTypeModel.KOTE:
        case ERoundEventTypeModel.TSUKI:
          result = true;
          break;
      }
      break;
    case "IAIDO":
      switch (type) {
        case ERoundEventTypeModel.HANTEI:
          result = true;
          break;
      }
      break;
    case "NAGINATA":
      switch (type) {
        case ERoundEventTypeModel.DO:
        case ERoundEventTypeModel.MEN:
        case ERoundEventTypeModel.HANSOKU:
        case ERoundEventTypeModel.IPON:
        case ERoundEventTypeModel.FUSENGACHI:
        case ERoundEventTypeModel.KOTE:
        case ERoundEventTypeModel.TSUKI:
        case ERoundEventTypeModel.SUNE:
          result = true;
          break;
      }
      break;
    case "SPORT CHANBARA":
      switch (type) {
        case ERoundEventTypeModel.DO:
        case ERoundEventTypeModel.MEN:
        case ERoundEventTypeModel.HANSOKU:
        case ERoundEventTypeModel.IPON:
        case ERoundEventTypeModel.FUSENGACHI:
        case ERoundEventTypeModel.KOTE:
        case ERoundEventTypeModel.TSUKI:
        case ERoundEventTypeModel.ASHI:
          result = true;
          break;
      }
      break;
  }
  return result
}

export function convertEvents(redFighterId: number, whiteFighterId: number, events: IApiRoundEventModel[]): IDisplayEvents {
  let redEvents: IIpponsDisplay = {
    ippons: [],
    warns: [],
  };
  let whiteEvents: IIpponsDisplay = {
    ippons: [],
    warns: [],
  };

  let redFirstStrike: undefined | boolean = undefined;

  //duplicate FUSEN GACHI for display
  const toDuplicate = events
    .findIndex(e => e && e.type === ERoundEventTypeModel.FUSENGACHI)
  if (toDuplicate >= 0) {
    events.splice(toDuplicate, 0, events[toDuplicate]);
  }


  events.sort((e1: IApiRoundEventModel, e2: IApiRoundEventModel) => {
    return 1;
  }).forEach((item: IApiRoundEventModel) => {

    let ippon = null;
    let warn = null;
    switch (item.type) {
      case ERoundEventTypeModel.UNDEFINED:
        ippon = "?";
        break;
      case ERoundEventTypeModel.DO:
        ippon = "D";
        break;
      case ERoundEventTypeModel.HANSOKU:
        warn = "X";
        break;
      case ERoundEventTypeModel.HANTEI:
        ippon = "Ht";
        break;
      case ERoundEventTypeModel.KOTE:
        ippon = "K";
        break;
      case ERoundEventTypeModel.SUNE:
        ippon = "S";
        break;
      case ERoundEventTypeModel.ASHI:
        ippon = "A";
        break;
      case ERoundEventTypeModel.IPON:
        ippon = "I";
        break;
      case ERoundEventTypeModel.FUSENGACHI:
        ippon = " ";
        break;
      case ERoundEventTypeModel.MEN:
        ippon = "M";
        break;
      case ERoundEventTypeModel.TSUKI:
        ippon = "T";
        break;
    }


    if (ippon !== null) {
      debug(JSON.stringify(item));
      if (item.fighterId === redFighterId) {
        redEvents.ippons.push({
          label: ippon,
          ref: item
        });
        if (redFirstStrike === undefined) redFirstStrike = true;
      }
      if (item.fighterId === whiteFighterId) {
        whiteEvents.ippons.push({
          label: ippon,
          ref: item
        });
        if (redFirstStrike === undefined) redFirstStrike = false;
      }
    }

    //add hansoky ippon
    if (warn !== null) {
      if (item.fighterId === redFighterId) {
        redEvents.warns.push({
          label: warn,
          ref: item
        });
        if (redEvents.warns.length % 2 === 0) {
          whiteEvents.ippons.push({
            label: "H"
          })
          if (redFirstStrike === undefined) redFirstStrike = false;
        }
      }
      if (item.fighterId === whiteFighterId) {
        whiteEvents.warns.push({
          label: warn,
          ref: item
        });
        if (whiteEvents.warns.length % 2 === 0) {
          redEvents.ippons.push({
            label: "H"
          })
          if (redFirstStrike === undefined) redFirstStrike = true;
        }
      }
    }
  });
  /*
  if (redEvents.ippons.length < 2){
    redEvents.ippons.push(null);
  }
  if (whiteEvents.ippons.length < 2){
    whiteEvents.ippons.push(null);
  }*/
  return { redEvents: redEvents, whiteEvents: whiteEvents, redFirstStrike: redFirstStrike };
}