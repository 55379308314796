import React from "react";
import { useTranslation } from 'react-i18next';

export function Requirement() {
    const { t, i18n } = useTranslation();

    let alertMessages = []
    if (!window.localStorage) {
        alertMessages.push(<div>{t('requirement.localstorage.ko')}</div>);
    }
    return <div>
        {
            alertMessages.map(item => {
                return item;
            })
        }
    </div>;
}