import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Select, styled } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ERoundEventTypeModel, IApiRoundEventModel } from "../../util/ApiModel/ApiEventModel";
import { searchFighterInFight } from "../../util/ApiModel/ApiFightgroupModel";
import { IFightTeamState } from "../../util/ApiModel/ApiFightModel";
import { IApiFightTeamScore } from "../../util/ApiModel/ApiFightScore";
import { IApiRoundModel } from "../../util/ApiModel/ApiRoundModel";
import { EventLabel } from "../Events/EventLabel";
import { addEvent, deleteEvent } from "../Operation/EventOperation";
import { TeamLabel } from "../Team/TeamLabel";
import { IServiceTableNotification } from "../Util/ErrorMessage";

import { Delete } from '@mui/icons-material';
import { IApiFightGroupModel } from "../../util/ApiModel/ApiFightgroupModel";

interface TeamDetailScore {
    team: IFightTeamState,
    teamScore: Map<number, IApiFightTeamScore>
    countIppon: number;
    countVictory: number;
    countPoint: number;
};

/*
function extractTeamsFromApiCompetitionModel(fg: IApiFightGroupModel, teamMap: Map<number, IApiFighterFightTeam>): Array<IApiFighterFightTeam> {
    const fgteamList: Array<IApiFighterFightTeam> = (fg?.fightGroupData.reduce((teams: Array<number>, fight) => {
        if (!teams.includes(fight.redTeamId)) teams.push(fight.redTeamId);
        if (!teams.includes(fight.whiteTeamId)) teams.push(fight.whiteTeamId);
        return teams;
    }, []).map(fid => teamMap?.get(fid) ?? null).filter(ft => ft !== null) ?? []) as Array<IApiFighterFightTeam>;
    return fgteamList;
}*/
/*
export function FightCardIppons(props: {
    fightGroup: IApiFightGroupModel
}) {
    const { t, i18n } = useTranslation();
    const teams = extractTeamsFromApiCompetitionTreeModel(props.fightGroup, props.teamList);
    const [selectedTeam, setSelectedTeam] = useState<IApiFighterFightTeam | undefined>(undefined);
    const [selectedEvent, setSelectedEvent] = useState<string | undefined>(undefined);
    const [selectedTeamTarget, setSelectedTeamTarget] = useState<IApiFighterFightTeam | undefined>(undefined);
    const competition = getSelection().selectedCompetition.find(c => c.id === props.fightGroup.competitionId);
    

    return <>
        <h5>Saisie des points</h5>



        <Select size="small" value={selectedTeam?.id ?? 0}
            onChange={(e) => {
                let team = teams.find(te => Number(te.id) === Number(e.target.value));
                setSelectedTeam(team);
            }}

        >
            <MenuItem value={0}>{t('label.word.none')}</MenuItem >
            {teams.map((team, k) => {
                return <MenuItem key={k} value={team.id}>{team.name}</MenuItem >
            })}
        </Select>

        <Select size="small" value={selectedEvent ?? ""}
            onChange={(e) => {
                setSelectedEvent(e.target.value);
            }}
        >
            <MenuItem value="">{t('label.word.none')}</MenuItem >
            {eventList.map((event, k) => {
                return <MenuItem key={k} value={event}>{event}</MenuItem >
            })}
        </Select>
        on
        <Select size="small" value={selectedTeamTarget?.id ?? 0}
            onChange={(e) => {
                let team = teams.find(te => Number(te.id) === Number(e.target.value));
                setSelectedTeamTarget(team);
            }}

        >
            <MenuItem value={0}>{t('label.word.none')}</MenuItem >
            {teams.map((team, k) => {
                return <MenuItem key={k} value={team.id}>{team.name}</MenuItem >
            })}
        </Select>
        <Button variant="contained" size="small" onClick={() => {
        }}>Ajouter</Button>
    </>
}*/

const IpponpDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


export interface IFightIpponTableProps {
    fightgroup: IApiFightGroupModel,
    dispatchNotification: React.Dispatch<IServiceTableNotification>,
    reload: () => void
}

export function FightIpponTable(props: IFightIpponTableProps) {
    const { t, i18n } = useTranslation();
    const [addIppon, setAddIppon] = React.useState<{
        round: IApiRoundModel | undefined,
        teamSource: IFightTeamState | undefined,
        teamTarget: IFightTeamState | undefined,
        ippon: string,
        show: boolean
    }>({
        round: undefined,
        teamSource: undefined,
        teamTarget: undefined,
        ippon: "",
        show: false
    });
    const eventList = Object.values(ERoundEventTypeModel).map(e => e.toString());

    let table: Array<{
        team: IFightTeamState,
        col: Array<
            {
                round: IApiRoundModel,
                team: IFightTeamState,
                events: Array<IApiRoundEventModel>
            }>
    }> = [];

    useEffect(() => {
        setAddIppon({
            round: undefined,
            teamSource: undefined,
            teamTarget: undefined,
            ippon: "",
            show: false
        })
    }, [props.fightgroup.id])


    props.fightgroup.fights.sort((f1, f2) => f1.order - f2.order).forEach(fight => {
        if (!table.find(row => row.team.id === fight.redFightTeam.id)) table.push({ 
            team: fight.redFightTeam, col: [] 
        });
        if (!table.find(row => row.team.id === fight.whiteFightTeam.id)) table.push({ 
            team: fight.whiteFightTeam, col: [] 
        });
        


    });


    props.fightgroup.fights.forEach((fight) => {
        fight.rounds?.forEach((round) => {
            
            round.events?.forEach((event: IApiRoundEventModel) => {
                if (event.points && event.fighterId) {
                    let [fighter, color, fighterteam] = searchFighterInFight(fight, event.fighterId);
                    let enemyteam = (color === 'red' ? fight.whiteFightTeam : fight.redFightTeam);
                    if (fighterteam) {
                        let tableteamrow = table.find(row => fighterteam !== undefined && row.team.id === fighterteam.id)
                        if (tableteamrow) {
                            //take enemy col
                            
                            let tableteamcol = tableteamrow.col.find(c => c.team.id === enemyteam.id);
                            if (!tableteamcol) {
                                tableteamrow.col.push({
                                    round: round,
                                    team: enemyteam,
                                    events: []
                                });
                                tableteamcol = tableteamrow.col.find(c => c.team.id === enemyteam.id);
                            }
                            if (tableteamcol) {
                                console.log(`Event ${fighterteam.name} ${color} ${event.id} on ${enemyteam.name}`)
                                tableteamcol.events.push(event);
                            }
                        }
                    }
                }
            }
            )
        })
    })

    props.fightgroup.fights.forEach((fight) => {
        fight.rounds?.forEach((round) => {
            let row = table.find(row => row.team.id === round.redFighter?.fightTeamId);
            let col = row?.col.find(col => col.team.id === round.whiteFighter?.fightTeamId);
            if (!col) {
                row?.col.push({
                    round: round,
                    team: fight.whiteFightTeam,
                    events: []
                });
            }

            row = table.find(row => row.team.id === round.whiteFighter?.fightTeamId);
            col = row?.col.find(col => col.team.id === round.redFighter?.fightTeamId);
            if (!col) {
                row?.col.push({
                    round: round,
                    team: fight.redFightTeam,
                    events: []
                });
            }
        })
    })
    return <>
        <Dialog
            open={addIppon.show}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} >
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        setAddIppon({
                            ...addIppon,
                            show: false
                        })
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <GridCloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Select value={addIppon.ippon}
                    onChange={(e) => {
                        setAddIppon({
                            ...addIppon,
                            ippon: e.target.value
                        });
                    }}
                >
                    <MenuItem value="">{t('label.word.none')}</MenuItem >
                    {eventList.map((event, k) => {
                        return <MenuItem key={k} value={event}>{event}</MenuItem >
                    })}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" size="small" onClick={() => {

                    if (addIppon.round && addIppon.ippon) {
                        addEvent(addIppon.round, {
                            fighterId: addIppon.teamSource?.fighters[0].id,
                            type: addIppon.ippon as ERoundEventTypeModel,
                            date: new Date(),
                        }, props.dispatchNotification, true).then(e => {
                            if (e) {
                                props.reload();
                                setAddIppon({
                                    round: undefined,
                                    teamSource: undefined,
                                    teamTarget: undefined,
                                    ippon: "",
                                    show: false
                                })
                            }
                        })
                    }
                }}>Ajouter</Button>
                <Button variant="contained" size="small" onClick={() => {
                }}>Anuler</Button>
            </DialogActions>

        </Dialog>
        <Table striped bordered hover>
            <thead>
                <tr>
                    <td></td>
                    {table.map((col, key) => {
                        return <td key={key}><TeamLabel team={col.team} /></td>
                    })}
                </tr>
            </thead>
            <tbody>
                {table.map((row, key) => {
                    return <tr key={key}>
                        <td>
                            <TeamLabel team={row.team} />
                        </td>

                        {table.map((col, key) => {
                            const roundEventsTeams = row.col.find(c => c.team.id === col.team.id)
                            
                            return <td key={key}>
                                
                                {roundEventsTeams?.events?.map((e, key) => {
                                    return <React.Fragment key={key}>
                                        <EventLabel event={e} style="short" />
                                        <Delete onClick={() => {
                                            deleteEvent(e.id, props.dispatchNotification, true).then(e => {
                                                if (e) {
                                                    props.reload();
                                                }
                                            })
                                        }} />&nbsp;
                                    </React.Fragment>
                                })}
                                {roundEventsTeams && <AddCircleIcon onClick={() => setAddIppon({
                                    round: roundEventsTeams?.round,
                                    teamSource: row.team,
                                    teamTarget: col.team,
                                    ippon: 'MEN',
                                    show: true
                                })} />}
                            </td>
                        })}
                    </tr>
                })
                }
            </tbody>
        </Table>
    </>
}

