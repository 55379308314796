import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IApiFightModel, IFightTeamState } from "../../util/ApiModel/ApiFightModel";
import { IApiFightDetailScoreModel, IApiFightScoresModel, IApiFightTeamScore } from "../../util/ApiModel/ApiFightScore";
import { IFightGroupState } from "../Table/FightGroup";
import { TeamLabel } from "../Team/TeamLabel";

interface TeamDetailScore {
    team: IFightTeamState,
    teamScore: Map<number, IApiFightTeamScore>
    countIppon: number;
    countVictory: number;
    countPoint: number;
    countIpponGets:number;
};

interface IFightScoreTable extends IFightGroupState{
    score: IApiFightScoresModel | undefined;
}

export function FightScoreTable(props: IFightScoreTable) {
    const { t } = useTranslation();
    let teamsDetailScores = new Map<number, TeamDetailScore>();
    //Init map with fightteam id for count
    props.fightgroup.fights.forEach((fight: IApiFightModel) => {
        if (!teamsDetailScores.has(fight.redFightTeam.id)) {
            teamsDetailScores.set(fight.redFightTeam.id, {
                team: fight.redFightTeam,
                teamScore: new Map(),
                countIppon: 0,
                countVictory: 0,
                countPoint: 0,
                countIpponGets:0
            });
        }
        if (!teamsDetailScores.has(fight.whiteFightTeam.id)) {
            teamsDetailScores.set(fight.whiteFightTeam.id, {
                team: fight.whiteFightTeam,
                teamScore: new Map(),
                countIppon: 0,
                countVictory: 0,
                countPoint: 0,
                countIpponGets:0
            });
        }
    });


    //pour chaque score de combat, stocker le score contre l'adversaire
    if (props.score) props.score.fightScores.forEach((score: IApiFightDetailScoreModel) => {
        let t = teamsDetailScores.get(score.redFightTeamId)?.teamScore;
        if (t && !t?.has(score.whiteFightTeamId)) {
            t.set(score.whiteFightTeamId, {
                ippons: 0,
                victories: 0,
                draws: 0,
                score: 0,
                ipponGets: 0
            });
        }
        let te = t?.get(score.whiteFightTeamId) as IApiFightTeamScore;
        te.ippons += score.fightScore.redTeamScore.ippons;
        te.victories += score.fightScore.redTeamScore.victories;
        te.draws += score.fightScore.redTeamScore.draws;
        te.score += score.fightScore.redTeamScore.score;
        te.ipponGets += score.fightScore.redTeamScore.ipponGets;

        t = teamsDetailScores.get(score.whiteFightTeamId)?.teamScore;

        if (t && !t?.has(score.redFightTeamId)) {
            t.set(score.redFightTeamId, {
                ippons: 0,
                victories: 0,
                draws: 0,
                score: 0,
                ipponGets: 0
            });
        }
        te = t?.get(score.redFightTeamId) as IApiFightTeamScore;
        te.ippons += score.fightScore.whiteTeamScore.ippons;
        te.victories += score.fightScore.whiteTeamScore.victories;
        te.draws += score.fightScore.whiteTeamScore.draws;
        te.score += score.fightScore.whiteTeamScore.score;
        te.ipponGets += score.fightScore.whiteTeamScore.ipponGets;
    });

    teamsDetailScores.forEach((teamScore: TeamDetailScore) => {
        teamScore.teamScore.forEach((score: IApiFightTeamScore) => {
            teamScore.countIppon += score.ippons;
            teamScore.countVictory += score.victories;
            teamScore.countPoint += score.score;
            teamScore.countIpponGets += score.ipponGets;
        });
    })


    return <Table striped bordered hover>
        <tbody>
            <tr>
                <td></td>
                {
                    Array.from(teamsDetailScores.values()).map((teamScore: TeamDetailScore, key: number) => {
                        return <td key={key}><TeamLabel team={teamScore.team} /></td>
                    })
                }
                <td>Points</td>
                <td>Rank</td>
            </tr>
            {
                Array.from(teamsDetailScores.values()).map((teamScore: TeamDetailScore, key: number) => {
                    let rank = props.score?.ranking.findIndex(t => t.teamId === teamScore.team.id) ?? -1;
                    if (rank >= 0) rank += 1;
                    return <React.Fragment key={key}>


                        <tr >
                            <td rowSpan={3}>
                                <TeamLabel team={teamScore.team} />

                            </td>
                            {
                                Array.from(teamsDetailScores.values()).map((teamScore2: TeamDetailScore, key2: number) => {
                                    return <td key={key2}>{teamScore.teamScore.get(teamScore2.team.id)?.ippons}</td>
                                })
                            }
                            <td>{teamScore.countIppon} IPPON(S)<br/>({teamScore.countIpponGets} {t('label.word.get')}</td>
                            <td rowSpan={3}>
                                {rank}
                            </td>
                        </tr>
                        <tr>
                            {
                                Array.from(teamsDetailScores.values()).map((teamScore2: TeamDetailScore, key2: number) => {
                                    return <td key={key2}>{teamScore.teamScore.get(teamScore2.team.id)?.victories}</td>
                                })
                            }
                            <td>{teamScore.countVictory} VICTOIRE(S) (rounds)</td>
                        </tr>
                        <tr>


                            {
                                Array.from(teamsDetailScores.values()).map((teamScore2: TeamDetailScore, key2: number) => {
                                    return <td key={key2}>{teamScore.teamScore.get(teamScore2.team.id)?.score}</td>
                                })
                            }
                            <td>{teamScore.countPoint} POINT(S) (combats)</td>
                          
                        </tr>

                    </React.Fragment>;
                })
            }
        </tbody>
    </Table>
}