import { Tooltip } from "@mui/material";
import React from "react";
import { IApiCompetitionModel } from '../../Model/ApiCompetitionModel';

export function CompetitionLabel(props: {
    competition: IApiCompetitionModel | undefined,
    short?: number
}): JSX.Element {
    return <Tooltip title={props.competition?.fullname ?? ''}>
        <p>{CompetitionLabelStr(props.competition, props.short)}</p>
    </Tooltip>
}

export function CompetitionLabelStr(competition: IApiCompetitionModel | undefined, short?: number): String {
    if (competition) {
        return `${short ? competition.fullname.substring(0, short) + (competition.fullname.length > short ? '...' : '') : competition.fullname} (${competition.id})`
    } else {
        return 'Competition Not found';
    }
}