import { FirstPage, LastPage } from '@mui/icons-material';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IApiFighterFightTeam } from '../../Model/ApiTeamModel';
import { getFollowingCompetitionAll } from '../../util/ApiFollowing';
import { IApiFightGroupModel } from '../../util/ApiModel/ApiFightgroupModel';
import { TApiShiajosModel } from '../../util/ApiModel/ApiShiajoModel';
import { IApiCompetitionTreeModel } from '../../util/ApiModel/Following/ApiCompetitionTree';
import { loadShiajoFightGroupNoTeam } from '../Operation/ShiajoOperation';
import { lazyLoadFightTeams } from '../Team/LazyFightTeam';
import { useSelectCompetition } from '../Use/UseSelectCompetition';
import { useErrorMessage } from '../Util/ErrorMessage';
import { useSseSecureClient } from '../Util/SseSecureClient';
import { FollowingArrayFightGroup } from './followingArrayFightGroup';

export function FollowingArray() {
    
    sessionStorage.setItem("module", "arbre");
    const { t, i18n } = useTranslation();
    const [tree, setTree] = useState<Array<IApiCompetitionTreeModel>>([]);
    const [dispatchNotification, errorComponent] = useErrorMessage(false);
    const [selectCompetition, competitionSummaryId, fightFinished] = useSelectCompetition({ dispatchNotification: dispatchNotification });
    const [teamList, setTeamList] = useState<Map<number, IApiFighterFightTeam>>(new Map());
    const [shiaijoTableSchedule, setShiaijoTableSchedule] = useState<TApiShiajosModel>();

    const onMessage = useCallback((fg: IApiFightGroupModel) => {
        let display = false;
        if (competitionSummaryId !== undefined) {
            getFollowingCompetitionAll(competitionSummaryId, dispatchNotification).then(tree => {
                if (display) {
                    setTree(tree);
                }
            })
        } else {
            setTree([]);
        }
        return () => {
            display = false;
        }
    }, [competitionSummaryId, dispatchNotification]);

    const [setSseFilter, sseConnected, connectedIcon] = useSseSecureClient(
        "followingArray",
        "FightGroups",
        onMessage,
        dispatchNotification,
        true
    )

    useEffect(() => {
        let display = true;
        //LOad the truee
        if (competitionSummaryId !== undefined) {
            getFollowingCompetitionAll(competitionSummaryId, dispatchNotification).then(tree => {
                if (display) {
                    setTree(tree);
                }
            })
        } else {
            setTree([]);
        }
        //LOAD SHIAIJOS SCHEDULE
        if (competitionSummaryId !== undefined) {
            loadShiajoFightGroupNoTeam(competitionSummaryId, dispatchNotification).then(d => {
                setShiaijoTableSchedule(d);
            });
        }
        else {
            setShiaijoTableSchedule(undefined);
        }
        if (competitionSummaryId !== undefined) {
            //LOAD TEAMS
            lazyLoadFightTeams({
                id: competitionSummaryId,
                dispatchNotification: dispatchNotification
            }).then((fightTeams: Array<IApiFighterFightTeam>) => {
                if (display) {
                    let newteamList = new Map();//teamList);
                    for (let index = 0; index < fightTeams.length; index++) {
                        const fightTeam = fightTeams[index];
                        newteamList.set(Number(fightTeam.id), fightTeam);
                    }
                    setTeamList(newteamList);
                }
            });
        } else {
            setTeamList(new Map());
        }
        return () => {
            display = false;
        }
    }, [competitionSummaryId, dispatchNotification])


    const reload = async () => {
        let display = true;
        if (competitionSummaryId !== undefined) {
            loadShiajoFightGroupNoTeam(competitionSummaryId, dispatchNotification).then(d => {
                if (display) {
                    setShiaijoTableSchedule(d);
                }
            });
        }
        else {
            setShiaijoTableSchedule(undefined);
        }

        if (competitionSummaryId !== undefined) {
            getFollowingCompetitionAll(competitionSummaryId, dispatchNotification).then(tree => {
                if (display) {
                    setTree(tree);
                }
            })
        } else {
            setTree([]);
        }
    }

    const treeLevels: Array<Array<IApiCompetitionTreeModel>> = [];
    for (const fg of tree) {
        while (treeLevels.length < fg.depth) treeLevels.push([]);
        treeLevels[fg.depth - 1].push(fg);
    }

    treeLevels.reverse();
    const maxLine: number = treeLevels.map(t => t.length).reduce((max, len) => {
        return Math.max(max, len);
    }, 0);

    //LOAD ALL FOLLOWING FIGHT
    //LOAD ALL SCHEDULLED FIGHT
    return <>
        {connectedIcon}
        <Grid container>
            {competitionSummaryId === undefined && <Grid item xs={12}>
                <Typography variant="h3" textAlign={'center'}>{t('label.title.selectCompetition')}</Typography>
            </Grid>}
            <Grid item xs={12}>
                {selectCompetition}
            </Grid>
            <Grid item xs={12}>
                {errorComponent}
            </Grid>

            <Grid item xs={12}>
                Focus on tree level <select onChange={(e) => {
                    document.getElementById(`following_tree_table_col_${e.target.value}`)?.scrollIntoView({block: "center", inline: "nearest"});
                }
                } >
                    <option value={undefined}></option>
                    {treeLevels.map((treeLevel, k) => {
                        return <option value={k}>{k === 0 ? "Pool" : `Tree ${k}`}</option>
                    })}
                </select><br />


            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {treeLevels.map((treeLevel, k) => {
                                    return <TableCell id={`following_tree_table_col_${k}`} key={k} className='tree-head-fightgouptype'>
                                        <FirstPage fontSize='large' onClick={() => {
                                            document.getElementById(`following_tree_table_col_${k - 1}`)?.scrollIntoView({block: "center", inline: "nearest"});
                                        }} />
                                        {k === 0 ? "Pool" : `Tree ${k}`}
                                        <LastPage fontSize='large' onClick={() => {
                                            document.getElementById(`following_tree_table_col_${k + 1}`)?.scrollIntoView({block: "center", inline: "nearest"});
                                        }} />
                                    </TableCell>
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[...Array(maxLine)].map((v, k) => {
                                return <TableRow
                                    key={k}>
                                    {treeLevels.map((treeLevel, k2) => {
                                        const coef = Math.pow(2, Math.max(k2 - 1, 0));
                                        const fg: IApiCompetitionTreeModel | undefined = treeLevel[k / coef];
                                        if (fg) {
                                            return <TableCell key={k2} scope="row" rowSpan={coef}>
                                                <FollowingArrayFightGroup
                                                    fightGroup={fg}
                                                    teamList={teamList}
                                                    dispatchNotification={dispatchNotification}
                                                    reload={reload}
                                                    shiaijoTableSchedule={shiaijoTableSchedule}
                                                />
                                            </TableCell>
                                        } else {
                                            return undefined;
                                        }
                                    })}
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    </>
}