
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { deleteShiajos, getShiajos, patchShiajos } from '../../Backend/ApiCompetition';
import { IApiCompetitionModel, IApiCompetitionShiajoModel, ICompetitionFilter, emptyCompetitionShiajoModel } from '../../Model/ApiCompetitionModel';
import { IAuthentication } from '../Auth/auth';
import { IEventNotification } from "../Util/ErrorMessage";
import { ShiaijoForm } from './ShiaijoForm';
import { useSearchShiaijo } from './UseSearchShiaijo';

export interface IShiaijosDataGrid {
    authentication: IAuthentication
    competition: IApiCompetitionModel,
    group: IApiCompetitionModel | undefined,
    dispatchNotification: React.Dispatch<IEventNotification>,
    competitionFilter: ICompetitionFilter
}

export function ShiaijosDataGrid(props: IShiaijosDataGrid) {
    const [searchShiaijo, setSearchShiaijo] = useState(false);
    const [shiaijosApi, setShiaijosApi] = useState<{
        loaded: boolean,
        shiaijos: undefined | Array<IApiCompetitionShiajoModel>
    }>({
        loaded: false,
        shiaijos: undefined
    });
    const [pageSize, setPageSize] = React.useState(10);
    const [selectedShiaijo, setSelectedShiaijo] = useState<IApiCompetitionShiajoModel | undefined>(undefined);
    const [searchShiaijoComp, searchedShiaijo] = useSearchShiaijo({
        dispatchNotification: props.dispatchNotification,
        competitionSelected: props.group,
        competitionFilter: props.competitionFilter,
        authentication: props.authentication
    })


    const loadShiaijos = useCallback(() => {
        getShiajos(props.dispatchNotification, props.competition.id).then(s => {
            setShiaijosApi({
                loaded: true,
                shiaijos: s
            });

            setSelectedShiaijo(undefined);
            setSearchShiaijo(false);
        })
    }, [props.competition.id, props.dispatchNotification]);

    const rowDeleteCell = useCallback((propsRow: any) => {
        const { api, id } = propsRow;

        const handleDeleteClick = (event: any) => {
            event.stopPropagation();
            const options = {
                title: 'Shiaijo delete confirmation',
                message: `Do you confirm shiaijo deletion for ${props.competition.fullname}. ${props.competition.type === 'group' ? `
                This is a group : shiaijo will be removed from all competition for this event` : ''}`,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            let shiaijo = shiaijosApi.shiaijos?.find(s => s.id === parseInt(id));
                            if (shiaijo) {
                                deleteShiajos(props.dispatchNotification, props.competition.id, shiaijo.id).then(r => {
                                    if (r) {
                                        api.updateRows([{ id, _action: 'delete' }]);
                                    }
                                })
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {

                        }
                    }
                ],
                childrenElement: () => <div />,
                closeOnEscape: true,
                closeOnClickOutside: true,
                willUnmount: () => { },
                afterClose: () => { },
                onClickOutside: () => { },
                onKeypressEscape: () => { },
                overlayClassName: "overlay-shiaijo"
            };
            confirmAlert(options);
        };

        return <div >
            <IconButton

                onClick={handleDeleteClick}
            >
                <DeleteIcon fontSize="small" />
            </IconButton>
        </div>
    }, [props.competition.id, props.dispatchNotification, shiaijosApi.shiaijos]);

    useEffect(() => {
        if (props.competition.id > 0) {
            loadShiaijos();
        }
    }, [props.competition.id, props.dispatchNotification, loadShiaijos]);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'id', width: 50 },
        {
            field: '',
            headerName: '',
            renderCell: rowDeleteCell,
            sortable: false,
            width: 50,
            headerAlign: 'center',
            filterable: false,
            align: 'center',
            disableColumnMenu: true,
            disableReorder: true,
        },
        { field: 'shortname', headerName: 'shortname', width: 150 },
        { field: 'fullname', headerName: 'fullname', width: 150 },
        { field: 'competitionId', headerName: 'competitions', width: 200 },
        { field: 'order', headerName: 'order', width: 50 }
    ];

    return <>
        {['competition', 'group'].includes(props.competition.type) && <>
            <Grid item xs={12}>
                {shiaijosApi.loaded === false && <>Loading</>}
            </Grid>
            {shiaijosApi.loaded && shiaijosApi.shiaijos && <>

                {searchShiaijo && <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Typography variant='h4'>Add existing shiaijo : </Typography>
                    </Grid>
                    {searchShiaijoComp}
                    <Grid item xs={6}>
                        <Button variant="contained"
                            onClick={(e) => {
                                if (searchedShiaijo) {
                                    patchShiajos(props.dispatchNotification, props.competition.id, searchedShiaijo.id, undefined).then(() => {
                                        loadShiaijos();
                                    })
                                }
                            }}
                        >Ajouter a la competition
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained"
                            onClick={(e) => {
                                setSearchShiaijo(false);
                            }}
                        >Annuler
                        </Button>
                    </Grid>
                </Grid>}
                {selectedShiaijo && <>
                    <ShiaijoForm
                        authentication={props.authentication}
                        shiaijo={selectedShiaijo}
                        competitionId={props.competition.id}
                        competitionFilter={props.competitionFilter}
                        onChange={(s) => {
                            loadShiaijos();
                        }
                        }
                        dispatchNotification={props.dispatchNotification}
                    />
                </>}
                {searchShiaijo === false && selectedShiaijo === undefined && <>
                    <ButtonGroup variant="contained" aria-label="outlined primary button group">
                        <Button
                            onClick={(e) => {
                                setSelectedShiaijo({ ...emptyCompetitionShiajoModel })
                            }}
                        >Add new Shiaijo</Button>
                        <Button
                            onClick={(e) => {
                                setSearchShiaijo(true);
                            }}
                        >Add existing Shiaijo</Button>
                    </ButtonGroup>
                    <div style={{ height: 300, width: '100%' }}>
                        <DataGrid
                            rows={shiaijosApi.shiaijos}
                            columns={columns}
                            onSelectionModelChange={(ids: Array<string | number>) => {
                                setSelectedShiaijo(shiaijosApi.shiaijos?.find(s => s.id === ids[0] ?? -1))
                            }}
                            sortModel={[{ field: 'order', sort: 'asc' }]}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[5, 10, 20]}
                            pagination
                        /></div>
                </>}
            </>}
        </>
        }
    </>
}