import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import yaml from "js-yaml";

var debug = require("debug")("ServiceTablesMinimalFrontend:i18n");
// the translations
// (tip move them in a JSON file and import them)
/*const resources = {
    fr: {
      translation: translationFr
      }
    };
  */
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(detector)
  .use(backend)
  .init(
    {
      //resources,
      lng: "fr",
      debug: true,
      keySeparator: ".", // we do not use keys in form messages.welcome
      saveMissing: false,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      ns: ["translation", "validation"],
      defaultNS: "translation",
      fallbackNS: "validation",
      backend: {
        loadPath: process.env.PUBLIC_URL + "/locales/{{lng}}/{{ns}}.yaml",
        addPath: process.env.PUBLIC_URL + "/locales/add/{{lng}}/{{ns}}",
        //allowMultiLoading: true,
        parse: function (data: any) {
          return yaml.load(data);
        },
      },
      react: {
        useSuspense: false,
      },
      //saveMissingTo: "current",
      fallbackLng: false,
    },
    function (err, t) {
      debug("Using translation path " + __dirname + "/../../locales/");
      debug("Api translate ready");
    }
  );

export default i18n;
