


import { Mutex } from 'async-mutex';
const sessioncCacheMutex = new Mutex();

export class SessionCache {
    static async set<T>(object: string, id: number | string, value: T, ttl: number) {
        const now = new Date()

        // `item` is an object which contains the original value
        // as well as the time when it's supposed to expire
        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        }
        sessionStorage.setItem(`${object}_${id}`, JSON.stringify(item))
    }

    /**
     * 
     * @param key 
     */
    static async get<T>(object: string, id: number | string, callIfNotSet?: () => Promise<T>, ttl: number = 500): Promise<T | null> {
        return sessioncCacheMutex.runExclusive(() => {
            let itemStr = sessionStorage.getItem(`${object}_${id}`)
            let value = null;
            if (itemStr) {
                const item = JSON.parse(itemStr)
                const now = new Date()
                // compare the expiry time of the item with the current time
                if (now.getTime() > item.expiry) {

                    // If the item is expired, delete the item from storage
                    // and return null
                    sessionStorage.removeItem(`${object}_${id}`);
                }
                else {
                    value = item.value;
                }
            }
            if (!value) {
                if (callIfNotSet) {
                    let ptimeouted = false
                    const promise1 = new Promise<T>((resolve, reject) => {
                        callIfNotSet().then((v) => {
                            resolve(v);
                        });
                    });
                    const ptimeout = new Promise<void | undefined>((resolve, reject) => {
                        setTimeout(() => {
                            ptimeouted = true;
                            reject("Timeout on execWithTimeout");
                        }, 15000);
                    });
                    return Promise.race([promise1, ptimeout])
                    .then((v) => {
                        if (v) {
                            SessionCache.set(object, id, v, ttl);
                        }
                        return v;
                    })
                    .catch((e) => {
                        if (ptimeouted) console.error("Timeout on mutex cache ( max 15000 ms )");
                        return null;//Promise.reject(e)
                    });
                }
            }
            return value;
        });
    }
}