import { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReport } from './UseReport';


function countReducer(count: number, action: "inc" | "clear") {
    switch (action) {
        case 'clear':
            return 0;
        case 'inc':
            return count + 1
    }
}

export function useSseReport(url: string, jwt: string, sseConvert?: (obj: any) => string): [JSX.Element, () => void, (value: number) => void] {
    const { t, i18n } = useTranslation();
    const [expectedEventNumber, setExpectedEventNumber] = useState(-1);
    const [count, reduceCount] = useReducer(countReducer, 0);
    const [component, dispatchRepport, setPercent] = useReport();
    const [eventSource, setEventSource] = useState<EventSource | undefined | null>(undefined);

    let connectSse = useCallback((setCallBack: (es: EventSource | null) => void): EventSource | null => {
        let neweventSource: EventSource | null = new EventSource(url);

        neweventSource.onopen = ev => {
            dispatchRepport({ type: 'add', value: t("message.archive.connectionOpened"), ts: Date.now() });
        }

        neweventSource.onmessage = e => {

            dispatchRepport({ type: 'add', value: (sseConvert ? sseConvert(e.data) : e.data), ts: Date.now() });
            reduceCount('inc');
            if (expectedEventNumber) setPercent(count / expectedEventNumber);
        }
        neweventSource.onerror = function (e) {
            dispatchRepport({ type: 'add', value: t("error.archive.connectionSseLost"), ts: Date.now() });

            if (neweventSource) neweventSource.close();

            setTimeout(() => {
                if (eventSource) {
                    setCallBack(connectSse(setCallBack));
                }
            }, 10000);
        }

        if (!neweventSource) {
            dispatchRepport({ type: 'add', value: t("error.archive.ssefailed"), ts: Date.now() });
            neweventSource = null;
        }
        return neweventSource;
    }, [count, dispatchRepport, eventSource, expectedEventNumber, setPercent, sseConvert, t, url]);

    useEffect(() => {
        expectedEventNumber ? setPercent((count * 100) / expectedEventNumber) : setPercent(-1);
        if (eventSource === undefined) {
            let neweventSource = connectSse(setEventSource);
            console.log(neweventSource);
            setEventSource(neweventSource);
        }
    }, [connectSse, count, eventSource, expectedEventNumber, setPercent])

    let clear = useCallback(() => {
        reduceCount('clear');
        expectedEventNumber ? setPercent(expectedEventNumber) : setPercent(-1);
        dispatchRepport({ value: "", type: 'clear', ts: Date.now() })
    }, [dispatchRepport, expectedEventNumber, setPercent])

    return [component, clear, setExpectedEventNumber];
}