import { IApiResult } from '../ApiCall';
import { IApiFightGroupModel } from './ApiFightgroupModel';


export interface IApiShiajoModel {
  id: number,
  externalId: number,
  competitionId: Array<number>,
  shortname: string,
  fullname: string,
  createdAt: string,//date
  updatedAt: string,//date
  fightGroups: Array<IApiFightGroupModel>,
  currentFightGroupId: number,
  currentFightGroup?: IApiFightGroupModel | null,
  displayMode: "welcome" | "standard" | "closing" | "poolsTree" | "noFightEmpty" | string,
  displayMessage: string,
  countryFlag: boolean,
  whiteLeft: boolean,
  screenWidthScale: number
}

export interface IApiShiajoResult extends IApiResult {
  data: IApiShiajoModel
}


export type TApiShiajosModel = Array<IApiShiajoModel>;

export interface IApiShiajosResult extends IApiResult {
  data: TApiShiajosModel
}

export const emptyShiajoResult:{data:IApiShiajoModel }= {
  data : {
    id: 0,
    externalId: 0,
    competitionId: [],
    shortname: "",
    fullname: "",
    createdAt: "",//date
    updatedAt: "",//date
    fightGroups: new Array<IApiFightGroupModel>(),
    currentFightGroupId: 0,
    displayMessage:"",
    displayMode: "standard",
    countryFlag: false,
    whiteLeft: false,
    screenWidthScale: 0
  }
}

export interface ShiajoUpdateModel/* extends ShiajoCreationModel */{
  currentFightGroupId?: number,
  displayMode?: "welcome" | "standard" | "closing" | "poolsTree" | "poolsTree" | "noFightEmpty",
  displayMessage?: string,
  whiteLeft?: boolean
  countryFlag?: boolean,
  screenWidthScale?: number //0 or 100 : no change
}