import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IApiCompetitionTreeModel } from "../../util/ApiModel/Following/ApiCompetitionTree";
import { IApiShiajoScoredModel } from "../Table/SummaryFightGroupTable";
import { TPopOverAction } from "../Use/UsePopOver";

export function translateFightgroupLabel(fightgroup: IApiCompetitionTreeModel | IApiShiajoScoredModel | undefined): {
    pos: string,
    label: string,
    depthToShow: string,
} {
    if (fightgroup === undefined) {
        return {
            pos: "",
            label: "undefined",
            depthToShow: ""
        }
    }
    const re = new RegExp("(?:^(Pool|Final) ?([0-9]+)?$)|(?:^\\(([0-9]+)\\) ([0-9]+\\/[0-9]+)$)");
    //debug(fightgroup.label);
    //(1) 1/2 
    //Pool 1
    //Final
    const mat = fightgroup.label?.match(re) ?? false;
    //debug(mat);
    const pos = mat && mat[2] ? mat[2] : mat && mat[3] ? mat[3] : "";
    const label = mat && mat[1] ? mat[1].toLowerCase() : mat && mat[4] ? 'depth_' + mat[4].replace("/", "_") : "";
    return {
        pos: pos,
        label: label,
        depthToShow: (fightgroup.depth ? (fightgroup.depth > 1 ? `${Math.pow(2, fightgroup.depth - 1)}` : '1'): '')
    };
}

export function FightGroupNodeLabel(props: {
    fg: IApiCompetitionTreeModel | number
    displayPopOver?: TPopOverAction<IApiCompetitionTreeModel>
}) {
    const { t } = useTranslation();
    const ref = useRef(null);
    const [fg, setFg] = useState<IApiCompetitionTreeModel | undefined>(undefined);

    useEffect(() => {
        if (typeof props.fg === 'number') {
            //TODO
            //not loaded anymore
        }
        else {
            setFg(props.fg)
        }
    }, [props.fg])

    if (fg) {
        const label = translateFightgroupLabel(fg);
        return <div ref={ref}
            onMouseEnter={() => {
                if (props.displayPopOver) props.displayPopOver("display", ref, fg);
            }}
            onMouseLeave={() => {
                if (props.displayPopOver) props.displayPopOver("hide",ref, undefined);
            }}
        >{label.pos} / {t(`label.fightgroup.${label.label}`)} {label.pos && t(`label.word.numero`)} {label.pos} ({fg.fightGroupNodeId})</div>
    }
    else if (typeof props.fg === 'number') {
        return <>{props.fg}</>
    } else {
        return <>{props.fg.fightGroupNodeId}</>
    }
}