import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TMartialArt } from '../../Model/ApiCompetitionModel';
import { getCompApiObject } from '../../UtilEvent/GenericOperation';
import { IEventNotification } from '../Util/ErrorMessage';


export const martialArtList: Array<TMartialArt> = [
    "INCONNUE" , "KENDO" , "IAIDO" , "NAGINATA" , "JODO" , "SPORT CHANBARA" , "KYUDO"
]

export function EventMartialArt(props: {
    value?: TMartialArt,
    dispatchNotification?: React.Dispatch<IEventNotification>,
    onChange: (event: any) => void
}) {
    const { t } = useTranslation();
    const [disciplines, setDisciplines] = useState<undefined | Array<string>>(undefined);

    useEffect(() => {
        let displayed = true;
        if (disciplines === undefined) {
            getCompApiObject("/listDisciplines", null, {
                succeed: false,
                data: []
            }, props.dispatchNotification, (window as any)['REACT_APP_API_LICENCIE_SERVICE']).then(d => {
                if (displayed) setDisciplines(Object.values(d.data).map(d => (d as any).name));
            })
        }
        return () => {
            displayed = false;
        };
    }, [disciplines, props.dispatchNotification]);

    return <Select
        fullWidth={true}
        labelId="martialArt"
        value={props.value}
        label={t(`label.competition.martialArt`)}
        onChange={props.onChange}
    >
        {disciplines && disciplines.map((v, k) => {
            return <MenuItem key={k} value={v}>{v}</MenuItem>
        })}
    </Select>;
}

export function MartialArtEditInputCell(props: GridRenderCellParams) {
    const { id, value, api, field } = props;

    const handleChange = (event: any) => {
        api.setEditCellValue({ id, field, value: event.target.value }, event);
        // Check if the event is not from the keyboard
        // https://github.com/facebook/react/issues/7407
        /*if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
            
            
        }*/
        api.commitCellChange({ id, field });
        api.setCellMode(id, field, 'view');
    };

    return (
        <div>
            <EventMartialArt
                
                value={value as TMartialArt}
                onChange={handleChange}
            />
        </div>
    );
}

export function renderMartialArtEditInputCell(params: any) {
    return <MartialArtEditInputCell {...params} />;
}