import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import produce from 'immer';
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IApiResult } from '../../util/ApiCall';
import { IApiFighterModel, noneFighter } from '../../util/ApiModel/ApiFighterModel';
import { IApiFightResult, IFightTeamState } from '../../util/ApiModel/ApiFightModel';
import { IApiRoundModel } from '../../util/ApiModel/ApiRoundModel';
import { putApiObject } from '../Operation/GenericOperation';
import { useErrorMessage } from '../Util/ErrorMessage';
import { IFightState } from './Fight';
import { Fighter } from './Fighter';
export interface ITeamOrderProps {
    teams: Array<IFightTeamState>,
    orderFightLimit: number,
    fight?: IFightState,
    onUpdated: () => void,
    onCancel: () => void
    message?: string
}

interface IFighterOrder {
    externalId: number,
    order: number
}

/**
 * 
 * @param props 
 * @returns 
 */
export function TeamOrder(props: ITeamOrderProps) {
    const [dispatchNotification, errorComponent] = useErrorMessage();
    const [order, setOrder] = useState<Map<number, Map<number, IApiFighterModel>> | undefined>();
    const [teams, setTeams] = useState<Array<IFightTeamState>>([]);
    const { t, i18n } = useTranslation();

    const loadTeamOrder = useCallback(() => {

        const loadedTeams = JSON.stringify(teams.map(t => JSON.stringify(t.id)));
        const propsTeams = JSON.stringify(props.teams.map(t => JSON.stringify(t.id)));
        if (loadedTeams !== propsTeams) {
            let newOrder: Map<number, Map<number, IApiFighterModel>> = new Map();
            //fill
            if (props.fight) {
                let redTeamWithRound: Array<IApiFighterModel> = [];
                let whiteTeamWithRound: Array<IApiFighterModel> = [];
                //if fight is defined, define order depending fight
                props.fight.data.rounds?.forEach(round => {

                    if (!newOrder.has(round.order)) {
                        newOrder.set(round.order, new Map());
                    }

                    if (props.fight) {
                        if (props.teams.find(f => f.id === props.fight?.data.redFightTeam.id)) {
                            //newOrder[round.order][props.fight.data.redFightTeam.externalId] = round.redFighter;
                            newOrder.get(round.order)?.set(props.fight.data.redFightTeam.externalId, round.redFighter);
                            redTeamWithRound.push(round.redFighter)
                        }
                        if (props.teams.find(f => f.id === props.fight?.data.whiteFightTeam.id)) {
                            //newOrder[round.order][props.fight.data.whiteFightTeam.externalId] = round.whiteFighter;
                            newOrder.get(round.order)?.set(props.fight.data.whiteFightTeam.externalId, round.whiteFighter);
                            whiteTeamWithRound.push(round.whiteFighter)
                        }
                    }

                });
                //add replacement fighter for choice
                const orderSize = newOrder.size;
                let redOrder = orderSize;
                let whiteOrder = orderSize;
                //search red team
                if (props.teams.find(f => f.id === props.fight?.data.redFightTeam.id)) {
                    if (props.fight) props.fight.data.redFightTeam.fighters.forEach(fighter => {
                        if (!redTeamWithRound.find(f => f && fighter.externalId === f.externalId)) {

                            if (!newOrder.has(redOrder)) {
                                newOrder.set(redOrder, new Map());
                                props.teams.forEach(team => newOrder.get(redOrder)?.set(team.externalId, noneFighter));
                            }

                            newOrder.get(redOrder)?.set(props.fight?.data.redFightTeam.externalId ?? 0, fighter);
                            redOrder++;
                        }
                    })
                }
                //search white team
                if (props.teams.find(f => f.id === props.fight?.data.whiteFightTeam.id)) {
                    if (props.fight) props.fight.data.whiteFightTeam.fighters.forEach(fighter => {
                        if (!whiteTeamWithRound.find(f => f && fighter.externalId === f.externalId)) {
                            if (!newOrder.has(whiteOrder)) {
                                newOrder.set(whiteOrder, new Map());
                                props.teams.forEach(team => newOrder.get(whiteOrder)?.set(team.externalId, noneFighter));
                            }

                            newOrder.get(whiteOrder)?.set(props.fight?.data.redFightTeam.externalId ?? 0, fighter);
                            whiteOrder++;
                        }
                    })
                }

            } else {
                //init
                for (let index = 0; index < props.orderFightLimit; index++) {
                    newOrder.set(index, new Map());
                    props.teams.forEach(team => newOrder.get(index)?.set(team.externalId, noneFighter));
                }
                //define order depending default team order
                props.teams.forEach(team => {
                    team.fighters.forEach(fighter => {
                        //set a default value if needed
                        if (!newOrder.has(fighter.order)) {
                            newOrder.set(fighter.order, new Map());
                            props.teams.forEach(team => newOrder.get(fighter.order)?.set(team.externalId, noneFighter));
                        }
                        //Si un combattant est déjà défini, on le met ailleur
                        if (newOrder.get(fighter.order)?.get(team.externalId) !== noneFighter) {
                            const index = [...newOrder.keys()].find(order => newOrder.get(order)?.get(team.externalId)?.id === noneFighter.id)
                            if (index) fighter.order = index
                        }
                        //newOrder[fighter.order][team.externalId] = fighter;
                        newOrder.get(fighter.order)?.set(team.externalId, fighter);
                    });
                });
            }
            setTeams(props.teams);
            setOrder(newOrder);
        }
    }, [props.fight, props.orderFightLimit, props.teams, teams]);

    useEffect(() => {
        loadTeamOrder();
    }, [loadTeamOrder, props.teams])

    let updateTeam = useCallback(() => {
        let teamsToUpdate: Map<number, Array<IFighterOrder>> = new Map();

        if (order) {


            let asyncCall = async () => {
                let valid = true;
                if (!props.fight) {
                    //Calcul teamsToUpdate
                    //for (let iOrder = 0; iOrder < order.length; iOrder++) {
                    for (const iOrder of order.keys()) {
                        const orderLine = order.get(iOrder);
                        orderLine?.forEach((fighter, teamExternalId) => {
                            let ar = teamsToUpdate.get(teamExternalId) ?? [];
                            ar.push({
                                externalId: fighter.externalId,
                                order: iOrder
                            });
                            teamsToUpdate.set(teamExternalId, ar);
                        });
                    }
                    let kar = Array.from(teamsToUpdate.keys());
                    for (let index = 0; index < kar.length; index++) {
                        const teamExternalId = kar[index];
                        //do not send empty fighters
                        const data = teamsToUpdate.get(teamExternalId)?.filter(f => f.externalId > 0);
                        valid = await putApiObject<IApiResult>("/FightTeam", teamExternalId, { fighters: data }, { succeed: false }, dispatchNotification).then((res) => {
                            if (res.succeed) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }) && valid;
                    };
                } else {
                    let data = {
                        rounds: new Array<{
                            id: number,
                            order: number,
                            redFighterId: number,
                            whiteFighterId: number
                        }>()
                    };

                    props.fight.data.rounds?.forEach((round: IApiRoundModel, ordern: number) => {
                        let redFighterId = 0;
                        let whiteFighterId = 0;
                        if (props.fight) {

                            if (props.teams.find(f => f.id === props.fight?.data.redFightTeam.id)) {
                                redFighterId = order.get(ordern)?.get(props.fight.data.redFightTeam.externalId)?.id ?? 0;
                            } else {
                                redFighterId = round.redFighterId ?? 0;
                            }
                            //if defined, else that mean team not present
                            if (props.teams.find(f => f.id === props.fight?.data.whiteFightTeam.id)) {
                                whiteFighterId = order.get(ordern)?.get(props.fight.data.whiteFightTeam.externalId)?.id ?? 0;
                            } else {
                                whiteFighterId = round.whiteFighterId ?? 0;
                            }
                        }

                        data.rounds.push({
                            id: round.id,
                            order: ordern,
                            redFighterId: redFighterId,
                            whiteFighterId: whiteFighterId
                        });
                    });
                    let apiData = await putApiObject<IApiFightResult>('/fights', props.fight.data.id, data, {
                        data: props.fight.data,
                        succeed: false
                    }, dispatchNotification);
                    valid = apiData.succeed;
                }
                if (valid) {
                    props.onUpdated();
                }
            }
            asyncCall();
        }
    }, [order, props, dispatchNotification]);


    return <>
        <Row>
            <Col md={12}>
                {errorComponent}
            </Col>
        </Row>
        {props.message && <Row>
            <Col md={12}>
                <Alert variant="danger">
                    {props.message}
                </Alert>
            </Col>
        </Row>}
        <Row>
            <Col md={12}>
                <Alert variant="danger">
                    {props.fight ? t("info.fightteam.changeOnlyForFight") : t("info.fightteam.changeOnlyForNotReadyFight")}
                </Alert>
            </Col>
        </Row>
        
        <Row>
            <Col md={12}>
                <Table striped bordered hover className='teamorder'>
                    <thead>
                        <tr>
                            <td>{t('label.word.order')}</td>
                            {props.teams.sort((t1, t2) => { return t1.externalId < t2.externalId ? -1 : 1 }).map((team, key) => {
                                return <React.Fragment key={key}>
                                    <td>{team.name} (comp team id : {team.externalId})</td><td></td>
                                </React.Fragment>
                            })}
                        </tr>
                    </thead>

                    <tbody>
                        {order && [...order.keys()].map((keyLine) => {
                            const orderLine = order.get(keyLine);
                            return <tr key={keyLine} className={keyLine < props.orderFightLimit ? "order-active" : "order-inactive"}>
                                <td>{keyLine}</td>
                                {orderLine && [...orderLine.keys()].map((keyTeam) => {
                                    const fighter = orderLine.get(keyTeam);
                                    return <React.Fragment key={keyTeam}> <td>

                                        {fighter && <Fighter
                                            data={fighter}
                                            editeable={true}
                                            fighterSelecteableList={props.teams.find(t => t.externalId === keyTeam)?.fighters ?? []}
                                            changeFighter={(fighterId: number, previousFighterId: number) => {
                                                let newOrder = produce(order, (newOrderDraft) => {
                                                    newOrderDraft.get(keyLine)?.set(keyTeam, props.teams.map(t => t.fighters).flat().find(f => f.id === fighterId) ?? noneFighter);
                                                });
                                                setOrder(newOrder);
                                            }} />}
                                    </td>
                                        <td>
                                            {keyLine > 0 && <p onClick={() => {
                                                let newOrder = produce(order, (newOrderDraft) => {
                                                    let f = newOrderDraft.get(keyLine)?.get(keyTeam);
                                                    const nf = newOrderDraft.get(keyLine - 1)?.get(keyTeam);
                                                    if (nf && f) {
                                                        newOrderDraft.get(keyLine)?.set(keyTeam, nf);
                                                        newOrderDraft.get(keyLine - 1)?.set(keyTeam, f);
                                                    }
                                                });
                                                setOrder(newOrder);
                                            }}><ArrowUpwardIcon /></p>}
                                            {keyLine < (order.size - 1) && <p onClick={() => {
                                                let newOrder = produce(order, (newOrderDraft) => {
                                                    let f = newOrderDraft.get(keyLine)?.get(keyTeam);
                                                    const nf = newOrderDraft.get(keyLine + 1)?.get(keyTeam);
                                                    if (nf && f) {
                                                        newOrderDraft.get(keyLine)?.set(keyTeam, nf);
                                                        newOrderDraft.get(keyLine + 1)?.set(keyTeam, f);
                                                    }
                                                });
                                                setOrder(newOrder);
                                            }}
                                            ><ArrowDownwardIcon /></p>
                                            }
                                        </td></React.Fragment>
                                })}</tr>;
                        })}
                    </tbody>
                </Table>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <Button onClick={() => updateTeam()}>{t("action.fightteam.saveorder")}</Button>
            </Col>
            <Col md={6}>
                <Button onClick={() => props.onCancel()}>{t("action.fightteam.cancelTeamOrder")}</Button>
            </Col>
        </Row>
    </>

}