import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Grid, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IApiFighterFightTeam } from '../../Model/ApiTeamModel';
import { putFightGroupRanks } from '../../util/ApiFightgroup';
import { cancelFight, getFollowingCompetitionAll, postponeSuivi, reload, resync } from '../../util/ApiFollowing';
import { IApiFightGroupResult, emptyFightgroup } from "../../util/ApiModel/ApiFightgroupModel";
import { IApiCompetitionTreeModel } from "../../util/ApiModel/Following/ApiCompetitionTree";
import { FightGroupNodeLabel, translateFightgroupLabel } from "../Fight/FightGroupNodeLabel";
import { FightGroupTeamsCard } from "../Fight/FightGroupTeamsCard";
import { ConfirmModal } from "../Modal/ConfirmModal";
import { getApiObject } from "../Operation/GenericOperation";
import { FightTeam } from "../Team/FightTeam";
import { lazyLoadFightTeams } from "../Team/LazyFightTeam";
import { usePopOver } from "../Use/UsePopOver";
import { TSizeType, useScreenSize } from '../Use/UseScreenSize';
import { useSelectCompetition } from "../Use/UseSelectCompetition";
import { useErrorMessage } from "../Util/ErrorMessage";
var debug = require('debug')('ServiceTablesMinimalFrontend:FollowingScreen');

export function FollowingScreen(props:{fullscreen?:boolean}) {
    const { t, i18n } = useTranslation();
    const [teamList, setTeamList] = useState<Map<number, IApiFighterFightTeam>>(new Map());
    const sizeType: TSizeType = useScreenSize();
    const [state, setState] = useState<Array<IApiCompetitionTreeModel>>(
        []
    );

    const [dispatchNotification, errorComponent] = useErrorMessage(false);
    const [forceranks, setForceranks] = useState<Map<number, Array<string>>>(new Map());
    const [loadedCompId, setLoadedCompId] = useState<number>(-1);
    const [popover, displayPopOver] = usePopOver<IApiCompetitionTreeModel>({
        dispatchNotification: dispatchNotification,
        loader: async (params: IApiCompetitionTreeModel | undefined) => {
            let result = {
                title: "",
                content: <></>
            };
            if (params) {
                let data = await getApiObject<IApiFightGroupResult>('/fightgroups/fightGroupNodeId', params.fightGroupNodeId, emptyFightgroup, dispatchNotification).then(
                    (result: IApiFightGroupResult) => {
                        if (result.succeed) {
                            return result.data;
                        } else {
                            return undefined;
                        }
                    }
                );
                if (data) {
                    result = {
                        title: `${data.fightGroupNodeId} - ${data.label}`,
                        content: <FightGroupTeamsCard f={data} />
                    }
                }
            }
            return result;
        }
    }
    );

    const [selectCompetition, competitionSummaryId, fightFinished] = useSelectCompetition({ dispatchNotification: dispatchNotification });
    //const competition = getConfigurationSelection().selectedCompetition.find(c => c === competitionSummaryId);
    const [fightGroupIdDetail, setFightGroupIdDetail] = useState<number>(0);


    useEffect(() => {
        let loaded = true;
        if (loadedCompId !== competitionSummaryId) {
            if (competitionSummaryId !== undefined && competitionSummaryId > 0) {
                let newForceRank = new Map();
                let newState: Array<IApiCompetitionTreeModel> = [];
                let call = async () => {
                    //LOAD TREE
                    const treeArrayList: Array<IApiCompetitionTreeModel> = await getFollowingCompetitionAll(competitionSummaryId, dispatchNotification);
                    setLoadedCompId(competitionSummaryId);
                    newState = newState.concat(treeArrayList);

                    if (loaded) {
                        setState(newState);

                        //LOAD TEAMS
                        await lazyLoadFightTeams({
                            id: competitionSummaryId,
                            dispatchNotification: dispatchNotification
                        }).then((fightTeams: Array<IApiFighterFightTeam>) => {
                            
                                let newteamList = new Map();//teamList);
                                for (let index = 0; index < fightTeams.length; index++) {
                                    const fightTeam = fightTeams[index];
                                    newteamList.set(Number(fightTeam.id), fightTeam);
                                }

                                setTeamList(newteamList);
                            
                        });

                        //SET FORCE RANK
                        for (const treeElement of treeArrayList) {
                            newForceRank.set(treeElement.fightGroupNodeId, []);
                        }
                        setForceranks(newForceRank);
                    }
                };
                call();
            } else {
                setLoadedCompId(0);
                setState([]);
                setTeamList(new Map());
            }

        }
        return () => {
            loaded = false;
        }
    }, [competitionSummaryId, dispatchNotification, loadedCompId]);

    function forceranking(fightgroupnodeid: number): void {
        if (forceranks.has(fightgroupnodeid)) {
            let data = forceranks.get(fightgroupnodeid);
            if (data) {
                putFightGroupRanks(fightgroupnodeid, data, dispatchNotification).then(r => {
                    if (r) setLoadedCompId(0);
                });
            }
        }
    }



    return <Grid container>
        <Grid item xs={12}
        >
            {selectCompetition}
            {popover}
        </Grid>
        <Grid item xs={12}>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>{t("label.word.depth")}</th>

                        <th>{t("label.word.fightGroupNodeId")}</th>
                        <th>{t("label.word.label")}</th>
                        <th>{t("label.word.status")}</th>
                        <th>{t("label.word.isPoolFightGroup")}</th>
                        <th>{t("label.word.fights")}</th>

                        <th>{t("label.following.nextFightGroupNodeIdColored")}</th>
                        <th>{t("label.word.ranking")}</th>
                        <th>{t("label.word.action")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={5}><Button variant="warning" onClick={() => resync(
                            dispatchNotification
                            , undefined, competitionSummaryId
                            ).then(r => {
                            if (r) setLoadedCompId(0)
                        })}>{t("action.following.resync")}</Button></td>
                        <td colSpan={5}><Button variant="warning" onClick={() => reload(dispatchNotification)}>{t("action.following.reload")}</Button></td>
                        
                        <td colSpan={4}>{errorComponent}</td>
                    </tr>
                    {state.map((fightgroup: IApiCompetitionTreeModel, key: any) => {
                        const fgteamList: Array<IApiFighterFightTeam> = fightgroup.fightGroupData.reduce((teams: Array<number>, fight) => {
                            if (!teams.includes(fight.redTeamId)) teams.push(fight.redTeamId);
                            if (!teams.includes(fight.whiteTeamId)) teams.push(fight.whiteTeamId);
                            return teams;
                        }, []).map(fid => teamList.get(fid) ?? null).filter(ft => ft !== null) as Array<IApiFighterFightTeam>;
                        const fgforceRank: Array<string> = (forceranks.has(fightgroup.fightGroupNodeId) ? forceranks.get(fightgroup.fightGroupNodeId) : []) as Array<string>;
                        return <tr key={key}>
                            <td>{fightgroup.depth}</td>
                            <td id={`following_table_fg_${fightgroup.fightGroupNodeId}`}>{fightgroup.fightGroupNodeId}</td>

                            <td>
                                <FightGroupNodeLabel fg={fightgroup} displayPopOver={(e, p, f) => displayPopOver(e, p, f)} />
                            </td>
                            <td>{t("label.following.fightgroup.status." + fightgroup.status)}</td>
                            <td>{fightgroup.poolFightGroup ? t("label.fightgroup.pool") : t("label.fightgroup.directelim")}</td>


                            <td>
                                {fightGroupIdDetail === fightgroup.fightGroupNodeId && <ArrowDropUpIcon onClick={() => setFightGroupIdDetail(0)} />}
                                {fightGroupIdDetail !== fightgroup.fightGroupNodeId && <p>{fightgroup.fightGroupData.length} {t("label.word.fights")}<ArrowDropDownIcon onClick={() => setFightGroupIdDetail(fightgroup.fightGroupNodeId)} /></p>}
                                {fightGroupIdDetail === fightgroup.fightGroupNodeId && fightgroup.fightGroupData.map((value: {
                                    redInstantiated: boolean,
                                    redTeamId: number,
                                    whiteInstantiated: boolean,
                                    whiteTeamId: number
                                }, key) => {
                                    return <React.Fragment key={key}>
                                        - <b>
                                            {value.redInstantiated && value.whiteInstantiated && t("label.word.team")}&nbsp;
                                            {value.redInstantiated &&
                                                (teamList.has(value.redTeamId) ? <FightTeam fightTeam={teamList.get(value.redTeamId)} />
                                                    : (value.redTeamId ? value.redTeamId : t("label.word.nobody")))
                                            }</b>
                                        &nbsp;{value.redInstantiated && value.whiteInstantiated && t("label.word.versus")}
                                        <b>&nbsp;{value.whiteInstantiated &&
                                            (teamList.has(value.whiteTeamId) ? <FightTeam fightTeam={teamList.get(value.whiteTeamId)} />
                                                : (value.whiteTeamId ? value.whiteTeamId : t("label.word.nobody")))}</b><br /></React.Fragment>
                                })}
                            </td>




                            {/* Distribution des sortants */}
                            <td>{fightgroup.nextFightGroupNodeIdColored.map((fightColor: {
                                color: string,
                                id: number
                            }, key2: any) => {
                                let fightgroupcolor = state.find(f => f.fightGroupNodeId === fightColor.id)
                                let label = translateFightgroupLabel(fightgroupcolor);


                                return <React.Fragment key={key2}>
                                    {t(`label.word.${fightColor.color}`)} :&nbsp;
                                    <a href={`#following_table_fg_${fightColor.id}`}>
                                        {label.pos} / {t(`label.fightgroup.${label.label}_short`)}
                                        &nbsp;<Tooltip title={`combat nodeid ${fightColor.id}`} arrow>
                                            <HelpOutlineIcon />
                                        </Tooltip>
                                    </a><br />
                                </React.Fragment>
                            })}</td>
                            {/* Liste des classement */}
                            <td>{fightgroup.ranking.map((rank: number, key2: any) => {
                                return <React.Fragment key={key2}>{key2 + 1} :&nbsp;
                                    {teamList.has(rank) ? <FightTeam fightTeam={teamList.get(rank) as IApiFighterFightTeam} /> : ""}
                                    <br /></React.Fragment>
                            })}</td>

                            <td>
                                <ConfirmModal label={t("action.following.cancelFight")} message={t("confirmation.following.cancelFight")} action={() => {
                                    cancelFight(fightgroup.fightGroupNodeId, dispatchNotification).then(r => {
                                        if (r) setLoadedCompId(0)
                                    })
                                }} />

                                <ConfirmModal label={t("action.following.postponeSuivi")} message={t("confirmation.following.postponeSuivi")} action={() => {
                                    postponeSuivi(fightgroup.fightGroupNodeId, dispatchNotification).then(r => {
                                        if (r) setLoadedCompId(0)
                                    })
                                }} />

                                <br />

                                {fightgroup.status === "ready" && <>

                                    {(new Array(fightgroup.nextFightGroupNodeIdColored.length).fill(0)).map((i, k) => {
                                        return <React.Fragment key={k}>
                                            <br />
                                            {k} : <select value={fgforceRank.length > k ? fgforceRank[k] : 0}
                                                onChange={(e) => {
                                                    let newMap = new Map(forceranks);
                                                    let newRank = newMap.get(fightgroup.fightGroupNodeId) ?? [];
                                                    newRank[k] = e.target.value;
                                                    newMap.set(fightgroup.fightGroupNodeId, newRank);
                                                    setForceranks(newMap);
                                                }}

                                            >
                                                <option key={k} value={0}>None</option>
                                                {fgteamList.map((fgteam, k) => {
                                                    return <option key={k} value={fgteam.id}>{fgteam.name}</option>
                                                })}
                                            </select>
                                        </React.Fragment>
                                    })}
                                    <Button size="sm" variant="warning" onClick={() => forceranking(fightgroup.fightGroupNodeId)}>{t("action.following.forceranking")}</Button>
                                </>
                                }
                            </td>
                        </tr>
                    })}
                </tbody>
            </Table>
        </Grid>
    </Grid>
}