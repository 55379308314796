import { IEventNotification } from "../EventComponent/Util/ErrorMessage";
import { apidelete, apiget, apipatch, apipost, apiput, IApiError, IApiResult, manageApiError } from "./ApiCall";

export function deleteCompApiObject<T extends IApiResult>(root: string, id: number | null, emptyErrorResult: T,
  dispatchNotification?: React.Dispatch<IEventNotification>,
  host: string = (window as any)['REACT_APP_API_COMP_SERVICE'] ? (window as any)['REACT_APP_API_COMP_SERVICE'] : "localhost", errorlabel = "", token?: string) {

  return apidelete<T>(root + (id ? "/" + id : ""), host, undefined, token)
    .then((res: T | IApiError) => {
      return manageApiError<T>(res)
    })
    .then((res: T) => {
      if (res.succeed) {
        if (dispatchNotification) dispatchNotification({ type: 'success', operation: "delete", message: "message.success.request" });
      }
      return res;
    })
    .catch((res: IApiError) => {
      if (dispatchNotification) dispatchNotification({ type: 'error', operation: "delete", message: (errorlabel ? errorlabel + "." : "") + res.errorLabel, details: res.errorMessage });
      return emptyErrorResult;
    });
}

export function getCompApiObject<T extends IApiResult>(root: string, id: number | null, emptyErrorResult: T,
  dispatchNotification?: React.Dispatch<IEventNotification>,
  host: string = (window as any)['REACT_APP_API_COMP_SERVICE'] ? (window as any)['REACT_APP_API_COMP_SERVICE'] : "localhost", errorlabel = "", token?: string, checkJson?: boolean): Promise<T> {

  return apiget<T>(root + (id ? "/" + id : ""), host, undefined, undefined, token, checkJson)
    .then((res: T | IApiError) => {
      return manageApiError<T>(res)
    })
    .then((res: T) => {
      if (res.succeed) {
        if (dispatchNotification) dispatchNotification({ type: 'success', operation: "read", message: "message.success.request" });
      }
      return res;
    })
    .catch((res: IApiError) => {

      if (dispatchNotification) {
        if (res.status === 401) dispatchNotification({ type: 'error', operation: "read", message: (errorlabel ? errorlabel + "." : "error.") + "authorization" });
        else dispatchNotification({
          type: 'error', operation: "read",message: (errorlabel ? errorlabel + "." : "") + (res.errorLabel !== "undefined" ? res.errorLabel : (res.errorMessage)), details: res.errorMessage
        });
      }
      return emptyErrorResult;
    })
}

export function putCompApiObject<T extends IApiResult>(root: string, id: string | number | null, data: any, emptyErrorResult: T,
  dispatchNotification: React.Dispatch<IEventNotification>,
  host: string = (window as any)['REACT_APP_API_COMP_SERVICE'] ? (window as any)['REACT_APP_API_COMP_SERVICE'] : "localhost", errorlabel = ""): Promise<T> {

  return apiput<T>(root + (id ? "/" + id : ""), data, host)
    .then((res: T | IApiError) => {
      return manageApiError<T>(res)
    })
    .then((res: T) => {
      if (res.succeed) {
        dispatchNotification({ type: 'success', operation: "update", message: "message.success.request" });
      }
      return res;
    })
    .catch((res: IApiError) => {

      dispatchNotification({ type: 'error', operation: "update", message: (errorlabel ? errorlabel + "." : "") + res.errorLabel , details: res.errorMessage});
      return emptyErrorResult;
    })
}



export function postCompApiObject<T extends IApiResult>(path: string, data: any, emptyErrorResult: T,
  dispatchNotification?: React.Dispatch<IEventNotification>,
  host: string = (window as any)['REACT_APP_API_COMP_SERVICE'] ? (window as any)['REACT_APP_API_COMP_SERVICE'] : "localhost", errorlabel = "", token?: string): Promise<T> {

  return apipost<T>(path, data ? data : {}, host, undefined, token)
    .then((res: T | IApiError) => {
      return manageApiError<T>(res)
    })
    .then((res: T) => {
      if (res.succeed) {
        if (dispatchNotification) dispatchNotification({ type: 'success', operation:"create", message: "message.success.request" });
      }
      return res;
    })
    .catch((res: IApiError) => {
      if (dispatchNotification)  dispatchNotification({ type: 'error',operation:"create", message: (errorlabel ? errorlabel + "." : "") + res.errorLabel + ":", details: res.errorMessage });
      return emptyErrorResult;
    })
}

export function patchCompApiObject<T extends IApiResult>(path: string, data: any, emptyErrorResult: T,
  dispatchNotification: React.Dispatch<IEventNotification> | undefined,
  host: string = (window as any)['REACT_APP_API_COMP_SERVICE'] ? (window as any)['REACT_APP_API_COMP_SERVICE'] : "localhost", errorlabel = "", token?: string): Promise<T> {

  return apipatch<T>(path, data !== undefined ? data : {}, host, undefined, token)
    .then((res: T | IApiError) => {
      return manageApiError<T>(res)
    })
    .then((res: T) => {
      if (res.succeed) {
        if (dispatchNotification) dispatchNotification({ type: 'success', operation:"update",message: "message.success.request" });
      }
      return res;
    })
    .catch((res: IApiError) => {
      if (dispatchNotification) dispatchNotification({ type: 'error', operation:"update", message: (errorlabel ? errorlabel + "." : "") + res.errorLabel , details: res.errorMessage});
      return emptyErrorResult;
    })
}