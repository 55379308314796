import { Paper, Select, TextField } from '@mui/material';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteUserProfile, postUserProfile } from '../../Backend/ApiCompetition';
import Grid, { GridSpacing } from '@mui/material/Grid';
import { IEventNotification } from '../Util/ErrorMessage';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { IApiUserCompetitionModel, IApiUserModel } from '../../Model/ApiUserModel';
import { getUsers } from '../../Backend/ApiUser';
import { useUserSearch } from '../User/useUserSearch';
import { SSO_SCOPES } from '../Auth/auth';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';

export interface IEventUsersProps {
    competitionId: number,
    eventDispatcher: React.Dispatch<IEventNotification>;
}


export function EventUsers(props: IEventUsersProps) {
    const [users, setUsers] = useState<Array<IApiUserCompetitionModel>>()
    const { t, i18n } = useTranslation();
    const [newProfile, setNewProfile] = useState<{ competitionId: number, profile: string }>({
        competitionId: props.competitionId,
        profile: ""
    })
    const [userSearch, userSearched] = useUserSearch({
        dispatchNotification: props.eventDispatcher,
    });

    const reload = useCallback(() => {
        getUsers(props.competitionId, props.eventDispatcher).then(users => setUsers(users));
    }, [props.competitionId, props.eventDispatcher]);

    useEffect(() => {
        reload();
        setNewProfile({
            competitionId: props.competitionId,
            profile: ""
        })
    }, [props.competitionId, props.eventDispatcher, reload])

    return <>
        
            <Grid container item xs={12} rowSpacing={2}>

                <Grid item xs={6}>
                    {userSearch}
                </Grid>
                <Grid item xs={6}>
                    Give role<br />
                    <Select
                        labelId="profileSelect"
                        id="profileSelect"
                        value={newProfile.profile}
                        label="Profil"
                        style={{ minWidth: '200px' }}
                        onChange={(e) => setNewProfile({ ...newProfile, profile: e.target.value as string })}
                    >
                        {[...SSO_SCOPES.keys()].map((v, k) => {
                            return <MenuItem key={k} value={v}>{v}</MenuItem>
                        })}
                    </Select>
                </Grid>

                <Grid item xs={12}>

                    <ButtonGroup variant="contained" aria-label="outlined primary button group">
                        <Button onClick={(e) => {
                            if (userSearched) {
                                postUserProfile(props.competitionId, userSearched.name, newProfile.profile, props.eventDispatcher).then(() => {
                                    reload();
                                });
                            }
                        }}
                        >{t('action.user.addCompetitionProfile')}</Button>
                    </ButtonGroup>
                </Grid>

            </Grid>
        

        {
            users && users.length > 0 && <Grid container item xs={12}>
                <Grid item xs={12}>
                    Role list :
                </Grid>
                <Grid item xs={12}>
                    <ul style={{ listStyle: 'unset' }}>
                        {users?.map((u, key) => {
                            return <li key={key}>
                                User {u.name} :&nbsp;

                                {u.profiles.map((profile, key) => {
                                    return <React.Fragment key={key}>

                                        {profile}

                                        <DeleteIcon onClick={(e) => {
                                            deleteUserProfile(props.competitionId, u.name, profile, props.eventDispatcher).then(() => {
                                                reload();
                                            })
                                        }} /> ;

                                    </React.Fragment>
                                })}
                            </li>
                        })}
                    </ul>
                </Grid>
            </Grid>
        }
    </ >
}