import { useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";


var debug = require('debug')('ServiceTablesMinimalFrontend:useScreenSize')

export type TSizeType = 15 | 17 | 24


export function useScreenSize() {
    const is17 = useMediaQuery({
        query: '(min-device-width: 1600px)'
    });
    const is24 = useMediaQuery({
        query: '(min-device-width: 1800px)'
    });
    const [sizeType, setSizeType] = useState<TSizeType>(17);
    const [handleResize, setHandleResize] = useState(false);

    
    let resizeFunction = useCallback(() => {
        if (is24) {
            return setSizeType(24);
        }
        else if(is17) {
            return setSizeType(17);
        } else {
            return setSizeType(15);
        }
    }, [is17, is24]);

    useEffect(() => {
        if (handleResize === false){
            window.addEventListener('resize', resizeFunction);
            setHandleResize(true);
        }
        resizeFunction();
    }, [handleResize, resizeFunction])
    

    return sizeType;
}