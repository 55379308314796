import React from 'react';
import { EApiDataSync } from "../../util/ApiDataSync";

export interface IApiDataString {
    value: string;
    //apiSync: EApiDataSync;
}

export function ApiDataString(props:IApiDataString){
    let className = "vertical-align";
    let role = "";
    /*if (props.apiSync === EApiDataSync.pending){
      className += " alert alert-warning";
      role = "alert";
    }
    if (props.apiSync === EApiDataSync.fail){
      className = " alert alert-danger";
      role = "alert";
    }*/
  return <p className={className + " m-0 p-0"}  role={role} >{props.value}{'\u00A0'}</p>
} 