const SEPARATOR = "//**//";
const CACHE_INTERVAL = 0.2 * 60 * 1000;

interface IsValidResponse {
  isValid: boolean;
  value?: string;
}

export function store(key: string, value: string) {
  const finalValue = `${value}${SEPARATOR}${Date.now().toString()}`;
  localStorage.setItem(key, finalValue);
}

export function getCacheValue(
  key: string,
  cacheInterval?: number,
  callback?: () => any
): any | undefined {
  let data = isValid(key, cacheInterval);
  if (data.isValid) {
    return data;
  } else {
    if (callback) {
      let value = callback();
      store(key, JSON.stringify(value));
      return value;
    }
  }
  return undefined;
}

export function isValid(key: string, cacheInterval?: number): IsValidResponse {
  if (!cacheInterval) cacheInterval = CACHE_INTERVAL;

  const value = localStorage.getItem(key);
  if (value === null) {
    return {
      isValid: false,
    };
  }
  const values = value.split(SEPARATOR);
  const timestamp = Number(values[1]);
  if (Number.isNaN(timestamp)) {
    return {
      isValid: false,
    };
  }
  const date = new Date(timestamp);
  if (date.toString() === "Invalid Date") {
    return {
      isValid: false,
    };
  }
  if (Date.now() - date.getTime() < CACHE_INTERVAL) {
    return {
      isValid: true,
      value: values[0],
    };
  }
  localStorage.removeItem(key);
  return {
    isValid: false,
  };
}
