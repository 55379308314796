import { Alert, AlertColor, Snackbar } from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react';

import { useTranslation } from 'react-i18next';

export interface IServiceTableNotification {
  type: "error" | "info" | "success" | "clear",
  operation: "create" | "read" | "update" | "delete",
  message?: string,
  context?: any,
  default?: string, //default if not translated
  details?: string
}

export type TServiceTableNotifications = Array<IServiceTableNotification>;
export type TServiceTableNotificationsMap = Map<String, {
  notification: IServiceTableNotification,
  count: number
}>;

//en cible : empiler les notifications, action de nettoyage
function notificationReducer(typeOfMessage: Array<string> = ["error", "success"]) {
  const func = (currentNotification: TServiceTableNotifications
    , action: IServiceTableNotification): TServiceTableNotifications => {
    switch (action.type) {
      case 'clear':
        return [];
      default:
        if (typeOfMessage.includes(action.type)){
          return [...currentNotification, action];
        }
    }
    return currentNotification;
  };
  return func;
}

export type TDispatchNotification = React.Dispatch<IServiceTableNotification>;

export function useErrorMessage(detail: boolean = true, typeOfMessage: Array<string> = ["error", "success"]): [React.Dispatch<IServiceTableNotification>, any] {
  const { t, i18n } = useTranslation();
  const [tout, setTout] = useState<any>(null);

  const [notifications, dispatchNotification] = useReducer(notificationReducer(typeOfMessage), []);

  useEffect(() => {
    if (notifications.length > 0) {
      if (tout) {
        clearTimeout(tout);
      }

      setTout(setTimeout(() => {
        if (notifications.length > 0) {
          dispatchNotification({
            type: "clear",
            operation: "delete"
          })
        }
      }, 5000));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);//DO NOT SET TOUT => infinite loop

  const notificationsReduced = notifications.reduce((nmap: TServiceTableNotificationsMap, n: IServiceTableNotification) => {
    const ncount = nmap.get(JSON.stringify(n));
    if (!ncount) {
      nmap.set(JSON.stringify(n), {
        notification: n,
        count: 1
      });
    } else {
      ncount.count = ncount.count + 1;
    }
    return nmap;
  }, new Map());

  let errorMessage: JSX.Element = <Snackbar
    open={notifications.length > 0}
  >
    <div>
      {[...notificationsReduced.keys()].map((notificationKey, key) => {
        const notification = notificationsReduced.get(notificationKey)?.notification;
        if (notification && (notification.operation !== "read" || notification.type === "error")) {
          const count = notificationsReduced.get(notificationKey)?.count ?? 0;
          // "danger" | "info" | "success" | "primary" | "light"
          let className: AlertColor = "info";
          switch (notification.type) {
            case "error": className = "error"; break;
            case "info": className = "info"; break;
            case "success": className = "success"; break;
          }

          let content = undefined;
          if (detail) {
            if (notification.message && notification.message.length > 0) {
              content = <Alert severity={className}>{i18n.exists(notification.message) ? t(notification.message) : (notification.default ?? notification.message)}
                {notification.details && <p style={{ fontSize: 10 }}><br />{notification.details}</p>} {count > 1 ? `(${count})` : ""}
              </Alert>
            }
          }
          else {
            if (typeOfMessage.includes(notification.type)) {
              content = <Alert severity={className}>{notification.message ? (i18n.exists(notification.message) ? t(notification.message, notification.context) : notification.message) : t("error.internal")}
                {notification.details && <p style={{ fontSize: 10 }}><br />{notification.details}</p>} {count > 1 ? `(${count})` : ""}
              </Alert>
            }
          }
          return <React.Fragment key={key}>
            {content}
          </React.Fragment>
        }
      })}

    </div>
  </Snackbar>;

  return [dispatchNotification, errorMessage];
}