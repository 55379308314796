
import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { getCompetitions } from '../../Backend/ApiCompetition';
import { IApiCompetitionModel, ICompetitionFilter } from '../../Model/ApiCompetitionModel';
import { IAuthentication } from '../Auth/auth';
import { IEventNotification } from "../Util/ErrorMessage";

export function AutocompleteCompetition(props: {
    dispatchNotification: React.Dispatch<IEventNotification>,
    onSelect: (competition: undefined | IApiCompetitionModel) => void,
    competitionSelected: undefined | IApiCompetitionModel,
    competitionFilter: ICompetitionFilter,
    authentication: IAuthentication
}): JSX.Element {
    const [competitionList, setCompetitionList] = useState<Array<IApiCompetitionModel> | undefined>();
    const [competitionSelected, setCompetitionSelected] = useState<IApiCompetitionModel | undefined>(props.competitionSelected);

    const [dispatchNotification, onSelect, competitionFilter] = [props.dispatchNotification, props.onSelect, props.competitionFilter];

    useEffect(() => {
        if (competitionList === undefined) {
            getCompetitions(dispatchNotification, true, {
                username: (props.authentication.scopes.includes("cnkdr_admin") || props.authentication.scopes.includes("cnkdr_superadmin")) ? undefined : props.authentication.name,
                fromDate: competitionFilter.pastEvent ? undefined : new Date(),
                withClosed: competitionFilter.closedEvent,
                type: "competitionANDgroup"
            }).then((cs: IApiCompetitionModel[]) => {
                setCompetitionList(cs);
            });
        }

    }, [competitionList, dispatchNotification, competitionFilter]);

    useEffect(() => {
        if (competitionList) {
            onSelect(props.competitionSelected ? competitionList.find(c => c.id === props.competitionSelected?.id) : undefined);
            setCompetitionSelected(props.competitionSelected ? competitionList.find(c => c.id === props.competitionSelected?.id) : undefined);
        }
    }, [props.competitionSelected, competitionList, onSelect])



    return <Autocomplete
        id="competitionSearch"
        value={competitionSelected}
        onChange={(event: any, newValue: IApiCompetitionModel | null) => {
            setCompetitionSelected(newValue ?? undefined);
            props.onSelect(newValue ?? undefined);
        }}
        getOptionLabel={(option) => `${option.fullname} ${option.place} ${option.competitionDate}`}
        options={competitionList ?? []}
        loading={competitionList === undefined}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} fullWidth={true} label="Competition" />}
    />
        ;

}