import { Select } from '@mui/material';
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { TApiEditCompetitionType } from '../../Model/ApiCompetitionModel';


export const eventTypeList: Array<TApiEditCompetitionType> = [
    "group", "competition"
]

export function EventType(props: {
    value?: TApiEditCompetitionType,
    onChange: (event: any) => void
}) {
    const { t } = useTranslation();

    return <Select
        fullWidth={true}
        label={t(`label.competition.Type`)}
        value={props.value}
        onChange={props.onChange}
    >
        <MenuItem value={undefined}>{t(`label.competition.type.undefined`)}</MenuItem>
        {eventTypeList.map((v, k) => {
            return <MenuItem key={k} value={v}>{t(`label.competition.type.${v}`)}</MenuItem>
        })}
    </Select>;
}

export function EventTypeEditInputCell(props: GridRenderCellParams) {
    const { id, value, api, field } = props;

    const handleChange = (event: any) => {
        api.setEditCellValue({ id, field, value: event.target.value }, event);
        // Check if the event is not from the keyboard
        // https://github.com/facebook/react/issues/7407
        /*if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
            api.commitCellChange({ id, field });
            api.setCellMode(id, field, 'view');
        }*/
        api.commitCellChange({ id, field });
        api.setCellMode(id, field, 'view');
    };

    return (
        <div>
            <EventType
                value={value as TApiEditCompetitionType}
                onChange={handleChange}
            />
        </div>
    );
}

export function renderEventTypeEditInputCell(params: any) {
    return <EventTypeEditInputCell {...params} />;
}