import { emptyFightTeamState } from "../../components/Team/FightTeam";
import { IChronometerActions } from "../../components/Util/Chronometer";
import { IApiResult } from "../ApiCall";
import { IApiFighterModel } from "./ApiFighterModel";
import { IApiRoundModel } from "./ApiRoundModel";


export enum EFightStatus {
    WAIT = 0,
    READY,
    INPROGRESS,
    FINISHED,
    UNDEFINED,
    //when can't be calculated because rounds are not loaded from db
}

export enum EFightType {
    STANDARD = "standard",
    ADDITIONAL = "additional"
}
export interface IFightTeamState {
    id: number,
    externalId: number,
    name: string,
    fighters: IApiFighterModel[],
    fighterOrderDate: Date | null,
    nationality?: string
}

export interface IApiFightCreationModel {
    redFightTeamExternalId: number;
    whiteFightTeamExternalId: number;
    //redFightTeam?: FightTeamModel;
    //whiteFightTeam?: FightTeamModel;
    //redFightTeamId?: number;
    //whiteFightTeamId?: number;
    type?: EFightType,
    order: number
}

export interface IApiFightModel {
    keyname: number;
    id: number;
    name: string;
    redFightTeam: IFightTeamState;
    whiteFightTeam: IFightTeamState;
    rounds?: IApiRoundModel[];
    currentRoundId: number | null;
    active: boolean;
    chrono: IChronometerActions | null;
    status: EFightStatus;
    reload(): void;
    type: EFightType;
    withEncho: boolean;
    ready: boolean;
    reloadKey?: number;
    order: number;
}


export interface IApiFightResult extends IApiResult {
    data: IApiFightModel;
}

export const emptyApiFightCreationModel: IApiFightCreationModel = {
    redFightTeamExternalId: 0,
    whiteFightTeamExternalId: 0,
    order: 0
}

export const emptyApiFightModel: IApiFightModel = {
    keyname: 0,
    id: 0,
    name: "none",
    redFightTeam: emptyFightTeamState,
    whiteFightTeam: emptyFightTeamState,
    rounds: [],
    currentRoundId: 0,
    active: false,
    chrono: null,
    status: EFightStatus.UNDEFINED,
    reload: () => { },
    type: EFightType.STANDARD,
    withEncho: false,
    ready: false,
    order: 0
}
